import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { webResourceService } from 'services/webResourceService';
import { propertyCharacteristicService } from 'services/propertyCharacteristicService';
import ProgressStore from 'stores/progress';
import  PropertyTypeStore  from 'stores/type';
import  IslandStore  from 'stores/island';
import  LocaleStore  from 'stores/locale';
import { propertyTypesService } from 'services/propertyTypes';
import { islandsService } from 'services/islandService';
import { without, get, some } from 'lodash';
import WebResourceStore from 'stores/webResource';
import PropertyCharacteristicStore from 'stores/propertyCharacteristic';
import { name } from 'utils/localized';
import { UPDATE_PROPERTY } from 'constants/action';
import { Form, Select, Button, Row, Col, Switch, Tooltip } from 'antd';
import { SALE_VALUE, RENT_VALUE, TRASPASO_VALUE } from 'constants/mixed'

import { IWebResorce } from 'webResource';
import { IPropertyCharacteristic } from 'propertyCharacteristic';
import { BLOCKED_TYPES, MANDATORY_FIELDS, ACCESS_OPERATION } from 'constants/rules';
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

interface IData {
  prev?(params: any): any,
  promotionAttributes: {
    id: undefined | number,
    website_ids: Array<number>,
    portal_ids: Array<number>,
    class_ids: Array<number>,
    label_ids: Array<number>,
    feature_ids: Array<number>,
    hide_price: boolean,
    property_type_id: undefined | number
  };
  onCreateProperty(params: any, callback: Function): any,
  handleCancel?(): void,
  isNotSteper?: boolean,
  property: any
}

const { Option } = Select;

const Promotion: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<any>();
  const lng = LocaleStore.locale;

  const [promotion, setPropmotion] = useState<any>({
      website_ids: [],
      portal_ids: [],
      class_ids: [],
      label_ids: [],
      feature_ids: [],
      hide_price: false
    });
  const [loading, setLoading] =  useState(false);

  useEffect(() => {
    webResourceService.loadResources();
    propertyCharacteristicService.loadResources();
    propertyTypesService.loadResources()
    islandsService.loadResources()
    if (props.promotionAttributes) {
      setPropmotion(props.promotionAttributes)
      form.setFieldsValue(props.promotionAttributes);
    }
  }, [])

  useEffect(() => {
    if (props.promotionAttributes) {
      setPropmotion(props.promotionAttributes)
      form.setFieldsValue(props.promotionAttributes);
    }
  }, [props.promotionAttributes])

  const handleSubmit = async () => {
    form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      setLoading(true)
      const newValues = { ...promotion, ...values }
      props.onCreateProperty({ ...newValues }, () => setLoading(false))
    }
  }

  const validateOperationByResource = (resource_name: string) => {
    const { property } = props;
    if((ACCESS_OPERATION[resource_name] || []).includes(SALE_VALUE) && property.operation.includes(SALE_VALUE)) {
      return false;
    }
    if((ACCESS_OPERATION[resource_name] || []).includes(RENT_VALUE) && property.operation.includes(RENT_VALUE)) {
      return false;
    }

    if((ACCESS_OPERATION[resource_name] || []).includes(TRASPASO_VALUE) && property.operation.includes(TRASPASO_VALUE)) {
      return false;
    }

    return true
  }

  const validationSwitch = (resource: any) => {
    const resource_name = resource.name;
    const { property_type_id } = props.promotionAttributes;
    const { property } = props;
    const type: any = PropertyTypeStore.values.find((item: any) => item.id == property_type_id)
    const blockByType = (BLOCKED_TYPES[resource_name] || []).includes(type.name['en'])
    const blockByOperation = validateOperationByResource(resource_name);
    let blockByFields = false;
    let errors = [];

    if(MANDATORY_FIELDS[resource_name]) {
      MANDATORY_FIELDS[resource_name].map((item: { currentField: string , children: Array<string> }) => {
        if(blockByFields) return;
        item['children'].forEach((field: string) => {
          const propertyValue = item['currentField'] ? property[item['currentField']][field] : property[field];

          if(type.name['en'].includes('Land') && field != 'total_area') {
            blockByFields = false
            return
          }

          if(`${propertyValue || ''}`.trim().length == 0 || `${propertyValue}` == '0') {
            blockByFields = true
            return
          }
        })
      })
    }

    if(blockByType) {
      errors.push("Can't be published on portal because wrong type")
    }

    if(blockByFields) {
      errors.push(
        `Can't be published on portal because fields ${getMandatoryFields(resource_name)}`
      )
    }

    if(blockByOperation && resource.web_type == "portal") {
      errors.push("Can't be published on portal because wrong operation")
    }

    const islands_resource_name = ["canarianluxury.com", "tenerifecenter.com", "intenerife.eu"]
    const alicante_resource_name = ["alicantecenter.com"]

    const island = IslandStore.values.find((item) => item.id == property.location_attributes.island_id);

    if ((get(island, "name") == "Alicante" && islands_resource_name.includes(resource_name))) {
      errors.push("Only properties from Canarian islands can be published on this website")
    }

    if (get(island, "name") != "Alicante" && alicante_resource_name.includes(resource_name)) {
      errors.push("Only properties from Alicante can be published on this website")
    }

    if (get(island, "name") != "Tenerife" && resource_name == "intenerife.eu") {
      errors.push("Only properties from Tenerife can be published on this website")
    }

    return errors
  }

  const getMandatoryFields = (resource_name: string) => {
    if(MANDATORY_FIELDS[resource_name]) {
      const fields = MANDATORY_FIELDS[resource_name].map((item: { currentField: string , children: Array<string> }) => item['children'])
      return `${(fields || []).flat().join(' or ')} are empty`;
    }
    return 'some fields are empty'
  }

  const prevStep = (e: any) => {
    form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      const newValues = { ...promotion, ...values }
      if (props.prev) {
        props.prev({ ...newValues })
      }
    }
  }

  const onHandleChange = (checked: boolean, type: string, resource_id: number) => {
    const field = `${type}_ids`;
    const stateData: any = { ...promotion };
    const newValues = checked ? stateData[field].concat([resource_id.toString()]) : without(stateData[field], resource_id.toString())
    setPropmotion({ ...promotion, [field]: newValues })
  }

  const handleCancel = () => {
    if (props.handleCancel) {
      props.handleCancel()
    }
  }

  const getButtons = () => {
    let buttons;

    if (props.isNotSteper) {
      buttons = <div>
        <Button className='mr-10' type="primary" onClick={handleSubmit}
          loading={ProgressStore.isLoading(UPDATE_PROPERTY)}
        >
          {t('save')}
        </Button>
        <Button onClick={handleCancel}>{t('cancel')}</Button>
      </div>
    } else {
      buttons = <div>
        <Button className='mr-15' onClick={prevStep}><ArrowLeftOutlined /> {t('back')}</Button>
        <Button type="primary" loading={loading} onClick={handleSubmit}>
          {t('save')}
        </Button>
      </div>
    }

    return buttons;
  }

  const getTooltips = (resource_name: string) => {
    const tooltipTexts: any = {
      'pisos.com': 'text for tooltip'
    }

    if(tooltipTexts[resource_name]) return '';

    return tooltipTexts[resource_name];
  }

  const renderResources = (type: string) => {
    const webResources = WebResourceStore.groupedValues[type];
    const stateValues: any = promotion;
    const { property } = props;
    const island = IslandStore.values.find((item) => item.id == property.location_attributes.island_id);

    if (!webResources) {
      return null;
    }

    const switchItemLayout = {
      labelCol: { span: 18 },
      wrapperCol: { span: 6 },
    };
    return webResources.map((resource: IWebResorce) => {
        let errors: Array<string> = [];
        let isDisable = false
        errors = validationSwitch(resource);
        if(type == 'portal') {
          isDisable = errors.length > 0
          if ((get(island, "name") == "Alicante" && get(resource, "name") == "intenerife.eu")) {
            isDisable = true
          }
        } else {
          if (
            (get(island, "name") == "Alicante" && get(resource, "name") != "alicantecenter.com") ||
            (get(island, "name") != "Alicante" && get(resource, "name") == "alicantecenter.com")
          ) {
            isDisable = true
          }
        }


        return <Col span={8} className='pr-10'>
          <Form.Item
            {...switchItemLayout}
            label={resource && resource.name}
          >
            <Tooltip placement="top" title={errors.join(', ')}>
              <Switch
                checked={(stateValues[`${type}_ids`] || []).includes(resource.id.toString())}
                disabled={isDisable}
                onChange={(checked, event) => onHandleChange(checked, type, resource.id)}
              />
            </Tooltip>
          </Form.Item>
        </Col>
      }
    )
  }

  const renderCharacteristic = (type: string) => {
    const characteristics = PropertyCharacteristicStore.groupedValues[type];

    if (!characteristics) {
      return null;
    }

    return characteristics.map((char: IPropertyCharacteristic) => {
      return <Option value={char.id.toString()}>
        {name(char, lng)}
      </Option>
    })
  }

    const { hide_price } = promotion;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
    const formItemRow = {
      wrapperCol: { span: 14, offset: 6 }
    };

    return (
      <div>
        <div>
          <Form onFinish={handleSubmit} form={form}>
            <Form.Item
              {...formItemRow}
            >
              <h3>{t('properties.promotion')}</h3>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.labels')}
              name="label_ids"
            >
              <Select placeholder={t('properties.placeholder.labels')} mode='multiple' filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {renderCharacteristic('label')}
              </Select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.classes')}
              name="class_ids"
            >
              <Select placeholder={t('properties.placeholder.classes')} mode='multiple' filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {renderCharacteristic('class')}
              </Select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.hidePrice')}
              name="hide_price"
              valuePropName='checked'
            >
              <Switch defaultChecked={hide_price} />
            </Form.Item>
            <Form.Item
              {...formItemRow}
            >
              <h3>{t('properties.websites')}</h3>
            </Form.Item>
            <Row>
              {renderResources('website')}
            </Row>
            <Form.Item
              {...formItemRow}
            >
              <h3>{t('properties.realPortal')}</h3>
            </Form.Item>
            <Row>
              {renderResources('portal')}
            </Row>
          </Form>
        </div>
        <Row justify="end" className='pl-30 pr-30'>
          {getButtons()}
        </Row>
      </div>
    )

})

export default Promotion;
