import { FC, useState, useEffect, useCallback} from 'react';
import { observer } from 'mobx-react';
import { debounce, get, isEmpty, some, startCase, toLower } from 'lodash';
import { sourceService } from 'services/sourceService';
import { Link } from 'react-router-dom';
import SourceStore from 'stores/source';
import workspaceStore from 'stores/workspace';
import { useTranslation } from 'react-i18next';
import clientStore from 'stores/client';
import { validate } from 'services/ClientService';
import classNames from 'classnames';
import { Form, Button, Input, Select, Row, Col, message, Tooltip } from 'antd';
import { LANGUAGES, REGEXP_EMAIL } from "constants/mixed"
import { CloseOutlined, PlusOutlined, HomeOutlined, UserOutlined, SolutionOutlined } from "@ant-design/icons";

import { keys } from 'lodash';

import { IClient } from "declarations/contact";
import { ISource } from "declarations/source";

interface IData {
  client?: IClient,
  onCreateClient?(params: IClient): void;
  onCancelButton(): void;
}

interface IClientInfo {
  id?: null | number,
  value?: string,
  _destroy?: boolean
}

const { Option, OptGroup } = Select;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const formItemRow = {
  wrapperCol: { sm: {span: 18, offset: 6} }
};
const InitialState = {
  client: {
    id: null,
    client_type: '0',
    name: '',
    website: '',
    address: '',
    client_person: '',
    nie: '',
    source_id: undefined,
    languages: [],
    other_languages: [],
    phones_attributes: [{ value: '', phonable_type: "Client" }],
    emails_attributes: [{ value: '', letterable_type: "Client" }]
  }
}
const ClientForm:  FC<IData> = observer(({client, onCancelButton, onCreateClient}) => {

  const [state, setState] = useState<any>({...InitialState});
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<{[key: string]: any}>({});


  useEffect(() => {
    setState({...InitialState})
    form.resetFields();
    if (client) {
      form.setFieldsValue({ ...client, client_type: String(client.client_type) })
      setState({
        ...state,
        client: {
          ...state.client,
          ...client
        }
      })
    }
    sourceService.loadResources()
    return () => {
      form.resetFields()
      setState(InitialState)
    }
  }, [])

  useEffect(() => {
    if (client) {
      form.setFieldsValue({ ...client, client_type: String(client.client_type) })
      if (client.emails_attributes) {
        let errorsValue = {}
        client.emails_attributes.map(({value}) =>
          validate(value, workspaceStore.selectedId as number, client?.id).catch(({response}: any) => {
            const respData = response.data;
            errorsValue = { ...errorsValue, [value]: respData }
            setErrors({ ...errors, ...errorsValue})
          })
        )
      };
      setState({
        ...state,
        client: {
          ...state.client,
          ...client
        }
      })
    }
  }, [state.client?.id])

  useEffect(() => {
    form.resetFields();
    setErrors({});
  }, [workspaceStore.selectedId])

  const handleSubmit = async () => {
    clientStore.clearErrors();
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      const clientValue: IClient = { ...state.client, ...values };
      clientValue['emails_attributes'] = clientValue['emails_attributes'].filter((item: { value: string }) => item.value)
      clientValue['phones_attributes'] = clientValue['phones_attributes'].filter((item: { value: string }) => item.value)
      if (state.client.id) {
        await clientStore.updateClient(state.client.id, clientValue)
        if(!clientStore.saveError) {
          message.success('Client updated!')
          onCancelButton();
        } else {
          console.log("🚀 ~ file: Form.tsx:128 ~ handleSubmit ~ clientStore.saveError:", clientStore.saveError)
          message.error(clientStore.saveError.response.data.message)
        }
      }

      if (onCreateClient && !state.client.id) {
        onCreateClient(clientValue)
      }
    }
  }

  const removeClientInfo = (attributesTitle: string, elIndex: number) => {
    const client: any = state.client;
    const itemObject = client[attributesTitle][elIndex];

    if (itemObject.id) {
      const newItem = { ...itemObject, _destroy: 'true' }
      const attributesArray = client[attributesTitle].map((item: IClientInfo) => item.id == itemObject.id ? newItem : item)

      setState({
        ...state,
        client: {
          ...state.client,
          [attributesTitle]: attributesArray
        }
      })
    } else {
      const newItems = client[attributesTitle].filter((item: IClientInfo, index: number) => index != elIndex);
      setState({
        ...state,
        client: {
          ...state.client,
          [attributesTitle]: newItems
        }
      })
    }
  }

  const addClientInfo = (type: string) => {
    const client: any = state.client;
    const attributesTitle = `${type}s_attributes`;
    setState({
      ...state,
      client: {
        ...state.client,
        [attributesTitle]: [...client[attributesTitle], ...[{ id: null, [`${type === "email" ? "email" : "phon"}able_type`]: "Client",  value: '' }]]
      }
    })
  }

  const debouncedValidate = useCallback(
    debounce((email, allErrors) => {
      validate(email, workspaceStore.selectedId as number, client?.id).catch(({response}: any) => {
        const respData = response.data;
        setErrors({ ...allErrors, [email]: respData })
      })
    }, 500), []
  );

  const handleInfoChange = (e: any, field: string, index: number) => {
    const client: any = state.client;
    const value = e.target.value;
    const newValue = { ...client[field][index], value: value, }
    setState({
      ...state,
      client: {
        ...state.client,
        [field]: client[field].map((item: IClientInfo, indexItem: number) => index == indexItem ? newValue : item)
      }
    })
    setErrors({ ...errors, [value]: undefined })
    if (value.match(REGEXP_EMAIL)) {
      debouncedValidate(value, errors)
    }
  }

  const onTypeChange = (value: string) => {
    setState({
      ...state,
      client: {
        ...state.client,
        client_type: value
      }
    })
  }

  const getErrors = (email?: string) => {
    const errorsData = email && errors[email];
    if (errorsData){
      return <div>
        {errorsData.map((item: any) =>
        <div>
          <span>{t(`clients.new.${item.type}Exists`)} <Link target="_blank" to={`/${item.type}s/${item.resource_id}/overview`}>{t("clients.new.viewDetails")} ({workspaceStore.getWorkspaceName(item.workspace_id) || t("anotherWorkspace")})</Link></span>
        </div>)}
      </div>
    }
    return undefined;
  }

  const renderClientInfo = (type: string) => {
    const client: any = state.client;
    const attributesTitle = `${type}s_attributes`;
    const lableTitle = startCase(toLower(type));

    return client[attributesTitle].map((item: IClientInfo, index: number) => {
      if (item._destroy) {
        return;
      }

      return (
        <Form.Item shouldUpdate noStyle>
          {() => (
            <Form.Item
              {...formItemLayout}
              label={index == 0 ? (lableTitle + " :") : ' '}
              className={classNames({ 'more-client-items': index != 0 })}
              colon={false}
              status="error"
              rules={type === "email" ? [{ type: 'email' }] : []}
              help={<span className="mb-20 text-orange">{getErrors(item.value)}</span>}
            >
              <Row align='middle' className='fx-nw'>
                <Input value={item.value} placeholder={lableTitle} type={type} onChange={(e) => handleInfoChange(e, attributesTitle, index)} />
                <CloseOutlined className='ml-10' onClick={() => removeClientInfo(attributesTitle, index)} />
              </Row>
            </Form.Item>
          )}
        </Form.Item>
      )
    })
  }

  const renderSources = () => {
    const sources = SourceStore.groupedValues;
    const sourceKeys = keys(sources)

    return sourceKeys.map((category: string) => {
      return <OptGroup key={`source_${category}`} label={category}>
        {sources[category].map((source: ISource) => <Option value={source.id} key={`source_${source.id}`}>{source.name}</Option>)}
      </OptGroup>
    })
  }

  return (
    <div>
      {/* {contextHolder} */}
      <div className="property-steps-container">
        <Form form={form}>
          <Form.Item
            {...formItemLayout}
            label={t('clients.filter.type')}
            name="client_type"
            initialValue="0"
          >
            <Select
              onChange={onTypeChange}
              value={String(state.client?.client_type || "")}
              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option value="0"><UserOutlined className='mr-5' />{t('clients.new.type.person')}</Option>
              <Option value="1"><HomeOutlined className='mr-5' />{t('clients.new.type.company')}</Option>
              <Option value="2"><SolutionOutlined className='mr-5' />{t('clients.new.type.agency')}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={t('clients.new.name')}
            name="name"
            rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
          >
            <Input placeholder={t('clients.new.name')} value={state.client?.name} />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({getFieldValue}) => {
              const isNotPerson = getFieldValue("client_type") !== "0";
              return isNotPerson &&
                <Form.Item
                  {...formItemLayout}
                  label={t('clients.new.website')}
                  name="website"
                >
                  <Input placeholder={t('clients.new.website')} />
                </Form.Item>
            }}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({getFieldValue}) => {
              const isNotPerson = getFieldValue("client_type") !== "0";
              return isNotPerson &&
                <Form.Item
                  {...formItemLayout}
                  label={t('clients.new.address')}
                  name="address"
                >
                  <Input placeholder={t('clients.new.address')} value={state.client?.address} />
                </Form.Item>
            }}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({getFieldValue}) => {
              const isNotPerson = getFieldValue("client_type") !== "0";
              return isNotPerson &&
                <Form.Item
                  {...formItemLayout}
                  label={t('clients.new.contactPerson')}
                  name="client_person"
                >
                  <Input placeholder={t('clients.new.contactPerson')} value={state.client.client_person} />
                </Form.Item>
            }}
          </Form.Item>
          {renderClientInfo('email')}
          <Form.Item
            {...formItemRow}
          >
            <a className='add-item-link' onClick={() => addClientInfo('email')}>
              <PlusOutlined className='mr-5' />
              {t('clients.new.email')}
            </a>
          </Form.Item>
          {renderClientInfo('phone')}
          <Form.Item
            {...formItemRow}
          >
            <a className='add-item-link' onClick={() => addClientInfo('phone')}>
              <PlusOutlined className='mr-5' />
              {t('clients.new.phone')}
            </a>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="languages"
            label={t('clients.new.mainLang')}
            initialValue={[]}
          >
            <Select mode="multiple" filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {LANGUAGES.map((lng: string) => (
                <Option key={`languages-${lng}`} value={lng}>{t(`contacts.new.lng.${lng}`)}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('contacts.new.otherLang')}
            name="other_languages"
          >
            <Select mode="multiple" filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {LANGUAGES.map((lng: string) => (
                <Option key={`other-languages-${lng}`} value={lng}>{t(`contacts.new.lng.${lng}`)}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('contacts.new.nie')}
            name="nie"
          >
            <Input placeholder={t('contacts.new.nie')} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('contacts.new.source')}
            name="source_id"
            rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
          >
            <Select
              placeholder={t('contacts.new.placeholderSource')}
              filterOption={(input: any, option: any) => typeof(option.props.children) == 'string' && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              showSearch
            >
              {renderSources()}
            </Select>
          </Form.Item>
        </Form>
        <Row>
          <Col sm={{ offset: 6 }}>
            <Button type='primary' className='mr-15' onClick={handleSubmit}>{t('save')}</Button>
            <Button onClick={onCancelButton}>{t('cancel')}</Button>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default ClientForm;
