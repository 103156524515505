import { FC, useState, useEffect} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Select, DatePicker, Row, Col, InputNumber } from 'antd';
import { dealService } from 'services/DealService';
import { propertyTypesService } from 'services/propertyTypes';
import UserStore from 'stores/user';
import PropertyTypeStore from 'stores/type';
import { userListService } from 'modules/users/containers/UserListService';
import workspaceStore from 'stores/workspace';

import { IUser } from "declarations/user";
import { PropertyType } from "declarations/propertyType";
import { omit } from 'lodash';
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const  DEFAUL_FILTER_VALUE = {
  operations: [],
  statuses: [],
  budget_min: null,
  type_ids: [],
  budget_max: null,
  manager_ids: [],
  property_status: [],
  change_status_date: null,
  creation_date: null
}

interface IData {
  lng: string;
}

const FilterDeal: FC<IData> = observer((props) => {

  const [filterKeys, setFilter] =  useState(DEFAUL_FILTER_VALUE)
  const [form] = Form.useForm();
  const { t } = useTranslation();


  useEffect(() => {
    propertyTypesService.loadResources();
    userListService.loadResources({}, 'all');
    form.resetFields();
  }, [workspaceStore.selectedId])

  const resetFilters = () => {
    form.resetFields();
    setFilter(DEFAUL_FILTER_VALUE)
    dealService.resetFilters();
  }

  const getFieldChange = async (value: any, name: string) => {

    await setFilter({...filterKeys, [name]: value})

    const dates: any = filterKeys.creation_date;
    let newFilterKeys = {...filterKeys, [name]: value};
    omit(newFilterKeys, "creation_date");
    let dataFilter = {'date_from':  null, 'date_to': null};
    if (dates) {
      dataFilter = { 'date_from':  dates[0], 'date_to': dates[1] };
    }
    newFilterKeys = {...newFilterKeys, ...dataFilter};
    dealService.changeFilters(newFilterKeys)
  }

  const onFilterDate = async (dates: any, key: string) => {
    await setFilter({...filterKeys, [key]: dates})
    let newFilterKeys = {filterKeys};
    omit(newFilterKeys, "creation_date");
    let dataFilter;
    if(dates && dates.length) {
      dataFilter = { [key]: { 'date_from':  dates[0], 'date_to': dates[1] } }
    } else {
      dataFilter = { [key]: null }
    }
    newFilterKeys = {...newFilterKeys, ...dataFilter}
    console.log("🚀 ~ file: FilterDeals.tsx:81 ~ onFilterDate ~ newFilterKeys:", newFilterKeys)
    dealService.changeFilters(newFilterKeys)
  }

  const renderUsers = () => {
    const users = UserStore.values;
    return (users || []).map((user: IUser) => <Option value={user.id} key={`user_${user.id}`}>{user.name}</Option>)
  }

  const renderTypes = () => {
    const lng: any = props.lng;
    const types : any = PropertyTypeStore.values;
    return (types || []).map((type: PropertyType) => {
      const name: any = type.name;
      return <Option value={type.id} key={`type_${type.id}`}>{ name[lng] }</Option>
    })
  }
    return (
    <Form className='filter-form' form={form}>
      <Form.Item
        {...formItemLayout}
        label={t('deals.new.operation')}
        name="operations"
      >
        <Select
          showArrow={true}
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'operations')}
          placeholder={t('placeholder.all')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value="0" key='operations_0'>{t('deals.new.dealOperation.sale')}</Option>
          <Option value="1" key='operations_1'>{t('deals.new.dealOperation.rent')}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('properties.table.status')}
        name="statuses"
      >
        <Select
          showArrow={true}
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'statuses')}
          placeholder={t('placeholder.all')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
          <Option value="0" key='statuses_0'>{t('deals.new.dealStatus.active')}</Option>
          <Option value="1" key='statuses_1'>{t('deals.new.dealStatus.sold')}</Option>
          <Option value="2" key='statuses_2'>{t('deals.new.dealStatus.canceled')}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('deals.new.propertyType')}
        name="type_ids"
      >
        <Select
          showArrow={true}
          onChange={(value: any) => getFieldChange(value, 'type_ids')}
          mode='multiple'
          showSearch
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          placeholder={t('placeholder.all')}
        >
          {renderTypes()}
        </Select>
      </Form.Item>
      <Row justify='space-between'>
        <Col span={11}>
          <Form.Item
            {...formItemLayout}
            label={t('deals.new.budget')}
            name="budget_min"
          >
            <InputNumber
              onChange={(value: any) => getFieldChange(value, 'budget_min')}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '\.')}
              parser={(value: any) => value.replace(/(\.*)/g, '')}
              placeholder="min price"
              className='w-100p'
            />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            {...formItemLayout}
            name="budget_max"
            className='pt-35'
          >
            <InputNumber
              onChange={(value: any) => getFieldChange(value, 'budget_max')}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '\.')}
              parser={(value: any) => value.replace(/(\.*)/g, '')}
              placeholder="max price"
              className='w-100p'
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        {...formItemLayout}
        label={t('manager')}
        name="manager_ids"
      >
        <Select
          showArrow={true}
          mode='multiple'
          showSearch
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value: any) => getFieldChange(value, 'manager_ids')}
          placeholder={t('placeholder.all')}
        >
          {renderUsers()}
        </Select>
      </Form.Item>
      <Form.Item
        className='mb-10'
        {...formItemLayout}
        label={t('deals.new.propertyStatus')}
        name="property_status"
      >
        <Select
          showArrow={true}
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'property_status')}
          placeholder={t('placeholder.all')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value="reservation" key='status_0'>{t('deals.new.statusList.reserved')}</Option>
          <Option value="contract" key='status_1'>{t('deals.new.statusList.contract')}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={''}
        name="change_status_date"
      >
        <DatePicker.RangePicker
          format="DD.MM.YYYY"
          onChange={(dates: any) => onFilterDate(dates, 'change_status_date')}
          className="w-100p"
          />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('deals.filter.creationDate')}
        name="creation_date"
        className="w-100p"
      >
        <DatePicker.RangePicker
          format="DD.MM.YYYY"
          onChange={(dates: any) => onFilterDate(dates, 'creation_date')}
          className="w-100p"
        />
      </Form.Item>
      <Form.Item
      >
        <Button type='primary' onClick={resetFilters} style={{ width: '100%' }}>
        {t('deals.filter.resetFilter')}
        </Button>
      </Form.Item>
    </Form>
  )
});

export default FilterDeal;
