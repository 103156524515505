import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import {reportsService} from 'services/reportService';
import ProgressStore from 'stores/progress';
import ReportStore from 'stores/report';
import { useTranslation } from 'react-i18next';
import { Tooltip, Table, Row, Col, Card, DatePicker } from 'antd';
import { LOAD_REPORTS } from 'constants/action';
import { Helmet } from 'react-helmet';
import { InfoCircleOutlined, DownloadOutlined, AreaChartOutlined } from "@ant-design/icons";


const { RangePicker } = DatePicker;

const Sale: FC = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    reportsService.loadResources('sale', {})
    return () => ReportStore.clearData();
  }, [])

  const onFilterDate = (dates: any) => {
    let dataFilter = {};
    dataFilter = { 'date_from':  dates[0], 'date_to': dates[1] }
    reportsService.loadResources('sale', dataFilter)
  }

  const changeDateFilter = (dates: any) => {
    if(!(dates[0] && dates[1])) {
      reportsService.loadResources('sale', {})
      return
    }
    onFilterDate(dates)
  }

  const columns = [
    {
      title: t('reports.rank'),
      dataIndex: 'rank',
      key: 'rank'
    }, {
      title: t('manager'),
      dataIndex: 'manager_name',
      key: 'manager_name'
    }, {
      title: t('menu.deals'),
      dataIndex: 'deals',
      key: 'deals',
    }, {
      title: t('reports.totalSales'),
      dataIndex: 'total_sales',
      key: 'total_sales',
    }, {
      title: t('reports.leadProvided'),
      dataIndex: 'lead_provided',
      key: 'lead_provided',
    }, {
      title: t('reports.propertyProvided'),
      dataIndex: 'property_provided',
      key: 'property_provided',
    }, {
      title: t('reports.leadProperty'),
      dataIndex: 'lead_property',
      key: 'lead_property',
    }, {
      title: t('reports.canceledDeals'),
      dataIndex: 'canceled_deals',
      key: 'canceled_deals',
    }
  ];

  const getData = () => {
    const values = ReportStore.values && ReportStore.values.managers;
    // console.log('user = ', user);
    return (values || []).map((item: any, index: number) => {
      return {
        rank: index + 1,
        manager_name: item.manager_name,
        deals: item.deals,
        total_sales: item.total_sales,
        lead_provided: item.lead_provided,
        property_provided: item.property_provided,
        lead_property: item.lead_property,
        canceled_deals: item.canceled_deals
      }
    })
  }

  const reportData = ReportStore.values;
  const tooltipTexts: any = {
    total_sales: t('reports.totalSales'),
    lead_provided: t('reports.leadProvided'),
    property_provided: t('reports.propertyProvided'),
    lead_property: t('reports.leadProperty')
  }

  if(!reportData) {
    return null;
  }
  return (
    <div className="expense-page page">
      <Helmet>
        <title>{t("reports.saleReport")}</title>
      </Helmet>
      <Row align='middle' justify='space-between' className='mb-40'>
        <Col>
          <h3>{t('reports.saleReport')}</h3>
        </Col>
        <Col>
        <RangePicker
          // ranges={{
          //   Today: [moment(), moment()],
          //   'This Month': [moment().startOf('month'), moment().endOf('month')],
          // }}
          // format="DD.MM.YYYY HH:mm:ss"
          onOk={onFilterDate}
          onChange={changeDateFilter}
          />
        </Col>
      </Row>
      <Row gutter={30} justify='space-between' className='mb-30'>
        <Col span={6}>
          <Card
            title={t('reports.totalSales')}
            extra={<Tooltip placement="top" title={tooltipTexts['total_sales']}><InfoCircleOutlined /></Tooltip>}
          >
            <div className='fs-25'>{reportData.total_sales}</div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            title={t('reports.leadProvided')}
            extra={<Tooltip placement="top" title={tooltipTexts['lead_provided']}><InfoCircleOutlined /></Tooltip>}
            >
            <div className='fs-25'>{reportData.lead_provided}</div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            title={t('reports.propertyProvided')}
            extra={<Tooltip placement="top" title={tooltipTexts['property_provided']}><InfoCircleOutlined /></Tooltip>}
          >
            <div className='fs-25'>{reportData.property_provided}</div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            title={t('reports.leadProperty')}
            extra={<Tooltip placement="top" title={tooltipTexts['lead_property']}><InfoCircleOutlined /></Tooltip>}
          >
            <div className='fs-25'>{reportData.lead_property}</div>
          </Card>
        </Col>
      </Row>
      <Row align='middle' justify='space-between' className='pxy-20 report-table-header'>
        <Col>
          <div className='fs-16'>
            <AreaChartOutlined className='mr-20 fs-20' />
              {t('reports.topManagers')}
          </div>
        </Col>
        <Col>
          <span className='c-p'><DownloadOutlined className='fs-20' /></span>
        </Col>
      </Row>
      <div className='page-content'>
        <Table
          dataSource={getData()}
          columns={columns}
          className='general-table'
          loading={ProgressStore.isLoading(LOAD_REPORTS)}
          bordered
          pagination={false}
        />
      </div>
    </div>
  );
})

export default Sale;
