import { FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ClientForm from '../../components/New/Form';
import clientStore from 'stores/client';
import { useNavigate } from "react-router-dom"
import { message } from 'antd';

import { IClient } from "declarations/contact";

interface IData {}

const New: FC<IData> = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const onCreateClient = async (params: IClient) => {
    await clientStore.createClient(params)
    if (!clientStore.saveError) {
      navigate('/clients')
      clientStore.fetchClients();
      messageApi.success('Client created!')
    } else {
      messageApi.error(clientStore.saveError.response.data.message)
    }
  }

  const onCancelButton = () => {
    navigate('/clients');
  }


  return (
    <div className="edit-contact-page page">
      {contextHolder}
      <div className='mb-30'>
        <h3 className='ta-c'>{t('clients.newClient')}</h3>
      </div>
      <div>
        <div>
          <ClientForm onCreateClient={onCreateClient} onCancelButton={onCancelButton} />
        </div>
      </div>
    </div>
  );
})

export default New;
