import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { name } from 'utils/localized';
import WebResourceStore from 'stores/webResource';
import LocaleStore from 'stores/locale';
import { webResourceService } from 'services/webResourceService';
import { propertyService } from 'modules/properties/PropertyService';
import { propertyCharacteristicService } from 'services/propertyCharacteristicService';
import PropertyCharacteristicStore from 'stores/propertyCharacteristic';
import PromotionForm from 'modules/properties/containers/New/Steps/Promotion'
import { Row, Col, Switch, Tag } from 'antd';
import { IPropertyCharacteristic } from 'propertyCharacteristic';
import { IWebResorce } from 'webResource';
import { Property as IProperty } from 'declarations/property';
import { CheckOutlined, EditOutlined } from "@ant-design/icons";

import { COLORS } from 'constants/mixed'
import { useTranslation } from 'react-i18next';

interface IData {
  property: any
}

const Promotion: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const lng = LocaleStore.locale;


  useEffect(() => {
    propertyCharacteristicService.loadResources();
    webResourceService.loadResources();
  }, [])

  const handleEditCancel = () => setIsEdit(false);

  const handleEdit = () => setIsEdit(true);

  const getPromotionValue = (key: string) => {
    const details = props.property.detail;
    const detailValueCount = details && details[`${key}_count`] || 0;
    const detailValueArea = details && details[`${key}_area`] || 0;
    return <span className='details-value'>{detailValueCount} ({detailValueArea}m²)</span>
  }

  const getCharacteristic = (type: string) => {
    const { property } = props;
    const groupChar = PropertyCharacteristicStore.groupedValues;
    if (!groupChar[type]) {
      return null;
    }
    const characteristics = groupChar[type].filter((item: IPropertyCharacteristic) => (property[`${type}_ids`] || []).includes(item.id.toString()))

    if (characteristics && characteristics.length == 0) {
      return '-'
    }

    return characteristics.map((char: IPropertyCharacteristic) => {
      return <Col className='mb-10'><Tag color={COLORS[1]}>{name(char, lng)}</Tag></Col>
    })
  }

  const onCreateProperty = (params: any) => {
    propertyService.updateResource('properties', params, handleEditCancel)
  }

  const renderContent = () => {
    const {
      id, website_ids, portal_ids, class_ids, label_ids, feature_ids, hide_price, property_type_id
    } = props.property;
    let content;

    if (isEdit) {
      content = <PromotionForm
        promotionAttributes={{ website_ids, portal_ids, class_ids, label_ids, feature_ids, hide_price, id, property_type_id }}
        isNotSteper
        handleCancel={handleEditCancel}
        onCreateProperty={onCreateProperty}
        property={props.property}
      />
    } else {
      content = staticContent()
    }

    return content;
  }

  const getUrl = (property: IProperty, resource: IWebResorce) => {
    const website_urls: any = property.website_urls;
    const urls: any = website_urls[resource.id];
    if(!urls) return '';
    return urls['sale_url'] || urls['rent_url'] || urls['url'] || ''
  }

  const renderResources = (type: string) => {
    const { property } = props;
    const webResources = WebResourceStore.groupedValues[type];

    if (!webResources) {
      return null;
    }

    return webResources.map((resource: IWebResorce) =>
      <Col span={7} className={classNames('mb-10', { 'op-25': !(property[`${type}_ids`] || []).includes(resource.id.toString()) })}>
        <span className='mr-5'>{resource.name}</span>
        {(property[`${type}_ids`] || []).includes(resource.id.toString()) &&
          <span>
            <CheckOutlined className='rent mr-5' />
            {(type === 'website' || (type === 'portal' && resource.name == 'rightmove.co.uk')) &&
              <span><a href={getUrl(property, resource)} target='_blank'>({t('view')})</a></span>
            }
          </span>
        }
      </Col>
    )
  }

  const staticContent = () => {
    const { property } = props;

    return (
      <div className='section-content'>
        <Row>
          <Col span={7}>
            <h5 className='mb-10'> {t('properties.new.labels')} </h5>
            <Row>{getCharacteristic('label')}</Row>
          </Col>
          <Col span={7}>
            <h5 className='mb-10'> {t('properties.new.classes')} </h5>
            <Row>{getCharacteristic('class')}</Row>
          </Col>
          <Col span={7}>
            <h5 className='mb-10'> {t('properties.new.hidePrice')}</h5>
            <Switch
              defaultChecked={property.hide_price}
              disabled
            />
          </Col>
        </Row>

        <div>
          <h5 className='mt-40 mb-20'> {t('properties.websites')} </h5>
          <Row justify='space-between'>
            {renderResources('website')}
          </Row>
        </div>
        <div>
          <h5 className='mt-40 mb-20'> {t('properties.realPortal')}</h5>
          <Row justify='space-between'>
            {renderResources('portal')}
          </Row>
        </div>
      </div>
    )
  }

  return (
    <div className="contact-card-item">
      <Row justify='space-between' align='middle' className='contact-card-header'>
        <h5>{t('properties.new.steps.promotion')}</h5>
        <EditOutlined onClick={handleEdit} />
      </Row>
      <div className="contact-card-content">
        {renderContent()}
      </div>
    </div>
  )
})

export default Promotion;
