import { FC } from 'react';
import { observer} from 'mobx-react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

interface IProps {
  link: string;
}


const ListContainer: FC<IProps> = observer(({ link }) => {
  const { t } = useTranslation();

  return (
    <div className='noAccess'>
        <div>
          <h1>403</h1>
          <div className='hint'>{t("noAccess")}</div>
            <Link to={link}>
              <Button className='mt-10 w-100'>
                {t('back')}
              </Button>
            </Link>
        </div>
      </div>
  );
});

export default ListContainer;
