import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { propertyService } from '../../PropertyService';

import { webResourceService } from 'services/webResourceService';
import propertyStore from 'stores/property';
import WebResourceStore from 'stores/webResource';
import General from '../New/Steps/General';
import Details from '../New/Steps/Details';
import Address from '../New/Steps/Address';
import Price from '../New/Steps/Price';
import Promotion from '../New/Steps/Promotion';
import Gallery from '../Single/components/Tabs/GalleryForm';
import Description from '../New/Steps/Description';
import { Tabs, Form } from 'antd';
import { validationResource } from 'utils/promValidation'
import { useParams, useNavigate} from "react-router-dom";

const { TabPane } = Tabs;

const  New: FC = observer(() => {
  const { t } = useTranslation();
  const match = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    const propertyId: any = match.id;
    if (propertyId) {
      propertyService.loadResource(propertyId)
      webResourceService.loadResources();
    }
  }, [])

  const handleCancel = () => {
    const propertyId: any = match.id;
    navigate(`/properties/${propertyId}/overview`);
  }

  const validateChanges = (params: any): Array<string> => {
    const property: any = propertyStore && propertyStore.selected;
    const newProperty = {...property, ...params}
    if(newProperty.portal_ids.length == 0) {
      return []
    }
    const resources = WebResourceStore.values;
    const propertyResources = resources.filter((resource: any) => newProperty.portal_ids.includes(resource.id.toString()) )

    return propertyResources.map((res: any) => validationResource(res.name, newProperty)).flat()
  }

  const onCreateProperty = (params: any) => {
    propertyService.updateResource('properties', params, handleCancel)
  }
  const property = propertyStore.selected;
  const {
    id, website_ids, portal_ids, class_ids, label_ids, feature_ids, hide_price, property_type_id
  } = property;

  return (
    <div className="page new-property-page">
      <div className='mb-30'>
        <h3>{t('properties.edit.header')}</h3>
      </div>
      <div className='contact-card-item with-tabs'>
        <div className='contact-card-content w-100p'>
          <Tabs type="card">
            <TabPane tab={t('properties.new.steps.general')} key="general">
              <General
                general={property}
                isNotSteper={true}
                handleCancel={handleCancel}
                validateChanges={validateChanges}
              />
            </TabPane>
            <TabPane tab={t('properties.new.steps.price')} key="price">
              <Price
                handleCancel={handleCancel}
                price={property.price_attributes}
                operation={property.operation}
                isNotSteper={true}
              />
            </TabPane>
            <TabPane tab={t('properties.new.steps.details')} key="details">
              <Details
                handleCancel={handleCancel}
                operation={property.operation}
                details={property.detail_attributes}
                isNotSteper={true}
                validateChanges={validateChanges}
              />;
            </TabPane>
            <TabPane tab={t('properties.new.steps.address')} key="address">
              <Address
                handleCancel={handleCancel}
                locationAttributes={property.location_attributes}
                isNotSteper={true}
                validateChanges={validateChanges}
                property={property}
                form={form}
              />
            </TabPane>
            <TabPane tab={t('properties.new.steps.gallery')} key="gallery">
              <Gallery
                handleCancel={handleCancel}
                images={property.images}
                property={property}
              />
            </TabPane>
            <TabPane tab={t('properties.new.steps.description')} key="description">
              <Description
                descriptionAttributes={property.description_attributes}
                isNotSteper
                handleCancel={handleCancel}
              />
            </TabPane>
            <TabPane tab={t('properties.new.steps.promotion')} key="promotion">
              <Promotion
                promotionAttributes={{ website_ids, portal_ids, class_ids, label_ids, feature_ids, hide_price, id, property_type_id }}
                isNotSteper
                handleCancel={handleCancel}
                onCreateProperty={onCreateProperty}
                property={property}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  )
})

export default New;
