import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {reportsService} from 'services/reportService';
import ReportStore from 'stores/report';
import { useTranslation } from 'react-i18next';
import { Table, Row, Col, DatePicker } from 'antd';
import { Helmet } from 'react-helmet';
import { ReconciliationOutlined } from "@ant-design/icons";
import workspaceStore from "stores/workspace";
import { capitalize, get } from 'lodash';
import { SOURCE_CATEGORIES } from 'constants/mixed';
import cn from "classnames";


const { RangePicker } = DatePicker;

const Promotion: FC = observer(() => {

  const [selectedCategory, setCategoryFilter] = useState("");
  const [filterDate, setFilterDate] = useState([])
  const { t } = useTranslation();

  useEffect(() => {
    loadData();
    return () => ReportStore.clearData();
  }, [workspaceStore.selectedId])

  const filterSource = (type: string = '') => {
    const dataFilter = { 'category':  type }
    setCategoryFilter(type);
    loadData(dataFilter)
  }

  const onFilterDate = (dates: any) => {
    let dataFilter = {};
    dataFilter = { 'date_from':  dates[0], date_to: dates[1] }
    loadData(dataFilter)
  }

  const loadData = (params: {[key: string]: unknown} = {}) => {
    const queryParams = {
      date_from:  get(filterDate, [0]),
      date_to: get(filterDate, [1]),
      category: selectedCategory,
      ...params
    }
    reportsService.loadResources('promotion', { ...queryParams })
  }

  const changeDateFilter = (dates: any) => {
    setFilterDate(dates);
    if(!dates) {
      loadData({ date_from:  "", date_to: "" });
      return
    }
    onFilterDate(dates)
  }

  const columns = [
    {
      title: t('contacts.new.source'),
      dataIndex: 'source',
      key: 'source',
      width: "40%",
    }, {
      title: t('reports.saleRequest'),
      dataIndex: 'sale_requests',
      key: 'sale_requests',
      width: "15%",
    }, {
      title: t('reports.rentRequest'),
      dataIndex: 'rent_requests',
      key: 'rent_requests',
      width: "15%",
    }, {
      title: t('reports.newClient'),
      dataIndex: 'new_clients',
      key: 'new_clients',
      width: "15%",
    }, {
      title: t('reports.existingClients'),
      dataIndex: 'existing_clients',
      key: 'existing_clients',
      width: "15%",
    }
  ];

  const getData = () => {
    const values = ReportStore.values;
    if(!Array.isArray(values)) {
      return [];
    }

    return (values || []).map((item: any) => {
      return {
        source: item.name,
        sale_requests: item.sale_requests,
        rent_requests: item.rent_requests,
        new_clients: item.new_clients,
        existing_clients: item.existing_clients
      }
    })
  }

  return (
    <div className="expense-page page">
      <Helmet>
        <title>{t("reports.promotion")}</title>
      </Helmet>
      <Row align='middle' justify='space-between' className='mb-40'>
        <Col>
          <h3>{t('reports.promotion')}</h3>
        </Col>
        <Col>
        <RangePicker
            // ranges={{
            //   Today: [moment(), moment()],
            //   'This Month': [moment().startOf('month'), moment().endOf('month')],
            // }}
            format="DD.MM.YYYY"
            onOk={onFilterDate}
            onChange={changeDateFilter}
          />
        </Col>
      </Row>
      <Row align='middle' justify='space-between' className='pxy-20 report-table-header'>
        <Col>
          <div>
            <ReconciliationOutlined className='mr-20' />
            {t('reports.topSources')}
          </div>
        </Col>
        <Col>
          <span className={cn('mr-40 c-p', {"text-primary": selectedCategory === ""})} onClick={() => filterSource("")}>{t('properties.all')}</span>
          {SOURCE_CATEGORIES?.map((category: string) => <span key={category} className={cn('mr-40 c-p', {"text-primary": selectedCategory === category})} onClick={() => filterSource(category)}>{capitalize(category)}</span>)}
        </Col>
      </Row>
      <div className='page-content'>
        <Table
          dataSource={getData()}
          columns={columns}
          className='general-table'
          loading={ReportStore.loading}
          bordered
          pagination={false}
        />
      </div>
    </div>
  );
})

export default Promotion;
