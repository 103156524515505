import { action } from 'utils/mobx';
import axios from 'axios';
import { message } from 'antd';
import dealStore  from 'stores/deal';
import { DEALS_PROPERTY_URL, DEAL_EMAILS_URL } from 'constants/api';
import { CREATE_EMAIL } from 'constants/action';

import { IDeal } from "declarations/deal";
import { IEmail } from "declarations/email";

interface IResponse {
  status: number,
  data: any
}

declare interface Props {
}

export class DealsPropertyService implements Props {

  async createResourceRequest(params: IDeal) {
    return axios.post(DEALS_PROPERTY_URL, {
      ...params
    })
  }


  async createResource(params: any, callback?: Function) {

    const response: IResponse = await this.createResourceRequest(params);

    if (response.status == 200) {
      if(callback) {
        callback();
      }
      // this.loadResources({contact_id: params['contact_id']});
      message.success('Property added to deal!')
    } else {
      message.error((response.data.message || []).join())
    }

  }


  async updateResourceRequest(resource_id: number, params: Object) {
    return axios.put(`${DEALS_PROPERTY_URL}/${resource_id}`, { ...params })
  }


  async updateResource(resource_id: number, params: Object, callback?: Function) {

    const response: { data: any, status: number } = await this.updateResourceRequest(resource_id, params);

    if (response.status) {
      if(callback) {
        // this.updateValue(response.data)
        callback(response.data)
      }
    }

  }

  async createDealEmailResourceRequest(params: IEmail) {
    return axios.post(DEAL_EMAILS_URL, params)
  }

  @action({ action: CREATE_EMAIL, minRequestTime: 500 })
  async createFromDealResource(params: any, callback: Function) {

    const response: IResponse = await this.createDealEmailResourceRequest(params);

    if (response.status == 200) {
      this.updateValue(response.data)
      callback();
      message.success('Email sent!')
    } else {
      message.error((response.data.message || []).join())
    }

  }

  async updatePositionsRequest(params: Object) {
    return axios.post(`${DEALS_PROPERTY_URL}/update_positions`, { deals_properties: params })
  }


  async updatePositions(params: Array<any>) {
    console.log("🚀 ~ file: DealsPropertyService.ts:88 ~ DealsPropertyService ~ updatePositions ~ params:", params)

    const response: { data: any, status: number } = await this.updatePositionsRequest(params);

    if (response.status) {
      // if(callback) {
      //   this.updateValue(response.data)
      //   callback()
      // }
    }
  }

  updateValue(deal: IDeal) {
    dealStore.updateValue(deal)
  }

}

export const dealPropertyService = new DealsPropertyService();
