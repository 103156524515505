import { action } from 'mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import { message } from 'antd';
import loginStore  from './loginStore';
import LocaleStore from 'stores/locale';
import Constants from 'constants';
import i18next from 'i18next';

export interface ILoginService {
  login(email: string, password: string, captcha: string): Promise<object>
}

class LoginService implements ILoginService {

  async login(email: string, password: string, captcha: string) {
    const response = await this.loginRequest(email, password, captcha);

    if (response.status == 200) {
      const user = response.data.data;
      if(user.user.active) {
        const token = user ? user.token : null;
        if (user && token) {
          await loginStore.login(user, token);
          const lang = user.user.language || 'en';
          LocaleStore.setLocale(lang);
        }
      } else {
        message.error("User does not exist, or you don't have access to it.\nContact your administrator")
        return 200
      }
    }

    return response.status;
  }

  loginRequest(email: string, password: string, captcha: string): any {
    return axios.post('/api/v1/auth/login', { email, password, captcha_response: captcha }).catch((error: any) =>
      message.error(i18next.t('login.cantAuthorize'))
    );
  }

  async logout() {
    if (loginStore.isLogged) {
      // await this.logoutRequest();
      await loginStore.logout();
    }
  }

  // @request({ action: LOGOUT, root: 'errors' })
  // logoutRequest(): any {
  //   return axios.delete('LOGIN_URL');
  // }

  async reset(password: string, password_confirmation: string, reset_password_token: string, callback: Function) {
    const response = await this.resetRequest(password, password_confirmation, reset_password_token);

    if (response && response.status == 200) {
      message.success('Password updated!')
      callback()
    } else {
      message.error(i18next.t('login.cantAuthorize'))
    }
    return response.status;
  }

  resetRequest(password: string, password_confirmation: string, reset_password_token: string): any {
    const data = {
      password: password,
      password_confirmation: password_confirmation,
      reset_password_token: reset_password_token
    }
    return axios.patch('/api/v1/update_passwords', { user: data});
  }

  async forgot(email: string, callback: Function) {
    const response = await this.forgotRequest(email);
    console.log(response)
    if (response.status == 200) {
      message.success('We sent you an email with details on how to create a new password');
      callback()
    }
  }

  forgotRequest(email: string): any {
    return axios.post('/api/v1/forgot_passwords', { email: email})
    .catch((error) => {
        message.error('There is no user with this email address in the system');
    })
  }
}

export default LoginService;
