import {FC, useEffect} from 'react';
import { observer } from 'mobx-react';
import { contactService } from 'services/ContactService'
import contactStore from 'stores/contact';
import ContactForm from '../../components/New/Form';
import { useParams, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import { IContact } from "declarations/contact";

const Edit: FC = observer(() => {
  const contact: any = contactStore.selected;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if(params.id) {
      contactService.loadResource(Number(params.id))
    }
    return () => contactStore.clearSelected();
  }, [params.id]);

  const onCreateContact = async (params: IContact) => {
    await contactService.createResourceRequest(params).then((resp) => {
      navigate('/contacts')
      contactService.loadResources();
      messageApi.success('Contact created!')
    }).catch(({response}) => messageApi.error((response.data.message || []).join()))
  }

  const onCancelButton = () => {
    navigate('/contacts');
  }


  if (!contact.id) {
    return null;
  }

  return (
    <div className="edit-contact-page page">
      {contextHolder}
      <div className='mb-30'>
        <h3 className='ta-c'>{t('contacts.editContact')}</h3>
      </div>
      <div>
        <div>
          <ContactForm contact={contact} onCreateContact={onCreateContact} onCancelButton={onCancelButton} />
        </div>
      </div>
    </div>
  );
});

export default Edit;
