import { groupBy } from 'lodash';
import { ICity } from 'declarations/city';
import { makeAutoObservable } from 'mobx';

export class CityStore {

  values: Array<ICity> = [];
  groupedValues: any = {};
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: Array<ICity>): void {
    this.values = values;
    this.groupedValues = groupBy(values, 'region_id')
  }

  setLoading(val: boolean) {
    this.loading = val;
  }
}

export default (new CityStore())