import { action } from 'mobx';
import axios from 'axios';
import { request } from 'utils/mobx';
import { message } from 'antd';
// import { request, action } from 'utils';
import  complexStore  from '../stores/complex';
import { CITY_COMPLEX_URL } from 'constants/api';
import { IComplex } from "declarations/complex";
import  workspaceStore  from '../stores/workspace';

interface FilterType {

};

declare interface Props {
  //loadResources(params?: FilterType, pageNum?: number): any,
  loadResourcesRequest(queryParams: {}): Promise<object>
}

export class ComplexService implements Props {

  // LOAD resourceS
  async loadResourcesRequest(params: any) {
    return axios.get(CITY_COMPLEX_URL, { params })
  }

  async loadResources(params: any = {}, callback?: Function) {
    const filter = {
      ...params,
      workspace_id: workspaceStore.selectedId,
      page: params['page'] || 'all',
    }
    complexStore.setFetchInProgress(true);
    const response: { status: number, data: { complexes: Array<IComplex>, complexes_count: number } } = await this.loadResourcesRequest(filter);

    if (response.status == 200) {
      let values = [];
      values = response.data.complexes;
      if(callback){
        callback(values)
      }
      this.addValues(values, response.data.complexes_count);
    }
    complexStore.setFetchInProgress(false);

    return response;
  }

  async createResourceRequest(params: IComplex) {
    return axios.post(CITY_COMPLEX_URL, { complex:
      params
    })
  }


  async createResource(params: any, callback: Function) {
    complexStore.setSaveInProgress(true);
    const response: { status: number, data: { message?: Array<string>} } = await this.createResourceRequest({...params, workspace_id: workspaceStore.selectedId});
    if (response.status == 200) {
      callback();
      this.loadResources({ page: 1 });
      message.success('Complex created!')
    } else {
      message.error((response.data.message || []).join())
    }
    complexStore.setSaveInProgress(false);

  }

  async deleteResourceRequest(params: IComplex) {
    return axios.delete(`${CITY_COMPLEX_URL}/${params['id']}`)
  }


  async deleteResource(params: any, callback?: Function) {
    complexStore.setDeleteInProgress(true);
    const response: { status: number, data: { message?: string } } = await this.deleteResourceRequest(params);

    if (response.status == 200) {
      message.success('Complex deleted!')
      this.loadResources({ page: 1 });
    } else {
      message.error(response.data.message)
    }
    complexStore.setDeleteInProgress(false);
    if(callback) {
      callback()
    }

  }

  addValues(values: Array<IComplex>, count: number) {
    complexStore.addValues(values, count);
  }
}

export const complexService = new ComplexService();
