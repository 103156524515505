import { FC, useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import { regionsService } from 'services/regionService';
import { islandsService } from 'services/islandService';
import { cityService } from 'services/cityService';
import { complexService } from 'services/complexService';
import { propertyService } from '../../../PropertyService';
import ProgressStore from 'stores/progress';
import RegionStore from 'stores/region';
import IslandStore from 'stores/island';
import CityStore from 'stores/city';
import ComplexStore from 'stores/complex';
import { UPDATE_PROPERTY } from 'constants/action';
import { isEmpty, uniq, keys, some } from 'lodash';

import MapComponent from 'components/MapComponent';

import { Form, Select, Input, Button, Row, Col, notification, FormInstance, Spin } from 'antd';

import { IRegion } from 'declarations/region';
import { ICity } from 'declarations/city';
import { IComplex } from 'declarations/complex';
import { IIsland } from 'island';
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

export interface IData {
  locationAttributes: any,
  handleCancel?(): any,
  prev?(params: any): void,
  next?(params: any): void,
  isNotSteper?: boolean;
  validateChanges?(params: any): Array<string>;
  property: any;
  form: FormInstance
}


const { Option } = Select;
const CENTER = { lat: 28.2268066312491, lng: -16.6391376609972 };

const Address: FC<IData> = observer(({ handleCancel, locationAttributes, prev, next, isNotSteper, validateChanges, property, form }) => {
  const [regions, setRegions] = useState<IRegion[]>([])
  const [cities, setCities] = useState<ICity[]>([])
  const [complexes, setComplexes] = useState<IComplex[]>([])
  // const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [state, setState] = useState<any>({
    location: {
      id: null,
      island_id: undefined,
      region_id: undefined,
      city_id: null,
      city_complex_id: null,
      address: '',
      postal_code: '',
      building: '',
      block: '',
      entrance: '',
      floor: '',
      room: '',
      lng: null,
      lat: null
    },
    regions: [],
    cities: [],
    complexes: []
  });

  useEffect(() => {
    Promise.all([
      islandsService.loadResources(),
      regionsService.loadResources((values: Array<IRegion>) => setRegions(values)),
      cityService.loadResources((values: Array<ICity>) => setCities(values)),
      complexService.loadResources({}, (values: Array<IComplex>) => setComplexes(values)),
    ]);

    if(locationAttributes) {
      setState({
        ...state,
        location: {
          ...state.location,
          ...locationAttributes
        }
      })
      form.setFieldsValue(locationAttributes);
    }
    setLoading(false);
  }, [])

  const onCancel = () => handleCancel && handleCancel()

  const handleSubmit = async (e: any) => {
    const location = form.getFieldsValue();
    if (!isEmpty(property.website_urls)) {
      if(location.island_id) {
        const island = IslandStore.values.find((i) => i.id == location.island_id);
        const islandName = island && island.name;
        const websiteNames: string[] = [];
        keys(property.website_urls).forEach((key) =>
          websiteNames.push(property.website_urls[key] && property.website_urls[key].resource_name || '')
        )

        if (
          (islandName == 'Alicante' && !websiteNames.includes("alicantecenter.com")) ||
          (islandName != 'Alicante' && websiteNames.includes("alicantecenter.com"))
        ) {
          notification['error']({
            message: "Can't update property",
            placement: 'topRight',
            duration: 0,
            description: `You need unswitch sync with websites in promotion tab`
          });
          return;
        }
      }
    }
    e.preventDefault();
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();

      // const locationsValue = {...this.state.location, ...values };
      const locationsValue = {...state.location, ...values };
      let invalidPortalsList: Array<string> = [];
      if(validateChanges) {
        invalidPortalsList = validateChanges({ location_attributes: locationsValue })
      }

      if(invalidPortalsList.length == 0) {
        propertyService.updateResource('locations', locationsValue, () => onCancel())
      } else {
        notification['error']({
          message: "Can't update property",
          placement: 'topRight',
          duration: 0,
          description: `Unpublish property from ${uniq(invalidPortalsList).join(' , ')} before updating.`
        });
      }
    }
  }

  const prevStep = async (e: any) => {
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length) && prev) {
      const values = form.getFieldsValue();
      const locationValues = {...values }

      prev({ location_attributes: locationValues })
    }
  }

  const nextStep = async (e: any) => {
    await form.validateFields();
    const values = form.getFieldsValue();
    const locationValues = {...values }

    if (!some(form.getFieldsError(), ({ errors }) => errors.length) && next) {
      next({ location_attributes: locationValues })
    }
  }

  const getButtons = () => {
    let buttons;

    if(isNotSteper) {
      buttons = <div>
                  <Col>
                    <Button className='mr-10' type="primary" onClick={handleSubmit}
                      loading={ProgressStore.isLoading(UPDATE_PROPERTY)}
                    >
                      {t('save')}
                    </Button>
                    <Button type="default" onClick={onCancel}>{t('cancel')}</Button>
                  </Col>
                </div>

    } else {
      buttons = <div>
                  <Button className='mr-10' onClick={prevStep}><ArrowLeftOutlined /> {t('back')}</Button>
                  <Button type="primary" onClick={nextStep}>
                  {t('continue')} <ArrowRightOutlined />
                  </Button>
                </div>
    }
    return buttons;
  }

  const handleClick = (data: any) => {
    const lat  = data.lat;
    const lng = data.lng;

    setState({
      ...state,
      location: {
        ...state.location,
        lat,
        lng,
      }
    })
  }

  const getIslandsOptions = () => {
    const islands = IslandStore.values;
    return islands.map((island: IIsland) => <Option value={island.id}>{island.name}</Option> )
  }

  const getRegionsOptions = (regions: IRegion[] = []) => {
    return regions.map((region: IRegion) => <Option value={region.id}>{region.name}</Option> )
  }
  const getCitiesOptions = (cities: ICity[] = []) => {
    return cities.map((city: IRegion) => <Option value={city.id}>{city.name}</Option> )
  }

  const getCityComplexesOptions = (complexes: IComplex[] = []) => {
    return complexes.map((complex: IRegion) => <Option value={complex.id}>{complex.name}</Option> )
  }

  const onChangeIsland = (island_id: number) => {
    // const regions = RegionStore.values.filter((item) => item.island_id === island_id)
    // const cities = CityStore.values.filter((item) => item.island_id === island_id)
    // const complexes = ComplexStore.values.filter((item) => item.island_id === island_id)
    form.setFieldsValue({
      region_id: null,
      city_id: null,
      city_complex_id: null
    })
    // setState({
    //   ...state,
    //   location: {
    //     ...state.location,
    //     city_complex_id: null,
    //     city_id: null,
    //     region_id: undefined,
    //     island_id: undefined
    //   },
    //   regions: regions,
    //   cities,
    //   complexes
    // })
  }

  const onChangeRegion = (region_id: number) => {
    const region: any = regions.find((region: IRegion) => region.id == region_id);
    const location = form.getFieldsValue();
    const island_id = region ? region.island_id : location.island_id;
    // const cities = region_id ? CityStore.groupedValues[region_id] : CityStore.values
    // const complexes = region_id ? ComplexStore.groupedValues[region_id] : ComplexStore.values
    form.setFieldsValue({
      city_id: null,
      city_complex_id: null,
      island_id
    })
    // setState({
    //   ...state,
    //   location: {
    //     ...state.location,
    //     city_complex_id: null,
    //     city_id: null,
    //     island_id,
    //     region_id
    //   },
    //   cities: cities || [],
    //   complexes: complexes || []
    // })
  }

  const onChangeCity = (city_id: number) => {
    const city: any = cities.find((city: ICity) => city.id == city_id);
    const location = form.getFieldsValue();

    const region_id = city ? city.region_id : location.region_id;
    const island_id = city ? city.island_id : location.island_id;

    form.setFieldsValue({city_complex_id: null, region_id: region_id, island_id })
  }

  const onChangeComplex = (complex_id: number) => {
    const complex: any = complexes.find((complex: IComplex) => complex.id == complex_id);
    if(!complex) {
      return
    }
    form.setFieldsValue({city_id: complex.city_id, region_id: complex.region_id, island_id: complex.island_id })
  }

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const formItemRow = {
    wrapperCol: { span: 14, offset: 6 }
  };


  return (
    <div>
      <Spin spinning={loading}>
        <div>
          <Form onFinish={handleSubmit} form={form} autoComplete="nope">
            <Form.Item
              {...formItemRow}
            >
              <h3 className='general-title'>{t('properties.new.address')}</h3>
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              label={t('properties.new.island')}
              name="island_id"
              rules={[{ required: true, message: t('validates.cantBeEmpty') },]}
            >
              <Select
                onChange={onChangeIsland}
                showSearch
                filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value=''>{t('properties.new.notSelected')}</Option>
                { getIslandsOptions() }
              </Select>
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const island_id = getFieldValue("island_id")
                const regions = island_id ? RegionStore.groupedValues[island_id] : RegionStore.values
                return <Form.Item
                  {...formItemLayout}
                  label={t('properties.new.region')}
                  name="region_id"
                  rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
                >
                  <Select
                    onChange={onChangeRegion}
                    showSearch
                    filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value=''>{t('properties.new.notSelected')}</Option>
                    { getRegionsOptions(regions) }
                  </Select>
                </Form.Item>
              }}
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const region_id = getFieldValue("region_id")
                const island_id = getFieldValue("island_id")
                let cities = CityStore.values;
                if (region_id){
                  cities = CityStore.values.filter((c) => c.region_id == region_id)
                } else if (island_id) {
                  cities = CityStore.values.filter((c) => c.island_id == island_id)
                }

              return <Form.Item
                  {...formItemLayout}
                  label={t('properties.new.city')}
                  name="city_id"
                  rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
                >
                  <Select
                    onChange={onChangeCity}
                    showSearch
                    filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value=''>{t('properties.new.notSelected')}</Option>
                    { getCitiesOptions(cities) }
                  </Select>
                </Form.Item>
              }}
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const region_id = getFieldValue("region_id")
                const island_id = getFieldValue("island_id")
                const city_id = getFieldValue("city_id")
                let complexes = ComplexStore.values;

                if (city_id) {
                  complexes = (complexes || []).filter((comp: IComplex) => comp.city_id == city_id)
                } else if (region_id) {
                  complexes = ComplexStore.groupedValues[region_id];
                } else if (island_id) {
                  complexes = (complexes || []).filter((comp: IComplex) => comp.city_id == city_id)
                }

                return <Form.Item
                  {...formItemLayout}
                  label={t('properties.new.complex')}
                  name="city_complex_id"
                  rules={[{ required: false, message: t('properties.placeholder.complex') }]}
                >
                  <Select
                    onChange={onChangeComplex}
                    showSearch

                    filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value=''>{t('properties.new.notSelected')}</Option>
                    { getCityComplexesOptions(complexes) }
                  </Select>
                </Form.Item>
              }}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.streetName')}
              name="address"
            >
              <Input placeholder={t('properties.new.streetName')} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.postalCode')}
              name="postal_code"
            >
              <Input placeholder={t('properties.new.postalCode')} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={`${t('properties.new.building')} #`}
              name="building"
            >
              <Input placeholder={t('properties.new.building')} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={`${t('properties.new.block')} #`}
              name="block"
            >
              <Input placeholder={t('properties.new.block')} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={`${t('properties.new.entrance')} #`}
              name="entrance"
            >
              <Input placeholder={t('properties.new.entrance')} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={`${t('properties.new.floor')} #`}
              name="floor"
            >
              <Input placeholder={t('properties.new.floor')} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={`${t('properties.new.room')} #`}
              name="room"
            >
              <Input placeholder={t('properties.new.room')} />
            </Form.Item>
            <Form.Item name="lat" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item name="lng" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.map')}
            >
              <div className='map-container'>
                <MapComponent handleClick={handleClick} coordinates={{lat: Number(locationAttributes.lat), lng: Number(locationAttributes.lng)}} center={CENTER} form={form} />
              </div>
            </Form.Item>
          </Form>
        </div>
        <Row>
          <Col span={6}></Col>
          <Col span={18}>
            <div className="form-controls">
              { getButtons() }
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  )
})

export default Address;
