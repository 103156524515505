import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { sourceService } from 'services/sourceService';
import { requestService } from 'services/requestService';
import { propertyService } from 'modules/properties/PropertyService';
import SourceStore from 'stores/source';
import PropertyStore from 'stores/property';
import ProgressStore from 'stores/progress';
import clientStore from 'stores/client';
import LocaleStore from 'stores/locale';
import { keys, some } from 'lodash';
import { SALE_VALUE, TRASPASO_VALUE, RENT_VALUE } from 'constants/mixed';
import { CREATE_REQUEST, LOAD_PROPERTIES } from 'constants/action';

import { ISource } from 'declarations/source';
import { IClient } from 'declarations/contact';
import { Property as IProperty } from 'declarations/property';

import { Form, Select, Input, Button, Row, Col, DatePicker, Spin, message } from 'antd';
import { LANGUAGES } from "constants/mixed"
import { name } from 'utils/localized';
import { IRequest } from 'declarations/request';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { type } from 'os';
var utc = require('dayjs/plugin/utc')
dayjs.extend(customParseFormat);
dayjs.locale("en");
dayjs.extend(utc)

const dateFormat = 'DD.MM.YYYY';

interface IData {
  handleCancel(): void,
  request?: IRequest;
  isFromContact?: undefined | number;
  isFromProperty?: undefined | number;
}

const { Option, OptGroup } = Select;
const defaulState = {
  id: null,
  name: '',
  email: '',
  phone: '',
  source_id: null,
  property_id: null,
  message: '',
  operation: 'sale',
  client_id: null,
  language: '',
  showing_date_at: '',
  showing_time: undefined
}

const New: FC<IData> = observer(({ isFromProperty, ...props }) => {
  const [state, setState] = useState<any>(defaulState);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const lng = LocaleStore.locale;
  const [antMessage, messageContext] = message.useMessage();

  useEffect(() => {
    const { request } = props;
    propertyService.loadSelectResources({
      operations: [SALE_VALUE, TRASPASO_VALUE],
      statuses: ["published", "draft"]
    })
    if(isFromProperty) {
      form.setFieldValue("property_id", isFromProperty);
    }
    sourceService.loadResources()
    if (request && request.id) {
      clientStore.fetchClients({}, 'all')
      setState(request)
      form.setFieldsValue({...request, showing_date_at: request.showing_date_at && dayjs(request.showing_date_at) });
    } else {
      setState({ ...state, ...defaulState })
    }
  }, [])

  const handleSubmit = async (e: any) => {
    const { request, isFromContact } = props;
    let filter = {};
    e.preventDefault();
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      const requestValues = { ...state, ...values, showing_date_at: values.showing_date_at && values.showing_date_at.format(dateFormat) }
      if (request && request.id) {

        requestService.updateResource(requestValues, props.handleCancel)
      } else {
        if (isFromContact) {
          requestValues['client_id'] = isFromContact;
          filter = { client_id: isFromContact }
        }
        if (isFromProperty) {
          filter = { property_id: isFromProperty }
        }
        await requestService.createResource(requestValues, props.handleCancel, antMessage, filter)
        if (isFromContact) {
          clientStore.fetchClient(isFromContact);
        }
        if (isFromProperty) {
          propertyService.loadResource(isFromProperty);
        }
      }
    }
  }
  const loadSources = () => {
    const sources = SourceStore.groupedValues;
    const sourceKeys = keys(sources)

    return sourceKeys.map((category: string) => {
      return <OptGroup key={`source_${category}`} label={category}>
        {sources[category].map((source: ISource) => <Option value={source.id} key={`source_${source.id}`}>{source.name}</Option>)}
      </OptGroup>
    })
  }

  const loadClients = () => {
    const contacts = clientStore.values;
    return contacts.map((contact: IClient) => <Option value={contact.id}>{contact.name}</Option>)
  }

  const loadProperty = () => {
    const properties = PropertyStore.selectValues;
    const prefix = state.operation == 'rent' ? 'VR' : 'VS';
    return properties.map((property: IProperty) =>
      <Option value={property.id}>{`${prefix}${property.identifier} - ${name(property, lng)}`}</Option>
    )
  }

  const changeOperation = (value: string) => {
    const operationFilter = value == 'rent' ? [RENT_VALUE] : [SALE_VALUE, TRASPASO_VALUE]
    setState({...state, operation: value})
    !isFromProperty && propertyService.loadSelectResources({ operations: operationFilter })
  }

    const { request, isFromContact } = props;

    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };

    return (
      <div className="add-user">
        {messageContext}
        <Form onFinish={handleSubmit} form={form}>
          {!(request && request.id) && !isFromContact &&
            <Form.Item
              {...formItemLayout}
              label={t('owners.table.name')}
              name="name"
              rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
            >
              <Input placeholder={t('owners.table.name')} />
            </Form.Item>
          }
          {!(request && request.id) && !isFromContact &&
            <Form.Item
              {...formItemLayout}
              label={t('tabs.email')}
              name="email"
              rules={[{ required: true, message: t('validates.cantBeEmpty') }, { type: "email", message: t('validates.emailNotValid') }]}
            >
              <Input placeholder={t('tabs.email')} type="email" />
            </Form.Item>
          }
          {!(request && request.id) && !isFromContact &&
            <Form.Item
              {...formItemLayout}
              label={t('owners.table.phone')}
              name="phone"
              rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
            >
              <Input placeholder={t('owners.table.phone')} />
            </Form.Item>
          }
          {request && request.id && !isFromContact &&
            <Form.Item
              {...formItemLayout}
              label={t('contact')}
              name="client_id"
              rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
            >
              <Select placeholder={t('properties.placeholder.contact')} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {loadClients()}
              </Select>
            </Form.Item>
          }
          <Form.Item
            {...formItemLayout}
            label={t('deals.new.operation')}
            name="operation"
            rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
          >
            <Select
              placeholder={t('deals.placeholder.operation')}
              onChange={changeOperation}
              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option value="sale">{t('deals.new.dealOperation.sale')}</Option>
              <Option value="rent">{t('deals.new.dealOperation.rent')}</Option>
            </Select>
          </Form.Item>

          <Spin spinning={ProgressStore.isLoading(LOAD_PROPERTIES)} style={{ height: "41px", width: "580px"}}>
            <Form.Item
              {...formItemLayout}
              label={t('owners.table.property')}
              name="property_id"
              rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
            >
              <Select
                placeholder={t('properties.placeholder.property')}
                showSearch
                filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                loading={ProgressStore.isLoading(LOAD_PROPERTIES)}
                disabled={!!isFromProperty}
              >
                {loadProperty()}
              </Select>
            </Form.Item>
          </Spin>

          <Form.Item
            {...formItemLayout}
            label={t('message')}
            name="message"
          >
            <Input.TextArea placeholder={t('message')} rows={6} />
          </Form.Item>
          {/* <Row className='grouped-details'>
            <Col span='12'> */}
              <Form.Item
                {...formItemLayout}
                label={t('requests.showingDate')}
                name="showing_date_at"
              >
                <DatePicker className='w-100p' format={dateFormat}/>
              </Form.Item>
            {/* </Col>
            <Col span='12'> */}
              <Form.Item
                {...formItemLayout}
                label={t('requests.showingTime')}
                name="showing_time"
              >
                <Select filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  <Option value="10:00-11:00">10:00-11:00</Option>
                  <Option value="11:00-12:00">11:00-12:00</Option>
                  <Option value="12:00-13:00">12:00-13:00</Option>
                  <Option value="13:00-14:00">13:00-14:00</Option>
                  <Option value="14:00-15:00">14:00-15:00</Option>
                  <Option value="15:00-16:00">15:00-16:00</Option>
                  <Option value="16:00-17:00">16:00-17:00</Option>
                  <Option value="17:00-18:00">17:00-18:00</Option>
                  <Option value="18:00-19:00">18:00-19:00</Option>
                </Select>
              </Form.Item>
            {/* </Col>
          </Row> */}
          <Form.Item
            {...formItemLayout}
            label={t('contacts.new.source')}
            name="source_id"
            rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
          >
            <Select
              placeholder={t('contacts.new.placeholderSource')}
              showSearch
              filterOption={(input: any, option: any) => typeof(option.props.children) == 'string' && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {loadSources()}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('contacts.new.language')}
            name="language"
          >
            <Select placeholder={t('contacts.new.placeholderLanguage')} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {LANGUAGES.map((lng: string) => (
                <Option key={`languages-${lng}`} value={lng}>{t(`contacts.new.lng.${lng}`)}</Option>
              ))}
            </Select>
          </Form.Item>
          <Row>
            <Col span={8}></Col>
            <Col span={16}>
              <div className="form-controls">
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  className='mr-10'
                  loading={ProgressStore.isLoading(CREATE_REQUEST)}
                >
                {t('save')}
                </Button>
                <Button onClick={() => props.handleCancel()}>
                {t('cancel')}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    )
});

export default New;
