import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DealStore from 'stores/deal';
import PropertyTypeStore from 'stores/type';
import DateByCreator from 'components/DateByCreator';
import { propertyTypesService } from 'services/propertyTypes';
import { dealService } from 'services/DealService';
import { Table, Menu, Dropdown, Tag, Button, Modal } from 'antd';
import ProgressStore from 'stores/progress';
import LocaleStore from 'stores/locale';
import { TYPE_OPERATIONS, DEAL_STATUS_FROM_OPERATION, COLORS, DEAL_STATUS_COLOR } from 'constants/mixed';
import { LOAD_DEALS } from 'constants/action';
import { name } from 'utils/localized';
import loginStore from 'modules/login/loginStore';
import { EditOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";

import { IContact } from 'declarations/contact';
import { IDeal } from 'declarations/deal';
import { PropertyType as IType } from 'declarations/propertyType';

interface IData {
  contact: IContact;
  hidePagination?: boolean;
  showModal: Function
}

const confirm = Modal.confirm;

const ListTable:  FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const lng = LocaleStore.locale;

  useEffect(() => {
    const filter = { contact_id: props.contact.id }
    propertyTypesService.loadResources()
    dealService.loadResources(filter, 'all');
    return () => DealStore.clearData()
  }, [])

  const columns = [
      {
        title: t('properties.table.id'),
        dataIndex: 'id',
        key: 'id',
      }, {
        title: t('manager'),
        dataIndex: 'manager',
        key: 'manager',
      }, {
        title: t('deals.list.newProperties'),
        dataIndex: 'relevant',
        key: 'relevant',
      }, {
        title: t('deals.new.operation'),
        dataIndex: 'operation',
        key: 'operation',
      }, {
        title: t('deals.new.status'),
        dataIndex: 'status',
        key: 'status',
      }, {
        title: t('deals.new.propertyType'),
        dataIndex: 'property_type',
        key: 'property_type',
      }, {
        title: t('deals.new.budget'),
        dataIndex: 'budget',
        key: 'budget',
      }, {
        title: t('properties.table.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
      }, {
        title: t('deals.list.lastActivity'),
        dataIndex: 'last_activity',
        key: 'last_activity',
      }, {
        title: '',
        dataIndex: 'view',
        key: 'view',
      }, {
        title: '',
        dataIndex: 'actions',
        key: 'actions'
      }
    ];

  const getData = (): any => {
    const dataSource = (DealStore.values || []).map((deal: IDeal) => {
      const updatedAtDate = moment(deal.updated_at).format('DD.MM.YYYY');
      const updatedAtTime = moment(deal.updated_at).format('\\at kk:mm');

      return {
        id: deal.id,
        manager: <p className='ws-nw'>{deal.manager && deal.manager.name || '-'}</p>,
        relevant: <Tag color='#655dc5' className='mb-5'>+{deal.labels.length} { t('new') }</Tag>,
        operation: <div>{TYPE_OPERATIONS[deal.operation]}</div>,
        status: getStatusContent(deal),
        property_type: renderPropertyTypes(deal),
        budget: getBudgetContent(deal),
        created_at: <DateByCreator resource={deal} />,
        last_activity: <span>{updatedAtDate} <p>{updatedAtTime}</p></span>,
        view: getViewComponent(deal),
        actions: <Dropdown overlay={getActionComponent(deal)} trigger={['click']}>
          <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
        </Dropdown>
      }
    })

    return dataSource;
  }

  const renderPropertyTypes = (deal: IDeal) => {
    const types = PropertyTypeStore.values;

    if (!deal.property_type) {
      return '-'
    }

    return types.map((type: IType) => {
      if ((deal.property_type || []).includes(type.id.toString())) {
        return <Tag color={COLORS[1]} className='mb-5'>{name(type, lng)}</Tag>
      }
    })

  }
  const getStatusContent = (deal: IDeal) => {
    const status = DEAL_STATUS_FROM_OPERATION[deal.operation][deal.status];

    return (
      <Tag color={DEAL_STATUS_COLOR[deal.status]} className={status.toLowerCase()}>{status}</Tag>
    )
  }

  const getBudgetContent = (deal: IDeal) => {
    return (
      <div className='deal-cell-price'>
        <span>{deal.max_price ? `${deal.max_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '-'}</span>
      </div>
    )
  }

  const getViewComponent = (deal: IDeal) => {
    return (
      <Link to={`/clients/${props.contact.id}/deals/${deal.id}`} className=''>
        <Button type='primary'> {t('deals.viewDeal')} </Button>
      </Link>
    )
  }
  const showConfirm = (deal: IDeal) => {
    const filter = { contact_id: props.contact.id };

      confirm({
        title: t('deals.confirmDelete'),
        content: t("deals.confirmDelete"),
        onOk() {
          return new Promise((resolve, reject) => {
            dealService.deleteResource(deal.id, filter, resolve)
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() { },
      });
  }


  const getActionComponent = (deal: any) => {

    return (
      <Menu
        mode="horizontal"
        className='actons-menu'
      >
        <Menu.Item key={`edit_deal_${deal.id}`} onClick={() => props.showModal(deal)}>
          <span className='edit-title'>
            <EditOutlined />
            {t('properties.table.edit')}
          </span>
        </Menu.Item>
        { loginStore.isAdmin &&
          <Menu.Item key={`delete_deal_${deal.id}`} onClick={() => showConfirm(deal)}>
            <span className='delete-title'>
              <DeleteOutlined />
              {t('owners.table.delete')}
            </span>
          </Menu.Item>
        }
      </Menu>
    )
  }

  const { hidePagination } = props;
  const pagination = hidePagination ? false : { pageSize: 10, showSizeChanger: false };

  return (
    <div className='list-table'>
      <Table
        dataSource={getData()}
        columns={columns}
        className='properties-table'
        loading={ProgressStore.isLoading(LOAD_DEALS)}
        bordered
        pagination={false}
      />
    </div>
  );
})

export default ListTable;
