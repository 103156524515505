import { FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'antd';
import PropertyStore from 'stores/property';
import { dealPropertyService } from '../DealsPropertyService';
import { dealService } from 'services/DealService';
import PropertyList from 'modules/properties/components/List/ListTable';

import { IDeal } from 'declarations/deal';
import { IContact } from 'declarations/contact';

interface IData {
  deal: IDeal,
  contact: IContact,
}

const DEALS_PROPERTY_STATUS = 'shortlist';
const DealPropertySearch: FC<IData> = observer((props) => {

  const { t } = useTranslation();

  const getPropertyListActions = (property_id: number) => {
    return <Button type='primary' onClick={() => createDealsProperty(property_id)}>
      { t('deals.addToShortlist') }
    </Button>
  }

  const createDealsProperty = async (property_id: number) => {
    const { deal } = props;
    const newDealsProperty = {
      deal_id: deal.id,
      status: DEALS_PROPERTY_STATUS,
      property_id
    }
    await dealPropertyService.createResource(newDealsProperty, () => { updateResources(deal.id, property_id) })
  }

  const updateResources = async (deal_id: number, property_id: number) => {
    await dealService.loadResource(deal_id)
    PropertyStore.removeValue(property_id)
  }

  const { deal } = props;

  return (
    <div className='portlet'>
      <div className='portlet-head'>
        <h5>{t('tabs.requests')}</h5>
      </div>

      <div className='portlet-body'>
        <PropertyList
          actionComponent={getPropertyListActions}
          filter={{ except_deal_id: deal.id, is_filter_deal: true, operations: [deal.operation] }}
        />
      </div>
    </div>
  );
})

export default DealPropertySearch;
