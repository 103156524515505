import { request, action } from 'utils/mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import { message } from 'antd';
import emailStore  from 'stores/email';
import { EMAIL_URL } from 'constants/api';
import { Filter } from "declarations/filters";
import { IEmail } from "declarations/email";
import { LOAD_EMAILS, CREATE_EMAIL } from 'constants/action';

interface FilterType {
  letterable_id: number;
  letterable_type: string;
  page: number;
};

interface IResponse {
  status: number,
  data: {
    letters: Array<IEmail>,
    count: number,
    message?: Array<string>
  }
}

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any;
  loadResourcesRequest(queryParams: FilterType): Promise<object>;
}

export class EmailService implements Props {

  // LOAD resourceS
  async loadResourcesRequest (params?: FilterType): Promise<IResponse> {
    return axios.get(EMAIL_URL, {
      params
    })
  }

  @action({ action: LOAD_EMAILS, minRequestTime: 500 })
  async loadResources(params?: FilterType) {
    emailStore.setFetchInProgress(true);
    const response: IResponse = await this.loadResourcesRequest(params);
    if (response.status == 200) {
      const queryParams: any = params;
      const count = response.data.count;
      this.addValues(response.data.letters,  queryParams['page'], count);
    }
    emailStore.setFetchInProgress(false);

    return response;
  }


  async createResourceRequest(params: IEmail) {
    return axios.post(EMAIL_URL, params)
  }

  @action({ action: CREATE_EMAIL, minRequestTime: 500 })
  async createResource(params: any, contactId: number, callback: Function) {
    emailStore.setSaveInProgress(true);
    const response: IResponse = await this.createResourceRequest(params);

    if (response.status == 201) {
      callback();
      message.success('Email sent!')
    } else {
      message.error((response.data.message || []).join())
    }
    emailStore.setSaveInProgress(false);

  }

  async createFromDealResourceRequest(params: IEmail) {
    return axios.post(EMAIL_URL, params)
  }

  @action({ action: CREATE_EMAIL, minRequestTime: 500 })
  async createFromDealResource(params: any, contactId: number, callback: Function) {
    emailStore.setSaveInProgress(true);
    const response: IResponse = await this.createFromDealResourceRequest(params);

    if (response.status == 200) {
      callback();
      message.success('Email sent!')
    } else {
      message.error((response.data.message || []).join())
    }
    emailStore.setSaveInProgress(false);

  }

  async updateResourceRequest(email: IEmail, params: any) {
    return axios.put(`${EMAIL_URL}/${email.id}`, params)
  }


  async updateResource(email: IEmail, params: any) {

    emailStore.setSaveInProgress(true);
    const response: IResponse = await this.updateResourceRequest(email, params);

    if (response.status == 200) {
      this.updateValue({...email, ...params})
      message.success('Email updated!')
    } else {
      message.error((response.data.message || []).join())
    }
    emailStore.setSaveInProgress(false);

  }

  async deleteResourceRequest(resource_id: number) {
    return axios.delete(`${EMAIL_URL}/${resource_id}`)
  }


  async deleteResource(resource: IEmail, contactId: number, callback: Function) {
    if(!resource.id) {
      return
    }
    const resourceType = resource.letterable_type;
    emailStore.setDeleteInProgress(true);
    const response: { status: number } = await this.deleteResourceRequest(resource.id);
    if (response.status == 200) {
      message.success('Email deleted!!')
      this.loadResources({ letterable_id: contactId, letterable_type: resourceType, page: 1 });
      callback()
    }
    emailStore.setDeleteInProgress(false);
  }

  addValues(values: Array<IEmail>, page: number, count: number) {
    console.log("🚀 ~ file: emailService.ts:127 ~ EmailService ~ addValues ~ values:", values)
    emailStore.addValues(values, page, count);
  }

  updateValue(email: IEmail) {
    emailStore.updateValue(email);
  }

}

export const emailService = new EmailService();
