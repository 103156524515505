import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import AddressForm from '../../../New/Steps/Address';
import { Row, Col, Form } from 'antd';
import { EditOutlined } from "@ant-design/icons";
import MapComponent from 'components/MapComponent';
import { useTranslation } from 'react-i18next';

export interface IData {
  property: any;
  validateChanges?(params: any): Array<string>;
}


const Address: FC<IData> = observer((props) => {

    const [isEdit, setIsEdit] = useState(false);
    const { t } = useTranslation();
    const [form] = Form.useForm();


  const handleEditCancel = () => setIsEdit(false);

  const handleEdit = () => setIsEdit(true);

  const renderContent = () => {
    const { property } = props;
    const location = property.location_attributes;
    let content;

    if (isEdit) {
      content =
      <div className='contact-card-content'>
        <AddressForm
          handleCancel={handleEditCancel}
          locationAttributes={location}
          form={form}
          isNotSteper={true}
          validateChanges={props.validateChanges}
          property={property}
        />
      </div>
    } else {
      content = staticContent()
    }

    return content;
  }

  const staticContent = () => {
    const { property } = props;
    const location = property.location_attributes;
    const coordinates = { lat: Number(location.lat), lng: Number(location.lng)};
    const CENTER = { lat: Number(location.lat) || 28.46824, lng: Number(location.lng) || -16.25462 };
    if(!location) {
      return null
    }
    return (
      <div className='contact-card-content'>
        <div className='location-details mb-10'>
          <Row justify='space-between'>
            <Col span={6}>
            {t('properties.new.island')}: <span className='accent d-b'>{location.island && location.island.name || '-'}</span>
            </Col>
            <Col span={6}>
            {t('properties.new.region')}: <span className='accent d-b'>{location.region && location.region.name || '-'}</span>
            </Col>
            <Col span={6}>
            {t('properties.new.city')}: <span className='accent d-b'>{location.city && location.city.name || '-'}</span>
            </Col>
            <Col span={6}>
            {t('properties.new.complex')}: <span className='accent d-b'>{location.city_complex && location.city_complex.name || '-'}</span>
            </Col>
          </Row>
          <Row justify='space-between' className='pt-20'>
            <Col span={6}>
            {t('properties.new.streetName')}: <span className='accent d-b'>{location.address || '-'}</span>
            </Col>
            <Col span={6}>
            {t('properties.new.postalCode')}: <span className='accent d-b'>{location.postal_code || '-'}</span>
            </Col>
            <Col span={6}>
            {t('properties.new.building')} #: <span className='accent d-b'>{location.building || '-'}</span>
            </Col>
            <Col span={6}>
            {t('properties.new.block')} #: <span className='accent d-b'>{location.block || '-'}</span>
            </Col>
          </Row>
          <Row justify='start' className='pt-20'>
            <Col span={6}>
              {t('properties.new.entrance')} #: <span className='accent d-b'>{location.entrance || '-'}</span>
            </Col>
            <Col span={6}>
            {t('properties.new.floor')} #: <span className='accent d-b'>{location.floor || '-'}</span>
            </Col>
            <Col span={6}>
            {t('properties.new.room')} #: <span className='accent d-b'>{location.room || '-'}</span>
            </Col>
          </Row>
        </div>
        <div className='map-container pt-10'>

          <MapComponent coordinates={coordinates} center={CENTER}/>
        </div>
      </div>
    )
  }

  const location = props.property.location_attributes;

  if (!location) {
    return null;
  }

  return (
    <div className="contact-card-item">
      <Row justify='space-between' align='middle' className='contact-card-header'>
        <h5>{t('properties.new.address')}</h5>
        <EditOutlined onClick={handleEdit} />
      </Row>

      {renderContent()}
    </div>
  )
})

export default Address;
