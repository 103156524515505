import {FC, useState, useEffect} from 'react';
import { observer } from 'mobx-react';
import { propertyService } from '../../../PropertyService';
import { galleryService } from 'services/galleryService';
import PropertyStore from 'stores/property';
import PropertyTypeStore from 'stores/type';
import RegionStore from 'stores/region';
import CityStore from 'stores/city';
import { ReactSortable } from "react-sortablejs";
import shortid from 'shortid';
import { ArrowLeftOutlined, ArrowRightOutlined, EyeOutlined, DeleteOutlined, PlusOutlined, GlobalOutlined, LockOutlined, MenuOutlined } from "@ant-design/icons";
import { Select, Button, Input, Row, Col, Modal, Spin } from 'antd';
import { arrayMove } from "@dnd-kit/sortable";
import { IImage } from 'declarations/image';
import { useTranslation } from 'react-i18next';
import ProgressStore from 'stores/progress';
import { get, isEmpty, omit, sortBy } from 'lodash';
import cn from "classnames";
import { DELETE_GALLERY } from 'constants/action';

interface IData {
  next?(params?: any): any;
  prev?(params?: any): any;
  images: Array<any>;
  galleryKey: string;
  videoUrl: string,
  demoUrl: string,
  isNotSteper?: boolean;
  handleCancel?(): void;
  propertyId?: number;
  property: any;
}

interface IState {
  items: Array<IImage>,
  images: Array<any>;
  demo_url: string;
  video_url: string;
  loading: boolean;
  previewVisible: boolean,
  previewImage: string;
  name: string;
}

const { Option } = Select;

const Gallery: FC<IData> = observer((props) => {
  const [currentImages, setImages] = useState<any>([]);
  const [state, setState] = useState<Partial<IState>>({
    // images: [],
    video_url: '',
    demo_url: '',
    loading: false,
    previewVisible: false,
    previewImage: '',
    items: [],
    name: ''
  })
  const { t } = useTranslation();

  useEffect(() => {
    setState({
      ...state,
      demo_url: props.demoUrl || "",
      video_url: props.videoUrl || "",
      // images: props.images,
      name: getImageName()
    })

    setImages(PropertyStore.images);
    if(!isEmpty(props.images)) {

      PropertyStore.setImages(props.images || [])
    }
  }, [])

  const onSortEnd = async (event: any, sortable: any) => {
    const { oldIndex, newIndex} = event;
    console.log("🚀 ~ file: Gallery.tsx:78 ~ onSortEnd ~ currentImages:", currentImages)
    const newImages = await arrayMove(currentImages, oldIndex, newIndex);
    console.log("🚀 ~ file: Gallery.tsx:78 ~ onSortEnd ~ newImages 1:", newImages)

    const images = newImages.map((image: any, index: number) => {
      galleryService.updateResource(image.id, { position: index })
      return { ...image, position: index }
    })
    console.log("🚀 ~ file: Gallery.tsx:84 ~ newImages.forEach ~ images = :", images)
    PropertyStore.setImages(images)
    await setImages(images)
  };

  const handleImageCancel = () => setState({ previewVisible: false, previewImage: '' })

  const showPreviewImage = (image: IImage) => {
    setState({
      ...state,
      previewImage: image.url.original,
      previewVisible: true,
    });
  }

  const createImage = async (formData: FormData) => {
    await galleryService.createResource(formData).then((resp: IImage) => {
      const UidImage: any = PropertyStore.images.find((image: any) => image.uid)
      const newImages = PropertyStore.images.map((image: any) => image.uid === UidImage.uid ? resp : image)
      PropertyStore.setImages(newImages);
      setImages(newImages);
    })
  }

  const renderEmptyImage = () => {
    return {
      is_public: true,
      uid: shortid(),
      sortKey: shortid(),
      url: { thumb: '', original: '', hd: '' }
    }
  }

  const getImageName = () => {
    const { property } = props;
    const propertyType: any = PropertyTypeStore.values.find((item: any) => item.id == property.property_type_id)
    const region: any = RegionStore.values.find((item: any) => item.id == property.location_attributes.region_id)
    const city: any = CityStore.values.find((item: any) => item.id == property.location_attributes.city_id)

    const partName = [
      propertyType.name['en'],
      city.name,
      region.name,
      property.identifier
    ]
    return partName.join(' ')
  }

  const generateFormData = async (files: any) => {
    let loadImagesArray = [];
    for (let index: number = 0; index < files.length; index++) {
      const emptyImage = renderEmptyImage()
      loadImagesArray.push(emptyImage);
    }

    const allImages = [...currentImages, ...loadImagesArray]
    setImages(allImages)
    PropertyStore.setImages(allImages);

    for (let index: number = 0; index < files.length; index++) {
      var data = new FormData();
      if (state) {
        const imagesCount = currentImages?.length || 0;

        data.append('property_key', props.galleryKey || shortid());
        data.append('image', files[index]);
        data.append('name', `${state.name}`)
        data.append('position', `${imagesCount + index}`);
        createImage(data)
      }
    }
  }

  const onUploadImage = async (e: any) => {
    const files = e.target.files;
    setState({...state, loading: true })
    await generateFormData(files)
    setState({...state, loading: false })

  }

  const deleteImage = (item: IImage) => {
    galleryService.deleteResource(item.id);
    const newImages = (currentImages || []).filter((image: any) => image.id != item.id);
    setState({ ...state, images: newImages })
    setImages(newImages)
    PropertyStore.setImages(newImages);
    PropertyStore.updateValue({ images: newImages })
  }


  const handleCancel = () => {
    if (props.handleCancel) {
      props.handleCancel();
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!props.propertyId) {
      return;
    }

    const { demo_url, video_url } = state;

    const propertyValue = { id: props.propertyId, demo_url, video_url }
    propertyService.updateResource('properties', propertyValue, () => handleCancel())
  }

  const nextStep = (e: any) => {
    const { video_url, demo_url } = state;

    if (props.next) {
      const galleryValues = { currentImages, video_url, demo_url }
      props.next(galleryValues)
    }
  }

  const prevStep = (e: any) => {
    const { video_url, demo_url } = state;

    if (props.prev) {
      const galleryValues = { currentImages, video_url, demo_url }
      props.prev(galleryValues)
    }
  }

  const getButtons = () => {
    let buttons;
    if (props.isNotSteper) {
      buttons = <div className='mt-25'>
        <Button type="primary" onClick={handleSubmit}>{t('save')}</Button>
      </div>
    } else {
      buttons = <div>
        <Button className='mr-10' onClick={prevStep} disabled={!!currentImages.find((i: any) => i.uid)}><ArrowLeftOutlined /> {t('back')}</Button>
        <Button type="primary" onClick={nextStep} disabled={!!currentImages.find((i: any) => i.uid)}>
        {t('continue')} <ArrowRightOutlined />
        </Button>
      </div>
    }

    return buttons;
  }

  const handleChange = async (item: any, index: number) => {
    const newImage = { ...item, is_public: !item.is_public }
    console.log("🚀 ~ file: Gallery.tsx:232 ~ handleChange ~ newImage:", newImage)
    const images = (PropertyStore.images || []).map((image: IImage) => {
      return image.id == newImage.id ? newImage : image
    })
    console.log("🚀 ~ file: Gallery.tsx:234 ~ handleChange ~ images:", images)
    PropertyStore.setImages(images)
    setImages(images);
    galleryService.updateResource(newImage.id, { is_public: newImage.is_public })
  }

  const handleUrlsChange = (e: any) => {
    const name: string = e.currentTarget.name;
    const value: string = e.currentTarget.value;

    setState({ ...state, [name]: value })
  }

  // const SortableList = () => {

    // const DragHandle = () =>
    //   <div style={{
    //     display: 'flex', justifyContent: 'flex-end', padding: '0 5px', position: 'absolute',
    //     zIndex: 2, right: '8px', color: 'white', cursor: 'pointer'
    //   }}>
    //     <MenuOutlined className='drag-menu' style={{ padding: '5px' }} />
    //   </div>;

    // const SortableItem = ({ item, index }: { item: any, index: number }) => {
    //   return (
    //     <Col key={item.sortKey} className='gallery-item'>
    //       {/* <DragHandle /> */}
    //       <div className="ant-upload-list ant-upload-list-picture-card relative">
    //         <div className="ant-upload-list-item ant-upload-list-item-done" style={{ width: '190px', height: '118px', padding: 0, margin: 0 }}>
    //           <div className="ant-upload-list-item-info">
    //             {item.uid ? <Spin style={{ position: "absolute" }} className="center-spinner-cont" tip={`${t('properties.new.status.published')}...`} /> :
    //               <img className="ant-upload-list-item-thumbnail" src={item.url && item.url.thumb || ''} width='100%' height='118' />
    //             }
    //           </div>
    //           <span className="ant-upload-list-item-actions">
    //             <EyeOutlined onClick={() => showPreviewImage(item)} />
    //             <DeleteOutlined className="ml-5" onClick={() => deleteImage(item)} />
    //           </span>
    //         </div>
    //       </div>
    //       <Select style={{ width: '98%' }} onChange={() => handleChange(item, index)} value={item.is_public?.toString()}>
    //         <Option value='true'><GlobalOutlined className='mr-5' /> {t('public')}</Option>
    //         <Option value='false'><LockOutlined className='mr-5' />{t('private')}</Option>
    //       </Select>
    //     </Col>
    //   )};

  //   const SortableList = SortableContainer(({ items }: { items: Array<any> }) => {
  //     return (
  //       <Row>
  //         {items?.map((item, index) => (
  //           <SortableItem key={`item-${index}`} index={index} /> //item={item} />
  //         ))}
  //         <Col span={6} className='gallery-item button-upload'>
  //           <span className='avatar-uploader'>
  //             <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
  //               <span className="ant-upload pos-r" role="button">
  //                 <Input
  //                   type='file'
  //                   multiple
  //                   accept='.jpg, .jpeg, .png, .bmp, .gif'
  //                   onChange={onUploadImage}
  //                   style={{ opacity: 0, position: 'absolute', fontSize: '100px', top: 0, left: 0, padding: '72px' }}
  //                 />
  //                 <PlusOutlined className="anticon anticon-plus" />
  //                 <div className="ant-upload-text">{t('upload')}</div>
  //               </span>
  //             </div>
  //           </span>
  //         </Col>
  //       </Row>
  //     );
  //   });

  //   // return <SortableList items={state.images} onSortEnd={onSortEnd} axis='xy' useDragHandle />;
  //   return <SortableList onSortEnd={onSortEnd} axis='xy' useDragHandle />;
  // }

    const { previewVisible, previewImage, demo_url, video_url } = state;
    const disable = !!currentImages.find((i: any) => i.uid);
    return (
      <div>
        <div className="w-100p">
          <Modal visible={previewVisible} footer={null} onCancel={handleImageCancel} className='gallery-preview' maskClosable={false}>
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>

          <div className='gallery-content'>
            <h3 className='mb-30'>{t('properties.new.steps.gallery')}</h3>
            <div>
              <ReactSortable
                list={currentImages}
                setList={setImages}
                className="d-fx fxw-w"
                invertSwap={true}
                filter=".button-upload"
                onEnd={onSortEnd}
                onMove={(e) => {
                  return e.related.className.indexOf('button-upload') === -1;
                }}
              >
                {currentImages?.map((item: any, index: number) => (
                   <Col key={item.sortKey} className={cn('gallery-item', {"button-upload": disable})}>
                    {/* <DragHandle /> */}
                    <div className="ant-upload-list ant-upload-list-picture-card relative">
                      <div className="ant-upload-list-item ant-upload-list-item-done" style={{ width: '190px', height: '118px', padding: 0, margin: 0 }}>
                        <div className="ant-upload-list-item-info">
                          { item.uid ? <Spin style={{ position: "absolute" }} className="center-spinner-cont" tip={`${t('properties.new.status.published')}...`} /> :
                            <img className="ant-upload-list-item-thumbnail" src={item.url && item.url.thumb || ''} width='100%' height='118' />
                          }
                        </div>
                        { !disable && <span className="ant-upload-list-item-actions">
                          <EyeOutlined onClick={() => showPreviewImage(item)} />
                          <DeleteOutlined className="ml-5" onClick={() => deleteImage(item)} />
                        </span>
                        }
                      </div>
                    </div>
                    <Select style={{ width: '98%' }} onChange={() => handleChange(item, index)} value={item.is_public?.toString()} disabled={disable}>
                      <Option value='true'><GlobalOutlined className='mr-5' /> {t('public')}</Option>
                      <Option value='false'><span className="text-red" ><LockOutlined className='mr-5' />{t('private')}</span></Option>
                    </Select>
                  </Col>
                ))}
                <Col span={6} className='d-fx button-upload'>
                  <span className='avatar-uploader relative p-5'>
                    <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
                      <span className="ant-upload pos-r cr-p" role="button">
                        <Input
                          type='file'
                          multiple
                          accept='.jpg, .jpeg, .png, .bmp, .gif'
                          onChange={onUploadImage}
                          style={{ opacity: 0, position: 'absolute', fontSize: '100px', top: 0, left: 0, padding: '72px' }}
                        />
                        <PlusOutlined className="anticon anticon-plus" />
                        <div className="ant-upload-text">{t('upload')}</div>
                      </span>
                    </div>
                  </span>
                </Col>
              </ReactSortable>
            </div>
          </div>
          <Row align='middle' className='mt-30 mb-30'>
            <Col span={6} className='ta-r'><span className='mr-10'>{t('gallery.demoUrl')}</span></Col>
            <Col span={14}>
              <Input name='demo_url' placeholder='https://matterport.com/' onChange={handleUrlsChange} value={demo_url} />
            </Col>
          </Row>
          <Row align='middle'>
            <Col span={6} className='ta-r'><span className='mr-10'>{t('gallery.video')}</span></Col>
            <Col span={14}>
              <Input name='video_url' placeholder='https://youtube.com/' onChange={handleUrlsChange} value={video_url} />
            </Col>
          </Row>
        </div>
        <Row>
          <Col span={6}></Col>
          <Col span={18}>
            <div className="form-controls">
              {getButtons()}
            </div>
          </Col>
        </Row>
      </div>
    )
})

export default Gallery;
