import { Route, Routes } from 'react-router-dom';
import NewContact from '../modules/contacts/containers/New';
import EditContact from '../modules/contacts/containers/Edit';
import Contacts from '../modules/contacts/containers/List';
import Requests from '../modules/requests/containers/List';
import Deals from '../modules/deals/containers/List';
import SingleContact from '../modules/contacts/containers/Single';
import Properties from '../modules/properties/containers/List';
import NewProperty from '../modules/properties/containers/New';
import EditProperty from '../modules/properties/containers/Edit';
import SingleProperty from '../modules/properties/containers/Single';
// import GeneralReports from '../modules/reports/containers/General';
// import PromotionReports from '../modules/reports/containers/Promotion';
// import SaleReports from '../modules/reports/containers/Sale';
import Overview from 'submodules/contacts/containers/Overview';
import Owner from 'submodules/contacts/containers/Owner';
import ContactDeals from 'submodules/contacts/containers/DealsList';
import ContactRequests from 'submodules/contacts/containers/RequestsList';
import Rents from 'submodules/contacts/containers/Rent';
import Notes from 'submodules/contacts/containers/Notes';
import Emails from 'submodules/emails/containers/index';
import Tasks from 'submodules/contacts/containers/Tasks';
import Files from 'submodules/contacts/containers/Files';
import Deal from 'submodules/contacts/containers/Deal';
import Clients from '../modules/clients/containers/List';
import EditClient from '../modules/clients/containers/Edit';
import NewClient from '../modules/clients/containers/New';
import SingleClient from '../modules/clients/containers/Single';
import ClientOverview from 'submodules/clients/containers/Overview';
import ClientDeals from 'submodules/clients/containers/DealsList';
import ClientContactRequests from 'submodules/clients/containers/RequestsList';
import ClientRents from 'submodules/clients/containers/Rent';
import ClientNotes from 'submodules/clients/containers/Notes';
import ClientEmails from 'submodules/clients/containers/Emails';
import ClientTasks from 'submodules/clients/containers/Tasks';
import ClientFiles from 'submodules/clients/containers/Files';
import ClientDeal from 'submodules/clients/containers/Deal';
import Owners from '../modules/owners/containers/List';
import EditOwner from '../modules/owners/containers/Edit';
import NewOwner from '../modules/owners/containers/New';
import SingleOwner from '../modules/owners/containers/Single';
import OwnerOverview from 'submodules/owners/containers/Overview';
import OwnerContactRequests from 'submodules/owners/containers/RequestsList';
import OwnerRents from 'submodules/owners/containers/Rent';
import OwnerNotes from 'submodules/owners/containers/Notes';
import OwnerEmails from 'submodules/owners/containers/Emails';
import OwnerTasks from 'submodules/owners/containers/Tasks';
import OwnerFiles from 'submodules/owners/containers/Files';
import OwnerProperties from 'submodules/owners/containers/Properties';
import Profile from '../modules/profile/containers';
import PropertyDeals from '../modules/properties/containers/Single/containers/Tabs/Deals'
import PropertyDetails from '../modules/properties/containers/Single/containers/Tabs/Details'
import PropertyOverview from '../modules/properties/containers/Single/containers/Tabs/Overview'
import PropertyPromotions from '../modules/properties/containers/Single/containers/Tabs/Promotions'
import PropertyRents from '../modules/properties/containers/Single/containers/Tabs/Rents'
import PropertyRequests from '../modules/properties/containers/Single/containers/Tabs/Request'
import PropertyTasks from '../modules/properties/containers/Single/containers/Tabs/Tasks'
import PropertyFiles from '../modules/properties/containers/Single/containers/Tabs/Files'
import PropertyNotes from '../modules/properties/containers/Single/containers/Tabs/Notes'

type Props = {}

const Manager = ({}: Props) => {
  return (
    <Routes>
      <Route path='*' element={
        <div style={{marginTop: "200px"}}>
          <div className='noAccess'>
            <h1>404</h1>
            <div style={{fontSize: "20px"}}>NOT FOUND</div>
          </div>
        </div>
        }
      />
      <Route element={<Clients />} path='/' />
      <Route element={<NewContact />} path='/contacts/new' />
      <Route element={<EditContact />} path='/contacts/:id/edit' />
      <Route element={<SingleContact />} path='/contacts/:id' />
      <Route element={<ContactDeals />} path="/contacts/:id/deals" />
      <Route element={<Rents />} path="/contacts/:id/rents" />
      <Route element={<ContactRequests />} path="/contacts/:id/requests" />
      <Route element={<Notes />} path="/contacts/:id/notes" />
      <Route element={<Emails />} path="/contacts/:id/emails" />
      <Route element={<Tasks />} path="/contacts/:id/tasks" />
      <Route element={<Files />} path="/contacts/:id/files" />
      <Route element={<Overview />} path="/contacts/:id/overview" />
      <Route element={<Owner />} path="/contacts/:id/owner" />
      <Route element={<Deal />} path="/contacts/:id/deals/:deal_id" />
      <Route element={<Requests />} path='/requests' />
      <Route element={<Contacts />} path='/contacts' />
      <Route element={<NewProperty />} path='/properties/new' />
      <Route element={<EditProperty />} path='/properties/:id/edit' />
      <Route element={<SingleProperty />} path='/properties/:id' />
      <Route element={<PropertyOverview />} path="/properties/:id/overview" />
      <Route element={<PropertyDeals />} path="/properties/:id/deals" />
      <Route element={<PropertyRents />} path="/properties/:id/rents" />
      <Route element={<NewClient />} path='/clients/new' />
      <Route element={<EditClient />} path='/clients/:id/edit' />
      <Route element={<SingleClient />} path='/clients/:id' />
      <Route element={<Clients />} path='/clients' />
      <Route element={<ClientOverview />} path="/clients/:id/overview" />
      <Route element={<ClientDeal />} path="/clients/:id/deals/:deal_id" />
      <Route element={<ClientDeals />} path="/clients/:id/deals" />
      <Route element={<ClientRents />} path="/clients/:id/rents" />
      <Route element={<ClientContactRequests />} path="/clients/:id/requests" />
      <Route element={<ClientNotes />} path="/clients/:id/notes" />
      <Route element={<ClientEmails />} path="/clients/:id/emails" />
      <Route element={<ClientTasks />} path="/clients/:id/tasks" />
      <Route element={<ClientFiles />} path="/clients/:id/files" />
      <Route element={<NewOwner />} path='/owners/new' />
      <Route element={<EditOwner />} path='/owners/:id/edit' />
      <Route element={<SingleOwner />} path='/owners/:id' />
      <Route element={<Owners />} path='/owners' />
      <Route element={<OwnerOverview />} path="/owners/:id/overview" />
      <Route element={<OwnerProperties />} path="/owners/:id/properties" />
      <Route element={<OwnerRents />} path="/owners/:id/rents" />
      <Route element={<OwnerContactRequests />} path="/owners/:id/requests" />
      <Route element={<OwnerNotes />} path="/owners/:id/notes" />
      <Route element={<OwnerEmails />} path="/owners/:id/emails" />
      <Route element={<OwnerTasks />} path="/owners/:id/tasks" />
      <Route element={<OwnerFiles />} path="/owners/:id/files" />
      <Route element={<PropertyRequests />} path="/properties/:id/requests" />
      <Route element={<PropertyDetails />} path="/properties/:id/details" />
      <Route element={<PropertyPromotions />} path="/properties/:id/promotions" />
      <Route element={<PropertyNotes />} path="/properties/:id/notes" />
      <Route element={<PropertyTasks />} path="/properties/:id/tasks" />
      <Route element={<PropertyFiles />} path="/properties/:id/files" />
      <Route element={<Properties />} path='/properties'/>
      {/* <Route element={<PromotionReports />} path='/report/requests' />
      <Route element={<SaleReports />} path='/report/sale' />
      <Route element={<GeneralReports />} path='/reports' /> */}
      <Route element={<Deals />} path='/deals' />
      <Route element={<Profile />} path='/profile' />
    </Routes>
  );
}

export default Manager;