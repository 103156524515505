import { FC, useEffect, useState, useCallback} from 'react';
import { observer} from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ownerStore from 'stores/owner';
import workspaceStore from 'stores/workspace';
import { Link } from 'react-router-dom';
import { debounce, isEmpty, uniqueId } from 'lodash';
import loginStore from 'modules/login/loginStore';
import { Table, Button, Modal, Dropdown, Input, Divider } from 'antd';
import { CONTACT_TYPES } from 'constants/mixed'
import { EditOutlined, DeleteOutlined, EllipsisOutlined, HomeOutlined, UserOutlined, SolutionOutlined } from "@ant-design/icons";
import { renderMenu } from "utils/menu";

import FilterOwners from '../../components/Filter/FilterContacts';
import DateByCreator from 'components/DateByCreator';
import ListContainer from 'modules/ListContainer';
import { Helmet } from 'react-helmet';
import { IOwner } from 'declarations/contact';

interface IData {
  contact?: IOwner;
}

interface IOwnerResources {
  id?: number;
  value: string
}

export const getContactIcon = (type: number, className?: string) => {
  switch (type) {
    case 0:
      return <UserOutlined className={className} />
    case 1:
      return <HomeOutlined className={className} />
    case 2:
      return <SolutionOutlined className={className} />
    default:
      return;
  }
}


const List: FC<IData> = observer(() => {

  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    ownerStore.fetchOwners();
    return () => {
      ownerStore.clear();
      setSearch("");
    }
  }, [workspaceStore.selectedId])

  const columns = [
      {
        title: t('owners.table.type'),
        dataIndex: 'owner_type',
        width: 130,
        key: 'owner_type',
        render: (type: keyof typeof CONTACT_TYPES, owner: IOwner) => (
          <div>{getContactIcon(owner.owner_type, "mr-10")}
          <span>{CONTACT_TYPES[owner.owner_type]}</span>
        </div>
        )
      }, {
        title: t('owners.table.name'),
        dataIndex: 'name',
        key: 'name',
        render: (name: string, owner: IOwner) => renderName(owner)
      }, {
        title: t('owners.properties'),
        width: 60,
        dataIndex: 'properties_count',
        key: 'properties_count',
      }, {
        title: t('contacts.table.language'),
        dataIndex: 'languages',
        width: 100,
        key: 'languages',
        render: (languages: string[], owner: IOwner) => (!isEmpty(languages) || !isEmpty(owner.other_languages)) && <div className='languages-list ws-nw'>
          <span className=''>{renderLanguages(languages)}</span>
          <Divider />
          {(owner.other_languages || []).length > 0 && <span>{renderLanguages(owner.other_languages)}</span>}
        </div>
      }, {
        title: t('owners.table.created_at'),
        dataIndex: 'created_at',
        width: 180,
        key: 'created_at',
        render: (_: string, owner: IOwner) => <DateByCreator resource={owner} />
      }, {
        title: '',
        width: 60,
        dataIndex: 'actions',
        key: 'id',
        render: (name: string, owner: IOwner) => <Dropdown menu={{ items: renderMenu(getActionComponent(owner)) }} trigger={['click']}>
          <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
        </Dropdown>
      }
    ];

  const renderName = (owner: IOwner) => {
    return (
      <div>
        <p><Link target="_blank" to={`/owners/${owner.id}/overview`} className='link'>{owner.name}</Link></p>
        {(owner.phones_attributes || []).map((phone: IOwnerResources) => <p key={uniqueId()}>{phone.value}</p>)}
        {(owner.emails_attributes || []).map((email: IOwnerResources) => <p key={uniqueId()}>{email.value}</p>)}
      </div>
    )
  }


  const renderLanguages = (languages: Array<string>) => {
    return (languages || []).map((language: string) =>
      <img key={uniqueId()} className='flag' src={require(`../../../../images/flags/${language}.png`)} />
    )
  }

  const showConfirm = (owner: IOwner) => {
    modal.confirm({
      title: t('owners.checkDelete'),
      content: '',
      closable: true,
      onOk: async () => {
        await ownerStore.deleteOwner(owner.id)
        ownerStore.fetchOwners();
      },
    });
  }

  const getActionComponent = (owner: IOwner) => {

    return [
      {
        key:`edit_${owner.id}`,
        label: <Link to={`/owners/${owner.id}/edit`} className='edit-title'>
          <EditOutlined className='mr-5' />
          {t('owners.table.edit')}
        </Link>
      },
      {
        key:`delete_${owner.id}`,
        label: <div onClick={() => showConfirm(owner)}>
          <span className='delete-title'>
            <DeleteOutlined className='mr-5' />
            {t('owners.table.delete')}
          </span>
        </div>,
        disabled: !loginStore.isAdmin
      }
    ]
  }


  const setSearchText = useCallback(debounce((value) => {
    ownerStore.updateFilter({ q: value })
    ownerStore.fetchOwners({}, 1)
    }, 500),
    []
    )

  const onSearch = (e: any) => {
    const value = e.target.value;
    setSearch(value)
    setSearchText(value);
  }

  const changePagination = (page: number) => {
    ownerStore.fetchOwners(ownerStore.filterKeys, page);
  }

  const exportOwners = () => {
    ownerStore.export()
  }


  if (!(ownerStore && ownerStore.values)) {
    return null;
  }

  const paginationProps = {
    pageSize: 10,
    onChange: changePagination,
    total: ownerStore.count,
    showSizeChanger: false,
    current: typeof ownerStore.page == "number" ? ownerStore.page : 1,
  }

  return (
    <ListContainer
      title={<span>{`${t('owners.header')} (${ownerStore.count})`}</span>}
      sidebar={<FilterOwners />}
      loading={ownerStore.fetchInProgress}
      extra={
        <>
          <Input.Search
            placeholder="Search ..."
            value={search}
            onChange={onSearch}
            size="large"
            className='h-100p'
            allowClear={true}
          />
          {(loginStore.isAdmin || loginStore.isGeneralManager) && <Button
            type='primary'
            size='large'
            loading={ownerStore.exportInProgress}
            onClick={exportOwners}>
              {t('owners.export')}
          </Button>}
          <Link to='/owners/new'><Button type='primary' size='large'>{t('owners.addOwner')} </Button></Link>
        </>
      }


    >
      <Helmet>
        <title>{t("owners.header")}</title>
      </Helmet>
      <Table
        dataSource={ownerStore.values}
        columns={columns}
        bordered
        loading={ownerStore.fetchInProgress}
        pagination={paginationProps}
        className='general-table'
      />
      {contextHolder}
    </ListContainer>
  );
});

export default List;
