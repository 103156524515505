import { FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'
import PropertyList from 'modules/properties/components/List/ListTable';
import AppLayout from "modules/owners/containers/Single/components/Layout";
import { HomeOutlined } from "@ant-design/icons";
import { Row } from "antd";


const Properties: FC = observer(() => {

  const { t } = useTranslation();
  const { id } = useParams();
  const propertyFilters = { owner_id: Number(id) }

  return (
    <AppLayout>
      <div className="tab">
        <div className='portlet'>
          <div className='portlet-head'>
            <Row className='py-5' justify='space-between' align='middle'>
              <h5><HomeOutlined className='mr-10 fs-20' />{t('ownerRequirements')}</h5>
            </Row>
          </div>

          <div className='portlet-body'>
            <PropertyList filter={propertyFilters} hidePagination={true} hideSearch={true} />
          </div>
        </div>
      </div>
    </AppLayout>
  );
})

export default Properties;
