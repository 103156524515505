import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import {reportsService} from 'services/reportService';
import ProgressStore from 'stores/progress';
import ReportStore from 'stores/report';
import LocaleStore from 'stores/locale';
import { useTranslation } from 'react-i18next';
import { Tooltip, Table, Row, Col, Card, DatePicker } from 'antd';
import { LOAD_REPORTS } from 'constants/action';
import { Helmet } from 'react-helmet';
import { InfoCircleOutlined, DownloadOutlined, AreaChartOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const Email: FC = observer((props) => {
  const { t } = useTranslation();
  const lng = LocaleStore.locale;

  useEffect(() =>{
    reportsService.loadResources('email', {})
    return () => ReportStore.clearData();
  }, [])

  const onFilterDate = (dates: any) => {
    let dataFilter = {};
    dataFilter = { 'date_from':  dates[0], 'date_to': dates[1] }
    reportsService.loadResources('email', dataFilter)
  }

  const changeDateFilter = (dates: any) => {
    if(!(dates[0] && dates[1])) {
      reportsService.loadResources('sale', {})
      return
    }
    onFilterDate(dates)
  }

  const columns = [
    {
      title: t('manager'),
      dataIndex: 'name',
      key: 'name'
    }, {
      title: t('reports.incomingEmails'),
      dataIndex: 'incoming_count',
      key: 'incoming_count'
    }, {
      title: t('reports.outgoingEmails'),
      dataIndex: 'outgoing_count',
      key: 'outgoing_count',
    }, {
      title: t('reports.min'),
      dataIndex: 'min',
      key: 'min',
    }, {
      title: t('reports.max'),
      dataIndex: 'max',
      key: 'max',
    }, {
      title: t('reports.avarage'),
      dataIndex: 'average',
      key: 'average',
    }, {
      title: t('reports.late'),
      dataIndex: 'late',
      key: 'late',
    }
  ];

  const getData = () => {
    const values = ReportStore.values && ReportStore.values.managers;
    return (values || []).map((item: any) => {
      return {
        name: item.name,
        incoming_count: item.incoming_count,
        outgoing_count: item.outgoing_count,
        min: parseTime(item.minimum),
        max: parseTime(item.maximum),
        average: parseTime(item.average),
        late: item.late
      }
    })
  }

  const parseTime = (minutes: number) => {
    if(minutes > 60 ) {
      const hours: number = Math.round(minutes / 60);
      const min = Math.round(minutes - (hours * 60));
      return `${hours}h ${min}m`
    }

    return `${minutes}m`
  }

    const reportData = ReportStore.values;
    const tooltipTexts: any = {
      total_sales: t('reports.totalSales'),
      lead_provided: t('reports.leadProvided'),
      property_provided: t('reports.propertyProvided'),
      lead_property: t('reports.leadProperty')
    }

    if(!reportData) {
      return null;
    }
    return (
      <div className="expense-page page">
        <Helmet>
          <title>{t("reports.emailReport")}</title>
        </Helmet>
        <Row align='middle' justify='space-between' className='mb-40'>
          <Col>
            <h3>{t('reports.emailReport')}</h3>
          </Col>
          <Col>
          <RangePicker
            // ranges={{
            //   Today: [moment(), moment()],
            //   'This Month': [moment().startOf('month'), moment().endOf('month')],
            // }}
            // format="DD.MM.YYYY HH:mm:ss"
            onOk={onFilterDate}
            onChange={changeDateFilter}
          />
          </Col>
        </Row>
        <Row gutter={30} justify='space-between' className='mb-30'>
          <Col span={6}>
            <Card
              title={t('reports.minRespTime')}
              extra={<Tooltip placement="top" title={tooltipTexts['total_sales']}><InfoCircleOutlined /></Tooltip>}
            >
              <div className='fs-25'>{parseTime(reportData.minimum)}</div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              title={t('reports.maxRespTime')}
              extra={<Tooltip placement="top" title={tooltipTexts['lead_provided']}><InfoCircleOutlined /></Tooltip>}
              >
              <div className='fs-25'>{parseTime(reportData.maximum)}</div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              title={t('reports.avarageTime')}
              extra={<Tooltip placement="top" title={tooltipTexts['property_provided']}><InfoCircleOutlined /></Tooltip>}
            >
              <div className='fs-25'>{parseTime(reportData.average)}</div>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              title={t('reports.lateResponses')}
              extra={<Tooltip placement="top" title={tooltipTexts['lead_property']}><InfoCircleOutlined /></Tooltip>}
            >
              <div className='fs-25'>{reportData.late}</div>
            </Card>
          </Col>
        </Row>
        <Row align='middle' justify='space-between' className='pxy-20 report-table-header'>
          <Col>
            <div className='fs-16'>
              <AreaChartOutlined className='mr-20 fs-20' />
                {t('reports.respTime')}
            </div>
          </Col>
          <Col>
            <span className='c-p'><DownloadOutlined className='fs-20' /></span>
          </Col>
        </Row>
        <div className='page-content'>
          <Table
            dataSource={getData()}
            columns={columns}
            className='general-table w-100p'
            loading={ProgressStore.isLoading(LOAD_REPORTS)}
            bordered
            pagination={false}
          />
        </div>
      </div>
    );

})

export default Email;
