import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col, Dropdown, Menu, Tooltip, Avatar, Tag, Collapse } from 'antd';
import moment from 'moment';
import { emailService } from 'services/emailService';
import loginStore from 'modules/login/loginStore';
import { IContact } from 'contact';
import { IEmail } from 'email';
import { FILE_TYPES_ICONS, COLORS } from 'constants/mixed'
import EmailForm from '../components/Form';
import { EllipsisOutlined, EditOutlined, DeleteOutlined, UserOutlined, DoubleLeftOutlined } from "@ant-design/icons";

interface IData {
  id?: number,
  contact: IContact,
  contactType: string;
  email: IEmail,
  isActivity?: boolean;
}

const confirm = Modal.confirm;
const { Panel } = Collapse;

const Item: FC<IData> = observer((props) => {
  const [isReply, setIsReply] =  useState(false);
  const { t } = useTranslation();

  const handleEdit = () => {
    const { email } = props;
    emailService.updateResource(email, { is_public: !email.is_public })
  }

  const showConfirm = (email: IEmail) => {
    const { contact } = props;

    confirm({
      title: t('emails.confirmDelete'),
      content: '',
      onOk() {
        return new Promise((resolve, reject) => {
          emailService.deleteResource(email, contact.id, resolve)
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }


  const renderActions = (email: IEmail) => {
    return (
      <Menu>
        <Menu.Item onClick={handleEdit} key="actions1"><EditOutlined className='mr-5' /> {email.is_public ? 'Make private' : 'Make public'}</Menu.Item>
        <Menu.Item onClick={() => showConfirm(email)} key="action2">
          <DeleteOutlined className='mr-5' /> { t('emails.deleteNow') }
        </Menu.Item>
      </Menu>
    )
  }

  const renderFiles = (file: { url: string, filename: string, filetype: string }) => {
    const iconName = FILE_TYPES_ICONS[file.filetype] || 'anyfile.png'
    return (
      <Row justify="start" className='mb-if-not-single'>
        <Col>
          <img className='fs-42 mr-10' width='40' src={require(`images/files/${iconName}`)} />
        </Col>
        <Col>
          <p className="fs-14">{file.filename}</p>
          <span> <a href={file.url} target='_blank' download className='link'>
            {t('emails.download')}
          </a></span>
        </Col>
      </Row>
    )
  }

  const checkSenderName = (email: IEmail) => {
    const { contact } = props;
    const isSentContact = contact.emails_attributes.find((item: any) => item.value == email.send_from)
    if(isSentContact){
      return contact.name
    } else {
      return email.creator && email.creator.name || ''
    }
  }

  const renderCardContent = () => {
    const { email } = props;
    const replyIndex = email.message.indexOf('<div class="gmail_quote">')
    let message = email.message;
    let messageReply='';

    if(replyIndex > 0) {
      message = email.message.substring(0, replyIndex)
      messageReply = email.message.substring(replyIndex)
    }

    return (
      <div>
        <div className='mb-20'>
          <span ><strong>{t('emails.from')}: </strong>{email.send_from}</span>
        </div>
        <div className='mb-20'>
          <span ><strong>{t('emails.to')}: </strong>{email.send_to}</span>
        </div>
        <div className='mb-20'>
          <span><strong>{t('emails.subject')}:</strong> {email.subject}</span>
        </div>
        <div className='mb-20'>
          <div dangerouslySetInnerHTML={{__html: message}} />
        </div>
        {messageReply &&
          <div className='mb-20'>
            <Collapse>
              <Panel header="Reply message(s)" key={`reply_${email.id}`}>
                <div dangerouslySetInnerHTML={{__html: messageReply}} />
              </Panel>
            </Collapse>
          </div>
        }
        <div className='file-title'>
          <div>
            {email.attachments.map((file: any) => renderFiles(file))}
          </div>
        </div>
      </div>
    )
  }

  const reply = () => setIsReply(true);

  const cancelReply = () => setIsReply(false);

  const { email, contact } = props;
  const createdDate = moment(email.created_at).format('D MMMM YYYY [at] HH:mm');
  const tooltipText = t('emails.tooltipText');
  const user = loginStore.session.user.user;
  const canReply = user.has_connection && user.has_connection.email != email.send_from;

  return (
    <div className='contact-card-item w-100p'>
      <Row className='contact-card-header' justify="space-between" align='middle'>
        <Col>
          <Row justify="start" align='middle'>
            <Col>
              <Avatar size={35} icon={<UserOutlined />} className='mr-10' />
            </Col>
            <Col>
              <p>{checkSenderName(email)} {t('emails.sendAnEmail')}</p>
              <span>{createdDate}</span>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row justify="end" align='middle'>
            {`${email.is_public}` == 'false' &&
              <Col>
                <Tooltip placement="top" title={tooltipText}>
                  <Tag color={COLORS[3]}>{t('private')}</Tag>
                </Tooltip>
              </Col>
            }
            { (loginStore.isAdmin || user.id == email.user_id) &&
              <Col>
                <Dropdown overlay={() => renderActions(email)} trigger={['click']}>
                  <div className='list-dropdown-button'>
                    <EllipsisOutlined className='three-dots ml-15' />
                  </div>
                </Dropdown>
              </Col>
            }
          </Row>
        </Col>
      </Row>
      <div className='contact-card-content'>
        {renderCardContent()}
      </div>

      {canReply && <div className='contact-card-footer'>
          <span ><DoubleLeftOutlined className='mr-10' /><a onClick={reply}>{t('emails.reply')}</a></span>
        </div>
      }
      {isReply &&
        <div className='mb-20'>
          <EmailForm
            contact={contact}
            onCancelButton={cancelReply}
            isReply={true}
            contactType={props.contactType}
            email={{
              thread_id: email.thread_id,
              reply_message_id: email.message_id,
              subject: email.subject,
              is_public: email.is_public,
              send_to: email.send_from,
              message: email.message
            }}
          />
        </div>
      }
    </div>
  );
})

export default Item;
