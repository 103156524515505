import { FC, useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { emailService } from 'services/emailService';
import { dealPropertyService } from 'submodules/clients/DealsPropertyService';
import ProgressStore from 'stores/progress';
import loginStore from 'modules/login/loginStore';
import { Form, Button, Input, Select, Row, Col, Upload, message as AntMessage } from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import { Editor } from '@tinymce/tinymce-react';
import { EDITOR_OPTIONS } from 'constants/mixed';
import { CREATE_EMAIL } from 'constants/action';
import ownerStore from "stores/owner";
import clientStore from "stores/client";

import { IContact } from "declarations/contact";
import { get, some } from 'lodash';

interface IData {
  contact: IContact,
  contactType: string;
  isActivity?: boolean;
  onCancelButton(): void;
  isReply?: boolean;
  email?: any;
  body?: any;
  deal_id?: number;
}
const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const InitialEmailValue = {
  id: null,
  subject: '',
  message: '',
  send_to: '',
  deal_id: null,
  reply_message_id: '',
  thread_id: '',
  is_public: false
}

const EmailForm: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const editorRef = useRef<any>(null);
  const [messageApi, contextHolder] = AntMessage.useMessage();

  const [state, setState] = useState<any>({
    email: InitialEmailValue,
    fileLists: [],
  });

  useEffect(() => {
    const { email} = props
    setEmailValues(email || state.email);
    return () => form.resetFields();
  }, [])

  const setEmailValues = (defaultValues: any) => {
    const { email, body } = props
    let message = "";
    const user = loginStore.session.user.user;
    let EmailValues = defaultValues;
    if (props.contact) {
      EmailValues = { ...EmailValues, send_to: get(props.contact.emails_attributes, "[0]['value']", "")}
    }

    if(email) {
      message =
        `<br /><br /> <div class='gmail_quote'><blockquote style="margin:0 0 0 .8ex;border-left:1px #ccc solid;padding-left:1ex">${email.message}</blockquote></div>`
    }
    console.log('body = ', body)
    if(body) {
      message = body;
      EmailValues = {...EmailValues, deal_id: props.deal_id }
    }
    if(user.signature){
      message = `${message} <br /><br /><br /><br /><br /><br /> -- <div dir='ltr' class='gmail_signature' data-smartmail='gmail_signature'>
                <div dir='ltr'>${user.signature}</div></div>`
    }
    if(message) {
      form.setFieldsValue({...EmailValues, message})
      setState({
        ...state,
        email: {
          ...EmailValues,
          message
        }
      })
    }
  }

  const handleChange = (info: any) => {
    let fileLists = [...info.fileList];
    setState({ ...state, fileLists });
  }

  const validateFileSize = () => {
    let hasError;
    const newFileList = state.fileLists.map((item: any) => {
      if(item.size / 1024 / 1024 <= 8) {
        return item
      } else {
        hasError = true
        return { ...item, status: 'error' }
      }
    })

    form.setFieldsValue({attachments: newFileList})
    setState({ ...state, fileLists: newFileList})
    return !hasError
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { fileLists } = state;
    const { contact } = props;
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length) && validateFileSize()) {
      console.log("🚀 ~ file: Form.tsx:130 ~ handleSubmit ~ props.contactType:", props.contactType)
      const values = form.getFieldsValue();
      const data = new FormData();
      const newValues = {...state.email, ...values, contact_id: contact.id}
      data.append('letter[subject]', newValues.subject)
      data.append('letter[letterable_id]', contact.id.toString())
      data.append('letter[letterable_type]', props.contactType)
      data.append('letter[message]', newValues.message)
      data.append('letter[deal_id]', newValues.deal_id)
      data.append('letter[send_to]', newValues.send_to)
      data.append('letter[is_public]', newValues.is_public)
      data.append('letter[reply_message_id]', newValues.reply_message_id || '')
      data.append('letter[thread_id]', newValues.thread_id)
      fileLists.forEach((file: any) => {
        if (file.originFileObj) {
          data.append('letter[attachments][]', file.originFileObj)
        }
      })
        if(newValues.deal_id) {
          dealPropertyService.createFromDealResource(data, handleCancel)
        } else {
          emailService.createResource(data, contact.id, handleCancel).then(() => {
            emailService.loadResources({ letterable_id: contact.id, letterable_type: props.contactType, page: 1});
            if(props.contactType === "Owner") {
              ownerStore.fetchOwner(props.contact.id)
            } else {
              clientStore.fetchClient(props.contact.id)
            }
          }).catch((err) => {
            messageApi.error(err.response.data.message)
          })
        }
    }
  }


  const onVisibilityChange = (value: string) => {
    setState({
      ...state,
      email: {
        ...state.email,
        is_public: value
      }
    })
  }

  const handleEditorChange = (value: string) => {
    form.setFieldsValue({ message: value })
    // setState({
    //   ...state,
    //   email: {
    //     ...state.email, message: value
    //   }
    // })
  }

  const renderVisibilityText = (isPublic: boolean) => {
    let text;
    if (isPublic) {
      text = t('publicTextEmail');
    } else {
      text = t('privateTextEmail')
    }
    return <Row className='mb-20'><Col offset={6} className='visibility-text'>{text}</Col></Row>;
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  const beforeUpload = (file: any) => {
    const isLt2M = file.size / 1024 / 1024 <= 8;
    if (!isLt2M) {
      message.error(`${t('imageText')} 8MB!`);
    }
    return isLt2M;
  }


    const { contact, isReply } = props;
    const { is_public, message } = state.email;

    const uploadProps = {
      action: 'https://run.mocky.io/v3/14c0cf7e-11cd-47c5-a2c9-8b9c9deb4034',
      onChange: handleChange,
      multiple: true,
    };

    const handleCancel = () => {
      form.resetFields();
      setEmailValues(InitialEmailValue);
      props.onCancelButton();
    }

  const dummyRequest = (onSuccess: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <div className="notes-form">
      <Form form={form}>
        { !isReply && <Form.Item
            {...formItemLayout}
            rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
            label={t('emails.sendTo')}
            name="send_to"
          >
            <Select filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {contact.emails_attributes.map((email: any) => <Option value={email.value} key={`email_${email.id}`}>{email.value}</Option>)}
            </Select>
          </Form.Item>
        }
        { !isReply &&
          <Form.Item
            {...formItemLayout}
            label={t('emails.subject')}
            rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
            name="subject"
          >
            <Input placeholder={t('emails.subject')} />
          </Form.Item>
        }
        <Form.Item name="message" noStyle><Input type="hidden" /></Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const val = getFieldValue("message");
            return <Form.Item
              {...formItemLayout}
              label={t('message')}
            >
              <Editor
                apiKey='rivvkn9wdgkazq99qryveaoe1sn1tve74p0emmbyb8tn0gxm'
                value={val}
                ref={editorRef}
                init={{...EDITOR_OPTIONS}}
                onEditorChange={handleEditorChange}
              />
            </Form.Item>
          }}
        </Form.Item>
        { !isReply &&
          <Form.Item
              {...formItemLayout}
              label={t('emails.visibility')}
              initialValue={false}
              className="mb-0"
              name="is_public"
            >
              <Select>
                <Option value={true}>{t('public')}</Option>
                <Option value={false}>{t('private')}</Option>
              </Select>
          </Form.Item>
        }
        { !isReply &&
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const visibility = getFieldValue("is_public");
              return renderVisibilityText(visibility)
            }}
          </Form.Item>
        }
        <Form.Item
          {...formItemLayout}
          label={t('emails.attachments')}
          name="attachments"
        >
          <Row justify="space-between">
            {/* {getFieldDecorator('attachments', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              initialValue: state.fileLists
            })( */}
              <Upload
                {...uploadProps}
                defaultFileList={state.fileLists}
                customRequest={({ onSuccess }) => dummyRequest(onSuccess)}
              >
                <Button>
                  <UploadOutlined /> {t('emails.upload')}
                </Button>
              </Upload>
            {/* )} */}
          </Row>
        </Form.Item>
      </Form>
      <Row>
        <Col span={6} />
        <Col span={18}>
          <div className="form-controls">
            <Button
              type='primary'
              className='mr-10'
              onClick={handleSubmit}
              loading={ProgressStore.isLoading(CREATE_EMAIL)}
            >
              {t('emails.send')}
            </Button>
            <Button onClick={handleCancel}>{t('cancel')}</Button>
          </div>
        </Col>
      </Row>
      {contextHolder}
    </div>
  );
})

export default EmailForm;
