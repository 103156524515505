import { FC, useState, useEffect} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Form, Select, Input, Button, Row, Col } from 'antd';
import { SOURCE_CATEGORIES } from 'constants/mixed';
import { ISource } from 'source';
import { some } from 'lodash';


interface IData {
  handleOk(complex: any): any,
  handleCancel(): void,
  source?: ISource | null
}

const { Option } = Select;
const defaulState = {
  id: null,
  name: '',
  category: ''
}

const New: FC<IData> = observer((props) =>  {

  const [state, setState] = useState<any>({ ...defaulState });
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    const { source } = props;
    if(source) {
      setState({ ...state, ...source })
      form.setFieldsValue({...source})
    } else {
      setState({ ...state, ...defaulState })
    }
  }, [])

  // componentWillUpdate(nextProps: IData, nextState: any) {

  //   if (nextProps.source && nextProps.source.id != state.id) {
  //     setState({ ...state, ...nextProps.source })
  //   }

  //   if (!nextProps.source && state.id) {
  //     setState({ ...state, ...defaulState })
  //   }
  // }

  const handleSubmit = async () => {
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      const sourceValues = { ...state, ...values }
      props.handleOk(sourceValues);
    }
  }

  const getCategoryOptions = () => {
    return SOURCE_CATEGORIES.map((category: string) => <Option value={category}>{category}</Option>)
  }

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  return (
    <div className="add-user">
      <Form onFinish={handleSubmit} form={form}>
        <Form.Item
          {...formItemLayout}
          label={t('owners.table.name')}
          name="name"
          rules={[{ required: true, message: t('validates.cantBeEmpty')}]}
        >
          <Input placeholder={t('owners.table.name')} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={t('expenses.category')}
          name="category"
          rules={[{ required: true, message: t('validates.cantBeEmpty')}]}
        >
          <Select showSearch filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            { getCategoryOptions() }
          </Select>
        </Form.Item>
        <Row>
          <Col span={8}></Col>
          <Col span={16}>
            <div className="form-controls">
              <Button type="primary" onClick={handleSubmit} className='mr-10'>
                {t('save')}
              </Button>
              <Button onClick={() => props.handleCancel()}>
                {t('cancel')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
})

export default New;
