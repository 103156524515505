import { action } from 'utils/mobx';
import { request } from 'utils/mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import { message } from 'antd';
import contactStore  from 'stores/contact';
import activityStore  from 'stores/activity';
import fileStore  from 'stores/file';
import clientStore  from 'stores/client';
import propertyStore  from 'stores/property';
import { ATTACHMENT_URL } from 'constants/api';
import { LOAD_FILES, UPDATE_FILE } from 'constants/action';

import { Filter } from "declarations/filters";
import { IFile } from "declarations/file";

interface FilterType {
  attachable_id: number;
  attachable_type: string;
  isActivity?: boolean | undefined;
};

interface IResponse {
  status: number,
  data: any
}

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any;
  loadResourcesRequest(queryParams: FilterType): Promise<object>;
}

export class FileService implements Props {

  async loadResourcesRequest (params?: FilterType): Promise<{ status: number, data: Array<IFile>}> {
    return axios.get(ATTACHMENT_URL, {
      params
    })
  }

  @action({ action: LOAD_FILES, minRequestTime: 500 })
  async loadResources(params?: FilterType) {
    fileStore.setFetchInProgress(true);
    const response: { status: number, data: Array<IFile>} = await this.loadResourcesRequest(params);

    if (response.status) {
      let values = [];
      values = response.data;

      this.addValues(values);
    }

    fileStore.setFetchInProgress(false);
    return response;
  }


  async createResourceRequest(formdata: FormData) {
    return axios.post(ATTACHMENT_URL, formdata)
  }

  @action({ action: UPDATE_FILE, minRequestTime: 500 })
  async createResource(resource: FormData, params: FilterType, callback: Function) {
    fileStore.setSaveInProgress(true);
    const response: IResponse = await this.createResourceRequest(resource);

    if (response.status == 201) {
      callback();
      const ResourceStore = this.getStore(params);

      ResourceStore.addSelectedAttributes({files_count: response.data.files_count})
      this.loadResources({attachable_id: params.attachable_id, attachable_type: params.attachable_type});
      message.success('File created!')
    } else {
      message.error((response.data.message || []).join())
    }
    fileStore.setSaveInProgress(false);

  }

  async updateResourceRequest(resource: FormData, resource_id: number) {
    return axios.put(`${ATTACHMENT_URL}/${resource_id}`, resource)
  }

  @action({ action: UPDATE_FILE, minRequestTime: 500 })
  async updateResource(resource: FormData, resource_id: number, params: FilterType, callback: Function) {
    fileStore.setSaveInProgress(true);
    const response: { data: any, status: number } = await this.updateResourceRequest(resource, resource_id);

    if (response.status == 200) {
      params['isActivity'] ? this.updateActivityValue(response.data) : this.updateValue(response.data);
      // this.loadResources({attachable_id: params.attachable_id, attachable_type: params.attachable_type});
      message.success('File updated!')
      callback()
    }
    fileStore.setSaveInProgress(false);

  }

  async deleteResourceRequest(resource_id: number) {
    return axios.delete(`${ATTACHMENT_URL}/${resource_id}`)
  }


  async deleteResource(resource: IFile, callback: Function) {
    if(!resource.id) {
      return
    }
    fileStore.setDeleteInProgress(true);
    const response: { status: number } = await this.deleteResourceRequest(resource.id);

    if (response.status) {
      message.success('File deleted!!')
      this.loadResources({ attachable_id: resource.attachable_id, attachable_type: resource.attachable_type });
      callback()
    }
    fileStore.setDeleteInProgress(false);

  }

  getStore(resource: any) {
    if(resource.attachable_type == 'Property') {
      return propertyStore
    } else if (resource.attachable_type == 'Client') {
      return clientStore
    } else {
      return contactStore
    }
  }

  addValues(values: Array<IFile>) {
    fileStore.addValues(values);
  }
  updateValue(value: IFile) {
    fileStore.updateValue(value);
  }

  updateActivityValue(value: IFile) {
    activityStore.updateValue(value);
  }
}

export const fileService = new FileService();
