import { FC, useEffect, useState, useCallback } from 'react';
import { observer} from 'mobx-react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import { propertyService } from '../../PropertyService';
import PropertyStore from 'stores/property';
import ProgressStore from 'stores/progress';
import PropertiesList from '../../components/List/ListTable';
import { EXPORT_PROPERTIES } from 'constants/action';
import loginStore from 'modules/login/loginStore';
import { Helmet } from 'react-helmet';
import { Button, Input, Row, Col } from 'antd';
import FilterProperties from '../../components/Filter/FilterProperties'
import { useTranslation } from 'react-i18next';


interface IData {
  key: number,
  properties: [] | null,
  type: string | null
}

const Search = Input.Search;

const List: FC = observer(() => {

  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    PropertyStore.clearData();
    return () => {
      propertyService.resetFilters();
    }
  }, []);

  const setSearchText = useCallback(
    debounce(async (search) => {
    propertyService.changeFilters({q: search})
  }, 500),
  []
  )

  const exportProperties = () => {
    propertyService.exportResources()
  }

  const onSearch = (e: any) => {
    const val = e.target.value;
    setSearch(val)
    setSearchText(val);
  }

  const count = PropertyStore && PropertyStore.count;

  return (
    <div className="properties-page page">
      <Helmet>
        <title>{t("properties.header")}</title>
      </Helmet>
      <Row align='middle' justify='space-between' className='page-subheader'>
        <Col>
          <h3>{ t('properties.header') } ({count})</h3>
        </Col>
        <Col>
          <Row>
            <Col className='mr-10'>
              <Search
                placeholder={t('properties.search')}
                value={search}
                onChange={onSearch}
                size="large"
                className='h-100p'
                allowClear={true}
              />
            </Col>
            <Col>
              { (loginStore.isAdmin || loginStore.isGeneralManager) && <Button
                type='primary'
                size='large'
                className='mr-10'
                loading={ProgressStore.isLoading(EXPORT_PROPERTIES)}
                onClick={exportProperties}>
                  {t('contacts.export')}
              </Button>}
              <Link to='/properties/new'><Button type='primary' size='large'>{ t('properties.addProperty') } </Button></Link>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify='space-between' gutter={20} className='page-content'>
        <Col span={4}>
          <FilterProperties />
        </Col>
        <Col span={20}>
          <PropertiesList />
        </Col>
      </Row>
    </div>
  );
})

export default List;
