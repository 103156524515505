import {FC, useEffect} from 'react';
import { observer } from 'mobx-react';
import ownerStore from 'stores/owner';
import OwnerForm from '../../components/New/Form';
import { useParams, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import { IOwner } from "declarations/contact";

const Edit: FC = observer(() => {
  const owner: any = ownerStore.selected;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if(params.id) {
      (async () => {
        await ownerStore.fetchOwner(Number(params.id))
      })()
    }
    return () => ownerStore.clearSelected();
  }, [params.id]);

  const onCreateOwner = async (params: IOwner) => {
    await ownerStore.update(params.id, params)
    if(!ownerStore.saveError) {
      navigate('/owners')
      ownerStore.fetchOwners();
      message.success('Owner updated!')
    } else {
      message.error(ownerStore.saveError.response.data.message)
    }
  }

  const onCancelButton = () => {
    navigate('/owners');
  }


  if (!owner?.id) {
    return null;
  }

  return (
    <div className="edit-owner-page page">
      <div className='mb-30'>
        <h3 className='ta-c'>{t('owners.editOwner')}</h3>
      </div>
      <div>
        <div>
          <OwnerForm owner={owner} onCreateOwner={onCreateOwner} onCancelButton={onCancelButton} />
        </div>
      </div>
    </div>
  );
});

export default Edit;
