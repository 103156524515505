import { FC, useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { dealService } from 'services/DealService';
import { propertyTypesService } from 'services/propertyTypes';
import { TYPE_OPERATIONS, DEAL_STATUS_FROM_OPERATION, DEAL_STATUS_COLOR } from 'constants/mixed';
import dealStore from 'stores/deal';
import workspaceStore from 'stores/workspace';
import LocaleStore from 'stores/locale';
import ProgressStore from 'stores/progress';
import PropertyTypeStore from 'stores/type';
import loginStore from 'modules/login/loginStore';
import DateByCreator from 'components/DateByCreator';
import { name } from 'utils/localized';
import { LOAD_DEALS, EXPORT_DEALS } from 'constants/action';
import DealForm from 'submodules/clients/components/DealForm';
import FilterDeals from '../../components/Filter/FilterDeals'
import { Helmet } from 'react-helmet';
import { Table, Button, Menu, Row, Modal, Dropdown, Tag, Col } from 'antd';
import { EditOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";


import { IDeal } from 'declarations/deal';
import { PropertyType as IType } from 'declarations/propertyType';

const confirm = Modal.confirm;

const List:FC = observer((props) => {

  const [editDeal, setEditDeal] = useState<IDeal | null | undefined>(undefined)
  const { t } = useTranslation();
  const lng = LocaleStore.locale;

  useEffect(() => {
    dealService.loadResources()
    propertyTypesService.loadResources()
    return () => dealStore.clearData();
  }, [workspaceStore.selectedId])


  const showModal = (deal?: IDeal | null) => {
    setEditDeal(deal);
  }

  const showConfirm = (deal: IDeal) => {
    const filter = {}

      confirm({
        title: t('deals.confirmDelete'),
        content: t("deals.confirmDelete"),
        onOk() {
          return new Promise((resolve, reject) => {
            dealService.deleteResource(deal.id, filter, resolve)
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() { },
      });
  }

  const handleCancel = () => setEditDeal(undefined);


  const columns = [
      {
        title: t('deals.list.id'),
        dataIndex: 'id',
        key: 'id'
      }, {
        title: '',
        dataIndex: 'details',
        key: 'details'
      }, {
        title: t('deals.list.client'),
        dataIndex: 'client',
        key: 'client'
      }, {
        title: t('deals.list.newProperties'),
        dataIndex: 'relevant',
        key: 'relevant',
      }, {
        title: t('manager'),
        dataIndex: 'manager',
        key: 'manager',
      }, {
        title: t('deals.new.operation'),
        dataIndex: 'operation',
        key: 'operation',
      }, {
        title: t('deals.new.status'),
        dataIndex: 'status',
        key: 'status',
      }, {
        title: t('deals.new.propertyType'),
        dataIndex: 'property_type',
        key: 'property_type',
      }, {
        title: t('deals.new.budget'),
        dataIndex: 'budget',
        key: 'budget',
      }, {
        title: t('deals.list.created'),
        dataIndex: 'created_at',
        key: 'created_at',
      }, {
        title: t('deals.list.lastActivity'),
        dataIndex: 'updatad_at',
        key: 'updatad_at',
      }, {
        title: '',
        dataIndex: 'actions',
        key: 'actions'
      }
    ];

  const renderLastVisit = (data: string) => {
    if (!data) {
      return '';
    }

    const createdAtDate = moment(data).format('DD.MM.YYYY');
    const createdAtTime = moment(data).format('\\at kk:mm');
    return <div>{createdAtDate} <p>{createdAtTime}</p> </div>
  }

  const renderClient = (deal: IDeal) => {
    const contactManager = deal.client.manager;
    return <div>
      <p className='ws-nw'>{t('contact')}: <Link to={`/clients/${deal.client.id}/overview`} className='link'>{deal.client && deal.client.name}</Link></p>
      <p>{t('manager')}: {contactManager && contactManager.name || '-'}</p>
    </div>
  }

  const renderPropertyTypes = (deal: IDeal) => {
    const types = PropertyTypeStore.values;

    if (!deal.property_type) {
      return '-'
    }

    return types.map((type: IType) => {
      if ((deal.property_type || []).includes(type.id.toString())) {
        return <Tag color='#655dc5' className='mb-5'>{name(type, lng)}</Tag>
      }
    })

  }

  const handleOk = async (deal: IDeal) => {
    if(deal.id) {
      await dealService.updateResource(deal, () => setEditDeal(undefined))
    } else {
      await dealService.createResource(deal, {}, () => setEditDeal(undefined))
    }
  }

  const getData = () => {
    const deals = dealStore.values;
    return (deals || []).map((deal: any, index: number) => {
      return {
        id: deal.id,
        details: linkToDealDetails(deal),
        client: renderClient(deal),
        manager: <p className='ws-nw'>{deal.manager && deal.manager.name || '-'}</p>,
        relevant: <Tag color='#655dc5' className='mb-5'>+{deal.labels.length} { t('new') }</Tag>,
        operation: <span>{TYPE_OPERATIONS[deal.operation]}</span>,
        status: <Tag color={DEAL_STATUS_COLOR[deal.status]}>{DEAL_STATUS_FROM_OPERATION[deal.operation][deal.status]}</Tag>,
        property_type: renderPropertyTypes(deal),
        budget: <span className='ws-nw'>{deal.max_price ? `${deal.max_price} €`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '-'}</span>,
        created_at: <DateByCreator resource={deal} />,
        updatad_at: renderLastVisit(deal.updated_at),
        actions: <Dropdown overlay={getActionComponent(deal)} trigger={['click']}>
          <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
        </Dropdown>
      }
    })
  }

  const linkToDealDetails = (deal: IDeal) => {
    return <Link to={`/clients/${deal.client_id}/deals/${deal.id}`}>
      <Button type='primary'>{t('deals.viewDeal')}</Button>
    </Link>
  }

  const getActionComponent = (deal: IDeal) => (
    <Menu
      mode="horizontal"
      className='actons-menu'
    >
      <Menu.Item key={`edit_user_${deal.id}`} onClick={() => showModal(deal)}>
        <span className='edit-title'>
          <EditOutlined />
          {t('owners.table.edit')}
        </span>
      </Menu.Item>
      { loginStore.isAdmin &&
        <Menu.Item key={`delete_deal_${deal.id}`} onClick={() => showConfirm(deal)}>
          <span className='delete-title'>
            <DeleteOutlined />
            {t('owners.table.delete')}
          </span>
        </Menu.Item>
      }
    </Menu>
  )



  const changePagination = (page: number) => {
    dealService.loadResources(dealStore.filterKeys, page);
  }

  const exportDeals = () => dealService.exportDeals({}, 'all');

  const paginationProps = {
    pageSize: 10, onChange: changePagination, total: dealStore.count, showSizeChanger: false
  }

  return (
    <div className="deals-page page">
      <Helmet>
        <title>{t("deals.header")}</title>
      </Helmet>
      <Modal
        title={t('deals.addDeal')}
        visible={!!editDeal || editDeal === null}
        footer={null}
        onCancel={handleCancel}
        maskClosable={false}
        className='modal'
        destroyOnClose
      >
        <DealForm handleOk={handleOk} handleCancel={handleCancel} client={editDeal && editDeal.client} deal={editDeal} />
      </Modal>

      <Row align='middle' justify='space-between' className='page-subheader'>
        <Col>
          <h3>{t('deals.header')} ({dealStore.count})</h3>
        </Col>
        <Col>
          { (loginStore.isAdmin || loginStore.isGeneralManager) &&
            <Button loading={ProgressStore.isLoading(EXPORT_DEALS)} type='primary' size='large' className='mr-10'
                  onClick={exportDeals}
            >
              {t('contacts.export')}
            </Button>
          }
          <Button type='primary' onClick={() => showModal(null)} size='large'>{t('deals.addDeal')}</Button>
        </Col>
      </Row>

      <Row justify='space-between' gutter={20} className='page-content'>
        <Col span={4}>
          <FilterDeals lng={lng || "en"}/>
        </Col>
        <Col span={20}>
          <Table
            dataSource={getData()}
            columns={columns}
            className='general-table'
            loading={ProgressStore.isLoading(LOAD_DEALS)}
            bordered
            pagination={paginationProps}
          />
        </Col>
      </Row>
    </div>
  );
});

export default List;
