// @flow
import { FC, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import LoginService from '../loginService';
import { useTranslation } from 'react-i18next';
import {
  Form, Input, Button, Row, Col
} from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { some, trim } from 'lodash';

let UserLoginService = new LoginService();
const RECAPTCHA_SITE_KEY='6LfnYG0kAAAAADhWIfiYuk4kZKjo-LBhUWpTmNLc';


interface ILoginAttributes {
  email: string,
  password: string
}

const Login: FC = observer(() => {
  const recaptchaRef = useRef(null) as any;
  const [form] = Form.useForm();
  const [disabledButton, setDisableButton] = useState(true);

  const { t } = useTranslation();

  const handleSubmit = async (values: ILoginAttributes) => {
    await form.validateFields();
    if (!(some(form.getFieldsError(), ({ errors }) => errors.length)) && recaptchaRef) {
      // const values = form.getFieldsValue(true);
      UserLoginService.login(trim(values.email), values.password, recaptchaRef.current?.getValue())
    }
  }

  return (
    <div className='login-page page'>
      <Row justify='center' className='h-100p' align="middle">
        <Col className='layout-content'>
          <Row className='layout-top' align='middle' justify='center'>
            <span>{t('login.vimCrm')}</span>
            <p>{t('login.signInContinue')}</p>
          </Row>
          <Row className='login-main'>
            <Form className="login-form" onFinish={handleSubmit}>
              <Form.Item
                name="email"
                rules={[{ required: true, message: t('login.placeholder.email') }]}
              >
                <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('tabs.email')} />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: t('login.placeholder.password') }]}
              >
                <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={t('login.password')} />
              </Form.Item>
              <Form.Item>
                <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} onChange={() => setDisableButton(false)} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  size='large'
                  htmlType="submit"
                  className='w-100p'
                  disabled={disabledButton}
                >
                  {t('login.logIn')}
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </Col>
      </Row>
    </div>
  );
});

export default Login;
