import { name } from 'utils/localized';
import { Property as IProperty } from 'property';
import { IContact } from 'contact';

const AVAILABLE_LANGUAGES = ['en', 'ru', 'es'];
export const getEmailBody = (params: {
    deal:any,
    contact: any,
    manager: any,
    emailColumn: any
  }
  ) => {
    const { deal, contact, manager, emailColumn } = params;
    const language = getLanguage(contact);

    switch (language) {
      case 'en':
        return enEmailTemplate(language, deal, contact, manager, emailColumn);
      case 'es':
        return esEmailTemplate(language, deal, contact, manager, emailColumn);
      case 'ru':
        return ruEmailTemplate(language, deal, contact, manager, emailColumn);
      default:
        return;
    }
  }

  const getLanguage = (contact: IContact) => {
    let language = 'en';
    (contact.languages || []).forEach((lng: string) => {
      if(AVAILABLE_LANGUAGES.indexOf(lng) > 0) {
        language = lng;
        return language;
      }
    })
    return language;
  }

  const getDealsOptions = (deal: any, language: string) => {
    let list: any = '';
    const fields = [
      { field: 'max_price', text: { en: 'Budget', es: 'Presupuesto', ru: 'Бюджет' }},
      { field: 'region_name', text: { en: 'Regions', es: 'Región', ru: 'Регион'}},
      { field: 'city_name', text: { en: 'Cities', es: 'Ciudad', ru: 'Город' }},
      { field: 'complex_name', text: { en: 'Complex', es: 'Complejo', ru: 'Комплекс'}}
    ];
    fields.forEach((item: {field: string, text: any}) => {
      if(deal[item.field]) {
        list = list.concat(`<li><strong>${item.text[language] || ''}</strong> ${deal[item.field]}</li>`)
      }
    })
    return list;
  }

  const getTenerifeUrl = (property: IProperty, language: string) => {
    const urls: any = property.tenerife_url;
    const activeUrl: any = urls['sale_url'] || urls['rent_url'];
    if(!urls) return '';
    if(activeUrl) return activeUrl[language];
    return urls['url'] || ''
  }

  const getTenerifeOptions = (emailColumn: any, language: string) => {
    let list: string = '';
    emailColumn.forEach((item: any) => {
      const property = item.property;
      const url = getTenerifeUrl(property, language);
      list = list.concat(`<li>${name(property, language)} ${property.price_attributes.amount} <p>
        <a className='link' target='_blank' href=${url}>${url}</a></p> </li>`)
    })
    return list;
  }

  const enEmailTemplate = (
    language: string,
    deal:any,
    contact: any,
    manager: any,
    emailColumn: any
  ) => {

    return `<div>
      <h3>Dear ${contact.name}</h3>
      <p>My name is ${manager.name} I represent Tenerife Real Estate Agency VYM Canarias.</p>
      <div>
        <p>We have received your application</p>
        <ul>
          ${getDealsOptions(deal, language)}
        </ul>
        <p>and will be happy to find the suitable option for you.</p>
      </div>
      <p><strong>Below are links to relevant properties based on the request for your attention:</strong></p>
      <ul>
        ${ getTenerifeOptions(emailColumn, language) }
      </ul>
      <p>For your convenience, below are <strong>links</strong> to our website for acquaintance <strong>with important information on the acquisition of real estate:</strong></p>
      <ul>
        <li>
          the process of buying a property and information about taxes and fees when buying:
          <a href='https://tenerifecenter.com/en/info/poryadok-oformleniya-sdelok-s-nedvizhimostju/' target='_blank'>
          https://tenerifecenter.com/en/info/poryadok-oformleniya-sdelok-s-nedvizhimostju/</a>
        </li>
        <li>
          if mortgage lending is necessary (the amount of possible loans for primary
          and secondary housing - up to 60%, banking facilities - up to 80%) - documents:
          <a href='https://tenerifecenter.com/en/info/dokumenty/' target='_blank'>
            https://tenerifecenter.com/en/info/dokumenty/
          </a>
        </li>
      </ul>
      <p>
        Please note that at the moment your e-mail
        ${contact.emails_attributes[0] && contact.emails_attributes[0].value || ''}
        will be included in the general newsletter of current real estate options for our customers.
      </p>
      <p>In case you do not want to receive letters with current real estate offers - please inform us, we will delete your e-mail from the general mailing list.</p>
      <p>The company <strong>VYM Canarias</strong> - since 2010 has been successfully developing in the south of the island of Tenerife, occupying a leading position:</p>
      <ul>
        <li>Five offices in central tourist locations</li>
        <li>service - from selection and support of the transaction to the after-sales rental department</li>
        <li>Property maintenance and management</li>
        <li>Tax support of clients</li>
      </ul>
      <p><strong>
        All contact information about our Company and about five offices in the south of Tenerife is located at:
        <a href='https://tenerifecenter.com/en/kontakty/' target='_blank'>
          https://tenerifecenter.com/en/kontakty/
        </a></strong>
      </p>
      <p>Yours faithfully, ${manager.name}</p>
    </div>`
  }
  const esEmailTemplate = (
    language: string,
    deal:any,
    contact: any,
    manager: any,
    emailColumn: any
  ) => {
    return `<div>
      <h3>Buenas tardes: ${contact.name}</h3>
      <p>Mi nombre es ${manager.name}, represento a la Agencia Inmobiliaria de Tenerife, VYM Canarias.</p>
      <div>
        <p>Hemos recibido su solicitud </p>
        <ul>
          ${getDealsOptions(deal, language)}
        </ul>
        <p>y estaremos encantados de encontrar la opción más adecuada para usted.</p>
      </div>
      <p><strong>A continuación, hay enlaces a las propiedades más  relevantes, basados en los datos de su  solicitud</strong></p>
      <ul>
        ${ getTenerifeOptions(emailColumn, language) }
      </ul>
      <p>
        Para su comodidad, a continuación encontrará enlaces a nuestro sitio web para
        conocer información importante sobre la adquisición de bienes inmuebles:
      </p>
      <ul>
        <li>
          el proceso de compra de una propiedad e información sobre impuestos y tasas al comprar:
          <a href='https://tenerifecenter.com/es/info/poryadok-oformleniya-sdelok-s-nedvizhimostju/' target='_blank'>
            https://tenerifecenter.com/es/info/poryadok-oformleniya-sdelok-s-nedvizhimostju/
          </a>
        </li>
        <li>
          Si necesita de préstamo hipotecarios (la cantidad de posibles préstamos para
          primera o segunda vivienda - hasta 60% no residentes - hasta 80% residentes) - documentos:
          <a href='https://tenerifecenter.com/es/info/dokumenty/' target='_blank'>
            https://tenerifecenter.com/es/info/dokumenty/
          </a>
        </li>
      </ul>
      <p>
        Tenga en cuenta que, por el momento, su correo electrónico
        ${contact.emails_attributes[0] && contact.emails_attributes[0].value || ''}
        se incluirá en el boletín general de opciones inmobiliarias actuales para nuestros clientes.
      </p>
      <p>
        En caso de que no desee recibir cartas con ofertas inmobiliarias actuales,
        infórmenos, eliminaremos su correo electrónico de la lista de correo general.
      </p>
      <p>
        La empresa <strong>VYM Canarias</strong> - desde 2010 se ha desarrollado con éxito en el sur de la
        isla de Tenerife, ocupando una posición de liderazgo:
      </p>
      <ul>
        <li>Cinco oficinas en ubicaciones turísticas centrales</li>
        <li>
          Servicio desde la selección y el soporte de la transacción, hasta el departamento
          de alquiler postventa
        </li>
        <li>Mantenimiento y gestión de la propiedad.</li>
        <li>Apoyo fiscal de los clientes.</li>
      </ul>
      <p><strong>
        Toda la información de contacto sobre nuestra empresa y de nuestras  cinco oficinas
        en el sur de Tenerife se encuentra en:
        <a href='https://tenerifecenter.com/es/kontakty/' target='_blank'>
          https://tenerifecenter.com/es/kontakty/
        </a></strong>
      </p>
      <p>Saludos, ${manager.name}</p>
    </div>`
  }

  const ruEmailTemplate = (
    language: string,
    deal:any,
    contact: any,
    manager: any,
    emailColumn: any
  ) => {
    return `<div>
      <h3>${contact.name}, добрый день!</h3>
      <p>Меня зовут ${manager.name}, я представляю агентство недвижимости на Тенерифе VYM Canarias.</p>
      <div>
        <p>Мы получили Вашу заявку</p>
        <ul>
          ${getDealsOptions(deal, language)}
        </ul>
        <p>и будем рады подобрать для Вас подходящий вариант.</p>
      </div>
      <p><strong>Ниже представлены ссылки на актуальные объекты исходя из запроса для Вашего внимания:</strong></p>
      <ul>
        ${ getTenerifeOptions(emailColumn, language) }
      </ul>
      <p>
        Для Вашего удобства ниже ссылки на наш сайт для ознакомления <strong> с важной информацией
        по приобретению недвижимости:</strong>
      </p>
      <ul>
        <li>
          процесс покупки недвижимости и информация о налогах и сборах при покупке:
          <a href='https://tenerifecenter.com/ru/info/poryadok-oformleniya-sdelok-s-nedvizhimostju/' target='_blank'>
            https://tenerifecenter.com/ru/info/poryadok-oformleniya-sdelok-s-nedvizhimostju/
          </a>
        </li>
        <li>
          в случае необходимости ипотечного кредитования (суммы возможных кредитов на
          первичное и вторичное жилье - до 60%, банковские объекты - до 80%) – документы:
          <a href='https://tenerifecenter.com/ru/info/dokumenty/' target='_blank'>
            https://tenerifecenter.com/ru/info/dokumenty/
          </a>
        </li>
      </ul>
      <p>
        Обращаем Ваше внимание, что на данный момент Ваш e-mail
        ${contact.emails_attributes[0] && contact.emails_attributes[0].value || ''}
        включен в общую рассылку актуальных вариантов недвижимости для наших клиентов.
      </p>
      <p>
        В случае, если Вы не хотите получать письма с актуальными предложениями
        недвижимости - просьба сообщить, мы удалим Ваш e-mail из общей рассылки.
      </p>
      <p>
        Компания <strong>VYM Canarias</strong> – с 2010 года успешно развивается на юге острова Тенерифе,
        занимая лидирующие позиции:
      </p>
      <ul>
        <li>Пять офисов в центральных туристических местах</li>
        <li>Сервис под ключ - от подбора и сопровождения сделки до отдела послепродажной аренды</li>
        <li>Содержание и управление недвижимостью</li>
        <li>Налоговое сопровождение клиентов</li>
      </ul>
      <p><strong>
        Вся контактная информация о нашей Компании и о пяти офисах на юге Тенерифе
        находится по ссылке:
        <a href='https://tenerifecenter.com/ru/kontakty/' target='_blank'>
          https://tenerifecenter.com/ru/kontakty/
        </a></strong>
      </p>
      <p>С уважением, ${manager.name}</p>
    </div>`
  }

