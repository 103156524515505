import {FC, useEffect, useState, PropsWithChildren} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment';
import { EditOutlined, DownOutlined, EllipsisOutlined, DeleteOutlined, BookOutlined, MailOutlined, ProfileOutlined, FileAddOutlined } from "@ant-design/icons";
import classNames from 'classnames';
import ManagerModal from './managerModal'
import { Button, Row, Col, Modal, Menu, Dropdown } from 'antd';
import NoteForm from 'submodules/notes/components/Form';
import TaskForm from 'submodules/tasks/components/Form';
import FileForm from 'submodules/files/components/Form';
import EmailForm from 'submodules/emails/components/Form';
import { Helmet } from 'react-helmet';
import loginStore from 'modules/login/loginStore';
import clientStore from 'stores/client';

import { IClient } from 'declarations/contact';
import { CONTACT_TYPES } from 'constants/mixed';
import { getContactIcon } from "modules/clients/containers/List"
import workspaceStore from 'stores/workspace';
import NoAccess from "modules/NoAccess";


const { confirm } = Modal;

const Single: FC<PropsWithChildren> = observer(({ children }) => {

  const[showModal, setShowModal] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const location = useLocation();
  const client: any = clientStore && clientStore.selected;
  const user = loginStore.session.user.user;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      (async () => {
        await clientStore.fetchClient(Number(id));
        if (clientStore.selected?.workspace_id && clientStore.selected?.workspace_id !== workspaceStore.selectedId ) {
          workspaceStore.setSelected(clientStore.selected?.workspace_id)
        }
      })();
    }
  }, [id])

  const handleOk = async (values: { manager_id: number }) => {
    const client = clientStore.selected;
    const value = { ...client, ...values }
    await clientStore.updateClient(Number(id), value);
    setShowModal(undefined)
  }

  const handleCancel = () => {
    setShowModal(undefined)
  }

  const updateShowModal = (type: string) => {
    setShowModal(type);
  }

  const showConfirm = (contact: IClient) => {

    confirm({
      title: t('contacts.checkDelete'),
      content: '',
      onOk: async () =>  {
        await clientStore.deleteClient(contact.id);
        navigate('/clients');
      },
      onCancel() { },
    });
  }

  const returnHeaderMenuAction = () => {

    return <Menu
      mode="horizontal"
      defaultSelectedKeys={[]}
      className='actons-menu'
    >
      <Menu.Item key={`edit_property_${client?.id}`}>
        <Link className='edit-title' to={`/clients/${client.id}/edit`}>
          <EditOutlined className='mr-5' />
          {t('properties.table.edit')}
        </Link>
      </Menu.Item>
      {
      (loginStore.isAdmin || loginStore.isGeneralManager) && <Menu.Item key={`delete_client_${client?.id}`} onClick={() => showConfirm(client)}>
        <span className='delete-title'>
          <DeleteOutlined className='mr-5' />
          {t('owners.table.delete')}
        </span>
      </Menu.Item>
    }
    </Menu>
  }

  const modalContent = () => {
    if (!showModal) {
      return ''
    }

    switch (showModal) {
      case 'manager':
        return <ManagerModal
          manager_id={client.manager_id}
          onSelectManager={handleOk}
          handleCancel={handleCancel}
        />
      case 'note':
        return <NoteForm onCancelButton={handleCancel} resource={client} resource_type='Client' />;
      case 'task':
        return <TaskForm onCancelButton={handleCancel} resource={client} resource_type='Client' />;
      case 'file':
        return <FileForm onCancelButton={handleCancel} resource={client} resource_type='Client' />;
      case 'email':
        return <EmailForm onCancelButton={handleCancel} contact={client} contactType="Client" />;
      default:
        return;
    }
  }

  const returnAddNewMenuAction = () => {
    return <Menu
      mode="horizontal"
      defaultSelectedKeys={[]}
      className='actons-menu'
    >
      <Menu.Item key={`client${client.id}_note`} onClick={() => updateShowModal('note')}>
        <Row align="middle"><BookOutlined className='mr-5' /> {t('tabs.note')}</Row>
      </Menu.Item>
      <Menu.Item key={`client_${client.id}_email`} onClick={() => updateShowModal('email')}>
        <Row align="middle"><MailOutlined className='mr-5' /> {t('tabs.email')}</Row>
      </Menu.Item>
      <Menu.Item key={`client_${client.id}_task`} onClick={() => updateShowModal('task')}>
        <Row align="middle"><ProfileOutlined className='mr-5' /> {t('tabs.task')}</Row>
      </Menu.Item>
      <Menu.Item key={`new_${client.id}_file`} onClick={() => updateShowModal('file')}>
        <Row align="middle"><FileAddOutlined className='mr-5' /> {t('tabs.file')}</Row>
      </Menu.Item>
    </Menu>
  }


  if(!clientStore.hasAcccess) {
    return <NoAccess link="/clients" />
  }

  if (!client?.id) {
    return null
  }
  const createdAtDate = moment(client.created_at).format('DD.MM.YYYY \\at HH:mm');

  return (
    <div className="contacts-page page single">
      <Modal
        title={t(`modal.add_${showModal}`)}
        visible={!!showModal}
        footer={null}
        width={'1000px'}
        onCancel={handleCancel}
        maskClosable={false}
        className='modal'
      >
        {modalContent()}
      </Modal>
      <Helmet>
        <title>{client.name} - {CONTACT_TYPES[client.client_type.toString()]}</title>
      </Helmet>
      <Row className='page-subheader portlet w-100p'>
        <Row justify='space-between' align='middle' className='subheader-top w-100p'>
          <Col>
            <Row align='middle'>
              <Col className='avatar mr-25'>{getContactIcon(client.client_type)}</Col>

              <Col>
                <Row className='mb-5'>
                  <h4>{client.name}</h4>
                </Row>
                <Row align='middle' gutter={30}>
                  <Col>{t('clients.table.type')}: <span>{CONTACT_TYPES[client.client_type.toString()]}</span></Col>
                  <Col>{t('clients.single.added')}: <span>{createdAtDate}</span></Col>
                  <Col>{t('clients.new.source')}: <span>{client.source && client.source.name}</span></Col>
                  { (loginStore.isAdmin || loginStore.isGeneralManager) &&
                    <Col>{t('manager')}:
                        <a onClick={() => setShowModal('manager')} className='link ml-5 mr-5'>{client.manager && client.manager.name || t('addManager')} <EditOutlined /></a>
                    </Col>
                  }
                  { (!(loginStore.isAdmin || loginStore.isGeneralManager) && loginStore.session.user.user.id === client.manager_id) &&
                    <Col>{t('manager')}:
                      <span className='link ml-5 mr-5'>{client.manager && client.manager.name}</span>
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
          </Col>

          <Col>
            <Dropdown overlay={returnAddNewMenuAction} trigger={['click']}>
              <Button type='primary' size='large'>
              {t('price.newPrice')} <DownOutlined />
              </Button>
            </Dropdown>
            <Dropdown overlay={returnHeaderMenuAction()} trigger={['click']}>
              <Button type='primary' size='large' className='ml-25'>
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>

        <div className="subheader-bottom">
          <ul className='subheader-navigation'>
            <li className={classNames({ active: location.pathname.includes('overview') })}>
              <Link to={`/clients/${client.id}/overview`}>{t('clients.single.overview')}</Link>
            </li>
            <li className={classNames({ active: location.pathname.includes('deals') })}>
              <Link to={`/clients/${client.id}/deals`}>{t('menu.deals')} ({client.deals_count})</Link>
            </li>
            <li className={classNames({ active: location.pathname.includes('rents') })}>
              <Link to={`/clients/${client.id}/rents`}>{t('tabs.rent')}  ({client.rents_count})</Link>
            </li>
            <li className={classNames({ active: location.pathname.includes('requests') })}>
              <Link to={`/clients/${client.id}/requests`}>{t('tabs.requests')} ({client.requests_count})</Link>
            </li>
            <li className={classNames({ active: location.pathname.includes('notes') })}>
              <Link to={`/clients/${client.id}/notes`}>{t('tabs.notes')} {client.notes_count}</Link>
            </li>
            <li className={classNames({ active: location.pathname.includes('emails') })}>
              <Link to={`/clients/${client.id}/emails`}>{t('tabs.emails')} ({client.emails_count})</Link>
            </li>
            <li className={classNames({ active: location.pathname.includes('tasks') })}>
              <Link to={`/clients/${client.id}/tasks`}>{t('tabs.tasks')} {client.tasks_count}</Link>
            </li>
            <li className={classNames({ active: location.pathname.includes('files') })}>
              <Link to={`/clients/${client.id}/files`}>{t('tabs.files')} {client.files_count}</Link>
            </li>
          </ul>
        </div>
      </Row>

      <Row className='page-content'>
        {children}
      </Row>
    </div>
  );
})

export default Single;
