import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Modal } from 'antd';
import RequestForm from 'modules/requests/components/New';
import RequestList from '../components/RequestList';
import AppLayout from "modules/owners/containers/Single/components/Layout";
import ownerStore from 'stores/owner';
import { FileAddOutlined } from "@ant-design/icons";

const RequestsList: FC = observer(() => {
  const [visibleModal, setVisibleModal] = useState(false);
  const { t } = useTranslation();
  const handleCancel = () => setVisibleModal(false);
  const owner: any = ownerStore.selected;

  return (
    <AppLayout>
      <div className="tab">
        <Modal
          title={t('requests.addRequest')}
          visible={visibleModal}
          footer={null}
          onCancel={handleCancel}
          destroyOnClose
          className='modal'
          maskClosable={false}
        >
          <RequestForm handleCancel={handleCancel} isFromContact={owner?.id} />
        </Modal>

        <div className='portlet'>
          <div className='portlet-head'>
            <Row justify='space-between' align='middle'>
              <h5><FileAddOutlined className='mr-10 fs-20' />{t('requests.single')} ({owner?.requests_count})</h5>
              <Button onClick={() => setVisibleModal(true)} type='primary'>
                {t('requests.newRequest')}
              </Button>
            </Row>
          </div>
          <div className='portlet-body'>
            <RequestList owner={owner} />
          </div>
        </div>
      </div>
    </AppLayout>
  );
})

export default RequestsList;
