import { action } from 'mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import { message } from 'antd';
import  PropertyTypeStore  from '../stores/type';
import { PROPERTY_TYPES_URL } from 'constants/api';
import { PropertyType } from "declarations/propertyType";

interface FilterType {

};

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any,
  loadResourcesRequest(queryParams: {}): Promise<object>
}

export class PropertyTypesService implements Props {

  // LOAD resourceS
  async loadResourcesRequest() {
    return axios.get(PROPERTY_TYPES_URL)
  }

  async loadResources() {
    // let { filters, page } = (ownerListStore || {});
    if(PropertyTypeStore.values && PropertyTypeStore.values.length > 0) {
      return 
    }
    const response: { status: number, data: Array<PropertyType>} = await this.loadResourcesRequest();

    if (response.status) {
      let values = [];
      values = response.data;

      this.addValues(values);
    }

    return response;
  }

  addValues(values: Array<PropertyType>) {
    PropertyTypeStore.addValues(values);
  }
}

export const propertyTypesService = new PropertyTypesService();
