import { action } from 'utils/mobx';
import { request } from 'utils/mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import { message } from 'antd';
import activityStore  from 'stores/activity';
import taskStore  from 'stores/task';
import contactStore  from 'stores/contact';
import clientStore  from 'stores/client';
import propertyStore  from 'stores/property';
import { TASK_URL } from 'constants/api';
import { LOAD_TASKS } from 'constants/action';

import { Filter } from "declarations/filters";
import { ITask } from "declarations/task";

interface FilterType {
  taskable_id: number;
  taskable_type: string;
};

interface IResponse {
  status: number,
  data: any
}

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any;
  loadResourcesRequest(queryParams: FilterType): Promise<object>;
}

export class TaskService implements Props {

  // LOAD resourceS
  @request()
  async loadResourcesRequest (params?: FilterType): Promise<{ status: number, data: Array<ITask>}> {
    return axios.get(TASK_URL, {
      params
    })
  }

  @action({ action: LOAD_TASKS, minRequestTime: 500 })
  async loadResources(params?: FilterType) {

    const response: { status: number, data: Array<ITask>} = await this.loadResourcesRequest(params);

    if (response.status == 200) {
      let values = [];
      values = response.data;

      this.addValues(values);
    }

    return response;
  }


  @request()
  async createResourceRequest(params: ITask) {
    return axios.post(TASK_URL, { task:
      params
    })
  }


  async createResource(params: any, callback: Function) {

    const response: IResponse = await this.createResourceRequest(params);

    if (response.status == 201) {
      const ResourceStore = this.getStore(params);
      ResourceStore.addSelectedAttributes({tasks_count: response.data.tasks_count})
      callback();
      this.loadResources({taskable_id: params['taskable_id'], taskable_type: params['taskable_type']});
      message.success('Task created!')
    } else {
      message.error((response.data.message || []).join())
    }

  }

  @request()
  async updateResourceRequest(resource: any) {
    return axios.put(`${TASK_URL}/${resource.id}`, { ...resource })
  }


  async updateResource(resource: Object, isActivity: boolean | undefined = false, callback?: Function) {

    const response: { data: any, status: number } = await this.updateResourceRequest(resource);

    if (response.status == 200) {
      const ResourceStore = this.getStore(resource);
      ResourceStore.addSelectedAttributes({tasks_count: response.data.tasks_count})
      isActivity ? this.updateActivityValue(response.data) : this.updateValue(response.data);
      message.success('Task updated!')
      if(callback) {
        callback()
      }
    }

  }

  @request()
  async deleteResourceRequest(resource_id: number) {
    return axios.delete(`${TASK_URL}/${resource_id}`)
  }


  async deleteResource(resource: ITask, callback: Function) {
    if(!resource.id) {
      return
    }
    const response: { status: number } = await this.deleteResourceRequest(resource.id);

    if (response.status) {
      message.success('Task deleted!!')
      this.loadResources({ taskable_id: resource.taskable_id, taskable_type: resource.taskable_type });
      callback()
    }

  }

  getStore(resource: any) {
    if(resource.taskable_type == 'Property') {
      return propertyStore
    } else if (resource.taskable_type == 'Client') {
      return clientStore
    } else {
      return contactStore
    }
  }

  addValues(values: Array<ITask>) {
    taskStore.addValues(values);
  }
  updateValue(value: ITask) {
    taskStore.updateValue(value);
  }
  updateActivityValue(value: ITask) {
    activityStore.updateValue(value);
  }
}

export const taskService = new TaskService();
