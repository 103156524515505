import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { SALE_VALUE, SALE_DEALS_BOARD_COLUMNS, RENT_DEALS_BOARD_COLUMNS } from 'constants/mixed';
import { Form, Select, Input, Button, Row, Col } from 'antd';

import { IDeal } from 'declarations/deal';
import { IDealsProperty } from 'declarations/dealsProperty';
import { some } from 'lodash';

interface IData {
  handleOk(dealProperty: IDealsProperty): void,
  handleCancel(): void,
  dealProperty: IDealsProperty | null,
  deal: IDeal;
  isRejected: boolean;
}

const { Option } = Select;
const defaultState = {
  id: null,
  status: '0',
  canceled_reason: '',
  notes: ''
}

const DealForm: FC<IData> = observer((props) => {


  const [state, setState] = useState<any>(defaultState);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    const { dealProperty } = props;
    if (dealProperty) {
      setState({ ...state, ...dealProperty })
      form.setFieldsValue({...dealProperty})
    } else {
      setState({ ...state, ...defaultState })
    }
    return () => setState({ ...state, ...defaultState })
  }, [props.dealProperty?.status])

  // componentWillUpdate(nextProps: IData, nextState: any) {
  //   if (nextProps.dealProperty && nextProps.dealProperty.id != state.id) {
  //     setState({ ...state, ...nextProps.dealProperty })
  //   }
  // }


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      const dealsPropertyValue = { ...state, ...values, status: "rejected" };
      delete dealsPropertyValue['property'];
      props.handleOk(dealsPropertyValue);
    }
  }

  const renderStatusOptions = () => {
    const { deal, dealProperty } = props;
    const columns = deal.operation == SALE_VALUE ? SALE_DEALS_BOARD_COLUMNS : RENT_DEALS_BOARD_COLUMNS;

    return columns.map((column: { name: string, key: string }) => {
      // if(column.key == 'email' && dealProperty &&  dealProperty.property.tenerife_url){
      //   return;
      // }
      return  <Option value={column.key}>{column.name}</Option>
    })
  }

    const { isRejected } = props;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    return (
      <div>
        <Form onFinish={handleSubmit} form={form}>

          <Form.Item
            {...formItemLayout}
            label={t('deals.new.stage')}
            name="status"
            rules={[{ required: !isRejected, message: t('validates.cantBeEmpty') }]}
          >
            <Select placeholder={t('deals.placeholder.status')} disabled>
              {renderStatusOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('deals.cancelation')}
            name="canceled_reason"
          >
            <Select placeholder={t('deals.placeholder.reason')} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              <Option value="0">{t('deals.new.reason.price')}</Option>
              <Option value="1">{t('deals.new.reason.view')}</Option>
              <Option value="2">{t('deals.new.reason.complexCondition')}</Option>
              <Option value="3">{t('deals.new.reason.apartmentCondition')}</Option>
              <Option value="4">{t('deals.new.reason.poolCondition')}</Option>
              <Option value="5">{t('deals.new.reason.area')}</Option>
              <Option value="6">{t('deals.new.reason.neighbors')}</Option>
              <Option value="7">{t('deals.new.reason.location')}</Option>
              <Option value="8">{t('deals.new.reason.other')}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('tabs.notes')}
            name="notes"
          >
            <Input.TextArea rows={10} placeholder={t('tabs.notes')} />
          </Form.Item>
          <Row>
            <Col span={6}></Col>
            <Col span={18}>
              <div className="form-controls">
                <Button type="primary" onClick={handleSubmit} className='mr-10'>
                {t('save')}
                </Button>
                <Button onClick={() => props.handleCancel()}>
                {t('cancel')}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    )
})

export default DealForm;
