import axios from 'axios';
import { WORKSPACE_URL } from 'constants/api';
import { IWorkspace } from 'declarations/workspace';

export const getWorkspaces = async (): Promise<IWorkspace[]> => {
  const resp = await axios.get(WORKSPACE_URL);
  return resp.data;
}


