import { FC, useState, useEffect } from 'react';
import { observer, } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { requestService } from 'services/requestService';
import clientStore from 'stores/client';
import requestStore from 'stores/request';
import ProgressStore from 'stores/progress';
import LocaleStore from 'stores/locale';
import { Table, Button, Menu, Modal, Dropdown, Row, Col } from 'antd';
import RequestForm from 'modules/requests/components/New'
import { name } from 'utils/localized';
import ManagerModal from 'modules/clients/containers/Single/components/managerModal';
import loginStore from 'modules/login/loginStore';
import propertyStore from 'stores/property';
import { LOAD_REQUESTS } from 'constants/action';
import { EditOutlined, DeleteOutlined, EllipsisOutlined, ShoppingOutlined } from "@ant-design/icons";
import { IRequest } from 'declarations/request';
import { IClient } from 'contact';
import { Property as IProperty } from 'property';
import AppLayout from '../../Layout';

const confirm = Modal.confirm;

const Request: FC = observer(() => {

  const [modalResource, setModalResource] = useState<{} | IRequest | IClient>({})
  const [showModal, setShowModal] = useState<string>("")
  const { t } = useTranslation();
  const lng = LocaleStore.locale;
  const property = propertyStore.selected;

  useEffect(() => {
    if (property.id) {
      requestService.loadResources({property_id: property.id})
    }
  }, [property.id])

  const displayModal = (resource: IClient | IRequest | {}, type: string) => {
    setShowModal(type);
    setModalResource(resource);
  }

  const handleCancel = () => {
    setShowModal("");
    setModalResource({});
  }

  const onSelectManager = async (values: { manager_id: number }) => {
    if (modalResource) {
      const client: any = modalResource;
      const value = { ...client, ...values }
      await clientStore.updateClient(client.id, value)
      handleCancel();
      requestService.loadResources({property_id: property.id})
    }
  }

  const renderModalContent = () => {
    switch (showModal) {
      case 'Manager':
        const currentModalResource = modalResource as IClient;
        return <ManagerModal
          manager_id={currentModalResource && currentModalResource.manager_id}
          handleCancel={handleCancel}
          onSelectManager={onSelectManager}
        />
      case 'Request':
        return <RequestForm handleCancel={handleCancel} request={modalResource as IRequest} isFromProperty={property.id} />
      default:
        return
    }
  }

  const columns = [
      {
        title: t('properties.table.id'),
        dataIndex: 'id',
        key: 'id'
      }, {
        title: t('contact'),
        dataIndex: 'contact',
        key: 'contact'
      }, {
        title: t('deals.new.operation'),
        dataIndex: 'operation',
        key: 'operation',
      }, {
        title: t('message'),
        dataIndex: 'message',
        key: 'message',
      }, {
        title: t('requests.showingDateTime'),
        dataIndex: 'showing_date',
        key: 'showing_date',
      }, {
        title: t('contacts.new.source'),
        dataIndex: 'source',
        key: 'source',
      }, {
        title: t('contacts.table.language'),
        dataIndex: 'language',
        key: 'language',
      }, {
        title: t('expenses.new.date'),
        dataIndex: 'date',
        key: 'date',
      }, {
        title: '',
        dataIndex: 'actions',
        key: 'actions'
      }
    ]

  const renderRequestDate = (data: string) => {
    if (!data) {
      return '';
    }
    const createdAtDate = moment(data).format('DD.MM.YYYY [at] HH:mm');
    return <span>{createdAtDate}</span>
  }

  const showManagerLink = (client: IClient) => {
    let managerContent;

    if(!loginStore.isAdmin && !loginStore.isGeneralManager) {
      return (loginStore.session.user.user.id == client.manager_id && client.manager) ? client.manager.name : "-"
    }

    if (client.manager_id && client.manager) {
      managerContent = <a onClick={() => displayModal(client, 'Manager')} className='link'>
        {/* Doesn't render without manager.name */}
        <span className='mr-5'>{client.manager && client.manager.name}</span>
        <EditOutlined />
      </a>
    } else {
      managerContent = <a onClick={() => displayModal(client, 'Manager')} className='mr-5 link'>
        {t('addManager')}
      </a>
    }
    return managerContent;

  }
  const renderClientInfo = (client: IClient) => {
    const phone = client.phones_attributes[0] && client.phones_attributes[0].value;
    const email = client.emails_attributes[0] && client.emails_attributes[0].value;

    return <div>
      <p><Link to={`/clients/${client.id}/overview`} className='link'>{client.name}</Link></p>
      <p>{phone}</p>
      <p>{email}</p>
      <p className='ws-nw'>{t('manager')}: {showManagerLink(client)}</p>
    </div>
  }

  const renderShowingDate = (request: IRequest) => {
    return(
      <div className='ws-nw'>
        <p>{request.showing_date_at ? moment(request.showing_date_at).format('DD.MM.YYYY') : ''}</p>
        <p>{request.showing_time}</p>
      </div>
    )
  }

  const getData = () => {
    const requests = requestStore.values;

    return (requests || []).map((request: IRequest, index: number) => {
      return {
        id: request.id,
        contact: renderClientInfo(request.client),
        operation: <span>{request.operation}</span>,
        message: <span>{request.message}</span>,
        showing_date: renderShowingDate(request),
        source: <span>{request.source_name}</span>,
        date: renderRequestDate(request.created_at),
        language: <div className='languages-list ws-nw'>
          { request.language ? <img className='flag' src={require(`../../../../../../images/flags/${request.language}.png`)} /> : '-' }
        </div>,
        actions: <Dropdown overlay={getActionComponent(request)} trigger={['click']}>
          <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
        </Dropdown>
      }
    })
  }

  const showConfirm = (request: IRequest) => {
    confirm({
      title: t('requests.confirmDelete'),
      content: '',
      onOk() {
        return new Promise((resolve, reject) => {
          requestService.deleteResource(request.id, resolve)
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }

  const getActionComponent = (request: IRequest) => {

    return (
      <Menu
        mode="horizontal"
        className='actons-menu'
      >
        <Menu.Item key={`edit_user_${request.id}`} onClick={() => displayModal(request, 'Request')}>
          <span className='edit-title'>
            <EditOutlined />
            {t('owners.table.edit')}
          </span>
        </Menu.Item>
        { (loginStore.isAdmin || loginStore.isGeneralManager)  &&
          <Menu.Item key={`delete_request_${request.id}`} onClick={() => showConfirm(request)}>
            <span className='delete-title'>
              <DeleteOutlined />
              {t('owners.table.delete')}
            </span>
          </Menu.Item>
        }
      </Menu>
    )
  }

  const changePagination = (page: number) => {
    requestStore.setPage(page);
    requestService.loadResources({property_id: property.id}, page);
  }

    const requests = requestStore.values;
    const paginationProps = {
      pageSize: 10, onChange: changePagination, total: requestStore.count, showSizeChanger: false
    }

    if (!(requests)) {
      return null;
    }

    return (
      <AppLayout>
        <div className="tab">
          <Modal
            title={t('requests.addRequest')}
            visible={!!showModal}
            footer={null}
            onCancel={handleCancel}
            destroyOnClose
            maskClosable={false}
            className='modal'
          >
            {renderModalContent()}
          </Modal>
          <div className='portlet'>
            <div className='portlet-head'>
              <Row justify='space-between' align='middle'>
                <h5><ShoppingOutlined className='mr-10 fs-20' />{t('tabs.requests')} ({requestStore.count})</h5>
                <Button onClick={() => displayModal({}, 'Request')} type='primary'>{t('requests.addRequest')}</Button>
              </Row>
            </div>
            <div className='portlet-body'>
              <Table
                dataSource={getData()}
                columns={columns}
                className='general-table'
                loading={ProgressStore.isLoading(LOAD_REQUESTS)}
                bordered
                pagination={paginationProps}
              />
            </div>
          </div>
        </div>
      </AppLayout>
    )

})

export default Request;
