import * as React from 'react';

// import Header from '../components/Header';

type Props = {
  children: any
}

const Guest = ({ children }: any) => {
  return (
    <div> { children } </div>
  );
}

export default Guest;
