import { FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { contactService } from 'services/ContactService'
import ContactForm from '../../components/New/Form';
import { useNavigate } from "react-router-dom"
import { message } from 'antd';

import { IContact } from "declarations/contact";

interface IData {}

const New: FC<IData> = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const onCreateContact = async (params: IContact) => {
    await contactService.createResourceRequest(params).then((resp) => {
      navigate('/contacts')
      contactService.loadResources();
      messageApi.success('Contact created!')
    }).catch(({response}) => messageApi.error((response.data.message || []).join()))
  }

  const onCancelButton = () => {
    navigate('/contacts');
  }


  return (
    <div className="edit-contact-page page">
      {contextHolder}
      <div className='mb-30'>
        <h3 className='ta-c'>{t('contacts.newContact')}</h3>
      </div>
      <div>
        <div>
          <ContactForm onCreateContact={onCreateContact} onCancelButton={onCancelButton} />
        </div>
      </div>
    </div>
  );
})

export default New;
