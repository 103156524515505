import { FC } from 'react';
import GuestRoutes from './guest';
import AdminRoutes from './admin';
import ManagerRoutes from './manager';
import ExtManagerRoutes from './externalManager';
import GeneralManagerRoutes from './generalManager';
import AdminLayout from '../modules/layouts/containers/Main';
import GuestLayout from '../modules/layouts/containers/Guest';
// import OrganizerRoutes from './Organizer';
import { observer } from 'mobx-react';
import { LoginStore } from 'stores';


const Routes: FC = observer(() => {

  const routes = () => {

    if (LoginStore.isLogged) {
      if(LoginStore.isAdmin){
        return <div className='container'><AdminRoutes /></div>
      }
      if(LoginStore.isManager){
        return <div className='container'><ManagerRoutes /></div>
      }

      if(LoginStore.isGeneralManager){
        return <div className='container'><GeneralManagerRoutes /></div>
      }

      if(LoginStore.isExternalManager){
        return <div className='container'><ExtManagerRoutes /></div>
      }
    } else {
      return <GuestRoutes />
    }
  }

  const layout = () => {
    if (LoginStore.isLogged) {
      return AdminLayout;
    } else {
      return GuestLayout;
    }
  }

  const Layout = layout();

  return (
    <Layout>
        {routes()}
    </Layout>
  );
})

export default Routes;
