import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { requestService } from 'services/requestService';
import requestStore from 'stores/request';
import LocaleStore from 'stores/locale';
import ProgressStore from 'stores/progress';
import { LOAD_REQUESTS } from 'constants/action';
import { Table } from 'antd';
import { name } from 'utils/localized';

import { IRequest } from 'declarations/request';
import { IContact } from 'contact';
import { Property as IProperty } from 'property';

interface IData {
  contact: IContact
}

const List: FC<IData> = observer((props) => {

  const { t } = useTranslation();
  const lng = LocaleStore.locale;

  useEffect(() => {
    const { contact } = props;
    requestService.loadResources({ contact_id: contact.id })
    return requestStore.clearData()
  }, [])

  const columns = [
      {
        title: t('deals.list.id'),
        dataIndex: 'id',
        key: 'id'
      }, {
        title: t('deals.new.operation'),
        dataIndex: 'operation',
        key: 'operation',
      }, {
        title: t('owners.table.property'),
        dataIndex: 'property',
        key: 'property',
      }, {
        title: t('message'),
        dataIndex: 'message',
        key: 'message',
      }, {
        title: t('requests.showingDateTime'),
        dataIndex: 'showing_date',
        key: 'showing_date',
      }, {
        title: t('contacts.new.source'),
        dataIndex: 'source',
        key: 'source',
      }, {
        title: t('contacts.table.language'),
        dataIndex: 'language',
        key: 'language',
      }, {
        title: t('expenses.new.date'),
        dataIndex: 'date',
        key: 'date',
      }
    ];

  const renderRequestDate = (data: string) => {
    if(!data) {
      return '';
    }
    const createdAtDate = moment(data).format('DD.MM.YYYY [at] HH:mm');
    return <span>{createdAtDate}</span>
  }


  const renderPropertyInfo = (property: IProperty) => {

    if(!property) {
      return null;
    }

    const price = property.price_attributes.amount;
    return <div>
      <p><Link to={`/properties/${property.id}/overview`} className='link'>{name(property, lng)}</Link></p>
      <div className='ws-nw'>
        <p>{t('properties.new.id')}: <span className="property-identifier">{property.identifier}</span></p>
        <p>{t('properties.table.price')}: {price ? <span>{`${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €</span> : "-"}</p>
        <p>{t('properties.new.owner')}: {property.owner ? <a href={`/owners/${property.owner.id}/overview`} target='_blank' className='link'>{property.owner.name}</a> : '-'}</p>
      </div>
    </div>
  }
  const renderShowingDate = (request: IRequest) => {
    return(
      <div className='ws-nw'>
        <p>{request.showing_date_at ? moment(request.showing_date_at).format('DD.MM.YYYY') : ''}</p>
        <p>{request.showing_time}</p>
      </div>
    )
  }

  const getData = () => {
    const requests = requestStore.values;

    return (requests || []).map((request: IRequest, index: number) => {
      return {
        id: request.id,
        operation: <span>{request.operation}</span>,
        property: renderPropertyInfo(request.property),
        message: <span>{request.message}</span>,
        showing_date: renderShowingDate(request),
        source: <span>{request.source_name}</span>,
        language: <div className='languages-list ws-nw'>
          { request.language ? <img className='flag' src={require(`../../../images/flags/${request.language}.png`)} /> : '-' }
        </div>,
        date: renderRequestDate(request.created_at)
      }
    })
  }

  const changePagination = (page: number) => {
    requestService.loadResources({}, page);
  }

  const paginationProps = {
    pageSize: 10, onChange: changePagination, total: requestStore.count, showSizeChanger: false
  }

  return (
    <div className="content main-container-list owners-list-page">
      <div className='list-table'>
        <Table
          dataSource={getData()}
          columns={columns}
          className='owners-table'
          loading={ProgressStore.isLoading(LOAD_REQUESTS)}
          bordered
          pagination={paginationProps}
        />
      </div>
    </div>
  );
})

export default List;
