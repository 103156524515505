import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { SALE_VALUE, SALE_DEALS_BOARD_COLUMNS, RENT_DEALS_BOARD_COLUMNS, RENT_VALUE } from 'constants/mixed';
import {  Row, Col, Modal, Tooltip, message } from 'antd';
import { get, groupBy, keys, sortBy } from 'lodash';
import { dealPropertyService } from '../DealsPropertyService';
import loginStore from 'modules/login/loginStore';
// import { Container, Draggable } from 'react-smooth-dnd';
import DealCardForm from './DealCardForm';
import LocaleStore from 'stores/locale';
import withoutImage from 'images/placeholder-image.png';
import { name } from 'utils/localized';
import { getEmailBody } from 'utils/template';
import { startCase, toLower } from 'lodash';
import EmailForm from 'submodules/emails/components/Form';
// import EmailTemplate from 'submodules/emails/components/Template';
import { ReactSortable, Sortable } from "react-sortablejs";
import { IClient } from 'declarations/contact';
import { IDeal } from 'declarations/deal';
import { Property as IProperty } from 'declarations/property';
import { IDealsProperty } from 'declarations/dealsProperty';
import email from 'stores/email';
import Container from "./BoardContainer";
import {
  DndContext,
  DragEndEvent,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

interface IData {
  client: IClient,
  deal: IDeal,
}


const groupedDealsPropertyByStatus = (properties: any) => {
  const groupItems = groupBy(properties, 'status');
  keys(groupItems).forEach((key: string) => sortBy(groupItems[key], "position"));
  return groupItems
}

const getColumns = (deal: IDeal) => {
  return deal.operation == SALE_VALUE ? SALE_DEALS_BOARD_COLUMNS : RENT_DEALS_BOARD_COLUMNS;
}

const DefaultBoardState = {
  shortlist: [],
  email: [],
  sent: [],
  discussion: [],
  show: [],
  contract: [],
  rejected: [],
  reservation: [],
}

const DealBoard: FC<IData> = (props) => {

  const { t } = useTranslation();
  const lng = LocaleStore.locale;
  const [properties, setProperties] = useState<any>([]);
  const [state, setState] = useState<any>({
    showModal: null,
    modalType: 'card',
    isRejected: false,
    deal: {}
  });
  const [activeId, setActiveId] = useState<null | string>();
  const [items, setItems] = useState<any>(DefaultBoardState);

  const [oldItems, setOldItems] = useState<any>(DefaultBoardState);

  const updateBoard = (dealProperties: IDealsProperty[]) => {
    setProperties(dealProperties)
    const groupedDealsProperty = groupedDealsPropertyByStatus(dealProperties)
    setItems({...DefaultBoardState, ...groupedDealsProperty})
  }

  useEffect(() => {
    if (props.deal) {
      updateBoard(props.deal.deals_properties)
    }
  }, [props.deal.id])

  const sendAupdatePosition = (column: string, items: any[]) => {
    const attributes = items.map((item: any, index: number) => ({ status: column, position: index, id: item.id }))
    dealPropertyService.updatePositions(attributes)
  }

  const handleCancel = () => {
    setState({
      showModal: false,
      isRejected: false,
      modalType: 'card'
    });
  }

  const showModal = (dealProperty: IDealsProperty | null, isRejected: boolean = false) => {
    setState({
      ...state,
      showModal: dealProperty,
      isRejected: isRejected
    });
  }

  const handleOk = (dealsProperty: IDealsProperty) => {
    dealPropertyService.updateResource(dealsProperty.id, dealsProperty, (data: any) => {
      setState({...state, showModal: false });
      updateBoard(data.deals_properties)
    })
  }

  const renderheaderModal = (dealProperty: IDealsProperty | null) => {
    const property = dealProperty && dealProperty.property;
    const { deal } = props;
    const isSale = deal.operation == SALE_VALUE;
    if (!property) {
      return t('emails.addEmail');
    }

    const price = property.price_attributes;

    return <Row className='modal-header'>
      <Col span={6}>
        <img src={property.current_image_url || withoutImage} width='195px' />
      </Col>
      <Col span={16}>
        <Row className='mb-15'>
          <h3>{t('deals.new.appartment')} {name(property, lng)}</h3>
        </Row>
        <Row>
          <Col span={12}>
            <p className='mb-10'>{t('properties.table.id')}: {isSale ? 'VS' : 'VR'}{property.identifier}</p>
            <p className='mb-10'>{t('properties.new.totalArea')}: {property.detail_attributes.total_area || 0} m2</p>
            <p className='mb-10'>{t('contacts.table.owner')}: {property.owner ? property.owner.name : '-'}</p>
          </Col>
          <Col span={12}>
            <p className='mb-10'>{t('properties.new.bedrooms')}: {property.detail_attributes.bathroom_count || 0}</p>
            <p className='mb-10'>{t('properties.table.price')}: {isSale ? (`${price.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')+' €') : price.monthly_price}</p>
            <p>
              <Link className='link-to-property' to={`/properties/${property.id}/overview`} target='_blank'>
              { t('properties.goToProfile') }
              </Link>
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  }

  const getModalContent = () => {
    let content;
    const { modalType } = state;
    const { deal, client } = props;

    switch(modalType) {
      case 'card':
        content = <DealCardForm
            handleOk={handleOk}
            handleCancel={handleCancel}
            deal={deal}
            isRejected={state.isRejected}
            dealProperty={state.showModal}
          />
          break;
      case 'email':
        content = <EmailForm
        onCancelButton={handleCancel}
        contact={client}
        contactType="Client"
        deal_id={deal.id}
        body={generateEmailBody()}
        />
        break;
      default:
        content = ''
    }

    return content;
  }

  const generateEmailBody = () => {
    const { client } = props;
    const user = loginStore.session.user.user;
    const deal: any = props.deal;

    // const emailColumn: any = items["email"].find((item: any) => item.id == 'email')
    const templateParams = {
      emailColumn: items["email"],
      contact: client,
      manager: user,
      deal: deal
    }
    return getEmailBody(templateParams)
  }

  const sendEmailPortal = () => {
    const emailCol: any = items['email'];

    if(emailCol && emailCol.length == 0 ) {
      return;
    }

    const propertyWithoutWebsite = emailCol.find((obj: any) => {
      const tenerifeUrl = obj.property.tenerife_url
      return tenerifeUrl.rent_url === null && tenerifeUrl.sale_url === null
    })

    if(propertyWithoutWebsite) {
      message.error("You have some properties that are not listed on the website. Please, move these properties to other columns and prese 'send email' again")
      return;
    }
    setState({
      modalType: 'email',
      showModal: true
    })
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function findContainer(id: number) {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].find((i: IDealsProperty) => i.id === id));
  }

  function handleDragStart(event: DragEndEvent) {
    const { active } = event;
    const { id } = active;
    setActiveId(id as string);
    setOldItems(items)
  }

  function handleDragOver(event: any) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over;


    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev: any) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex((ai: IDealsProperty) => ai.id === id);
      const overIndex = overItems.findIndex((oi: IDealsProperty) => oi.id === overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          Number(draggingRect?.offsetTop) > Number(over.rect?.offsetTop) + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item: any) => item.id !== active.id)
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length)
        ]
      };
    });
  }

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);
    const activeIndexValue = get(items, `[${activeContainer}]`).find((i: IDealsProperty) => i.id === active.id);
    if(overContainer === 'email' && !activeIndexValue.property?.tenerife_url) {
      message.error(
        `property has to be published on tenerifecenter.com before you can move it to
          "Emails" column!`
      );
      setItems(oldItems);
      return null;
    }
    if(overContainer === 'rejected') {
      showModal(activeIndexValue, true)
    }
    if(overContainer == 'contract' || overContainer === 'reservation') {
      if(oldItems[overContainer].length !== 0) {
        message.error(`In "${startCase(toLower(overContainer))}" only one property is allowed`);
        setItems(oldItems);
        return null;
      }
    }

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }


    const activeIndex = items[activeContainer].findIndex((ai: IDealsProperty) => ai.id === active.id);
    const overIndex = items[overContainer].findIndex((oi: IDealsProperty) => oi.id === overId);

    const params = { status: overContainer, position: overIndex || 0 }
    dealPropertyService.updateResource(id, params)

    if (activeIndex !== overIndex) {

      const movedItems = arrayMove(items[overContainer], activeIndex, overIndex)
      await setItems((items: any) => ({
        ...items,
        [overContainer]: movedItems
      }));
      sendAupdatePosition(overContainer as string, movedItems)
    }

    setActiveId(null);
  }

  return (
    <div className='w-100p of-x-scroll'>
      <div className='draggable-component d-fx'>
        <Modal
          title={renderheaderModal(state.showModal)}
          open={!!state.showModal}
          footer={null}
          width='900px'
          onCancel={handleCancel}
          destroyOnClose
          className='modal'
          maskClosable={false}
        >
          { getModalContent() }
        </Modal>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          {getColumns(props.deal).map((item) => {
            const status = item.key;
            return (
              <div className="ml-5 mr-5 mw-300 mh-400">
                <Container id={status} items={items[status]} showModal={showModal} deal={props.deal} sendEmailPortal={sendEmailPortal} />
              </div>
            )
          })}
        </DndContext>
      </div>
    </div>
  );
}

export default DealBoard;
