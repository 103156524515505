import axios from 'axios';
// import { request, action } from 'utils';
import  propertyCharacteristic  from '../stores/propertyCharacteristic';
import { PROPERTY_CHARACTERISTIC_URL } from 'constants/api';
import { IPropertyCharacteristic } from "declarations/propertyCharacteristic";

interface FilterType {

};

declare interface Props {
  loadResources(params?: FilterType): any,
  loadResourcesRequest(queryParams: {}): Promise<object>
}

export class PropertyCharacteristicService implements Props {

  // LOAD resourceS
  async loadResourcesRequest() {
    return axios.get(PROPERTY_CHARACTERISTIC_URL)
  }

  async loadResources() {
    // let { filters, page } = (ownerListStore || {});
    console.log('propertyCharacteristic.values = ', propertyCharacteristic.values)
    if(propertyCharacteristic.values.length) {
      return
    }

    const response: { status: number, data: Array<IPropertyCharacteristic>} = await this.loadResourcesRequest();

    if (response.status) {
      let values = [];
      values = response.data;

      this.addValues(values);
    }

    return response;
  }

  addValues(values: Array<IPropertyCharacteristic>) {
    propertyCharacteristic.addValues(values);
  }
}

export const propertyCharacteristicService = new PropertyCharacteristicService();
