import { FC, useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import New from '../../components/New';
import { sourceService } from 'services/sourceService';
import sourceStore  from 'stores/source';
import { Table, Button, Menu, Modal, Dropdown, Row, Col, message, Input, Select } from 'antd';
import { SOURCE_CATEGORIES } from 'constants/mixed';
import { Helmet } from 'react-helmet';
import { ISource } from 'declarations/source';
import { EditOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";


const confirm = Modal.confirm;
const Search = Input.Search;
const { Option } = Select;

const List: FC = observer(() => {
  const [editSource, setEditSource] = useState<null | ISource | undefined>(undefined)
  const [search, setSearch] = useState("")
  const [category, setCategory] = useState<string | undefined>(undefined)
  const [page, setPage] = useState(1)
  const { t } = useTranslation();

  useEffect(() => {
    sourceService.loadResources({ page: 1 })
  }, [])

  const showModal = (source: ISource | null) => setEditSource(source)


  const handleOk = (source: ISource) => {
    if (source.id) {
      sourceService.updateResource(source, () => handleCancel())
    } else {
      sourceService.createResource(source, () => handleCancel());
    }
  }

  const handleCancel = () => setEditSource(undefined);

  const columns = [
    {
      title: t('properties.table.id'),
      dataIndex: 'id',
      key: 'id'

    }, {
      title: t('owners.table.name'),
      dataIndex: 'name',
      key: 'name'

    }, {
      title: t('expenses.category'),
      dataIndex: 'category',
      key: 'category'
    }, {
      title: t('tabs.requests'),
      dataIndex: 'requests_count',
      key: 'requests_count',
    }, {
      title: t('menu.contacts'),
      dataIndex: 'contacts_count',
      key: 'contacts_count',
    }, {
      title: t('deals.filter.creationDate'),
      dataIndex: 'created_at',
      key: 'created_at',
    }, {
      title: '',
      dataIndex: 'actions',
      key: 'action'
    }
  ];

  const setSearchText = useCallback(debounce(({ search }) => {
    sourceService.loadResources({ q: search, page: 1 })
  }, 500), [])


  const onSearch = async (e: any) => {
    setSearch(e.target.value);
    setPage(1);
    setCategory(undefined);
    setSearchText({ search: e.target.value});
  }

  const renderDate = (data: string) => {
    if (!data) {
      return '';
    }

    const createdAtDate = moment(data).format('DD.MM.YYYY');
    const createdAtTime = moment(data).format('\\at kk:mm');
    return <div>{createdAtDate} <p>{createdAtTime}</p> </div>
  }

  const getData = () => {
    const sources = sourceStore.values;
    return (sources || []).map((source: any, index: number) => {
      return {
        id: source.id,
        name: source.name,
        category: source.category,
        requests_count: source.requests_count,
        contacts_count: source.contacts_count,
        created_at: renderDate(source.created_at),
        actions: <Dropdown overlay={getActionComponent(source)} trigger={['click']}>
            <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
        </Dropdown>
      }
    })
  }

  const showConfirm = (source: ISource) => {
    if(source.requests_count > 0 || source.contacts_count > 0) {
      return message.error(
        t('sources.cantDelete')
      )
    }
    confirm({
      title: t('sources.confirmDelete'),
      content: '',
      onOk() {
        return new Promise((resolve, reject) => {
          sourceService.deleteResource(source, resolve)
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }

  const getActionComponent = (source: ISource) => {
    return (
      <Menu
        mode="horizontal"
        defaultSelectedKeys={['2']}
        className='actons-menu'
      >
        <Menu.Item key={`edit_source_${source.id}`} onClick={() => showModal(source)}>
          <span className='edit-title'>
            <EditOutlined />
            {t('owners.table.edit')}
          </span>
        </Menu.Item>
        <Menu.Item key={`delete_source_${source.id}`} onClick={() => showConfirm(source)}>
          <span className='delete-title'>
            <DeleteOutlined />
            {t('owners.table.delete')}
          </span>
        </Menu.Item>
      </Menu>
    )
  }

  const renderUserModal = () => {
    return <New handleOk={handleOk} handleCancel={handleCancel} source={editSource}
    />
  }

  const changePagination = (page: number) => {
    sourceService.loadResources({ page, search, category });
    setPage(page)
  }

  const getCategoryOptions = () => {
    return SOURCE_CATEGORIES.map((category: string) => <Option value={category}>{category}</Option>)
  }

  const onChange = async (value: string) => {
    setCategory(value)
    setSearch("")
    setPage(1);
    sourceService.loadResources({ page: 1, category: value });
  }

  const paginationProps = {
    pageSize: 10, onChange: changePagination, total: sourceStore.count, showSizeChanger: false, current: page
  }
  return (
    <div className="users-page page">
      <Helmet>
        <title>{t("sources.header")}</title>
      </Helmet>
      <Modal
        title={t('sources.addSource')}
        visible={!!editSource || editSource === null }
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
        className='modal'
        maskClosable={false}
      >
        {renderUserModal()}
      </Modal>

      <Row align='middle' justify='space-between' className='page-subheader'>
        <Col span={8}>
          <h3>{t('sources.header')} ({sourceStore.count})</h3>
        </Col>
        <Col span={16}>
          <Row justify='end'>
            <Col className='mr-10'>
              <Select
                showSearch
                placeholder={t('expenses.category')}
                onChange={onChange}
                style={{ width: '200px' }}
                size='large'
                value={category}
                filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value=''>{t('sources.allCategories')}</Option>
                { getCategoryOptions() }
              </Select>
            </Col>
            <Col className='mr-10'>
              <Search
                placeholder={t('properties.search')}
                value={search}
                onChange={onSearch}
                className='h-100p'
                size='large'
                allowClear={true}
              />
            </Col>
            <Col>
              <Button type='primary' size='large' onClick={() => showModal(null)}>{t('sources.addSource')}</Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className='page-content'>
        <Table
          dataSource={getData()}
          columns={columns}
          className='general-table'
          bordered
          pagination={paginationProps}
        />
      </div>
    </div>
  );
})

export default List;
