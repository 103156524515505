import { FC, useState, useEffect, forwardRef } from 'react';
import { observer } from 'mobx-react';
import { galleryService } from 'services/galleryService';
import ProgressStore from 'stores/progress';
import shortid from 'shortid';
import { UPDATE_GALLERY } from 'constants/action';
import { ReactSortable } from "react-sortablejs";

import { Select, Button, Input, Row, Col, Modal, Space } from 'antd';
import { PlusOutlined, DeleteOutlined, EyeOutlined, GlobalOutlined, LockOutlined } from "@ant-design/icons";
import { IImage } from 'declarations/image';
import { useTranslation } from 'react-i18next';

const sortableOptions = {
  animation: 150,
  fallbackOnBody: true,
  swapThreshold: 0.65,
  ghostClass: "ghost",
  group: "shared",
  forceFallback: true
};

interface IData {
  images: Array<any>;
  handleCancel(): void;
  property: any;
}

interface IState {
  items: Array<IImage>,
  images: Array<any>;
  demo_url: string;
  video_url: string;
  loading: boolean;
  previewVisible: boolean,
  previewImage: string;
}

const { Option } = Select;

const GalleryForm: FC<IData> = observer((props) => {
  const { t } = useTranslation()
  const [state, setState] = useState<IState>({
    images: [],
    video_url: '',
    demo_url: '',
    loading: false,
    previewVisible: false,
    previewImage: '',
    items: []
  });

 const [images, setImages] = useState<any>([]);
 const [deleteImages, setDeleteImages] = useState<any>([]);

  useEffect(() => {
    const  { video_url, demo_url } = props.property;
    setState({
      ...state,
      video_url,
      demo_url,
      images: props.images
    })
    setImages(props.images)
  }, [])

  // const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
  //   const movedImages = arrayMove(images.filter((item: any) => !item.is_delete), oldIndex, newIndex);
  //   const removedImages = images.filter((item: any) => item.is_delete)

  //   setState(({ images }: { images: any }) => ({
  //     images: movedImages.concat(removedImages)
  //   }));
  // };

  const handleImageCancel = () => setState({ ...state, previewVisible: false, previewImage: '' })

  const showPreviewImage = (image: IImage) => {
    setState({
      ...state,
      previewImage: image.url.original,
      previewVisible: true,
    });
  }

  const setNewImage = (file: any) => {
    const url = URL.createObjectURL(file)
    return {
      is_public: true,
      uid: shortid(),
      url: { thumb: url, original: url, hd: url, file: file }
    }
  }

  const getImageName = () => {
    const { property } = props;
    const partName = [
      property.property_type.name['en'],
      property.location_attributes.city.name,
      property.location_attributes.region.name,
      property.identifier
    ]
    return partName.join(' ')
  }

  const handleSubmit = () => {
    const {video_url, demo_url} = state;
    const imageName = getImageName()
    var data = new FormData();
    setState({...state, loading: true})
    data.append('galleries[property_key]', props.property.gallery_key)
    data.append('galleries[property][id]', props.property.id)
    data.append('galleries[property][video_url]', video_url)
    data.append('galleries[property][demo_url]', demo_url)
    const allImages = [...images, ...deleteImages];

    allImages?.forEach((image: any, index: number) => {
      data.append('galleries[images][][id]', image.id ? image.id : '')
      data.append('galleries[images][][image]', image.id ? image.url.thumb : image.url.file)
      data.append('galleries[images][][property_key]', props.property.gallery_key)
      data.append('galleries[images][][name]', imageName)
      data.append('galleries[images][][position]', index.toString())
      data.append('galleries[images][][is_public]', image.is_public)
      data.append('galleries[images][][_delete]', image.is_delete ? 'true' : '')
    })
    galleryService.changesResource(data, props.handleCancel)
  }

  const onUploadImage = async (e: any) => {
    const files = e.target.files;
    let loadImagesArray = [];
    for (let index: number = 0; index < files.length; index++) {
      const newImage = setNewImage(files[index])
      loadImagesArray.push(newImage);
    }
    setImages([...images, ...loadImagesArray])
  }

  const deleteImage = (item: IImage) => {
    let newImages;
    if(item.id) {
      const newItem = {...item, is_delete: true }
      newImages = images?.map((image: IImage) => image.id == item.id ? newItem : image);
      setDeleteImages([...deleteImages, ...[newItem]]);
    } else {
      newImages = images?.filter((image: IImage) => image.url.thumb != item.url.thumb)
    }

    setImages(newImages);
    // setState({ ...state, images: newImages })
  }

  const getButtons = () => {
    return <div>
        <Button className='mr-10' type="primary" onClick={handleSubmit}
          loading={ProgressStore.isLoading(UPDATE_GALLERY)}
        >
          {t('save')}
        </Button>
        <Button onClick={props.handleCancel}>{t('cancel')}</Button>
    </div>
  }

  const handleChange = (item: any) => {
    const newImage = { ...item, is_public: !item.is_public }
    const key = item.id ? 'id' : 'uid';
    setImages(images?.map((image: IImage) => image[key] == newImage[key] ? newImage : image))
  }

  const handleUrlsChange = (e: any) => {
    const name: string = e.currentTarget.name;
    const value: string = e.currentTarget.value;

    setState({ ...state, [name]: value })
  }

  const CustomComponent = forwardRef<HTMLDivElement, any>((props, ref) => {
    return (
      <div className='d-fx' ref={ref}>
        {props.children}
        <div className='gallery-item button-upload'>
          <span className='avatar-uploader'>
            <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
              <span className="ant-upload pos-r" role="button">
                <Input
                  type='file'
                  multiple
                  accept='.jpg, .jpeg, .png, .bmp, .gif'
                  onChange={onUploadImage}
                  style={{ opacity: 0, position: 'absolute', fontSize: '100px', top: 0, left: 0, padding: '72px' }}
                />
                <PlusOutlined className="anticon anticon-plus" />
                <div className="ant-upload-text">{t('upload')}</div>
              </span>
            </div>
          </span>
        </div>
      </div>
    );
  });


    // const SortableItem = ({ item }: { item: any }) =>
    //   <Col className='gallery-item' style={{ margin: '20px 10px', padding: '0', width: '190px', height: '118px', zIndex: 1, position: 'relative' }}>
    //     <DragHandle />
    //     <div className="ant-upload-list ant-upload-list-picture-card">
    //       <div className="ant-upload-list-item ant-upload-list-item-done" style={{ width: '190px', height: '115px', padding: 0, margin: 0 }}>
    //         <div className="ant-upload-list-item-info">
    //           <img className="ant-upload-list-item-thumbnail" src={item.url.thumb} width='175' height='115' />
    //         </div>
    //         <span className="ant-upload-list-item-actions">
    //           <EyeOutlined className="anticon" onClick={() => showPreviewImage(item)} />
    //           <DeleteOutlined className="anticon anticon-delete" onClick={() => deleteImage(item)} />
    //         </span>
    //       </div>
    //     </div>
    //     <Select style={{ width: '100%' }} onChange={() => handleChange(item)} value={item.is_public.toString()}>
    //       <Option value='true'><i className='flaticon-earth-globe mr-5'></i>
    //         {t('public')}
    //       </Option>
    //       <Option value='false'><i className='flaticon-lock mr-5'></i>
    //         {t('private')}
    //       </Option>
    //     </Select>
    //   </Col>;

  //   const SortableList = SortableContainer(({ items }: { items: Array<any> }) => {
  //     return (
  //       <Row gutter={20}>
  //         {items?.map((item, index) => (
  //           <SortableItem key={`item-${index}`} index={index} />
  //         ))}
  //         <Col className='gallery-item button-upload'>
  //           <span className='avatar-uploader'>
  //             <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
  //               <span className="ant-upload pos-r" role="button">
  //                 <Input
  //                   type='file'
  //                   multiple
  //                   accept='.jpg, .jpeg, .png, .bmp, .gif'
  //                   onChange={onUploadImage}
  //                   style={{ opacity: 0, position: 'absolute', fontSize: '100px', top: 0, left: 0, padding: '72px' }}
  //                 />
  //                 <PlusOutlined className="anticon anticon-plus" />
  //                 <div className="ant-upload-text">{t('upload')}</div>
  //               </span>
  //             </div>
  //           </span>
  //         </Col>
  //       </Row>
  //     );
  //   });
  //   const imageList = images?.filter((item: any) => !item.is_delete);
  //   // return <SortableList items={imageList} onSortEnd={onSortEnd} axis='xy' useDragHandle />;
  //   return <SortableList  axis='xy' useDragHandle />;
  // }

  const { previewVisible, previewImage, demo_url, video_url } = state;
  return (
    <div>
      <div>
        <Modal visible={previewVisible} footer={null} onCancel={handleImageCancel} className='gallery-preview' maskClosable={false}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>

        <div className='gallery-content'>
          <h3 className='mb-30'>{t('properties.new.steps.gallery')}</h3>
          {/* <SortableList /> */}
          <div>
            <ReactSortable
              list={images.filter((item: any) => !item.is_delete)}
              setList={setImages}
              className="d-fx fxw-w"
              invertSwap={true}
              filter=".button-upload"
              onMove={(e) => {
                return e.related.className.indexOf('button-upload') === -1;
              }}
            >
              {images.filter((item: any) => !item.is_delete)?.map((item: any) => (
                // <SortableItem key={item.id} item={item} />
                <Col key={item.id} className='gallery-item'>
                  {/* <DragHandle /> */}
                  <div className="ant-upload-list ant-upload-list-picture-card">
                    <div className="ant-upload-list-item ant-upload-list-item-done" style={{ width: '190px', height: '118px', padding: 0, margin: 0 }}>
                      <div className="ant-upload-list-item-info">
                        <img className="ant-upload-list-item-thumbnail" src={item.url.thumb} width='100%' height='118' />
                      </div>
                      <span className="ant-upload-list-item-actions">
                        <EyeOutlined  onClick={() => showPreviewImage(item)} />
                        <DeleteOutlined className="ml-5" onClick={() => deleteImage(item)} />
                      </span>
                    </div>
                  </div>
                  <Select style={{ width: '100%' }} onChange={() => handleChange(item)} value={item.is_public.toString()}>
                    <Option value='true'><GlobalOutlined className='mr-5' />
                      {t('public')}
                    </Option>
                    <Option value='false'><span className="text-red" ><LockOutlined className='mr-5' />
                      {t('private')}</span>
                    </Option>
                  </Select>
                </Col>
              ))}
              <div className='button-upload d-fx'>
                <span className='avatar-uploader relative p-5'>
                  <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
                    <span className="ant-upload pos-r" role="button">
                      <Input
                        type='file'
                        multiple
                        accept='.jpg, .jpeg, .png, .bmp, .gif'
                        onChange={onUploadImage}
                        style={{ opacity: 0, position: 'absolute', fontSize: '100px', top: 0, left: 0, width: 175, height: 118 }}
                      />
                      <PlusOutlined className="anticon anticon-plus" />
                      <div className="ant-upload-text">{t('upload')}</div>
                    </span>
                  </div>
                </span>
              </div>
            </ReactSortable>
          </div>
        </div>
        <Row align='middle' className='mt-30 mb-30'>
          <Col span={6} className='ta-r'><span className='mr-10'>{t('gallery.demoUrl')}</span></Col>
          <Col span={14}>
            <Input name='demo_url' placeholder='https://matterport.com/' onChange={handleUrlsChange} value={demo_url} />
          </Col>
        </Row>
        <Row align='middle'>
          <Col span={6} className='ta-r'><span className='mr-10'>{t('gallery.video')}</span></Col>
          <Col span={14}>
            <Input name='video_url' placeholder='https://youtube.com/' onChange={handleUrlsChange} value={video_url} />
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={6}></Col>
        <Col span={18}>
          <div className="form-controls mt-20 mb-20">
            {getButtons()}
          </div>
        </Col>
      </Row>
    </div>
  )
})

export default GalleryForm;
