import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { propertyService } from '../../../PropertyService';
import { LANGUAGES } from 'constants/mixed'
import ProgressStore from 'stores/progress';

import {
  Form, Input, Button, Row, Col
} from 'antd';
import { UPDATE_PROPERTY } from 'constants/action';
import { useTranslation } from 'react-i18next';
import { some } from 'lodash';
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

export interface IData {
  descriptionAttributes: any,
  handleCancel?(): any,
  prev?(params: any): any,
  next?(params: any): any,
  isNotSteper?: boolean
}


const Description: FC<IData> = observer(({descriptionAttributes, handleCancel, prev, next, isNotSteper}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [state, setState] = useState({
    id: null,
    value: {}
  });

  useEffect(() => {
    if (descriptionAttributes) {
      setState({
        ...state,
        ...descriptionAttributes
      })
      form.setFieldsValue(descriptionAttributes);
    }
  }, [descriptionAttributes])

  const onCancel = () => handleCancel && handleCancel();

  const handleSubmit = async () => {
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      // const descriptionValue = { ...this.state, ...values };
      const descriptionValue = { ...(descriptionAttributes || {}), ...values };
      propertyService.updateResource('descriptions', descriptionValue, () => onCancel())
    }
  }

  const prevStep = async () => {
    await form.validateFields();
      if (!some(form.getFieldsError(), ({ errors }) => errors.length) && prev) {
        const values = form.getFieldsValue();
        const descriptionValues = { ...values }

        prev({ description_attributes: descriptionValues })
      }
  }

  const nextStep = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    const descriptionValues = { ...values }

    if (!some(form.getFieldsError(), ({ errors }) => errors.length) && next) {
      next({ description_attributes: descriptionValues })
    }
  }

  const getButtons = () => {
    let buttons;

    if (isNotSteper) {
      buttons = <div className='mb-20'>
        <Col>
          <Button className='mr-10' type="primary" onClick={handleSubmit}
            loading={ProgressStore.isLoading(UPDATE_PROPERTY)}
          >
            {t('save')}
          </Button>
          <Button onClick={onCancel}>{t('cancel')}</Button>
        </Col>
      </div>
    } else {
      buttons = <div>
        <Button className='mr-10' onClick={prevStep}><ArrowLeftOutlined /> {t('back')}</Button>
        <Button type="primary" onClick={nextStep}>
        {t('continue')} <ArrowRightOutlined />
        </Button>
      </div>
    }
    return buttons;
  }

  const getFormItems = () => {
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    return LANGUAGES.map((language: string) => {
      return <Form.Item
        {...formItemLayout}
        label={`${t('properties.new.description')} ${language}`}
        key={`description-${language}`}
        name={["value", language]}
        rules={[{ required: language == 'en', message: t('validates.cantBeEmpty') }]}
      >
        <Input.TextArea placeholder={t('properties.new.description')} rows={6} />
      </Form.Item>
    })
  }

  const formItemRow = {
    wrapperCol: { span: 14, offset: 6 }
  };


  return (
    <div>
      <div>
        <Form onFinish={handleSubmit} form={form}>
          <Form.Item
            {...formItemRow}
          >
            <h3>{t('properties.new.description')}</h3>
          </Form.Item>
          {getFormItems()}
        </Form>
      </div>
      <Row>
        <Col span={6}></Col>
        <Col span={18}>
          <div className="form-controls">
            {getButtons()}
          </div>
        </Col>
      </Row>
    </div>
  )
})

export default Description;
