import { FC, PropsWithChildren, ReactElement} from 'react';
import { observer} from 'mobx-react';
import { Row, Col, Space, Skeleton } from 'antd';

interface IData extends PropsWithChildren{
  loading?: boolean;
  sidebar?: ReactElement;
  extra?: ReactElement;
  title: ReactElement;
}


const ListContainer: FC<IData> = observer(({loading, title, extra, sidebar, children}) => {
  return (
    <div className="py-30">
      <Row align='middle' justify='space-between' className='page-subheader mb-20'>
        <h3>{loading ? <Skeleton.Input className='w-200' /> : title}</h3>
        <Space size={10}>
          {extra}
        </Space>
      </Row>

      <Row justify='space-between' gutter={20} className='page-content'>
        <Col span={4}>
          {sidebar}
        </Col>
        <Col span={20}>
          {children}
        </Col>
      </Row>

    </div>
  );
});

export default ListContainer;
