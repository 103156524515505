import { FC, useState, useEffect, useRef} from 'react';
import {observer} from 'mobx-react';
import { Form, Select, Input, Button, Row, Col } from 'antd';
import { userListService } from '../../users/containers/UserListService';
import loginStore from 'modules/login/loginStore';
import { Editor } from '@tinymce/tinymce-react';
import userStore from 'stores/user';
// import GoogleLogin from 'react-google-login';
import { IUser } from 'user';
import { EDITOR_OPTIONS } from 'constants/mixed';
import { useTranslation } from 'react-i18next';
import { some } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';


const { Option } = Select;

const Profile: FC = observer(() => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const profile = userStore.selected;
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const editorRef = useRef<Editor | null>(null);
  // const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = loginStore.session.user.user;
    userListService.loadProfile(user.id)
    if(profile) {
      form.setFieldsValue({...profile})
    }
  }, [profile?.id])

  const handleFormSubmit = async (e: any) => {
    console.log("🚀 ~ file: Form.tsx:38 ~ handleFormSubmit ~ e:", e)
    await form.validateFields();
    console.log("asdasdasd")

    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      handlePut(values);
    }
  }

  const handlePut = (values: {}) => {
    let newValues : any = { ...profile, ...values };
    if (newValues.hasOwnProperty('password_confirm')) {
      delete newValues['password_confirm'];
    }
    newValues.id = profile?.id;
    userListService.updateResource(newValues, succesUpdate);
  }

  const succesUpdate = (user: IUser) => {
    loginStore.updateCurrentUser(user)
  }

  const handleChange = (value: any, name: string) => {
    form.setFieldsValue({[name]: value });
  }

  const handleCancel = () => navigate('/properties');

  const handleConfirmBlur = (e: React.ChangeEvent<HTMLInputElement> )=> {
    const value = e.target.value;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(t('login.messagePassword'));
    } else {
      callback();
    }
  };

  const validateToNextPassword = (_: any,  value: any) => {
    if (value && confirmDirty) {
      form.validateFields(['password_confirm']);
    }
    return Promise.resolve();
  };

  const passwordItems = (formItemLayout: any) => {
    return (
      <>
        <Form.Item
            {...formItemLayout}
            label={t('login.newPassword')}
            hasFeedback
            key='pass1'
            name="password"
            rules={[
              {
                required: true,
                message: t('login.placeholder.password'),
              },
              () => ({
                validator(_, value) {
                  if (value && confirmDirty) {
                    form.validateFields(['password_confirm']);
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
           <Input.Password onChange={(e: any) => handleChange(e.currentTarget.value, 'password')}/>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('login.repeatNewPassword')}
            hasFeedback
            key='pass2'
            name="password_confirm"
            rules={[
              {
                required: true,
                message: t('login.message.confirmPassword'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password  />
        </Form.Item>
      </>
    )
  }
  const disconnect = () => {
    const user: any = userStore.selected;
    userListService.deleteConnection({ id: user.has_connection.id })
  }

  const responseGoogle = (resp: any) => {
    const params = { code: resp.code }
    userListService.createConnection(params)
  }

  const respErrorGoogle = (resp: any) => {
    console.log('error resp = ', resp)
  }

  const login = useGoogleLogin({
    onSuccess: response => responseGoogle(response),
    onError: respErrorGoogle,
    flow: 'auth-code',
    scope: "email profile https://mail.google.com/"
  });

  const renderConnectionButton = () => {
    const user: any = userStore.selected;
    let button;

    if(user.has_connection) {
      button = <Button type='primary' onClick={disconnect}>{t('login.disconnect')}</Button>
    } else {
      button = <Button type='primary' onClick={() => login()}>{t('login.connect')}</Button>
      // button = <Button type='primary' onClick={this.showModal}>connect</Button>
      // button = <GoogleLogin
      //   buttonText={t('login.connect')}
      //   accessType='offline'
      //   responseType='code'
      //   prompt="consent"
      //   // redirectUri='https://developers.google.com/oauthplayground'
      //   scope='email profile https://mail.google.com/'
      //   onSuccess={responseGoogle}
      //   onFailure={respErrorGoogle}
      // />
    }
    return button
  }

  const handleEditorChange = (value: string) => {
    form.setFieldsValue({ signature: value })
  }

  const formItemLayout = {
    colon: false,
    labelCol: {
      xs: { span: 24},
      sm: { span: 6 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { offset: 1, span: 17 }
    },
  };

  const controlsItemLayout = {
    wrapperCol: {
      xs: { span: 24 },
      sm: {offset: 7, span: 17 }
    }
  }

  const user: any = userStore.selected;

  if(!(user && user.id)) {
    return null;
  }

  return (
    <Form name="profile-form" form={form}>
      <Form.Item
        {...formItemLayout}
        label={t('login.new.firstName')}
        name="first_name"
        rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('login.new.lastName')}
        name="last_name"
        rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={t('login.email')}
        name="email"
        rules={[
          {
            type: 'email',
            message: t('login.message.email'),
          },
          {
            required: true,
            message: t('validates.cantBeEmpty'),
          },
        ]}
        >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('login.new.defaultLang')}
        name="language"
        rules={[{required: true, message: t('validates.cantBeEmpty')}]}
      >
        <Select showArrow={true} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
          <Option value="en" key='language_0'>{t('contacts.new.lng.en')}</Option>
          <Option value="es" key='language_1'>{t('contacts.new.lng.es')}</Option>
          <Option value="ru" key='language_2'>{t('contacts.new.lng.ru')}</Option>
        </Select>
      </Form.Item>
    <Form.Item
        {...formItemLayout}
        label={t('login.new.gmailAccount')}
      >
        <Row justify='start'>
          <Col>
            {renderConnectionButton()}
          </Col>
          { user.has_connection &&
            <Col className='ml-10'>
              { user.has_connection.email }
            </Col>
          }
        </Row>

      </Form.Item>
      <Form.Item name="signature" noStyle><Input type="hidden" /></Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('login.new.signature')}
      >
        <Editor
          apiKey='rivvkn9wdgkazq99qryveaoe1sn1tve74p0emmbyb8tn0gxm'
          initialValue={profile?.signature}
          ref={editorRef}
          // onInit={(evt, editor) => editorRef.current = editor}
          init={{...EDITOR_OPTIONS}}
          onEditorChange={handleEditorChange}
        />
      </Form.Item>
      {confirmPassword && passwordItems(formItemLayout)}
      {!confirmPassword && loginStore.isAdmin && <Form.Item
        {...controlsItemLayout}
      >
        <Button danger onClick={() => setConfirmPassword(true)}>{t('login.new.changePassword')}</Button>
      </Form.Item>}
      <Form.Item
        {...controlsItemLayout}
      >
        <Button type='primary' className='mr-10' onClick={handleFormSubmit}>{t('save')}</Button>
        <Button onClick={handleCancel}>{t('cancel')}</Button>
      </Form.Item>
    </Form>
  )
})

export default Profile;