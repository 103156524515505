import { FC } from 'react';
import { observer, } from 'mobx-react';
import propertyStore from 'stores/property';
import AppLayout from '../../Layout';
import Tasks from 'submodules/tasks/containers/index';

const PropertyTasks: FC = observer(() => {
  const property = propertyStore.selected;

  return (
    <AppLayout>
      <Tasks resource={property} resource_type='Property' />
    </AppLayout>
  )

})

export default PropertyTasks;
