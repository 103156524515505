import { PropertyType } from 'declarations/propertyType';
import { makeAutoObservable } from 'mobx';

export class TypeStore {

  values: Array<PropertyType> = [];
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: Array<PropertyType>): void {
    this.values = values;
  }

  setLoading(val: boolean) {
    this.loading = val;
  }
}

export default (new TypeStore())

