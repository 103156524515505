import { FC, useEffect} from 'react';
import { observer} from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { userListService } from 'modules/users/containers/UserListService';
import UserStore from 'stores/user';
import { Form, Button, Select, Row, Col } from 'antd';

import { IUser } from "declarations/user";
import { some } from 'lodash';

interface IData {
  onSelectManager(values: { manager_id: number }): void;
  handleCancel(): void;
  manager_id: number | undefined;
}

const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const ContactForm: FC<IData> = observer(({onSelectManager, manager_id, handleCancel}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    userListService.loadResources({}, 'all')
    form.setFieldsValue({ manager_id })
  }, [])


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await form.validateFields();

    if (!(some(form.getFieldsError(), ({ errors }) => errors.length))) {
      onSelectManager(form.getFieldsValue())
    }
  }


  const renderManagers = () => {
    const managers = UserStore.values;
    return managers.map((user: IUser) => <Option value={user.id}>{user.name}</Option>)
  }

  return (
    <div>
      <Form form={form}>
        <Form.Item
          {...formItemLayout}
          label={t('manager')}
          name="manager_id"
        >
          <Select
            placeholder={t('contacts.new.placeholderManager')}
            showSearch
            allowClear
            filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {renderManagers()}
          </Select>
        </Form.Item>
      </Form>
      <Row>
        <Col span={6}>

        </Col>
        <Col span={18}>
          <div className="form-controls">
            <Button type="primary" onClick={handleSubmit} className='mr-10'>{t('save')}</Button>
            <Button onClick={handleCancel}>{t('cancel')}</Button>
          </div>
        </Col>
      </Row>
    </div>
  );
})

export default ContactForm;
