import { groupBy } from 'lodash';
import { ISource } from 'declarations/source';
import { makeAutoObservable } from 'mobx';

export class SourceStore {

  values: Array<ISource> = [];
  count: number = 0
  fetchInProgress = false;
  saveInProgress = false;
  deleteInProgress = false;

  groupedValues: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: Array<ISource>, count: number): void {
    this.values = values;
    this.count = count;
    this.groupedValues = groupBy(values, 'category')
  }

  updateValue(value: ISource) {
    this.values = this.values.map((item: ISource) => item.id == value.id ? value : item)
  }

  setFetchInProgress(val: boolean) {
    this.fetchInProgress = val;
  }
  setSaveInProgress(val: boolean) {
    this.saveInProgress = val;
  }
  setDeleteInProgress(val: boolean) {
    this.deleteInProgress = val;
  }

}

export default (new SourceStore())