import { FC, useState, useEffect} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { fileService } from 'services/fileService';
import { Form, Button, Input, Select, Row, Col, Upload } from 'antd';
import ProgressStore from 'stores/progress';
import { UPDATE_FILE } from 'constants/action';
import { UploadOutlined } from "@ant-design/icons";
import ownerStore from "stores/owner";
import clientStore from "stores/client";
import { propertyService } from 'modules/properties/PropertyService';

import { IContact } from "declarations/contact";
import { IFile } from "declarations/file";
import { Property as IProperty } from "declarations/property";
import { some } from 'lodash';

interface IData {
  attachment?: IFile;
  resource: IContact | IProperty;
  resource_type: string;
  isActivity?: boolean;
  onCreateContact?(params: IContact): void;
  onCancelButton(): void;
}
const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const FileForm: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [state, setState] = useState<any>({
    attachment: {
      id: undefined,
      note: '',
      is_public: 'true'
    },
    fileLists: [],
    deletedLists: []
  });

  useEffect(() => {
    const { attachment } = props;

    if (attachment && attachment.id) {
      const files = customizeFiles();
      setState({
        ...state,
        attachment: {
          ...state.attachment,
          ...attachment
        },
        fileLists: files
      })
      form.setFieldsValue({...attachment, files})
    }
    return () => form.resetFields();
  }, [])

  const customizeFiles = () => {
    const { attachment } = props;

    return (attachment && attachment.files || []).map((file: any) => {
      return {
        uid: file.filename,
        size: file.size,
        name: file.filename,
        url: file.url,
        status: "done",
        type: file.content_type
      }
    })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { attachment, fileLists, deletedLists } = state;
    const { resource, resource_type, isActivity } = props;

    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      const data = new FormData();
      const resource_id = resource.id;
      data.append('attachment[attachable_id]', resource_id.toString())
      data.append('attachment[attachable_type]', resource_type)
      data.append('attachment[note]', values['note'] || "")
      data.append('attachment[is_public]', attachment.is_public)
      fileLists.forEach((file: any) => {
        if (file.originFileObj) {
          data.append('attachment[files][]', file.originFileObj)
        }
      })
      deletedLists.forEach((filename: string) => {
        data.append('attachment[deleted_files][]', filename)
      })
      const attachInfo = { attachable_id: resource_id, attachable_type: resource_type, isActivity: isActivity };
      if (attachment && attachment.id) {
        fileService.updateResource(data, attachment.id || 0, attachInfo, props.onCancelButton)
      } else {
        await fileService.createResource(data, attachInfo, props.onCancelButton)
        if(props.resource_type === "Owner") {
          ownerStore.fetchOwner(props.resource.id)
        } else if (props.resource_type === "Property") {
          propertyService.loadResource(props.resource.id)
        } else {
          clientStore.fetchClient(props.resource.id)
        }
      }
    }
  }


  const onVisibilityChange = (value: string) => {
    setState({
      ...state,
      attachment: {
        ...state.attachment,
        is_public: value
      }
    })
  }

  const renderVisibilityText = () => {
    let text;
    const isPublic = state.attachment.is_public == 'true';
    if (isPublic) {
      text = t('publicTextFile');
    } else {
      text = t('privateTextFile')
    }
    return <p className='visibility-text'>{text}</p>;
  }

  const handleChange = (info: any) => {
    let fileLists = [...info.fileList];
    if (info.file.status == 'removed' && !info.file.lastModified) {
      const deletedFiles = [...state.deletedLists, info.file.name]
      setState({ ...state, deletedLists: deletedFiles })
    }
    fileLists = fileLists.map((file: any) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setState({ ...state,  fileLists });
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  const handleCancel = () => {
    form.resetFields();
    props.onCancelButton();
  }

  const is_public = state?.attachment?.is_public;

  const uploadProps = {
    action: 'https://run.mocky.io/v3/8d8c922c-9f31-48de-a45f-460bd4c75321',
    onChange: handleChange,
    multiple: true,

  };

  const dummyRequest = (onSuccess: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

    return (
      <div className="notes-form">
        <Form form={form}>
          <Form.Item
            {...formItemLayout}
            label={t('tabs.files')}
            name="files"
            rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
          >
              {/* {getFieldDecorator('files', {
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
                rules: [{ required: true, message: t('validates.cantBeEmpty') }],
                initialValue: state.fileLists
              })( */}
            <Upload {...uploadProps} multiple fileList={state.fileLists} customRequest={({ onSuccess }) => dummyRequest(onSuccess)}>
              <Button>
                <UploadOutlined /> {t('emails.upload')}
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('tabs.note')}
            name="note"
          >
            <Input.TextArea placeholder={t('tabs.note')} rows={6} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('emails.visibility')}
          >
            <Select onChange={onVisibilityChange} value={is_public.toString()}>
              <Option value="true">{t('public')}</Option>
              <Option value="false">{t('private')}</Option>
            </Select>
            {renderVisibilityText()}
          </Form.Item>
        </Form>
        <Row>
          <Col span={6}></Col>
          <Col span={18}>
            <div className="form-controls">
              <Button
                type='primary'
                className='mr-10'
                loading={ProgressStore.isLoading(UPDATE_FILE)}
                onClick={handleSubmit}
              >
                {t('save')}
              </Button>
              <Button onClick={handleCancel}>{t('cancel')}</Button>
            </div>
          </Col>
        </Row>
      </div>
    );
})

export default FileForm;
