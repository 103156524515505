import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { SALE_VALUE, SALE_DEALS_BOARD_COLUMNS, RENT_DEALS_BOARD_COLUMNS, RENT_VALUE } from 'constants/mixed';
import {  Row, Col, Modal, Tooltip, message } from 'antd';
import { get, groupBy, keys, sortBy } from 'lodash';
import { dealPropertyService } from '../DealsPropertyService';
import loginStore from 'modules/login/loginStore';
// import { Container, Draggable } from 'react-smooth-dnd';
import DealCardForm from './DealCardForm';
import LocaleStore from 'stores/locale';
import withoutImage from 'images/placeholder-image.png';
import { name } from 'utils/localized';
import { getEmailBody } from 'utils/template';
import { startCase, toLower } from 'lodash';
import EmailForm from 'submodules/emails/components/Form';
// import EmailTemplate from 'submodules/emails/components/Template';
import { ReactSortable, Sortable } from "react-sortablejs";
import { IContact } from 'declarations/contact';
import { IDeal } from 'declarations/deal';
import { Property as IProperty } from 'declarations/property';
import { IDealsProperty } from 'declarations/dealsProperty';
import email from 'stores/email';
import Container from "./BoardContainer";
import {
  DndContext,
  DragOverlay,
  DragEndEvent,
  DragOverEvent,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

interface IData {
  contact: IContact,
  deal: IDeal,
}

// interface State {
//   scene: {
//     type: string;
//     props: {
//       orientation: string;
//     },
//     children: Array<IColumn>;
//   },
//   deal: IDeal
// }

// interface IColumn {
//   id: string;
//   type: string;
//   name: string;
//   props: {
//     orientation: string;
//     className: string;
//   };
//   children: Array<ICard>;
// }

// interface ICard {
//   type: string;
//   id: string;
//   props: {
//     className: string;
//     style: { backgroundColor: string };
//   };
//   data: any;
// }

const generateChildrenFromDeal = (deal: IDeal) => {
  let children = [];
  const columns = getColumns(deal);
  const groupedItems = groupedDealsPropertyByStatus(deal);

  // return columns.map((item: { name: string, key: string }) => {
  //   return {
  //     id: item.key,
  //     type: "container",
  //     name: item.name,
  //     props: {
  //       orientation: "vertical",
  //       className: "card-container"
  //     },
  //     children: generateCardItem(item.key, groupedItems)
  //   }
  // })
}

const groupedDealsPropertyByStatus = (properties: any) => {
  // return groupBy(sortBy(properties, ["status", "position"]), 'status');
  const groupItems = groupBy(properties, 'status');
  console.log("🚀 ~ file: DealBoard.tsx:97 ~ groupedDealsPropertyByStatus ~ groupItems:", groupItems)
  const values = keys(groupItems).forEach((key: string) => sortBy(groupItems[key], "position"));
  console.log("🚀 ~ file: DealBoard.tsx:99 ~ groupedDealsPropertyByStatus ~ groupItems:", groupItems)
  return groupItems
}


// const generateCardItem = (key: string, groupedItems: any) => {
//   return (groupedItems[key] || []).map((item: any) => {
//     return {
//       type: "draggable",
//       id: `${key}${item.id}`,
//       props: {
//         className: "card"
//       },
//       data: { ...item }
//     }
//   })
// }

const getColumns = (deal: IDeal) => {
  return deal.operation == SALE_VALUE ? SALE_DEALS_BOARD_COLUMNS : RENT_DEALS_BOARD_COLUMNS;
}

const updateState = (deal: IDeal) => {
  return {
    scene: {
      type: "container",
      props: {
        orientation: "horizontal",
        className: 'test-class'
      },
      children: generateChildrenFromDeal(deal)
    },
    deal: deal
  }
}

const DefaultBoardState = {
  shortlist: [],
  email: [],
  sent: [],
  discussion: [],
  show: [],
  contract: [],
  rejected: [],
  reservation: [],
}

const DealBoard: FC<IData> = (props) => {

  const { t } = useTranslation();
  const lng = LocaleStore.locale;
  const [state, setState] = useState<any>({
    // scene: {
    //   type: "container",
    //   props: {
    //     orientation: "horizontal",
    //     className: 'test-class'
    //   },
    //   children: []
    // },
    showModal: null,
    modalType: 'card',
    isRejected: false,
    deal: {}
  });
  const [activeId, setActiveId] = useState<null | string>();
  const [items, setItems] = useState<any>(DefaultBoardState);

  const [oldItems, setOldItems] = useState<any>(DefaultBoardState);

  const updateBoard = (dealProperties: IDealsProperty[]) => {
    const groupedDealsProperty = groupedDealsPropertyByStatus(dealProperties)
    setItems({...DefaultBoardState, ...groupedDealsProperty})
  }

  useEffect(() => {
    if (props.deal) {
      updateBoard(props.deal.deals_properties)
    }
  }, [props.deal.id])


  // const getDerivedStateFromProps = (props: IData, state: State) => {
  //   if (props.deal != state.deal) {
  //     return updateState(props.deal)
  //   }

  //   return null;
  // }

  // const getCardPayload = (columnId: number, index: number) => {
  //   const moveItem: Array<any> = state.scene.children.filter((col: any) => col.id === columnId)
  //   return moveItem[0].children[index];
  // }

  // const getUrl = (property: IProperty) => {
  //   const { deal } = props;
  //   const urls: any = property.tenerife_url;
  //   if(!urls) return '';
  //   return deal.operation.toString() == RENT_VALUE  ? urls['rent_url'] : urls['sale_url']
  // }

  // const renderCardContent = (dealsProperty: any) => {
  //   // const dealsProperty: IDealsProperty = cardProperty.data;
  //   const property: IProperty = dealsProperty.property;
  //   const { deal } = props;

  //   return (
  //     <div>
  //       <Row>
  //         <div className='card-property-name' onClick={() => showModal(dealsProperty)}>
  //           {name(property, lng)}
  //         </div>
  //       </Row>
  //       <Row justify='space-between' align='middle' className='mt-5'>
  //         <span className='property-identifier'>
  //           {deal.operation == SALE_VALUE ? 'VS' : 'VR'}{property.identifier}
  //         </span>
  //         <span className='price-property'>{property.price_attributes.amount}€</span>
  //       </Row>
  //       <Row className='mt-5'>
  //         {!!dealsProperty.notes && <i className='flaticon-notes mr-5' />}
  //         {!!getUrl(property) && <Tooltip placement="top" title={t('deals.publishedOn')}><i className='flaticon-earth-globe' /></Tooltip>}
  //       </Row>
  //     </div>
  //   )
  // }

  // const onCardDrop = (columnId: number, dropResult: any) => {

  //   if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
  //     const scene: any = Object.assign({}, state.scene);
  //     const column: IColumn = scene.children.filter((col: any) => col.id === columnId)[0];
  //     let attributes = []
  //     // console.log('column = ', column)
  //     const columnIndex: number = scene.children.indexOf(column);
  //     // console.log('scene = ', scene)
  //     const params = { status: column.id, position: dropResult.addedIndex || 0 }
  //     // debugger
  //     if (dropResult.addedIndex != null && dropResult.removedIndex == null) {
  //       // const params = { status: column.id, position: dropResult.addedIndex }
  //       if(column.id == 'rejected') {
  //         showModal(dropResult.payload.data, true)
  //       }
  //       if(column.id == 'email' && !dropResult.payload.data.property.tenerife_url) {
  //         message.error(
  //           `property has to be published on tenerifecenter.com before you can move it to
  //            "Emails" column!`
  //         );
  //         rollbackSceneValue()
  //         return null;
  //       }
  //       if(column.id == 'contract' || column.id == 'reservation') {
  //         if(scene.children[columnIndex].children.length != 0) {
  //           message.error(`In "${startCase(toLower(column.id))}" only one property is allowed`);
  //           rollbackSceneValue()
  //           return null;
  //         }
  //       }
  //       dealPropertyService.updateResource(dropResult.payload.data.id, params)
  //     }
  //     // dealPropertyService.updateResource(dropResult.payload.data.id, params)

  //     const newColumn: any = Object.assign({}, column);
  //     newColumn.children = applyDrag(newColumn.children, dropResult);
  //     (scene.children || []).splice(columnIndex, 1, newColumn);



  //     setState({ ...scene })
  //     sendAupdatePosition(scene, column, columnIndex);
  //   }
  // }

  // const rollbackSceneValue = () => {
  //   const newContent = updateState(props.deal)
  //   setState({ ...state, scene: newContent.scene });
  // }

  const sendAupdatePosition = (column: string, items: any[]) => {
    const attributes = items.map((item: any, index: number) => ({ status: column, position: index, id: item.id }))
    dealPropertyService.updatePositions(attributes)
  }

  // const applyDrag = (arr: any, dragResult: any) => {
  //   const { removedIndex, addedIndex, payload } = dragResult;
  //   if (removedIndex === null && addedIndex === null) return arr;

  //   const result = [...arr];
  //   let itemToAdd = payload;

  //   if (removedIndex !== null) {
  //     itemToAdd = result.splice(removedIndex, 1)[0];
  //   }

  //   if (addedIndex !== null) {
  //     result.splice(addedIndex, 0, itemToAdd);
  //   }

  //   return result;
  // };

  const handleCancel = () => {
    setState({
      showModal: false,
      isRejected: false,
      modalType: 'card'
    });
  }

  const showModal = (dealProperty: IDealsProperty | null, isRejected: boolean = false) => {
    setState({
      ...state,
      showModal: dealProperty,
      isRejected: isRejected
    });
  }

  // const showEmailTemplate = () => {
  //   setState({
  //     modalType: 'email'
  //   })
  // }

  const handleOk = (dealsProperty: IDealsProperty) => {
    dealPropertyService.updateResource(dealsProperty.id, dealsProperty, (data: any) => {
      // const newContent = updateState(data)
      // setState({...state, scene: newContent.scene});
      // setState({...state, scene: newContent.scene, showModal: false });
      setState({...state, showModal: false });
      updateBoard(data.deals_properties)
      // setItems({
      //   ...items,
      //   [dealsProperty.status]: items[dealsProperty.status].filter((id: number) => id !== dealsProperty.id),
      //   rejected: newItems
      // })
    })
  }

  const renderheaderModal = (dealProperty: IDealsProperty | null) => {
    const property = dealProperty && dealProperty.property;
    const { deal } = props;
    const isSale = deal.operation == SALE_VALUE;
    if (!property) {
      return t('emails.addEmail');
    }

    const price = property.price_attributes;

    return <Row className='modal-header'>
      <Col span={6}>
        <img src={property.current_image_url || withoutImage} width='195px' />
      </Col>
      <Col span={16}>
        <Row className='mb-15'>
          <h3>{t('deals.new.appartment')} {name(property, lng)}</h3>
        </Row>
        <Row>
          <Col span={12}>
            <p className='mb-10'>{t('properties.table.id')}: {isSale ? 'VS' : 'VR'}{property.identifier}</p>
            <p className='mb-10'>{t('properties.new.totalArea')}: {property.detail_attributes.total_area || 0} m2</p>
            <p className='mb-10'>{t('contacts.table.owner')}: {property.owner ? property.owner.name : '-'}</p>
          </Col>
          <Col span={12}>
            <p className='mb-10'>{t('properties.new.bedrooms')}: {property.detail_attributes.bathroom_count || 0}</p>
            <p className='mb-10'>{t('properties.table.price')}: {isSale ? (`${price.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')+' €') : price.monthly_price}</p>
            <p>
              <Link className='link-to-property' to={`/properties/${property.id}/overview`} target='_blank'>
              { t('properties.goToProfile') }
              </Link>
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  }

  const getModalContent = () => {
    let content;
    const { modalType } = state;
    const { deal, contact } = props;

    switch(modalType) {
      case 'card':
        content = <DealCardForm
            handleOk={handleOk}
            handleCancel={handleCancel}
            deal={deal}
            isRejected={state.isRejected}
            dealProperty={state.showModal}
          />
          break;
      case 'email':
        content = <EmailForm
        onCancelButton={handleCancel}
        contact={contact}
        contactType="Contact"
        deal_id={deal.id}
        body={generateEmailBody()}
        />
        break;
      default:
        content = ''
    }

    return content;
  }

  const generateEmailBody = () => {
    const { contact } = props;
    const user = loginStore.session.user.user;
    const deal: any = props.deal;

    // const emailColumn: any = items["email"].find((item: any) => item.id == 'email')
    const templateParams = {
      emailColumn: items["email"],
      contact: contact,
      manager: user,
      deal: deal
    }
    return getEmailBody(templateParams)
  }

  const sendEmailPortal = () => {
    const { scene } = state;
    const emailCol: any = items['email'];

    if(emailCol && emailCol.length == 0 ) {
      return;
    }

    const propertyWithoutWebsite = emailCol.find((obj: any) => {
      const tenerifeUrl = obj.property.tenerife_url
      return tenerifeUrl.rent_url === null && tenerifeUrl.sale_url === null
    })

    if(propertyWithoutWebsite) {
      message.error("You have some properties that are not listed on the website. Please, move these properties to other columns and prese 'send email' again")
      return;
    }
    setState({
      modalType: 'email',
      showModal: true
    })
  }
  // const groupedItems = groupedDealsPropertyByStatus(properties);
  // const handleChange = (a: any, b: any, c: any) => {
  //   console.log("🚀 ~ file: DealBoard.tsx:400 ~ handleChange ~ c:", c)
  //   console.log("🚀 ~ file: DealBoard.tsx:400 ~ handleChange ~ b:", b)
  //   console.log("🚀 ~ file: DealBoard.tsx:402 ~ handleChange ~ a:", a)

  // }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  function findContainer(id: number) {
    // console.log("🚀 ~ file: DealBoard.tsx:472 ~ findContainer ~ id:", id)
    // console.log("🚀 ~ file: DealBoard.tsx:474 ~ findContainer ~ items:", items)
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].find((i: IDealsProperty) => i.id === id));
  }

  function handleDragStart(event: DragEndEvent) {
    const { active } = event;
    const { id } = active;
    setActiveId(id as string);
    setOldItems(items)
  }

  function handleDragOver(event: any) {
    const { active, over, draggingRect } = event;
    // console.log("🚀 ~ file: DealBoard.tsx:496 ~ handleDragOver ~ event:", event)
    const { id } = active;
    const { id: overId } = over;


    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev: any) => {
      const activeItems = prev[activeContainer];
      // console.log("🚀 ~ file: DealBoard.tsx:508 ~ setItems ~ activeItems:", activeItems)
      const overItems = prev[overContainer];
      // console.log("🚀 ~ file: DealBoard.tsx:510 ~ setItems ~ overItems:", overItems)

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex((ai: IDealsProperty) => ai.id === id);
      const overIndex = overItems.findIndex((oi: IDealsProperty) => oi.id === overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          Number(draggingRect?.offsetTop) > Number(over.rect?.offsetTop) + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }
      // console.log("🚀 ~ file: DealBoard.tsx:531 ~ setItems ~ newIndex:", newIndex)
      // console.log("🚀 ~ file: DealBoard.tsx:535 ~ setItems ~ overIndex:", overIndex)
      // console.log("🚀 ~ file: DealBoard.tsx:542 ~ setItems ~ prev[activeContainer]:", prev[activeContainer])
      // console.log("🚀 ~ file: DealBoard.tsx:542 ~ setItems ~ prev[overContainer]:", prev[overContainer])

      // if(overContainer == 'rejected') {
      //   showModal(activeItems[activeIndex], true)
      // }
      // console.log("🚀 ~ file: DealBoard.tsx:545 ~ setItems ~ overContainer == 'email' && !activeItems[activeIndex].property.tenerife_url:", overContainer == 'email' && !activeItems[activeIndex].property?.tenerife_url?.url)
      // console.log("🚀 ~ file: DealBoard.tsx:545 ~ setItems ~ activeItems[activeIndex]:", activeItems[activeIndex])
      // console.log("🚀 ~ file: DealBoard.tsx:547 ~ setItems ~ overContainer:", overContainer)
      // if(overContainer == 'email' && !activeItems[activeIndex].property?.tenerife_url?.url) {
      //   console.log("aaaaaaa")
      //   message.error(
      //     `property has to be published on tenerifecenter.com before you can move it to
      //       "Emails" column!`
      //   );
      //   // return prev;
      // }
      // if(overContainer == 'contract' || overContainer == 'reservation') {
      //   if(overItems.length != 0) {
      //     message.error(`In "${startCase(toLower(overContainer))}" only one property is allowed`);
      //     return prev;
      //   }
      // }
      // const params = { status: overContainer, position: overIndex || 0 }
      // dealPropertyService.updateResource(id, params)

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item: any) => item.id !== active.id)
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length)
        ]
      };
    });
  }

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);
    const activeIndexValue = get(items, `[${activeContainer}]`).find((i: IDealsProperty) => i.id === active.id);
    if(overContainer === 'email' && !activeIndexValue.property?.tenerife_url) {
      message.error(
        `property has to be published on tenerifecenter.com before you can move it to
          "Emails" column!`
      );
      setItems(oldItems);
      return null;
    }
    if(overContainer === 'rejected') {
      showModal(activeIndexValue, true)
    }
    if(overContainer == 'contract' || overContainer === 'reservation') {
      if(oldItems[overContainer].length !== 0) {
        message.error(`In "${startCase(toLower(overContainer))}" only one property is allowed`);
        setItems(oldItems);
        return null;
      }
    }


    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }


    const activeIndex = items[activeContainer].findIndex((ai: IDealsProperty) => ai.id === active.id);
    const overIndex = items[overContainer].findIndex((oi: IDealsProperty) => oi.id === overId);

    const params = { status: overContainer, position: overIndex || 0 }
    dealPropertyService.updateResource(id, params)

    if (activeIndex !== overIndex) {

      const movedItems = arrayMove(items[overContainer], activeIndex, overIndex)
      await setItems((items: any) => ({
        ...items,
        [overContainer]: movedItems
      }));
      sendAupdatePosition(overContainer as string, movedItems)
    }

    setActiveId(null);
  }

  return (
    <div className='w-100p of-x-scroll'>

        <div className='draggable-component d-fx'>
          <Modal
            title={renderheaderModal(state.showModal)}
            open={!!state.showModal}
            footer={null}
            width='900px'
            onCancel={handleCancel}
            destroyOnClose
            className='modal'
            maskClosable={false}
          >
            { getModalContent() }
          </Modal>
            {/* {getColumns(props.deal).map((item) => {
              const status = item.key;
              return <div className="ml-5 mr-5 mw-300 mh-400">
                <Row justify='space-between' align='middle' className="card-column-header mb-20">
                  <h4>{status}</h4>
                  { status === 'email' &&
                    <a className='link' onClick={sendEmailPortal}>{t('deals.sendEmail')}</a>
                  }
                  <span>{(groupedItems[status] || []).length}</span>
                </Row>
                <div>
                  <ReactSortable list={properties} setList={setProperties} tag={"div"} group="properties"  onChange={handleChange} swap>
                    {(groupedItems[status] || []).map((property: any) =>
                      renderCardContent(property)
                    )}
                  </ReactSortable>
                </div>
              </div>
            })} */}
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDragEnd={handleDragEnd}
            >
              {getColumns(props.deal).map((item) => {
                const status = item.key;
                return (
                  <div className="ml-5 mr-5 mw-300 mh-400">
                    <Container id={status} items={items[status]} showModal={showModal} deal={props.deal} sendEmailPortal={sendEmailPortal} />
                  </div>
                )
              })}
            </DndContext>
          {/* <Container
            // orientation="horizontal"
          // dragHandleSelector='not drag'
          // nonDragAreaSelector='not drag'
          // style={{ width: '100%' }}
          // dropPlaceholder={{
          //   animationDuration: 150,
          //   showOnTop: true,
          //   className: 'cards-drop-preview'
          // }}
        > */}
          {/* {state.scene.children.map((column: any) => {
            return (
              <Draggable key={column.id} className='col-board'>
                <div className={`${column.props.className} ml-5 mr-5`}>
                  <Row justify='space-between' align='middle' className="card-column-header mb-20">
                    <h4>{column.name}</h4>
                    { column.id == 'email' &&
                      <a className='link' onClick={sendEmailPortal}>{t('deals.sendEmail')}</a>
                    }
                    <span>{(column.children || []).length}</span>
                  </Row>
                  <Container
                    {...column.props}
                    groupName="col"
                    onDrop={e => onCardDrop(column.id, e)}
                    getChildPayload={index =>
                      getCardPayload(column.id, index)
                    }
                    dragClass="card-ghost"
                    dropClass="card-ghost-drop"
                    dropPlaceholder={{
                      animationDuration: 150,
                      showOnTop: true,
                      className: 'drop-preview'
                    }}
                    dropPlaceholderAnimationDuration={200}
                  >
                    {column.children.map((card: any) => {
                      return (
                        <Draggable key={card.id}>
                          {renderCardContent(card)}
                        </Draggable>
                      );
                    })}
                  </Container>
                </div>
              </Draggable>
            );
          })} */}
        {/* </Container> */}
      </div>
    </div>
  );
}

export default DealBoard;
