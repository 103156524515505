import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Form, Select, Input, Button, Switch, Row, Col, Checkbox } from 'antd';

import { IUser } from 'declarations/user';
import { some } from 'lodash';
import workspaceStore from "stores/workspace";
import localeStore from "stores/locale";

interface IData {
  handleOk(user: any): any,
  handleCancel(): void,
  user?: IUser | null;
}

const { Option } = Select;
const defaulState = {
  id: null,
  first_name: '',
  last_name: '',
  email: '',
  language: 'en',
  active: true,
  role: undefined,
  password: null
}

const New: FC<IData> = observer((props) => {

  const [state, setState] = useState<any>({ ...defaulState });
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    const { user } = props;

    if (user) {
      setState({ ...state, ...user })
      form.setFieldsValue({...user})
    } else {
      setState({ ...state, ...defaulState })
    }
  }, [])

  // componentWillUpdate(nextProps: IData, nextState: any) {

  //   if (nextProps.user && nextProps.user.id != state.id) {
  //     setState({ ...state, ...nextProps.user })
  //   }

  //   if (!nextProps.user && state.id) {
  //     setState({ ...state, ...defaulState })
  //   }
  // }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      const userValues = { ...state, ...values }
      props.handleOk(userValues);
    }
  }

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  return (
    <div className="add-user">
      <Form onFinish={handleSubmit} form={form}>
        <Form.Item
          {...formItemLayout}
          label={t('login.new.firstName')}
          name="first_name"
          rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
        >
          <Input placeholder={t('login.new.firstName')} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('login.new.lastName')}
          name="last_name"
          rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
        >
          <Input placeholder={t('login.new.lastName')} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('login.new.active')}
          name="active"
          initialValue={true}
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('tabs.email')}
          name="email"
          rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
        >
          <Input placeholder={t('tabs.email')} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('users.userRole')}
          name="role"
          rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
        >
          <Select placeholder="Please select a role" filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            <Option value="admin">{t('users.role.admin')}</Option>
            <Option value="general_manager">{t('users.role.general_manager')}</Option>
            <Option value="manager">{t('users.role.manager')}</Option>
            <Option value="external_manager">{t('users.role.external_manager')}</Option>
          </Select>
        </Form.Item>
        <Form.Item name="workspace_ids" label={t("users.hasAccess")} {...formItemLayout} rules={[{ required: true, message: t('validates.cantBeEmpty') }]}>
          <Checkbox.Group
            options={workspaceStore.values.map((w) => ({
              label: w.name[localeStore.locale as string],
              value: w.id
            }))}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('login.new.defaultLang')}
          rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
          name="language"
        >
          <Select placeholder="Please select a language" filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            <Option value="en">{t('contacts.new.lng.en')}</Option>
            <Option value="es">{t('contacts.new.lng.es')}</Option>
            <Option value="ru">{t('contacts.new.lng.ru')}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={t('login.password')}
          name="password"
          rules={[{ required: !state.id, message: t('validates.cantBeEmpty') }]}
        >
          <Input placeholder={t('login.password')} type='password' />
        </Form.Item>
        <Row>
          <Col span={8}></Col>
          <Col span={16}>
            <div className="form-controls">
              <Button type="primary" onClick={handleSubmit} className='mr-10'>
                {t('save')}
              </Button>
              <Button onClick={() => props.handleCancel()}>
                {t('cancel')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
});

export default New;
