import { action } from 'utils/mobx';
import { request } from 'utils/mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import { message } from 'antd';
import dealStore  from 'stores/deal';
import { DEALS_URL, DEAL_LABELS_URL } from 'constants/api';
import { LOAD_DEALS, LOAD_DEAL, EXPORT_DEALS } from 'constants/action';
import { propertyService } from 'modules/properties/PropertyService';
import { Filter } from "declarations/filters";
import { IDeal } from "declarations/deal";
import { saveAs } from 'file-saver';
import workspaceStore from "stores/workspace";


interface FilterType {

};

interface IResponse {
  status: number,
  data: { message?: Array<string>, deals: Array<IDeal>, count: number }
}

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any;
  loadResourcesRequest(queryParams: {}): Promise<object>;
}

export class DealService implements Props {

  // LOAD resourceS
  async loadResourcesRequest (params: FilterType | {}){
    return axios.get(DEALS_URL, {
      params
    })
  }
  @action({ action: LOAD_DEALS, minRequestTime: 500 })
  async loadResources(params: FilterType = {}, pageNum: number | 'all' = 1) {
    // let { filters, page } = (dealStore || {});
    dealStore.setFetchInProgress(true);
    let page = pageNum;

    const queryParams = {
      // ...filters,
      workspace_id: workspaceStore.selectedId,
      ...params,
      page
    };

    const response: IResponse = await this.loadResourcesRequest(queryParams);

    if (response.status == 200) {
      let values = response.data.deals;
      this.addValues(values, pageNum, response.data.count, queryParams);
    }
    dealStore.setFetchInProgress(false);

    return response;
  }

  async resetFilters () {
    dealStore.resetFilter();
    this.loadResources({});
  }

  async changeFilters (newFilter: {}) {
    dealStore.updateFilter(newFilter);
    dealService.loadResources(dealStore.filterKeys);
  }

    async exportRequest (params: FilterType | {}){
      return axios.get(`${DEALS_URL}/export`, {
        params
      })
    }

    @action({ action: EXPORT_DEALS, minRequestTime: 500 })
    async exportDeals(params: FilterType = {}, pageNum: number | 'all' = 1) {
      let page = pageNum;
      dealStore.setExportInProgress(true);
      const queryParams = {
        // ...filters,
        workspace_id: workspaceStore.selectedId,
        ...params,
        page
      };

      const response: any = await this.exportRequest(queryParams);

      if (response.status == 200) {
        const blob = new Blob([response.data])
        saveAs(blob, 'deals.csv')
      }

      dealStore.setExportInProgress(false);
      return response;
    }


  async loadResourceRequest (resource_id: number) {
    return axios.get(`${DEALS_URL}/${resource_id}`)
  }

  @action({ action: LOAD_DEAL, minRequestTime: 500 })
  async loadResource(resource_id: number, callback?: Function) {
    dealStore.setFetchInProgress(true);
    const response: { status: number, data: Object} = await this.loadResourceRequest(resource_id);

    if (response.status) {
      if(callback) {
        callback()
      }
      let value: any = {};
      value = response.data;
      this.addSelectedValue(value);
    }
    dealStore.setFetchInProgress(false);

    return response;
  }

  async createResourceRequest(params: IDeal) {
    return axios.post(DEALS_URL, { deal:
      params
    })
  }


  async createResource(deal: any, filter: any, callback: Function) {
    dealStore.setSaveInProgress(true);
    const response: IResponse = await this.createResourceRequest({...deal, workspace_id: workspaceStore.selectedId });

    if (response.status == 200) {
      callback();
      this.loadResources(filter);
      message.success('Deal created!')
    } else {
      message.error((response.data.message || []).join())
    }
    dealStore.setSaveInProgress(false);

  }

  async updateResourceRequest(resource: any) {
    return axios.put(`${DEALS_URL}/${resource.id}`, { ...resource })
  }


  async updateResource(resource: Object, callback: Function) {
    dealStore.setSaveInProgress(true);
    const response: { data: any, status: number } = await this.updateResourceRequest(resource);

    if (response.status == 200) {
      this.updateValue(response.data)
      this.changeValue(response.data)
      message.success('Deal updated!')
      callback()
    } else {
      message.error(response.data.message)
    }
    dealStore.setSaveInProgress(false);

  }

  async updateLabelsRequest(deal_id: number) {
    return axios.put(`${DEAL_LABELS_URL}/${deal_id}`)
  }


  async updateLabels(deal_id: number) {

    const response: { data: any, status: number } = await this.updateLabelsRequest(deal_id);

    if (response.status == 200) {
      // this.updateValue(response.data)
      propertyService.loadResources({is_filter_deal: true, except_deal_id: deal_id})
      this.changeValue(response.data)
      message.success('Updated!')
    } else {
      message.error(response.data.message)
    }

  }

  async deleteResourceRequest(deal_id: number) {
    return axios.delete(`${DEALS_URL}/${deal_id}`)
  }


  async deleteResource(deal_id: number, filter: any, callback: Function) {
    dealStore.setDeleteInProgress(true);
    const response: { status: number } = await this.deleteResourceRequest(deal_id);

    if (response.status) {
      message.success('Deal deleted!!')
      this.loadResources(filter)
      callback()
    }
    dealStore.setDeleteInProgress(false);

  }

  addValues(values: Array<IDeal>, page: number | 'all', count: number, queryParams: {}) {
    dealStore.addValues(values, page, count, queryParams);
  }

  addSelectedValue(value: any) {
    dealStore.addSelectedValue(value);
  }

  updateValue(deal: any) {
    dealStore.addSelectedValue(deal);
  }

  changeValue(deal: any) {
    dealStore.changeValue(deal);
  }
}

export const dealService = new DealService();
