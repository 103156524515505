import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { emailService } from 'services/emailService';
import EmailStore from 'stores/email';
import EmailList from 'submodules/emails/components/List';
import AppLayout from "modules/clients/containers/Single/components/Layout";
import clientStore from 'stores/client';


const Emails: FC = observer(() => {
  const client: any = clientStore.selected;

  useEffect(() => {
    if (client?.id) {
      emailService.loadResources({ letterable_id: client.id, letterable_type: "Client", page: 1 })
    }
    return () => EmailStore.clearData();
  }, [client?.id])

  return (
    <AppLayout>
      <EmailList contact={client} contactType="Client" />
    </AppLayout>
  );
})

export default Emails;
