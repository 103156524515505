import { groupBy } from 'lodash';
import { IRegion } from 'declarations/region';
import { makeAutoObservable } from 'mobx';

export class RegionStore {

  values: Array<IRegion> = [];
  groupedValues: any = {};
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: Array<IRegion>): void {
    this.values = values;
    this.groupedValues = groupBy(values, 'island_id')
  }
  setLoading(val: boolean) {
    this.loading = val;
  }
}

export default (new RegionStore())

