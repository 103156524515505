import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    load: "languageOnly",
    debug: true,
    returnNull: false,
    react: {
      bindI18n: 'languageChanged loaded',
      nsMode: 'default'
    }
  }, function(err, t) {
    // initialized and ready to go!
    console.log(err);
  });

export default i18n;