import { IUser } from 'declarations/user';
import { makeAutoObservable } from 'mobx';

export class UserStore {
  values: Array<IUser> = [];
  count: number = 1;
  page: number | 'all' = 1;
  selected?: IUser;
  fetchInProgress = false;
  saveInProgress = false;
  deleteInProgress = false;

  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: Array<IUser>, page: number | 'all', count: number, queryParams: Object): void {
    this.values = values;
    this.page = page
    this.count = count
  }

  setSelected(value: IUser) {
    this.selected = value;
  }

  updateSelected(value: IUser) {
    this.selected = {...this.selected, ...value};
  }

  setFetchInProgress(val: boolean) {
    this.fetchInProgress = val;
  }
  setSaveInProgress(val: boolean) {
    this.saveInProgress = val;
  }
  setDeleteInProgress(val: boolean) {
    this.deleteInProgress = val;
  }

}

export default (new UserStore())

