import { action } from 'mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import  islandStore  from '../stores/island';
import  workspaceStore  from '../stores/workspace';
import { ISLAND_URL } from 'constants/api';
import { IIsland } from "declarations/island";

interface FilterType {

};

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any,
  loadResourcesRequest(queryParams: {}): Promise<object>
}

export class IslandsService implements Props {

  // LOAD resourceS
  async loadResourcesRequest() {
    return axios.get(ISLAND_URL, { params: {workspace_id: workspaceStore.selectedId }})
  }

  async loadResources() {
    islandStore.setLoading(true);
    const response: { status: number, data: Array<IIsland>} = await this.loadResourcesRequest();

    if (response.status == 200) {
      let values = [];
      values = response.data;

      this.addValues(values);
    }
    islandStore.setLoading(false);
    return response;
  }

  addValues(values: Array<IIsland>) {
    islandStore.addValues(values);
  }
}

export const islandsService = new IslandsService();
