import { action } from 'mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import  cityStore  from '../stores/city';
import { CITY_URL } from 'constants/api';
import { ICity } from "declarations/city";
import  workspaceStore  from '../stores/workspace';

interface FilterType {

};

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any,
  loadResourcesRequest(queryParams: {}): Promise<object>
}

export class CityService implements Props {

  // LOAD resourceS
  async loadResourcesRequest() {
    return axios.get(CITY_URL, { params: {workspace_id: workspaceStore.selectedId }})
  }

  async loadResources(callback?: Function) {
    // let { filters, page } = (ownerListStore || {});
    // if(cityStore.values.length > 0) {
    //   if(callback){
    //     callback(cityStore.values)
    //   }
    //   return;
    // }
    cityStore.setLoading(true);
    const response: { status: number, data: Array<ICity>} = await this.loadResourcesRequest();

    if (response.status == 200) {
      let values = [];
      values = response.data;
      if(callback){
        callback(values)
      }
      this.addValues(values);
    }
    cityStore.setLoading(false);

    return response;
  }

  addValues(values: Array<ICity>) {
    cityStore.addValues(values);
  }
}

export const cityService = new CityService();
