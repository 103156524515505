import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { emailService } from 'services/emailService';
import ProgressStore from 'stores/progress';
import EmailStore from 'stores/email';
import EmailItem from '../components/Item';
import EmailForm from '../components/Form';
import { LOAD_EMAILS } from 'constants/action';
import { Button, Pagination, Row, Col, Modal, Empty, Spin } from 'antd';
import { IContact } from "declarations/contact";

interface IData {
  contact: IContact;
  contactType: string;
}

const List: FC<IData> = observer(({ contact, contactType }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleCancel = () => setVisible(false);

  const showModal = () => setVisible(true);

  const renderCardLists = () => {
    const emails = EmailStore.values;

    if (!ProgressStore.isLoading(LOAD_EMAILS) && emails.length === 0) {
      return <Empty className='mt-100' description={t('emails.noEmails')} />
    }

    if (!ProgressStore.isLoading(LOAD_EMAILS) && emails.length > 0) {
      return emails.map((email: any) => <EmailItem contact={contact} email={email} contactType={contactType} />)
    }
  }

  const onChangePagination = (page: number) => {
    emailService.loadResources({ letterable_id: contact.id, letterable_type: contactType, page: page})
  }

  const { page, count} = EmailStore;

  if(ProgressStore.isLoading(LOAD_EMAILS)) {
    return <Spin className='loader-spiner-container' />
  }

  return (
    <div className='tab'>
      <Row justify="center">
          <Modal
            title={t('emails.addEmail')}
            visible={visible}
            footer={null}
            width='1000px'
            destroyOnClose
            onCancel={handleCancel}
            className='modal'
            maskClosable={false}
          >
            <EmailForm onCancelButton={handleCancel} contact={contact} contactType={contactType} />
          </Modal>

          <Col span={16}>
            <Row justify="space-between" className='mb-30' align='middle'>
              <Col>
                <h3>{t('tabs.emails')} ({contact.emails_count})</h3>
              </Col>
              <Col>
                <Button className='ant-btn ant-btn-primary ant-btn-md' onClick={showModal}>
                  {t('emails.newEmail')}
                </Button>
              </Col>
            </Row>

            <Row className='card-list' justify="center">
              {renderCardLists()}
            </Row>
            {!ProgressStore.isLoading(LOAD_EMAILS) && contact.emails_count > 0 && <div>
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  total={count}
                  onChange={onChangePagination}
                />
              </div>
            }
          </Col>
        </Row>
      </div>
  );
})

export default List;
