import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { userListService } from 'modules/users/containers/UserListService';
import { islandsService } from 'services/islandService';
import { regionsService } from 'services/regionService';
import { cityService } from 'services/cityService';
import { complexService } from 'services/complexService';
import { propertyTypesService } from 'services/propertyTypes';
import UserStore from 'stores/user';
import IslandStore from 'stores/island';
import RegionStore from 'stores/region';
import CityStore from 'stores/city';
import clientStore from 'stores/client';
import LocaleStore from 'stores/locale';
import ComplexStore from 'stores/complex';
import PropertyTypeStore from 'stores/type';
import { name } from 'utils/localized';
import { DEAL_STATUSES } from 'constants/mixed';

import { Form, Select, Input, Button, Row, Col, InputNumber } from 'antd';

import { IClient } from 'declarations/contact';
import { IDeal } from 'declarations/deal';
import { IRegion } from 'declarations/region';
import { PropertyType as IType } from 'declarations/propertyType';
import { IUser } from "declarations/user";
import { ICity } from 'declarations/city';
import { IComplex } from 'declarations/complex';
import { some } from 'lodash';

interface IData {
  handleOk(deal: IDeal): void,
  handleCancel(): void,
  client?: null | IClient;
  deal?: IDeal | null;
}

const { Option } = Select;
const defaultState = {
  deal: {
    id: null,
    status: 'Active',
    operation: undefined,
    property_type: undefined,
    max_price: null,
    island_ids: undefined,
    region_ids: undefined,
    city_ids: undefined,
    client_id: null,
    complex_ids: undefined,
    manager_id: undefined,
    bedrooms: [],
    note: ''
  }
}


const DealForm: FC<IData> = observer((props) => {

  const [state, setState] = useState<any>({ ...defaultState });
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const lng = LocaleStore.locale;

  useEffect(() => {
    const { deal } = props;
    userListService.loadResources({}, 'all')
    islandsService.loadResources();
    regionsService.loadResources();
    cityService.loadResources();
    complexService.loadResources();
    propertyTypesService.loadResources();
    if(!props.client){
      clientStore.fetchClients({}, 'all')
    }

    if (deal) {
      form.setFieldsValue({...deal, status: String(deal.status)})
      setState({
        ...state,
        deal: {
          ...state.deal,
          ...deal
        }
      })
    } else {
      setState({ ...state.deal, ...defaultState })
    }
  }, [])

  const getPropertyTypesOptions = () => {
    const types = PropertyTypeStore.values;
    return types.map((type: IType) => <Option value={type.id.toString()}>{name(type, lng)}</Option>)
  }

  const getIslandsOptions = () => {
    const islands = IslandStore.values;
    return islands.map((island) => <Option value={island.id.toString()}>{island.name}</Option>)
  }

  const getRegionsOptions = () => {
    const regions = RegionStore.values;
    return regions.map((region: IRegion) => <Option value={region.id.toString()}>{region.name}</Option>)
  }

  const getCitiesOptions = () => {
    const cities  = CityStore.values;
    return cities.map((city: ICity) => <Option value={city.id.toString()}>{city.name}</Option>)
  }

  const getCityComplexesOptions = () => {
    const complexes = ComplexStore.values;
    return complexes.map((complex: IComplex) => <Option value={complex.id.toString()}>{complex.name}</Option>)
  }

  const renderManagers = () => {
    const managers = UserStore.values;
    return managers.map((user: IUser) => <Option value={user.id}>{user.name}</Option>)
  }


  const handleSubmit = async () => {
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      const dealValues = { ...state.deal, ...values };
      if(props.client) {
        dealValues['client_id'] = props.client.id;
      }
      props.handleOk(dealValues);
    }
  }

  const onChangeOperation = (value: string) => {
    setState({
      ...state,
      deal: {
        ...state.deal,
        operation: value
      }
    })
  }

  const renderDealStatuses = () => {
    const { operation } = state.deal;

    if(operation == null || operation == undefined) {
      return
    }

    return DEAL_STATUSES[`${operation}`].map((item: string, index: number) => {
      return <Option value={index.toString()}>{item}</Option>
    })
  }

  const loadClients = () => {
    const clients = clientStore.values;
    return clients.map((c: IClient) => <Option value={c.id}>{c.name}</Option>)
  }

  const getBedroomsOptions = () => {
    let options = []
    for (let step = 0; step <= 10; step++) {
      options.push(<Option value={step}>{step}</Option>)
    }

    return options;
  }

    const { client } = props;
    const { id } = state.deal;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    return (
      <div className="add-user">
        <Form onFinish={handleSubmit} form={form}>
          { client &&
            <Form.Item
              {...formItemLayout}
              label={t('contacts.table.client')}
            >
              <h4>{client.name}</h4>
            </Form.Item>
          }
          {!client &&
            <Form.Item
            {...formItemLayout}
            label={t('contacts.table.client')}
            name="client_id"
            rules={[{ required: true, message: t('validates.cantBeEmpty')}]}
          >
            <Select
              showSearch
              placeholder={t('contacts.new.validates.client')}
              optionFilterProp="children"
              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              { loadClients() }
            </Select>
          </Form.Item>
          }
          <Form.Item
            {...formItemLayout}
            label={t('deals.new.operation')}
            rules={[{ required: true, message: t('validates.cantBeEmpty')}]}
            name="operation"

          >
            <Select placeholder={t('deals.placeholder.operation')} onChange={onChangeOperation}>
              <Option value={0}>{t('deals.new.dealOperation.sale')}</Option>
              <Option value={1}>{t('deals.new.dealOperation.rent')}</Option>
            </Select>
          </Form.Item>
          { id &&
            <Form.Item
              {...formItemLayout}
              label={t('deals.dealStatus')}
              rules={[{ required: true, message: t('validates.cantBeEmpty')}]}
              name="status"
            >
              <Select placeholder={t('deals.placeholder.status')} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                { renderDealStatuses() }
              </Select>
            </Form.Item>
          }
          <Form.Item
            {...formItemLayout}
            label={t('deals.new.propertyType')}
            name="property_type"
          >
            <Select
              mode='multiple'
              placeholder={t('deals.placeholder.propertyType')}
              optionFilterProp="children"
              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {getPropertyTypesOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('deals.new.budget')}
            name="max_price"
          >
            <InputNumber
              placeholder={t('properties.placeholder.maxPrice')}
              min={0}
              formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              parser={(value: any) => value.replace(/(\.*)/g, '')}
              addonAfter="€"
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('properties.new.islands')}
            name="island_ids"
          >
            <Select
              placeholder={t('properties.new.islands')}
              mode='multiple'
              optionFilterProp="children"
              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {getIslandsOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('properties.new.regions')}
            name="region_ids"
          >
            <Select
              placeholder={t('properties.new.regions')}
              mode='multiple'
              optionFilterProp="children"
              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {getRegionsOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('properties.new.cities')}
            name="city_ids"
          >
            <Select
              placeholder={t('properties.new.cities')}
              optionFilterProp="children"
              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              mode='multiple'
            >
              {getCitiesOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('properties.new.complexes')}
            name="complex_ids"
          >
            <Select
              placeholder={t('properties.placeholder.complexes')}
              mode='multiple'
              optionFilterProp="children"
              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {getCityComplexesOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('properties.new.bedrooms')}
            name="bedrooms"
          >
            <Select
              mode='multiple'
              placeholder={t('properties.new.bedrooms')}
              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {getBedroomsOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('manager')}
            rules={[{ required: true, message: t('validates.cantBeEmpty')}]}
            name="manager_id"
          >
            <Select
              placeholder={t('expenses.placeholder.manager')}
              showSearch
              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {renderManagers()}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('tabs.notes')}
            name="note"
          >
            <Input.TextArea placeholder={t('tabs.notes')} rows={6} />
          </Form.Item>
          <Row>
            <Col span={6}></Col>
            <Col span={18}>
              <div className="form-controls">
                <Button type='primary' className='mr-10' onClick={handleSubmit}>
                  {t('save')}
                </Button>
                <Button onClick={() => props.handleCancel()}>
                  {t('cancel')}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    )
});

export default DealForm;
