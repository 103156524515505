import { IWebResorce } from 'declarations/webResource';
import { groupBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

export class WebResorceStore {
  values: Array<IWebResorce> = [];
  groupedValues: any = {};
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: Array<IWebResorce>): void {
    this.values = values;
    this.groupedValues = groupBy(values, 'web_type')
  }

  setLoading(val: boolean) {
    this.loading = val;
  }
}

export default (new WebResorceStore())

