import { FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import OwnerForm from '../../components/New/Form';
import ownerStore from 'stores/owner';
import { useNavigate } from "react-router-dom"
import { message } from 'antd';

import { IOwner } from "declarations/contact";

interface IData {}

const New: FC<IData> = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onCreateOwner = async (params: IOwner) => {
    await ownerStore.create(params)

    if (!ownerStore.saveError) {
      message.success({
        key: 'successCreate',
        content: 'Owner created!',
      });
      navigate('/owners')
      ownerStore.fetchOwners();
    } else {
      message.open({
        key: 'errorCreate',
        type: 'error',
        content: ownerStore.saveError.response.data.message,
      });
    }
  }

  const onCancelButton = () => {
    navigate('/owners');
  }


  return (
    <div className="edit-contact-page page">
      <div className='mb-30'>
        <h3 className='ta-c'>{t('owners.newOwner')}</h3>
      </div>
      <div>
        <div>
          <OwnerForm onCreateOwner={onCreateOwner} onCancelButton={onCancelButton} />
        </div>
      </div>
    </div>
  );
})

export default New;
