import { FC } from 'react';
import { observer, } from 'mobx-react';
import propertyStore from 'stores/property';
import AppLayout from '../../Layout';
import Files from 'submodules/files/containers/index';

const PropertyFiles: FC = observer(() => {
  const property = propertyStore.selected;

  return (
    <AppLayout>
      <Files resource={property} resource_type='Property' />
    </AppLayout>
  )

})

export default PropertyFiles;
