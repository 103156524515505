import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { requestService } from 'services/requestService';
import requestStore from 'stores/request';
import clientStore from 'stores/client';
import LocaleStore from 'stores/locale';
import workspaceStore from 'stores/workspace';
import ProgressStore from 'stores/progress';
import { Table, Button, Menu, Modal, Dropdown, Row, Col, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import RequestForm from 'modules/requests/components/New'
import { LOAD_REQUESTS } from 'constants/action';
import { name } from 'utils/localized';
import ManagerModal from 'modules/clients/containers/Single/components/managerModal';
import FilterRequests from '../../components/Filter/FilterRequests';
import {EXPORT_REQUESTS} from 'constants/action'
import loginStore from 'modules/login/loginStore';
import { Helmet } from 'react-helmet';
import { IRequest } from 'declarations/request';
import { IClient } from 'contact';
import { Property as IProperty } from 'property';
import { EditOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";

const confirm = Modal.confirm;

const List: FC = observer(() => {
  const [modal, setModal] = useState("");
  const [modalResource, setModalResource] = useState({});
  const { t } = useTranslation();
  const lng = LocaleStore.locale;

  useEffect(() => {
    requestService.loadResources()
    return () => requestStore.clearData()
  }, [workspaceStore.selectedId])


  const showModal = (resource: IClient | IRequest | {}, type: string) => {
    setModal(type)
    setModalResource(resource);
  }

  const handleCancel = () => {
    setModal("");
    setModalResource({});
  }

  const onSelectManager = async (values: { manager_id: number }) => {
    if (modalResource) {
      const client: any = modalResource;
      const value = { ...client, ...values }
      await clientStore.updateClient(client.id, value)
      handleCancel();
      requestService.loadResources()
    }
  }

  const renderModalContent = () => {
    switch (modal) {
      case 'Manager':
        const currentModalResource = modalResource as IClient;
        return <ManagerModal
          manager_id={currentModalResource && currentModalResource.manager_id}
          handleCancel={handleCancel}
          onSelectManager={onSelectManager}
        />
      case 'Request':
        return <RequestForm handleCancel={handleCancel} request={modalResource as IRequest} />
      default:
        return
    }
  }

  const columns: ColumnsType<IRequest> = [
    {
      title: t('deals.list.id'),
      dataIndex: 'id',
      fixed: "left",
      key: 'id',
    }, {
      title: t('clients.table.client'),
      dataIndex: 'contact',
      key: 'contact'
    }, {
      title: t('deals.new.operation'),
      dataIndex: 'operation',
      key: 'operation',
    }, {
      title: t('owners.table.property'),
      dataIndex: 'property',
      key: 'property',
    }, {
      title: t('message'),
      dataIndex: 'message',
      key: 'message',
    }, {
      title: t('requests.showingDateTime'),
      dataIndex: 'showing_date',
      key: 'showing_date',
      width: 150
    }, {
      title: t('contacts.new.source'),
      dataIndex: 'source',
      key: 'source',
    }, {
      title: t('contacts.table.language'),
      dataIndex: 'language',
      key: 'language',
    }, {
      title: t('expenses.new.date'),
      dataIndex: 'date',
      key: 'date',
    }, {
      title: '',
      dataIndex: 'actions',
      fixed: "right",
      key: 'actions'
    }
  ];

  const renderRequestDate = (data: string) => {
    if (!data) {
      return '';
    }
    const createdAtDate = moment(data).format('DD.MM.YYYY [at] HH:mm');
    return <span>{createdAtDate}</span>
  }

  const showManagerLink = (contact: IClient) => {
    let managerContent;

    if(!loginStore.isAdmin && !loginStore.isGeneralManager) {
      return (loginStore.session.user.user.id == contact.manager_id && contact.manager) ? contact.manager.name : "-"
    }

    if (contact.manager_id && contact.manager) {
      managerContent = <a onClick={() => showModal(contact, 'Manager')} className='link'>
        <span className='mr-5'>{contact.manager && contact.manager.name}</span>
        <EditOutlined />
      </a>
    } else {
      managerContent = <a onClick={() => showModal(contact, 'Manager')} className='mr-5 link'>
        {t('addManager')}
      </a>
    }
    return managerContent;

  }
  const renderContactInfo = (request: IRequest) => {
    const contact = request.client;
    const phone = contact.phones_attributes[0] && contact.phones_attributes[0].value;
    const email = contact.emails_attributes[0] && contact.emails_attributes[0].value;

    return <div>
      { request.is_new_contact && <p><Tag color='#108ee9'>{t('clients.newClient')}</Tag></p> }
      <p><Link to={`/clients/${contact.id}/overview`} className='link'>{contact.name}</Link></p>
      <p>{phone}</p>
      <p>{email}</p>
      <p className='ws-nw'>{t('manager')}: {showManagerLink(contact)}</p>
    </div>
  }

  const renderPropertyInfo = (property: IProperty) => {
    if (!property) {
      return null;
    }
    const price = property.price_attributes.amount;
    return <div>
      <p><Link to={`/properties/${property.id}/overview`} className='link'>{name(property, lng)}</Link></p>
      <div className='ws-nw'>
        <p>{t('properties.new.id')}: <span className="property-identifier">{property.identifier}</span></p>
        <p>{t('properties.table.price')}: {price ? <span>{`${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €</span> : "-"}</p>
        <p>{t('properties.new.owner')}: {property.owner ? <a href={`/owners/${property.owner.id}/overview`} target='_blank' className='link'>{property.owner.name}</a> : '-'}</p>
      </div>
    </div>
  }

  const renderShowingDate = (request: IRequest) => {
    return(
      <div className='ws-nw'>
        <p>{request.showing_date_at ? moment(request.showing_date_at).format('DD.MM.YYYY') : ''}</p>
        <p>{request.showing_time}</p>
      </div>
    )
  }

  const getData = (): any => {
    const requests = requestStore.values;

    return (requests || []).map((request: IRequest, index: number) => {
      return {
        id: request.id,
        contact: renderContactInfo(request),
        operation: <span>{request.operation}</span>,
        property: renderPropertyInfo(request.property),
        message: <span>{request.message}</span>,
        showing_date: renderShowingDate(request),
        source: <span>{request.source_name}</span>,
        language: <div className='languages-list ws-nw'>
          { request.language ? <img className='flag' src={require(`../../../../images/flags/${request.language}.png`)} /> : '-' }
        </div>,
        date: renderRequestDate(request.created_at),
        actions: <Dropdown overlay={getActionComponent(request)} trigger={['click']}>
          <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
        </Dropdown>
      }
    })
  }

  const showConfirm = (request: IRequest) => {
    confirm({
      title: t('requests.confirmDelete'),
      content: '',
      onOk() {
        return new Promise((resolve, reject) => {

          // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          requestService.deleteResource(request.id, resolve)
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }

  const getActionComponent = (request: IRequest) => {
    return (
      <Menu
        mode="horizontal"
        className='actons-menu'
      >
        <Menu.Item key={`edit_user_${request.id}`} onClick={() => showModal(request, 'Request')}>
          <span className='edit-title'>
            <EditOutlined />
            {t('owners.table.edit')}
          </span>
        </Menu.Item>
        { (loginStore.isAdmin || loginStore.isGeneralManager) && <Menu.Item key={`delete_request_${request.id}`} onClick={() => showConfirm(request)}>
          <span className='delete-title'>
            <DeleteOutlined />
            {t('owners.table.delete')}
          </span>
        </Menu.Item>}
      </Menu>
    )
  }

  const changePagination = (page: number) => {
    requestStore.setPage(page);
    requestService.loadResources(requestStore.filterKeys, page);
  }

  const exportRequests = () => {
    requestService.exportResources()
  }

  const requests = requestStore.values;
  const paginationProps = {
    pageSize: 10, onChange: changePagination, total: requestStore.count, showSizeChanger: false, current: typeof requestStore.page === "number" ? requestStore.page : 1
  }

  if (!(requests)) {
    return null;
  }

  return (
    <div className="deals-page page">
      <Modal
        title={t('requests.addRequest')}
        visible={!!modal}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
        maskClosable={false}
        className='modal'
      >
        {renderModalContent()}
      </Modal>
      <Helmet>
        <title>{t("requests.header")}</title>
      </Helmet>
      <Row align='middle' justify='space-between' className='page-subheader'>
        <Col>
          <h3>{t('requests.header')} ({requestStore.count})</h3>
        </Col>
        <Col>
          { (loginStore.isAdmin || loginStore.isGeneralManager) && <Button
            type='primary'
            size='large'
            loading={ProgressStore.isLoading(EXPORT_REQUESTS)}
            className='mr-10'
            onClick={exportRequests}>
              {t('contacts.export')}
          </Button>}
          <Button type='primary' onClick={() => showModal({}, 'Request')} size='large'>
            {t('requests.addRequest')}
          </Button>
        </Col>
      </Row>
      <Row justify='space-between' gutter={20} className='page-content'>
        <Col span={4}>
          <FilterRequests />
        </Col>
        <Col span={20}>
          <Table
            dataSource={getData()}
            columns={columns}
            className='general-table'
            bordered
            loading={ProgressStore.isLoading(LOAD_REQUESTS)}
            pagination={paginationProps}
          />
        </Col>
      </Row>
    </div>
  );
})

export default List;
