import { INote } from 'declarations/note';
import { makeAutoObservable } from 'mobx';

export class NoteStore {

  values: Array<INote> = [];
  selected: any = {};
  fetchInProgress = false;
  saveInProgress = false;
  deleteInProgress = false;

  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: Array<INote>, page?: number, queryParams?: Object): void {
    this.values = values;
  }

  removeValue(resource_id: number): void {
    this.values = this.values.filter((item: INote) => item.id != resource_id);
  }

  addSelectedValue(value: any): void {
    this.selected = value;
  }

  clearValues(): void {
    this.values = [];
  }

  updateValue(value: INote): void {
    this.values = this.values.map((item: INote) => item.id != value.id ? item : value)
  }
  setFetchInProgress(val: boolean) {
    this.fetchInProgress = val;
  }
  setSaveInProgress(val: boolean) {
    this.saveInProgress = val;
  }
  setDeleteInProgress(val: boolean) {
    this.deleteInProgress = val;
  }
}

export default (new NoteStore())

