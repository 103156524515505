import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { fileService } from 'services/fileService';
import FileStore from 'stores/file';
import ProgressStore from 'stores/progress';
import FileItem from '../components/Item';
import FileForm from '../components/Form';
import { LOAD_FILES } from 'constants/action';
import { Button, Checkbox, Row, Col, Menu, Modal, Empty, Spin } from 'antd';

import { IContact } from 'contact';
import { Property as IProperty } from 'property';

interface IData {
  resource: IContact | IProperty,
  resource_type: string;
}

const Index: FC<IData> = observer((props) => {

  const [visible, setVisisble] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const { resource, resource_type } = props;
    if (resource.id) {
      fileService.loadResources({ attachable_id: resource.id, attachable_type: resource_type })
    }
    return () => FileStore.clearValues();
  }, [])

  const renderFilter = () => {
    return (
      <Menu>
        <Menu.Item key="1"><Checkbox>{t('files.myFiles')}</Checkbox></Menu.Item>
        <Menu.Item key="2"><Checkbox>{t('files.otherFiles')}</Checkbox></Menu.Item>
      </Menu>
    )
  }

  const handleCancel = () => setVisisble(false);


  const renderCardLists = () => {
    const { resource } = props;
    const files = FileStore.values;

    if (files.length > 0) {
      return files.map((item) => <FileItem {...props} resource={resource} attachment={item} />)
    } else {
      return <Empty className='mt-100' description={t('files.noFiles')} />
    }
  }

  const { resource, resource_type } = props;
  const files = FileStore.values;

  if(ProgressStore.isLoading(LOAD_FILES)) {
    return <Spin className='loader-spiner-container' />
  }

  if (!files) {
    return null;
  }
  return (
    <div className='tab'>
      <Row justify="center">
        <Modal
          title={t('files.newFile')}
          visible={visible}
          footer={null}
          width='650px'
          onCancel={handleCancel}
          destroyOnClose
          className='modal'
          maskClosable={false}
        >
          <FileForm onCancelButton={handleCancel} resource={resource} resource_type={resource_type} />
        </Modal>

        <Col span={16}>
          <Row justify="space-between" className='mb-30' align='middle'>
            <Col>
              {/* <Dropdown overlay={renderFilter} trigger={['click']}>
                <Button>
                  {t('files.filterFiles')} 1/2 <Icon type="down" />
                </Button>
              </Dropdown> */}
              <h3>{t('tabs.files')} ({files.length})</h3>
            </Col>
            <Col>
              <Button onClick={() => setVisisble(true)} type='primary'>
                {t('files.newFile')}
              </Button>
            </Col>
          </Row>

          <Row className="card-list" justify="center">
            {renderCardLists()}
          </Row>
        </Col>
      </Row>
    </div>
  );
})

export default Index;
