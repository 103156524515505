import { FC, useState} from 'react';
import { observer} from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { propertyService } from 'modules/properties/PropertyService';

import YouTube from 'react-youtube';
import { Row, Col, Modal, Empty} from 'antd';
import Carousel from '@brainhubeu/react-carousel';
import GalleryForm from './GalleryForm';
import '@brainhubeu/react-carousel/lib/style.css';
import { EditOutlined, EyeOutlined, GlobalOutlined, LockOutlined } from "@ant-design/icons";
import { IImage } from 'declarations/image';

interface IData {
  property: any;
  images: Array<any>;
}

const Gallery:  FC<IData> = observer((props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [previewVisible, setPreview] = useState("");
  const { t } = useTranslation();


  const handleEditCancel = () => setIsEdit(false);
  const handleEdit = () => setIsEdit(true);

  const getGalleryValue = (key: string) => {
    const details = props.property.detail;
    const detailValueCount = details && details[`${key}_count`] || 0;
    const detailValueArea = details && details[`${key}_area`] || 0;
    return <span className='details-value'>{detailValueCount} ({detailValueArea}m²)</span>
  }

  const renderGalleryItem = () => {
    const images = props.images;
    return images.map((image) => {
      const typeValue = image.is_public ? 'public' : 'private';

      return <Col className='gallery-item mb-10'>
        <div className="ant-upload-list ant-upload-list-picture-card">
          <div className="ant-upload-list-item ant-upload-list-item-done" style={{ width: '190px', height: '122px', padding: 0, margin: 0 }}>
            <div className="ant-upload-list-item-info">
              <img src={image.url.thumb} width='100%' height='118' />
            </div>
            <span className="ant-upload-list-item-actions">
              <EyeOutlined onClick={() => showPreviewImage(image)} />
            </span>
          </div>
        </div>
        <div className={`gallery-type ${typeValue}`}>
            <span className='d-ib pt-5 pb-5 pl-10 pr-10'>
              {typeValue === 'public' ? <GlobalOutlined className='mr-5' /> : <LockOutlined className='mr-5' /> }
              {typeValue}
            </span>
        </div>
      </Col>
    })
  }

  const handleImageCancel = () => setPreview('');

  const showPreviewImage = (image: IImage) => {
    setPreview(image.url.original);
  }

  const renderContent = () => {
    let content;
    const { property, images } = props;

    if(isEdit) {
      content = <GalleryForm images={images} property={property} handleCancel={handleEditCancel} />
    } else {
      content = staticContent()
    }

    return content;
  }

  const getImagesZip = () => {
    const { property } = props;
    propertyService.getZipResources(property)
  }

  const staticContent = () => {
    const { property, images } = props;
    let videoId, videoTime, data;
    let opts = {
      height: '140',
      width: '100%',
      playerVars: {
        start: 0
      }
    };

    if (images.length == 0 && !property.video_url && !property.demo_url) {
      return <Empty className='mt-50 mb-50' description={t('gallery.noPhotos')} />;
    }
    if(property.video_url) {
      data = property.video_url.includes('v=') ? property.video_url.split('v=') : property.video_url.split('/');
      videoId = data.slice(-1)[0];
      videoTime = property.video_url.split('&t=')[1] || 0;
      opts['playerVars']['start'] = parseInt(videoTime, 10);
    }

    return (
      <div>
        {images.length !== 0 && <>
          <Row justify='start'>
            <h5 className='mb-20 mr-10'>
              {t('gallery.photos')}
              <span
                className='ml-10 download-zip'
                // loading={ProgressStore.isLoading(ZIP_GALLERIES)}
                onClick={getImagesZip}>
                  {t('downloadAll')}
              </span>
            </h5>
          </Row>
          <Row gutter={20} justify="start" className='galleries-list ant-upload-wrapper ant-upload-picture-card-wrapper'>
            {renderGalleryItem()}
          </Row>
        </>
        }
        <Row className='mt-30'>
          {videoId &&
            <Col span={4} className='video-item mr-10 w-200'>
              <h5 className='gallery-video-title mb-10'>{t('gallery.video')}</h5>
              <YouTube
                videoId={videoId}
                className="w-200"
                opts={opts}
              />
            </Col>
          }
          {property.demo_url &&
            <Col span={4} className='video-item mr-10 w-200'>
              <h5 className='gallery-video-title mb-10'>{t('gallery.tour')}</h5>
              <video width="200" height="140" controls>
                <source src="https://www.youtube.com/embed/HULgZLu-O68" type="video/mp4" />
              </video>
            </Col>
          }
        </Row>
      </div>
    )
  }

  const preview = () => {
    const { images } = props;
    return (
      <Carousel
        itemWidth={780}
        // infinite
        // value={0}
      >
        {images.map((item: any) => <img src={item.url.original} width='750' height='562' />)}
    </Carousel>
    )
  }

  const { images } = props;

  if (!images) {
    return null;
  }

  return (
    <div className="contact-card-item">
      <Modal visible={!!previewVisible} footer={null} onCancel={handleImageCancel} className='preview-image' maskClosable={false}>
        <img style={{ width: '100%' }} src={previewVisible} />
        {/* {preview()} */}
      </Modal>

      <Row justify='space-between' align='middle' className='contact-card-header'>
        <h5>{t('properties.new.steps.gallery')}</h5>
        <EditOutlined onClick={handleEdit} />
      </Row>
      <div className="contact-card-content">
        {renderContent()}
      </div>
    </div >
  )
})

export default Gallery;
