import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { propertyTypesService } from 'services';
import { propertyService } from '../../../PropertyService';
import PropertyTypeStore from 'stores/type';
import ProgressStore from 'stores/progress';
import { some, uniq } from 'lodash';
import { Form, Select, Input, Button, Row, Col, Divider, Checkbox, InputNumber, notification } from 'antd';
import { UPDATE_PROPERTY } from 'constants/action';

import { PropertyType } from 'declarations/propertyType'
import { Details as IDetails } from 'declarations/details'
import { ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

interface IData {
  next?(params: any): any,
  prev?(params: any): any,
  details: IDetails,
  operation: any,
  handleCancel?(): any,
  isNotSteper?: boolean,
  validateChanges?(params: any): Array<string>;
}

notification.config({
  placement: 'topRight',
  duration: 0
})
// interface Props {
//   form: any
// }
const OPTIONS_COUNT = 20;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};
const formItemLayoutTwin = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const formItemLayoutTwinSecond = {
  wrapperCol: { span: 24 }
};

const formItemRow = {
  wrapperCol: { span: 14, offset: 6 }
};

const DISTANCE_TO_OCEAN = [
  {key: 'less_100', value: 'less than 100 m'},
  {key: '300', value: '300 m'},
  {key: '500', value: '500 m'},
  {key: '800', value: '800 m'},
  {key: '1000', value: '1000 m'},
  {key: '1500', value: '1500 m'},
  {key: '2000', value: '2000 m'},
  {key: 'more_2000', value: 'more than 2000 m'},
]


const Details: FC<IData> = observer(({
  next,
  prev,
  details,
  operation,
  handleCancel,
  isNotSteper,
  validateChanges
}) => {

  const [form] = Form.useForm();
  const { t } = useTranslation();

  // state = {
  //   details: {
  //     id: null,
  //     view: undefined,
  //     furniture: null,
  //     kitchen: undefined,
  //     bedroom_count: undefined,
  //     bedroom_area: '',
  //     bathroom_count: undefined,
  //     bathroom_area: '',
  //     toilet_count: undefined,
  //     toilet_area: '',
  //     terrasse_count: undefined,
  //     terrasse_area: '',
  //     balcony_count: undefined,
  //     balcony_area: '',

  //     parking_count: undefined,
  //     parking_area: '',

  //     garage_count: undefined,
  //     garage_area: '',
  //     storeroom_count: undefined,
  //     storeroom_area: '',

  //     dist_to_ocean: '',
  //     condition: '',
  //     air_conditioner: '',
  //     private_pool: undefined,
  //     private_pool_area: '',
  //     public_pool: undefined,
  //     private_garden: undefined,
  //     private_garden_heating: undefined,
  //     public_garden: undefined,
  //     public_garden_area: '',
  //     private_parking_count: undefined,
  //     private_parking_area: '',
  //     public_parking: undefined,
  //     public_parking_area: '',
  //     roof_terrasse: undefined,
  //     roof_terrasse_area: '',
  //     wardrobe_count: undefined,
  //     public_pool_heating: 'false',
  //     private_pool_heating: 'false',
  //     wardrobe_area: '',
  //     living_area: '',
  //     plot_area: '',
  //     total_area: '',
  //     utility_bills: '',
  //     electricity: '',
  //     water: '',
  //     garbage: '',
  //     water_inc: 'false',
  //     electricity_inc: 'false',
  //     garbage_inc: 'false'
  //   }
  // };

  useEffect(() => {
    propertyTypesService.loadResources()
    // if (this.props.details) {
    //   this.setState({
    //     ...this.state,
    //     details: {
    //       ...this.state.details,
    //       ...this.props.details
    //     }
    //   })
    // }
    form.setFieldsValue(details)

  }, [])

  const onCancel = () => handleCancel && handleCancel();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await form.validateFields();


      // if(values['living_area'] == '0') {
      //   this.props.form.setFields({'living_area': {value: values['living_area'], errors: [new Error('Can not be 0')]}})
      // }
      // if(values['total_area'] == '0') {
      //   this.props.form.setFields({'total_area': {value: values['total_area'], errors: [new Error('Can not be 0')]}})
      // }
    const newErrors: any = form.getFieldsError();

    if (!some(form.getFieldsError(), ({ errors }) => errors.length) && !newErrors['total_area'] &&  !newErrors['living_area']) {
      const values = form.getFieldsValue();
      const detailsValue = { ...details, ...values };
      let invalidPortalsList: Array<string> = [];
      if(validateChanges) {
        invalidPortalsList = validateChanges({ detail_attributes: detailsValue })
      }
      if(invalidPortalsList.length == 0) {
        propertyService.updateResource('details', detailsValue, () => onCancel())
      } else {
        notification['error']({
          message: "Can't update property",
          placement: 'topRight',
          duration: 0,
          description: `Unpublish property from ${uniq(invalidPortalsList).join(' , ')} before updating.`
        });
      }
      // this.props.onCreateProperty({ detail_attributes: values })
    }
  }

  const nextStep = async (e: any) => {
    form.validateFields();
      // if(values['living_area'] == '0') {
      //   this.props.form.setFields({'living_area': {value: values['living_area'], errors: [new Error('Can not be 0')]}})
      // }
      // if(values['total_area'] == '0') {
      //   this.props.form.setFields({'total_area': {value: values['total_area'], errors: [new Error('Can not be 0')]}})
      // }
    const newErrors: any = form.getFieldsError();
    if ((!some(form.getFieldsError(), ({ errors }) => errors.length) || !newErrors['total_area'] || !newErrors['living_area']) && next) {
      const values = form.getFieldsValue();
      const detailsValue = { ...details, ...values };
      next({ detail_attributes: detailsValue })
    }

  }

  const prevStep = async () => {
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length) && prev) {
      const values = form.getFieldsValue();
      const detailsValue = { ...details, ...values };
      prev({ detail_attributes: detailsValue })
    }
  }

  // const getTypeOptions = (): Array<React.ReactNode> => {
  //   return (PropertyTypeStore.values || []).map((propertyType: PropertyType) => {
  //     return <Option value={propertyType.id}>{propertyType.name}</Option>
  //   })
  // }

  const getButtons = () => {
    let buttons;

    if (isNotSteper) {
      buttons = <div>
                  <Col>
                    <Button className='mr-10' type="primary" onClick={handleSubmit}
                      loading={ProgressStore.isLoading(UPDATE_PROPERTY)}
                    >
                      {t('save')}
                    </Button>
                    <Button type="default" onClick={onCancel}>{t('cancel')}</Button>
                  </Col>
                </div>
    } else {
      buttons = <div>
        <Button className='mr-10' onClick={prevStep}><ArrowLeftOutlined /> {t('back')}</Button>
        <Button type="primary" onClick={nextStep}>
        {t('continue')} <ArrowRightOutlined />
        </Button>
      </div>
    }

    return buttons;
  }


  const getOptions = () => {
    let options = []
    for (let step = 0; step <= OPTIONS_COUNT; step++) {
      options.push(<Option value={step}>{step}</Option>)
    }

    return options;
  }

  const getRoomsContent = (rooms: Array<string>, is_require: boolean = false) => {
    return rooms.map((room) => {
      return (
        <Row className='grouped-details'>
          <Col span='12'>
            <Form.Item
              {...formItemLayoutTwin}
              label={t(`properties.new.${room}`)}
              name={`${room}_count`}
              rules={[{ required: is_require, message: t('validates.cantBeEmpty') }]}
            >
              <Select
                className='details-options'
                placeholder={t('properties.new.notSelected')}
                filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value=''>{t('properties.new.notSelected')}</Option>
                {getOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col span='7' offset='1'>
            <Form.Item
              {...formItemLayoutTwinSecond}
              name={`${room}_area`}
            >
              <Input type='number' min={0} className='details-options' addonAfter="m2" />
            </Form.Item>
          </Col>
        </Row>
      )
    })
  }

  const getRoomCountContent = (rooms: Array<string>, is_require: boolean = false) => {

    return rooms.map((room) => {
      return (
        <Row className='grouped-details' justify='start'>
          <Col span='24'>
            <Form.Item
              {...formItemLayout}
              label={t(`properties.new.${room}`)}
              name={`${room}_count`}
              rules={[{ required: is_require, message: t('validates.cantBeEmpty') }]}
            >
              <Select className='details-options' placeholder={t('properties.new.notSelected')} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value=''>{t('properties.new.notSelected')}</Option>
                {getOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )
    })
  }

  // changeArea = (e: any, field: string) => {
  //   const value = e.target.value;
  //   console.log('get fieds', this.props.form.getFieldsValue())
  //   // console.log('get fieds', this.props.form)
  //   if(value == '0') {
  //     this.props.form.setFields({[field]: {errors: [new Error('Can not be 0')]}})
  //     return
  //   }
  //   this.props.form.setFields({[field]: { value: value, errors: []}})

  // }

    return (
      <div>
        <div>
          <Form onFinish={handleSubmit} form={form}>
            <Form.Item
              {...formItemRow}
            >
              <h3>{t('properties.propertyDetails')}</h3>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.view')}
              name="view"
            >
              <Select placeholder={t('properties.placeholder.view')} mode='multiple' filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="0">{t('properties.new.ocean')}</Option>
                <Option value="1">{t('properties.new.mountains')}</Option>
                <Option value="2">{t('properties.new.pool')}</Option>
                <Option value="3">{t('properties.new.sea')}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.distToOcean')}
              name="dist_to_ocean"
            >
              <Select placeholder={t('properties.new.notSelected')} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="">{t('properties.new.notSelected')}</Option>
                { DISTANCE_TO_OCEAN.map((item: any) => <Option value={item.key}>{item.value}</Option>) }
              </Select>
            </Form.Item>
            { !(operation.includes('1') && operation.length == 1) &&
              <Form.Item
                {...formItemLayout}
                label={t('properties.new.condition')}
                name="condition"
                initialValue={""}
              >
                <Select placeholder={t('properties.new.notSelected')} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  <Option value="">{ t('properties.new.notSelected') }</Option>
                  <Option value="new_building">{ t('properties.new.newBuilding') }</Option>
                  <Option value="off_plan">{ t('properties.new.offPlan') }</Option>
                  <Option value="resale">{t('properties.new.resale')}</Option>
                </Select>
              </Form.Item>
            }
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.furniture')}
              name="furniture"
              initialValue={""}
            >
              <Select placeholder={t('properties.placeholder.furniture')} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="">{t('properties.new.notSelected')}</Option>
                <Option value="full">{t('properties.new.full')}</Option>
                <Option value="partial">{t('properties.new.partial')}</Option>
                <Option value="no">{t('properties.new.no')}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.kitchen')}
              name="kitchen"
              initialValue={""}
            >
              <Select placeholder={t('properties.new.notSelected')} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="">{t('properties.new.notSelected')}</Option>
                <Option value="independent">{t('properties.new.independent')}</Option>
                <Option value="open_plan">{t('properties.new.open_plan')}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.airConditioner')}
              name="air_conditioner"
            >
              <Select placeholder={t('properties.placeholder.airConditioner')} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="">{t('properties.new.notSelected')}</Option>
                <Option value="true">{t('properties.new.yes')}</Option>
                <Option value="false">{t('properties.new.no')}</Option>
              </Select>
            </Form.Item>
            <Divider type='horizontal' />
            {getRoomsContent(['bedroom', 'bathroom'], false)}
            {getRoomsContent(['toilet'])}
            {getRoomCountContent(['wardrobe'])}
            {getRoomsContent(['storeroom'])}
            <Divider type='horizontal' />
            {getRoomsContent(['balcony', 'terrasse'])}
            <Row className='grouped-details'>
              <Col span='12'>
                <Form.Item
                  {...formItemLayoutTwin}
                  label={t('properties.new.roofTerrasse')}
                  name="roof_terrasse"
                >
                  <Select className='details-options'
                    placeholder={t('properties.new.notSelected')}
                    filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value=''>{t('properties.new.notSelected')}</Option>
                    <Option value='true'>{t('properties.new.yes')}</Option>
                    <Option value='false'>{t('properties.new.no')}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span='7' offset='1'>
                <Form.Item
                  {...formItemLayoutTwinSecond}
                  name="roof_terrasse_area"
                >
                  <Input type='number' min={0} className='details-options' addonAfter="m2" />
                </Form.Item>
              </Col>
            </Row>
            <Divider type='horizontal' />

            <Form.Item
              {...formItemLayout}
              label={t('properties.new.publicParking')}
              name="public_parking"
              >
              <Select
                className='details-options'
                placeholder={t('properties.new.notSelected')}
                filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value=''>{t('properties.new.notSelected')}</Option>
                <Option value='true'>{t('properties.new.yes')}</Option>
                <Option value='false'>{t('properties.new.no')}</Option>
              </Select>
            </Form.Item>
            {getRoomCountContent(['private_parking'])}
            {getRoomsContent([ 'garage'])}
            <Divider type='horizontal' />
            <Row className='grouped-details'>
              <Col span='24'>
                <Form.Item
                  {...formItemLayout}
                  label={t('properties.new.publicGarden')}
                  name="public_garden"
                >
                  <Select
                    className='details-options'
                    placeholder={t('properties.new.notSelected')}
                    filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value=''>{t('properties.new.notSelected')}</Option>
                    <Option value='true'>{t('properties.new.yes')}</Option>
                    <Option value='false'>{t('properties.new.no')}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className='grouped-details'>
              <Col span='24'>
                <Form.Item
                  {...formItemLayout}
                  label={t('properties.new.privateGarden')}
                  name="private_garden"
                >
                  <Select
                    className='details-options'
                    placeholder={t('properties.new.notSelected')}
                    filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value=''>{t('properties.new.notSelected')}</Option>
                    <Option value='true'>{t('properties.new.yes')}</Option>
                    <Option value='false'>{t('properties.new.no')}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Divider type='horizontal' />
            <Row className='grouped-details'>
              <Col span='16'>
                <Form.Item
                  labelCol={ { span: 9 } }
                  wrapperCol={ { span: 14 } }
                  label={t('properties.new.publicPool')}
                  name="public_pool"
                >
                  <Select
                    className='details-options'
                    placeholder={t('properties.new.notSelected')}
                    filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value=''>{t('properties.new.notSelected')}</Option>
                    <Option value='true'>{t('properties.new.yes')}</Option>
                    <Option value='false'>{t('properties.new.no')}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span='4' offset={2}>
                <Form.Item
                  wrapperCol={ { span: 24 } }
                  name="public_pool_heating"
                  valuePropName='checked'
                >
                  <Checkbox>{t('properties.new.heating')}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row className='grouped-details'>
              <Col span='10' offset={1}>
                <Form.Item
                  {...formItemLayoutTwin}
                  label={t('properties.new.privatePool')}
                  name="private_pool"
                >
                  <Select
                    className='details-options'
                    placeholder={t('properties.new.notSelected')}
                    filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value=''>{t('properties.new.notSelected')}</Option>
                    <Option value='true'>{t('properties.new.yes')}</Option>
                    <Option value='false'>{t('properties.new.no')}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span='6' offset='1'>
                <Form.Item
                  wrapperCol={ { span: 13 } }
                  name="private_pool_area"
                >
                  <Input type='number' min={0} className='details-options' addonAfter="m2" />
                </Form.Item>
              </Col>
              <Col span='4'>
                <Form.Item
                  wrapperCol={ { span: 24 } }
                  name="private_pool_heating"
                  valuePropName='checked'
                >
                  <Checkbox>{t('properties.new.heating')}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider type='horizontal' />
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.totalLivingArea')}
              name="living_area"
            >
              <Input
                type='number'
                min={1}
                placeholder="100m2"
                addonAfter="m2"
                // onChange={(e: any) => this.changeArea(e, 'living_area')}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.totalServiceArea')}
              name="plot_area"
            >
              <Input type='number' min={1} placeholder="100m2" addonAfter="m2" />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.totalArea')}
              name="total_area"
            >
              <Input type='number' min={1} placeholder="100m2" addonAfter="m2" />
            </Form.Item>
            <Form.Item
              {...formItemRow}
            >
              <h3>{t('properties.new.utilities')}</h3>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.utilityBills')}
              name="utility_bills"
            >
              <InputNumber
                placeholder="100€"
                min={0}
                formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                parser={(value: any) => value.replace(/(\.*)/g, '')}
                addonAfter="€"
              />
            </Form.Item>
            <Row className='grouped-details'>
              <Col span='16'>
                <Form.Item
                  labelCol={ { span: 9 } }
                  wrapperCol={ { span: 14 } }
                  label={t('properties.new.electricity')}
                  name="electricity"
                >
                  <InputNumber
                    placeholder="100€"
                    min={0}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={(value: any) => value.replace(/(\.*)/g, '')}
                    addonAfter="€"
                  />
                </Form.Item>
              </Col>
              <Col span='4' offset={2}>
                <Form.Item
                  wrapperCol={ { span: 24 } }
                  name="electricity_inc"
                  valuePropName='checked'
                >
                  <Checkbox>{t('properties.new.inc')}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row className='grouped-details'>
              <Col span='16'>
                <Form.Item
                  labelCol={ { span: 9 } }
                  wrapperCol={ { span: 14 } }
                  label={t('properties.new.water')}
                  name="water"
                >
                  <InputNumber
                    placeholder="100€"
                    min={0}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={(value: any) => value.replace(/(\.*)/g, '')}
                    addonAfter="€"
                  />
                </Form.Item>
              </Col>
              <Col span='4' offset={2}>
                <Form.Item
                  wrapperCol={ { span: 24 } }
                  name="water_inc"
                  valuePropName='checked'
                >
                  <Checkbox>{t('properties.new.inc')}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row className='grouped-details'>
              <Col span='16'>
                <Form.Item
                  labelCol={ { span: 9 } }
                  wrapperCol={ { span: 14 } }
                  label={t('properties.new.garbage')}
                  name="garbage"
                >
                  <InputNumber
                    placeholder="100€"
                    min={0}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={(value: any) => value.replace(/(\.*)/g, '')}
                    addonAfter="€"
                  />
                </Form.Item>
              </Col>
              <Col span='4' offset={2}>
                <Form.Item
                  wrapperCol={ { span: 24 } }
                  name="garbage_inc"
                  valuePropName='checked'
                >
                    <Checkbox>{t('properties.new.inc')}</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <Row>
          <Col span={6}></Col>
          <Col span={18}>
            <div className="form-controls">
              {getButtons()}
            </div>
          </Col>
        </Row>
      </div>
    )
})

export default Details;
