import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { rentService } from 'services/rentService';
import DateByCreator from 'components/DateByCreator';
import ProgressStore from 'stores/progress';
import { LOAD_RENTS } from 'constants/action';
import rentStore from 'stores/rent';
import RentForm from '../../../../components/Single/RentForm';
import { Table, Button, Menu, Row, Modal, Tag, Dropdown } from 'antd';
import ManagerModal from 'modules/clients/containers/Single/components/managerModal';
import { IRent } from 'declarations/rent';
import { IClient } from 'contact';
import loginStore from 'modules/login/loginStore';
import { EditOutlined, DeleteOutlined, EllipsisOutlined, ShoppingOutlined } from "@ant-design/icons";
import propertyStore from 'stores/property';
import AppLayout from '../../Layout';


const confirm = Modal.confirm;


const Rent: FC = observer(() => {

  const [modalResource, setModalResource] = useState<{} | IRent | IClient>({})
  const [showModal, setShowModal] = useState<string>("")
  const { t } = useTranslation();
  const property = propertyStore.selected;

  useEffect(() => {
    if (property.id) {
      const filter = { property_id: property.id }
      rentService.loadResources(filter)
    }
  }, [property.id])

  const displayModal = (resource: IClient | IRent | {}, type: string) => {
    setShowModal(type);
    setModalResource(resource);
  }

  const handleCancel = () => setShowModal("");

  const onSelectManager = (values: { manager_id: number }) => {
    if (modalResource) {
      const contact: any = modalResource;
      const value = { ...contact, ...values }
      // contactService.updateManagerResource(value, (contact: IClient) => {
        setShowModal("")
        setModalResource({})
        rentStore.updateContactValue(value)
      // })
    }
  }

  const renderModalContent = () => {
    switch (showModal) {
      case 'Manager':
        const currentModalResource = modalResource as IClient;
        return <ManagerModal
          manager_id={currentModalResource && currentModalResource.manager_id}
          handleCancel={handleCancel}
          onSelectManager={onSelectManager}
        />
      case 'Rent':
        return <RentForm property={property} onCancelButton={handleCancel} rent={modalResource as IRent}/>
      default:
        return
    }
  }



  const columns = [
    {
      title: t('rents.tenant'),
      dataIndex: 'tenant',
      key: 'tenant'
    }, {
      title: t('rents.startDate'),
      dataIndex: 'start_date',
      key: 'start_date',
    }, {
      title: t('rents.endDate'),
      dataIndex: 'end_date',
      key: 'end_date',
    },
    {
      title: t('rents.status'),
      dataIndex: 'status',
      key: 'status',
    }, {
      title: t('properties.new.monthlyPrice'),
      dataIndex: 'monthly_price',
      key: 'monthly_price',
    }, {
      title: t('deals.list.created'),
      dataIndex: 'created_at',
      key: 'created_at',
    }, {
      title: '',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  const renderLastVisit = (data: string) => {
    if (!data) {
      return '';
    }

    const createdAtDate = moment(data).format('DD.MM.YYYY');
    const createdAtTime = moment(data).format('\\at kk:mm');
    return <div>{createdAtDate} <p>{createdAtTime}</p> </div>
  }

  const getData = () => {
    const rents = rentStore.values;

    return (rents || []).map((rent: any, index: number) => {
      return {
        tenant:  renderContactInfo(rent.client),
        start_date: rent.start_date,
        end_date: rent.end_date,
        status: <Tag>{rent.status}</Tag>,
        monthly_price: <span>{`${rent.monthly_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €</span>,
        created_at: <DateByCreator resource={rent} />,
        actions: <Dropdown overlay={getActionComponent(rent)} trigger={['click']}>
        <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
      </Dropdown>
      }
    })
  }

  const showConfirm = (rent: IRent) => {
    confirm({
      title: t('rents.confirmDelete'),
      content: '',
      onOk() {
        return new Promise((resolve, reject) => {
          rentService.deleteResource(rent, resolve)
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }

  const getActionComponent = (rent: IRent) => {

    return (
      <Menu
        mode="horizontal"
        className='actons-menu'
      >
        <Menu.Item key={`edit_user_${rent.id}`} onClick={() => displayModal(rent, 'Rent')}>
          <span className='edit-title'>
            <EditOutlined />
            {t('owners.table.edit')}
          </span>
        </Menu.Item>
        <Menu.Item key={`delete_property_${rent.id}`} onClick={() => showConfirm(rent)}>
          <span className='delete-title'>
            <DeleteOutlined />
            {t('owners.table.delete')}
          </span>
        </Menu.Item>
      </Menu>
    )
  }

  const showManagerLink = (client: IClient) => {
    let managerContent;

    if(!loginStore.isAdmin && !loginStore.isGeneralManager) {
      return (loginStore.session.user.user.id == client.manager_id && client.manager) ? client.manager.name : "-";
    }

    if (client.manager_id && client.manager) {
      managerContent = <a onClick={() => displayModal(client, 'Manager')} className='link'>
        {/* Doesn't render without manager.name */}
        <span className='mr-5'>{client.manager && client.manager.name}</span>
        <EditOutlined />
      </a>
    } else {
      managerContent = <a onClick={() => displayModal(client, 'Manager')} className='mr-5 link'>{t('addManager')}</a>
    }
    return managerContent;

  }
  const renderContactInfo = (client: IClient) => {
    const phone = client.phones_attributes[0] && client.phones_attributes[0].value;
    const email = client.emails_attributes[0] && client.emails_attributes[0].value;

    return <div>
      <p><Link to={`/clients/${client.id}/overview`} className='link'>{client.name}</Link></p>
      <p>{phone}</p>
      <p>{email}</p>
      <p className='ws-nw'>{t('manager')}: {showManagerLink(client)}</p>
    </div>
  }

  return (
    <AppLayout>
      <div className="tab">
        <Modal
          title={t('tabs.rent')}
          visible={!!showModal}
          footer={null}
          onCancel={handleCancel}
          destroyOnClose
          maskClosable={false}
          className='modal'
        >
          {renderModalContent()}
        </Modal>

        <div className='portlet'>
          <div className='portlet-head'>
            <Row justify='space-between' align='middle'>
              <h5><ShoppingOutlined className='mr-10 fs-20' />{t('rents.header')}</h5>
              <Button onClick={() => displayModal({}, 'Rent')} type='primary'>{t('rents.addRent')}</Button>
            </Row>
          </div>
          <div className='portlet-body'>
            <Table
              dataSource={getData()}
              loading={ProgressStore.isLoading(LOAD_RENTS)}
              columns={columns}
              className='owners-table' bordered />
          </div>
        </div>
      </div>
    </AppLayout>
  );
})

export default Rent;
