import { FC, useEffect, useState, useCallback} from 'react';
import { observer} from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { contactService } from 'services/ContactService';
import ManagerModal from 'modules/contacts/containers/Single/components/managerModal';
import clientStore from 'stores/client';
import ProgressStore from 'stores/progress';
import { Link } from 'react-router-dom';
import { debounce, isEmpty, uniqueId } from 'lodash';
import loginStore from 'modules/login/loginStore';
import { Table, Button, Modal, Dropdown, Row, Col, Input, Divider } from 'antd';
import { CONTACT_TYPES } from 'constants/mixed'
import { LOAD_CONTACTS, EXPORT_CONTACTS } from 'constants/action';
import { EditOutlined, DeleteOutlined, EllipsisOutlined, HomeOutlined, UserOutlined, SolutionOutlined } from "@ant-design/icons";
import { renderMenu } from "utils/menu";

import FilterContacts from '../../components/Filter/FilterContacts';
import DateByCreator from 'components/DateByCreator';
import { Helmet } from 'react-helmet';
import { IClient } from 'declarations/contact';

interface IData {
  client?: IClient;
}

interface IClientResources {
  id?: number;
  value: string
}

const confirm = Modal.confirm;

export const getContactIcon = (type: number, className?: string) => {
  switch (type) {
    case 0:
      return <UserOutlined className={className} />
    case 1:
      return <HomeOutlined className={className} />
    case 2:
      return <SolutionOutlined className={className} />
    default:
      return;
  }
}


const List: FC<IData> = observer(() => {

  const [search, setSearch] = useState('');
  const [modalContact, setContact] = useState<IClient | false>(false);
  const { t } = useTranslation();

  useEffect(() => {
    contactService.loadResources()
    return () => clientStore.clearData();
  }, [])

  const getColumns = () => {

    return [
      {
        title: t('contacts.table.type'),
        dataIndex: 'contact_type',
        key: 'contact_type',
      }, {
        title: t('contacts.table.name'),
        dataIndex: 'name',
        key: 'name',
        // render: (text: string) => <a href="javascript:;">{text}</a>,
      }, {
        title: t('contacts.table.owner'),
        dataIndex: 'owner',
        key: 'owner',
      }, {
        title: t('contacts.table.client'),
        dataIndex: 'client',
        key: 'client',
      }, {
        title: t('contacts.table.language'),
        dataIndex: 'language',
        key: 'language',
      }, {
        title: t('contacts.table.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
      }, {
        title: '',
        dataIndex: 'actions',
        key: 'id'
      }
    ]
  }


  const getData = () => {
    const clients = clientStore.values;
    return (clients || []).map((client: IClient, index: number) => {
      // const createdAtDate = moment(contact.created_at).format('MM/DD/YYYY');
      return {
        client_type: <div>{getContactIcon(client.client_type, "mr-10")}
          <span>{CONTACT_TYPES[client.client_type]}</span>
        </div>,
        name: renderName(client),
        owner: <div>{t('properties.header')}: <span>{client.properties_count}</span></div>,
        client: renderClientContent(client),
        language: (!isEmpty(client.languages) || !isEmpty(client.other_languages)) && <div className='languages-list ws-nw'>
          <span className=''>{renderLanguages(client.languages)}</span>
          <Divider />
          {(client.other_languages || []).length > 0 && <span>{renderLanguages(client.other_languages)}</span>}
        </div>,
        created_at: <DateByCreator resource={client} />,
        actions: <Dropdown menu={{ items: renderMenu(getActionComponent(client)) }} trigger={['click']}>
          <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
        </Dropdown>
      }
    })
  }

  const renderName = (client: IClient) => {
    return (
      <div>
        <p><Link to={`/clients/${client.id}/overview`} className='link'>{client.name}</Link></p>
        {(client.phones_attributes || []).map((phone: IClientResources) => <p key={uniqueId()}>{phone.value}</p>)}
        {(client.emails_attributes || []).map((email: IClientResources) => <p key={uniqueId()}>{email.value}</p>)}
      </div>
    )
  }

  const renderClientContent = (client: IClient) => {
    let managerContent;

    if(!loginStore.isAdmin && !loginStore.isGeneralManager) {
      return (
        <div>
          <div>{t('menu.deals')}:
            <span className='ml-5'>{(loginStore.session.user.user.id == client.manager_id && client.manager) ? client.deals_count : "-"}</span></div>
          <div className='ws-nw'>{t('manager')}: {
          (loginStore.session.user.user.id == client.manager_id && client.manager) ? client.manager.name : "-"
          }</div>
        </div>
      )
    }


    if (client.manager_id && client.manager) {
      managerContent = <a onClick={() => setShowModal(client)} className='link'>
        {/* Doesn't render without manager.name */}
        <span className='mr-5'>{client.manager && client.manager.name} </span>
        <EditOutlined />
      </a>
    } else {
      managerContent = <a onClick={() => setShowModal(client)} className='mr-5 link'>{t('addManager')}</a>
    }
    return (
      <div>
        <div>{t('menu.deals')}: <span>{client.deals_count}</span></div>
        <div className='ws-nw'>{t('manager')}: {managerContent}</div>
      </div>
    )
  }

  const renderLanguages = (languages: Array<string>) => {
    return (languages || []).map((language: string) =>
      <img key={uniqueId()} className='flag' src={require(`../../../../images/flags/${language}.png`)} />
    )
  }

  const showConfirm = (client: IClient) => {
    confirm({
      title: t('contacts.checkDelete'),
      content: '',
      onOk() {
        return new Promise((resolve, reject) => {
          contactService.deleteResource(client.id, resolve)
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }

  const getActionComponent = (client: IClient) => {

    return [
      {
        key:`edit_${client.id}`,
        label: <Link to={`/clients/${client.id}/edit`} className='edit-title'>
          <EditOutlined className='mr-5' />
          {t('contacts.table.edit')}
        </Link>
      },
      {
        key:`delete_${client.id}`,
        label: <div onClick={() => showConfirm(client)}>
          <span className='delete-title'>
            <DeleteOutlined className='mr-5' />
            {t('contacts.table.delete')}
          </span>
        </div>,
        disabled: !loginStore.isAdmin
      }
    ]
  }

  const handleCancel = () => setContact(false);

  const setShowModal = (client: IClient) => setContact(client);

  const handleOk = (values: { manager_id: number }) => {
    if (modalContact) {
      const contact: any = modalContact;
      const value = { ...contact, ...values }
      contactService.updateManagerResource(value, () => {
        setContact(false);
      })
    }
  }

  const setSearchText = useCallback(debounce((value) => {
    contactService.changeFilters({ q: value })
    }, 500),
    []
    )

  const onSearch = (e: any) => {
    const value = e.target.value;
    setSearch(value)
    setSearchText(value);
  }

  const changePagination = (page: number) => {
    contactService.loadResources(clientStore.filterKeys, page);
  }

  const exportContacts = () => {
    contactService.exportResources()
  }


  const showModal: any | IClient = modalContact;

  if (!(clientStore && clientStore.values)) {
    return null;
  }

  const paginationProps = {
    pageSize: 10, onChange: changePagination, total: clientStore.count, showSizeChanger: false
  }

  return (
    <div className="py-30">
      <Modal
        title={t('contacts.addManager')}
        className='modal'
        open={!!modalContact}
        footer={null}
        onCancel={handleCancel}
        maskClosable={false}
        destroyOnClose
      >
        <ManagerModal
          manager_id={showModal && showModal.manager_id}
          onSelectManager={handleOk}
          handleCancel={handleCancel}
        />
      </Modal>
      <Helmet>
        <title>{t("contacts.header")}</title>
      </Helmet>
      <Row align='middle' justify='space-between' className='page-subheader mb-20'>
        <h3>{t('contacts.header')} ({clientStore.count})</h3>
        <Row>
          <Col className='mr-10' >
            <Input.Search
              placeholder="Search ..."
              value={search}
              onChange={onSearch}
              size="large"
              className='h-100p'
              allowClear={true}
            />
          </Col>
          <Col>
            { loginStore.isAdmin && <Button
              type='primary'
              size='large'
              loading={ProgressStore.isLoading(EXPORT_CONTACTS)}
              className='mr-10'
              onClick={exportContacts}>
                {t('contacts.export')}
            </Button> }
            <Link to='/contacts/new'><Button type='primary' size='large'>{t('contacts.addContact')} </Button></Link>
          </Col>
        </Row>
      </Row>

      <Row justify='space-between' gutter={20} className='page-content'>
        <Col span={4}>
          <FilterContacts />
        </Col>
        <Col span={20}>
          <Table
            dataSource={getData()}
            columns={getColumns()}
            bordered
            loading={ProgressStore.isLoading(LOAD_CONTACTS)}
            pagination={paginationProps}
            className='general-table'
          />
        </Col>
      </Row>

    </div>
  );
})

export default List;
