import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { FC, useEffect, useRef } from 'react';
import { FormInstance, Spin } from 'antd';

const CENTER = { lat: 28.2268066312491, lng: -16.6391376609972 };
const ZOOM = 10;

interface IData {
  handleClick?(e: any): void;
  coordinates: {lat: null | number, lng: null | number}
  center: google.maps.LatLngLiteral;
  zoom?: number;
  form?: FormInstance
}

const render = (status: string, props: IData) => {
  switch (status) {
    case Status.LOADING:
      return <Spin />;
    case Status.FAILURE:
      return <div>"Error"</div>;
    case Status.SUCCESS:
      return <MyMapComponent {...props} center={CENTER} zoom={ZOOM} />;
    default:
      return <div />
  }
};


const  MyMapComponent: FC<IData> = ({
  center,
  zoom,
  coordinates,
  form
}) =>  {
  const ref = useRef<HTMLDivElement>(null);
  const prevMarkersRef = useRef<any>([]);

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current as HTMLDivElement, {
      center,
      zoom,
    });

    console.log("🚀 ~ file: MapComponent.tsx:46 ~ useEffect ~ coordinates.lat:", coordinates.lat)
    if(coordinates.lat) {
      const marker = createMarker({ lat: coordinates.lat, lng: coordinates.lng }, map);
      clearMarkers(prevMarkersRef.current);
      prevMarkersRef.current.push(marker);
    }
    if (form) {
      window.google.maps.event.addListener(map, "click", (e: any) => {
        const m = createMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() }, map);
        clearMarkers(prevMarkersRef.current); //clear prev markers
        prevMarkersRef.current.push(m);
        form?.setFieldsValue({ lat: e.latLng.lat(), lng: e.latLng.lng() })
      });
    }
  });

  function clearMarkers(markers: any) {
    for (let m of markers) {
      m.setMap(null);
    }
  }

  function createMarker(position: any, map: any) {
    return new window.google.maps.Marker({
      position: position,
      map: map
    });
  }

  return <div className="w-100p h-100p" ref={ref} id="map" />;
}

const MapWraper: FC<IData> = (props) => {

  return <Wrapper apiKey="AIzaSyAC-ZK_Vs3KY72kCr4zcvQXYqkP3549_HQ" render={(status) => render(status, props)} />

}

export default MapWraper;