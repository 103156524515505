import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { noteService } from 'services/noteService';
import { Form, Button, Input, Select, Row, Col } from 'antd';

import { IContact } from "declarations/contact";
import { Property as IProperty } from "declarations/property";
import { INote } from "declarations/note";
import { some } from 'lodash';
import ownerStore from "stores/owner";
import clientStore from "stores/client";
import { propertyService } from 'modules/properties/PropertyService';

interface IData {
  resource: IContact | IProperty,
  resource_type: string;
  isActivity?: boolean;
  note?: INote,
  onCreateContact?(params: IContact): void;
  onCancelButton(): void;
}
const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const NoteForm: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [state, setState] = useState<any>({
    note: {
      id: null,
      value: '',
      is_public: 'true'
    }
  });

  useEffect(() => {
    const { note } = props;

    if (note && note.id) {
      form.setFieldsValue({...note})
      setState({
        ...state,
        note: {
          ...state.note,
          ...note
        }
      })
    }
    return () => form.resetFields();
  }, [])

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { note } = state;
    const { isActivity } = props;
    form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      const noteValue: INote = { ...note, ...values };
      noteValue['notable_id'] = props.resource.id;
      noteValue['notable_type'] = props.resource_type;
      if (note.id) {
        noteService.updateResource(noteValue, isActivity, props.onCancelButton)
      } else {
        noteService.createResource(noteValue, props.onCancelButton)
        if(props.resource_type === "Owner") {
          ownerStore.fetchOwner(props.resource.id)
        } else if (props.resource_type === "Property") {
          propertyService.loadResource(props.resource.id)
        } else {
          clientStore.fetchClient(props.resource.id)
        }
      }
    }
  }


  const onVisibilityChange = (value: string) => {
    setState({
      ...state,
      note: {
        ...state.note,
        is_public: value
      }
    })
  }

  const renderVisibilityText = () => {
    let text;
    const isPublic = state.note.is_public.toString() == 'true';

    if (isPublic) {
      text = t('publicTextNote');
    } else {
      text = t('privateTextNote');
    }
    return <p className='visibility-text'>{text}</p>;
  }

  const { is_public } = state.note;

  const handleCancel = () => {
    form.resetFields();
    props.onCancelButton();
  }

  return (
    <div className="notes-form">
      <Form form={form}>
        <Form.Item
          {...formItemLayout}
          label={t('tabs.note')}
          rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
          name="value"
        >
          <Input.TextArea placeholder={t('tabs.note')} rows={6} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('emails.visibility')}
        >
          <Select onChange={onVisibilityChange} value={is_public.toString()}>
            <Option value="true">{t('public')}</Option>
            <Option value="false">{t('private')}</Option>
          </Select>
          {renderVisibilityText()}
        </Form.Item>
      </Form>
      <Row>
        <Col span={6} />
        <Col span={18}>
          <div className="form-controls">
            <Button type='primary' className='mr-10' onClick={handleSubmit}>{t('save')}</Button>
            <Button onClick={handleCancel}>{t('cancel')}</Button>
          </div>
        </Col>
      </Row>
    </div>
  );
})

export default NoteForm;
