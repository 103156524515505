import { action } from 'utils/mobx';
import { request } from 'utils/mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import { message } from 'antd';
import rentStore  from 'stores/rent';
import { RENT_URL } from 'constants/api';
import { Filter } from "declarations/filters";
import { IRent } from "declarations/rent";
import contactStore  from 'stores/contact';
import propertyStore  from 'stores/property';
import activityStore  from 'stores/activity';
import { LOAD_RENTS } from 'constants/action';


interface FilterType {
  property_id?: number;
  client_id?: number;
};

interface IResponse {
  status: number,
  data: any
}

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any;
  loadResourcesRequest(queryParams: FilterType): Promise<object>;
}

export class RentService implements Props {

  // LOAD resourceS
  @request()
  async loadResourcesRequest (params?: FilterType): Promise<{ status: number, data: Array<IRent>}> {
    return axios.get(RENT_URL, {
      params
    })
  }

  @action({ action: LOAD_RENTS, minRequestTime: 500 })
  async loadResources(params?: FilterType) {

    const response: { status: number, data: Array<IRent>} = await this.loadResourcesRequest(params);

    if (response.status == 200) {
      let values = [];
      values = response.data;

      this.addValues(values);
    }

    return response;
  }


  @request()
  async createResourceRequest(params: IRent) {
    return axios.post(RENT_URL, { rent:
      params
    })
  }


  async createResource(params: any, filter: any, callback: Function) {

    const response: IResponse = await this.createResourceRequest(params);

    if (response.status == 201) {

      // rentStore.addSelectedAttributes({notes_count: response.data.notes_count})
      callback();
      await this.loadResources(filter);

      if(filter['client_id']) {
        contactStore.addSelectedAttributes({rents_count: rentStore.values.length})
      }
      if(filter['property_id']) {
        propertyStore.addSelectedAttributes({rents_count: rentStore.values.length})
      }

      message.success('Rent created!')
    } else {
      message.error((response.data.message || []).join())
    }

  }

  @request()
  async updateResourceRequest(resource: any) {
    return axios.put(`${RENT_URL}/${resource.id}`, { ...resource })
  }


  async updateResource(resource: Object, callback: Function) {

    const response: { data: any, status: number } = await this.updateResourceRequest(resource);

    if (response.status == 200) {

      this.updateValue(response.data);
      message.success('Rent updated!')
      callback()
    }

  }

  @request()
  async deleteResourceRequest(resource_id: number) {
    return axios.delete(`${RENT_URL}/${resource_id}`)
  }


  async deleteResource(resource: IRent, callback: Function) {
    if(!resource.id) {
      return
    }
    const response: { status: number } = await this.deleteResourceRequest(resource.id);

    if (response.status) {
      message.success('Rent deleted!!')
      this.loadResources({ property_id: resource.property_id });
      callback()
    }

  }

  addValues(values: Array<IRent>) {
    rentStore.addValues(values);
  }
  updateValue(value: IRent) {
    rentStore.updateValue(value);
  }
  updateActivityValue(value: IRent) {
    activityStore.updateValue(value);
  }
}

export const rentService = new RentService();
