import { FC } from 'react';
import { observer } from 'mobx-react';
import AppLayout from "modules/clients/containers/Single/components/Layout";
import clientStore from 'stores/client';
import Notes from 'submodules/notes/containers/index';


const ClientNotes: FC = observer(() => {
  const client: any = clientStore.selected;

  return (
    <AppLayout>
      <Notes resource_type='Client' resource={client} />
    </AppLayout>
  );
})

export default ClientNotes;
