import { makeAutoObservable } from 'mobx';

import { IDeal } from 'declarations/deal';
interface IFilterKeys {
  operations?: any,
  statuses?: any,
  budget_min?: number,
  budget_max?: number,
  manager_ids?: any,
  property_status?: any,
  change_status_date?: any,
  creation_date?: any
}

export interface IOwnerList {
  values: IDeal
  // debouncedSetToken(token: string): any
}

export class Deal {
  values: Array<IDeal> = [];
  selected: {} | IDeal = {};
  count: number = 0;
  page: number | 'all' = 1;
  fetchInProgress = false;
  saveInProgress = false;
  deleteInProgress = false;
  exportInProgress = false;

  filterKeys: IFilterKeys = {
    operations: [],
    statuses: [],
    budget_min: undefined,
    budget_max: undefined,
    manager_ids: [],
    property_status: [],
    change_status_date: null,
    creation_date: null
  }

  filters: Object = {};

  constructor() {
    makeAutoObservable(this);
  }


  addValues(values: Array<IDeal>, page: number | 'all', count: number, queryParams: Object) {
    this.values = values;
    this.page = page;
    this.count = count;
  }

  addSelectedValue(value: IDeal): void {
    this.selected = value;
  }

  updateValue(deal: IDeal) {
    this.selected = deal
  }

  clearData(): void {
    this.selected = {};
    this.values = [];
  }

  changeValue(value: IDeal) {
    this.values = this.values.map((item: IDeal) => item.id == value.id ? value : item)
  }

  updateFilter(newFilter: {}) {
    this.filterKeys = {...this.filterKeys, ...newFilter}
  }

  resetFilter() {
    this.filterKeys = {
      operations: [],
      statuses: [],
      budget_min: undefined,
      budget_max: undefined,
      manager_ids: [],
      property_status: null,
      change_status_date: null,
      creation_date: null
    }
  }

  setFetchInProgress(val: boolean) {
    this.fetchInProgress = val;
  }
  setSaveInProgress(val: boolean) {
    this.saveInProgress = val;
  }
  setDeleteInProgress(val: boolean) {
    this.deleteInProgress = val;
  }

  setExportInProgress(val: boolean) {
    this.exportInProgress = val;
  }

}

export default (new Deal())

