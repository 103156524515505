
export const ROLES = {
  'admin': 0,
  'sales': 1,
  'secretary': 2,
  'accountant': 3,
  'lawyer': 4
}

export const SALE_VALUE = '0';
export const RENT_VALUE = '1';
export const TRASPASO_VALUE = '2';
export const FIXED_PRICE_VALUE = 0;
export const PERCENTAGE_PRICE_VALUE = 1;
export const COMISSION_INCLUDED_VALUE = 0;
export const COMISSION_NOT_INCLUDED_VALUE = 1;


export const MAP_MARKER_URL = 'https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png';
// export const MAP_MARKER_URL = marker;
// export const MAP_API_KEY = 'AIzaSyAaN_52WDM-1gclp7QYFXS7UuX8YKthjOg';
export const MAP_API_KEY = 'AIzaSyAC-ZK_Vs3KY72kCr4zcvQXYqkP3549_HQ';
// export const MAP_API_KEY = 'AIzaSyB1yCKvvsD4LV2VoVGCmX-pJZsSjXGuPKg';

export const COMISSION_TYPE_SYMBOLS: any = {
  '0': '€',
  '1': '%'
}

export const TYPE_OPERATIONS: any = {
  '0': 'Sale',
  '1': 'Rent',
  '2': 'Traspaso'
}

export const DEAL_STATUS_FROM_OPERATION: any = {
  '0': {'0': 'Active', '1': 'Sold', '2': 'Canceled', '3': 'Passive', '4': 'Reserved'},
  '1': {'0': 'Active', '1': 'Rented', '2': 'Canceled', '3': 'Passive', '4': 'Reserved'}
}

export const DEAL_STATUS_COLOR: any = {
  '0': '#36a3f7',
  '1': '#34bfa3',
  '2': '#ff0000',
  '3': '#9e9e9e',
  '4': '#ff9f1a'
}
export const PRICE_STATUS_COLOR: any = {
  'available': '#4CAF50',
  'is_available': '#4CAF50',
  'is_reserved': '#9E9E9E',
  'reserved': '#9E9E9E',
  'sold': '#FF9800',
  'rented': '#FF9800'
}

export const  DEAL_STATUSES: any = {
  '0': ['Active', 'Sold', 'Canceled', 'Passive', 'Reserved'],
  '1': ['Active', 'Rented', 'Canceled', 'Passive', 'Reserved']
}
export const WITH_COMISSION_VALUES: any = {
  '0': 'included',
  '1': 'not included'
}

export const FURNITURE_VALUES: any = {
  0: 'Full',
  1: 'Partial',
  2: 'No'
}

export const VIEW_VALUES: any = {
  "0": 'Ocean',
  "1": 'Mountains',
  "2": 'Swimming Pool',
  "3": "Sea"
}

export const CONTACT_TYPES: any = {
  '0': 'Person',
  '1': 'Company',
  '2': 'Agency'
}

export const RENT_DEALS_BOARD_COLUMNS = [
  {
    name: 'Shortlist',
    key: 'shortlist'
  },
  {
    name: 'Email',
    key: 'email',
  },
  {
    name: 'Sent',
    key: 'sent',
  },
  {
    name: 'Discussion',
    key: 'discussion'
  },
  {
    name: 'Show',
    key: 'show'
  },
  {
    name: 'Contract (Signed)',
    key: 'contract'
  },
  {
    name: 'Rejected',
    key: 'rejected'
  }
]

export const SALE_DEALS_BOARD_COLUMNS = [
  {
    name: 'Shortlist',
    key: 'shortlist'
  },
  {
    name: 'Email',
    key: 'email',
  },
  {
    name: 'Sent',
    key: 'sent',
  },
  {
    name: 'Discussion',
    key: 'discussion'
  },
  {
    name: 'Show',
    key: 'show'
  },
  {
    name: 'Reservation',
    key: 'reservation'
  },
  {
    name: 'Contract (Signed)',
    key: 'contract'
  },
  {
    name: 'Rejected',
    key: 'rejected'
  }
]

export const SALE_STATUSES: any = ['is_available', 'is_reserved', 'sold']
export const RENT_STATUSES: any = ['is_available', 'is_reserved', 'rented']

export const FILE_TYPES_ICONS: any = {
  xls: 'xls.png',
  xlsx: 'xls.png',
  zip: 'archive.png',
  rar: 'archive.png',
  avi: 'video.png',
  mp4: 'video.png',
  doc: 'doc.png',
  docx: 'doc.png',
  pdf: 'pdf.png',
  csv: 'csv.png',
  jpg: 'jpg.png',
  jpeg: 'jpg.png',
  png: 'png.png',
}

export const LANGUAGES = ['en', 'ru', 'es', 'it', 'de', 'pl', 'fr', 'nl', 'uk', 'zh-hans']

export const COLORS: any = {
  1: '#716aca', // purple
  2: '#34bfa3',  // green-sea
  3: '#FFA500',  // orange
  4: '#36a3f7',  // blue
  5: '#c4c5d6'  // grey
}

export const DISTANCE_TO_OCEAN_VALUE: any = {
  'less_100': 'less than 100 m',
  '300': '300 m',
  '500': '500 m',
  '800': '800 m',
  '1000': '1000 m',
  '1500': '1500 m',
  '2000': '2000 m',
  'more_2000': 'more than 2000 m'
}

export const CONDITION_VALUE: any = {
  new_building: 'New Building',
  off_plan: 'Off-plan',
  resale: 'Resale'
}

// export const EDITOR_OPTIONS =  {
//   menubar:false,
//   // statusbar: false,
//   // plugins: 'link image imagetools code lists',
//   // toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright| bullist numlist | link | formatselect fontsizeselect',
//   plugins: [
//     'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
//     'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
//     'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
//   ],
//   toolbar: 'undo redo | blocks | ' +
//     'bold italic forecolor | alignleft aligncenter ' +
//     'alignright alignjustify | bullist numlist outdent indent | ' +
//     'removeformat | help',
//   // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
//   // branding: true,
//   skin: 'jam',
//   icons: 'jam',
//   content_style: '#tinymce p { padding: 0; margin: 2px 0; }',
//   height: 350
// }

export const EDITOR_OPTIONS =  {
  menubar:false,
  // statusbar: false,
  plugins: 'link image imagetools code lists',
  toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright| bullist numlist | link | blocks fontsize',
  // branding: true,
  skin: 'jam',
  icons: 'jam',
  content_style: '#tinymce p { padding: 0; margin: 2px 0; }',
  height: 350
}

export const SOURCE_CATEGORIES =  ['office', 'website', 'portal', 'other', 'inmobiliarias']

export const GIFT_TYPES = ['no','laptop','alarm','tv','notary','dinner','cashback']
export const REGEXP_EMAIL = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;