import { request, action } from 'utils/mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import { message as antMessage, notification } from 'antd';
import requestStore  from 'stores/request';
import { REQUESTS_URL } from 'constants/api';
import { LOAD_REQUESTS } from 'constants/action';
import { Filter } from "declarations/filters";
import { IRequest } from "declarations/request";
import {EXPORT_REQUESTS, CREATE_REQUEST} from 'constants/action'
import { saveAs } from 'file-saver';
import workspaceStore from "stores/workspace";


interface FilterType {

};

interface IResponse {
  status: number,
  data: { message?: Array<string>, requests: Array<IRequest>, count: number }
}

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any;
  loadResourcesRequest(queryParams: {}): Promise<object>;
}

notification.config({
  placement: 'topRight',
  duration: 0
})


export class RequestService implements Props {

  // LOAD resourceS
  async loadResourcesRequest (params: FilterType | {}){
    return axios.get(REQUESTS_URL, {
      params
    })
  }

  @action({ action: LOAD_REQUESTS, minRequestTime: 500 })
  async loadResources(params: FilterType = {}, pageNum: number | 'all' = 1) {
    let page = pageNum;

    const queryParams = {
      ...params,
      workspace_id: workspaceStore.selectedId,
      page
    };

    const response: IResponse = await this.loadResourcesRequest(queryParams);

    if (response.status == 200) {
      let values = response.data.requests;
      this.addValues(values, pageNum, response.data.count, queryParams);
    }

    return response;
  }

  async resetFilters () {
    requestStore.resetFilter();
    this.loadResources({});
  }

  async changeFilters (newFilter: {}) {
    requestStore.updateFilter(newFilter);
    requestService.loadResources(requestStore.filterKeys);
  }


  async createResourceRequest(params: IRequest) {
    return axios.post(REQUESTS_URL, params)
  }

  @action({ action: CREATE_REQUEST, minRequestTime: 500 })
  async createResource(params: any, callback: Function, message: any, filter: FilterType = {}) {

    try {
      const response: IResponse = await this.createResourceRequest({...params, workspace_id: workspaceStore.selectedId });
      if (response.status == 201) {
        this.loadResources(filter);
        message.success('Request created!')
        callback();
      }
    } catch (e: any) {
      message.error((e.response.data.message || []).join())
    }
  }

  async updateResourceRequest(resource: any) {
    return axios.put(`${REQUESTS_URL}/${resource.id}`, { ...resource })
  }


  async updateResource(resource: Object, callback: Function) {

    const response: { data: any, status: number } = await this.updateResourceRequest(resource);

    if (response.status == 200) {
      this.updateValue(response.data)
      callback()
    }

  }

  async deleteResourceRequest(request_id: number) {
    return axios.delete(`${REQUESTS_URL}/${request_id}`)
  }


  async deleteResource(request_id: number, callback: Function) {

    const response: { status: number } = await this.deleteResourceRequest(request_id);

    if (response.status == 403) {
      notification['error']({
        message: "You don't have access for this operation",
        placement: 'topRight',
        duration: 0,
      });
      callback();
      // this.loadResources();
      return;
    }

    if (response.status == 200) {
      antMessage.success('Request deleted!!')
      this.loadResources(requestStore.filterKeys, requestStore.page)
      callback()
    }

  }

  // LOAD resourceS
  async exportRequest (params: FilterType | {}){
    return axios.get(`${REQUESTS_URL}/export`, {
      params
    })
  }

  @action({ action: EXPORT_REQUESTS, minRequestTime: 500 })
  async exportResources(params: FilterType = {}, pageNum: number | 'all' = 1) {
    let page = pageNum;

    const queryParams = {
      ...params,
      workspace_id: workspaceStore.selectedId,
      page
    };

    const response: any = await this.exportRequest(queryParams);

    if (response.status == 200) {
      const blob = new Blob([response.data])
      saveAs(blob, 'requests.csv')
    }

    return response;
  }

  updateValue(request: IRequest) {
    requestStore.updateValue(request)
  }

  addValues(values: Array<IRequest>, page: number | 'all', count: number, queryParams: {}) {
    requestStore.addValues(values, page, count, queryParams);
  }

}

export const requestService = new RequestService();
