import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {  Row, Tooltip } from 'antd';
import { name } from 'utils/localized';
import { Property as IProperty } from 'declarations/property';
import { IDealsProperty } from 'declarations/dealsProperty';
import { SALE_VALUE, RENT_VALUE } from 'constants/mixed';
import { useTranslation } from 'react-i18next';
import { BookOutlined, GlobalOutlined } from "@ant-design/icons";

import LocaleStore from 'stores/locale';
import { IDeal } from 'declarations/deal';
export function Item(props: any) {
  const { id } = props;

  const style = {
    width: "100%",
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid black",
    margin: "10px 0",
    background: "white"
  };

  return <div style={style}>{id}</div>;
}

interface IProps {
  dealProperty: IDealsProperty;
  showModal: (property: IDealsProperty | null, isRejected?: boolean) => void;
  deal: IDeal;
  id: number;
}

export default function SortableItem({ id, deal, dealProperty, showModal}: IProps) {
  // console.log("🚀 ~ file: BoardItem.tsx:38 ~ SortableItem ~ dealProperty:", dealProperty)
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id });
  const { t } = useTranslation();
  const lng = LocaleStore.locale;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  const property = dealProperty.property;
  const styleBlock = {
    padding: "10px",
    marginBottom: "10px",
    background: "white"
  };

  const getUrl = (property: IProperty) => {
    const urls: any = property.tenerife_url;
    if(!urls) return '';
    return deal.operation.toString() == RENT_VALUE  ? urls['rent_url'] : urls['sale_url']
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div style={styleBlock}>
        <Row>
          <div className='card-property-name' onClick={() => showModal(dealProperty)}>
            {name(property, lng)}
          </div>
        </Row>
        <Row justify='space-between' align='middle' className='mt-5'>
          <span className='property-identifier'>
            {deal.operation == SALE_VALUE ? 'VS' : 'VR'}{property.identifier}
          </span>
          <span className='price-property'>{property.price_attributes.amount}€</span>
        </Row>
        <Row className='mt-5'>
          {!!dealProperty.notes && <BookOutlined className='mr-5' />}
          {!!getUrl(property) && <Tooltip placement="top" title={t('deals.publishedOn')}><GlobalOutlined /></Tooltip>}
        </Row>
      </div>
    </div>
  );
}
