import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import New from '../../components/New';
import { userListService } from '../UserListService';
import { userStore } from 'stores';
import loginStore from 'modules/login/loginStore';
import { Table, Button, Menu, Modal, Dropdown, Row, Col, Tag } from 'antd';
import { Helmet } from 'react-helmet';
import { IUser } from 'declarations/user';
import { EditOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import workspaceStore from 'stores/workspace';
import localeStore from 'stores/locale';
import { IWorkspace } from 'workspace';

interface IData {
  key: number,
  properties: [] | null,
  type: string | null
}

const COLUMNS: Array<string> = ['id', 'first_name', 'last_name', 'email', 'active', 'last_visit_at', 'created_at'];
const confirm = Modal.confirm;

const List: FC = observer(() => {
  const [editUser, setEditUser] = useState<undefined | IUser | null>()
  const { t } = useTranslation();
  const lng = localeStore.locale;

  useEffect(() => {
    userListService.loadResources()
  }, [])

  console.log("🚀 ~ file: index.tsx:79 ~ constList:FC=observer ~ workspaceStore.values:", workspaceStore.values)

  const showModal = (user?: IUser | null) => setEditUser(user);

  const handleOk = (user: IUser) => {
    const currentUser = loginStore.session.user.user;

    if (user.id) {
      userListService.updateResource(user, (resp: IUser) => setEditUser(undefined))
      // if(currentUser.id == resp.id) {
      //   loginStore.updateCurrentUser(resp)
      // }
    } else {
      userListService.createResource(user, () => setEditUser(undefined))
    }
  }

  const handleCancel = () => setEditUser(undefined);

  const columns = [
    {
      title: t('properties.table.id'),
      dataIndex: 'id',
      key: 'id'

    }, {
      title: t('login.new.firstName'),
      dataIndex: 'first_name',
      key: 'first_name'

    }, {
      title: t('login.new.lastName'),
      dataIndex: 'last_name',
      key: 'last_name'
    }, {
      title: t('tabs.email'),
      dataIndex: 'email',
      key: 'email',
    }, {
      title: t('users.userRole'),
      dataIndex: 'role',
      key: 'role',
      render: (role: string) => t(`users.role.${role}`)
    },{
      title: t('users.workspaces'),
      dataIndex: 'workspaces',
      key: 'workspaces',
      render: (workspaces: IWorkspace[]) => {
        console.log("🚀 ~ file: index.tsx:82 ~ constList:FC=observer ~ workspaces:", workspaces)
        return (workspaces || []).map((w) => <Tag>{w.name[lng || "en"]}</Tag>)
      }
    }, {
      title: t('properties.table.status'),
      dataIndex: 'active',
      key: 'active',
      render: (status: boolean) => (
      <Tag color={status ? "green" : "gray"}>
          {Boolean(status) ? t("properties.new.status.active") : t("properties.new.status.inactive")}
        </Tag>
      )
    }, {
      title: t('users.lastVisit'),
      dataIndex: 'last_visit_at',
      key: 'last_visit_at',
      render: (data: string) => renderDate(data),
    }, {
      title: t('deals.filter.creationDate'),
      dataIndex: 'created_at',
      key: 'created_at',
    }, {
      title: '',
      dataIndex: 'actions',
      key: 'id',
      render: (_: unknown, user: IUser) => <Dropdown overlay={getActionComponent(user)} trigger={['click']}>
      <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
    </Dropdown>
    }
  ];

  const renderDate = (data: string) => {
    if (!data) {
      return '';
    }

    const createdAtDate = moment(data).format('DD.MM.YYYY');
    const createdAtTime = moment(data).format('\\at HH:mm');
    return <div>{createdAtDate} <p>{createdAtTime}</p> </div>
  }

  const showConfirm = (user: IUser) => {
    confirm({
      title: t('users.confirmDelete'),
      content: '',
      onOk: async () => {
        user.id && userListService.deleteResource(user.id)
      },
      onCancel() { },
    });
  }

  const getActionComponent = (user: IUser) => {

    return (
      <Menu
        mode="horizontal"
        defaultSelectedKeys={['2']}
        className='actons-menu'
      >
        <Menu.Item key={`edit_user_${user.id}`} onClick={() => showModal(user)}>
          <span className='edit-title'>
            <EditOutlined className='mr-5' />
            {t('owners.table.edit')}
          </span>
        </Menu.Item>
        <Menu.Item key={`delete_user_${user.id}`} onClick={() => showConfirm(user)}>
          <span className='delete-title'>
            <DeleteOutlined className='mr-5' />
            {t('owners.table.delete')}
          </span>
        </Menu.Item>
      </Menu>
    )
  }

  const renderUserModal = () => {
    return <New handleOk={handleOk} handleCancel={handleCancel} user={editUser} />
  }

  const changePagination = (page: number) => {
    userListService.loadResources({}, page);
  }

  const paginationProps = {
    pageSize: 10, onChange: changePagination, total: userStore.count, showSizeChanger: false
  }
  return (
    <div className="users-page page">
      <Helmet>
        <title>{t("menu.users")}</title>
      </Helmet>
      <Modal
        title={t(`users.${editUser?.id ? "editUser" : "addUser"}`)}
        visible={!!editUser || editUser === null}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
        className='modal'
        maskClosable={false}
      >
        {renderUserModal()}
      </Modal>

      <Row align='middle' justify='space-between' className='page-subheader'>
        <h3>{t('menu.users')} ({userStore.count})</h3>
        <Button type='primary' size='large' onClick={() => showModal(null)}>{t('users.addUser')}</Button>
      </Row>

      <div className='page-content'>
        <Table
          dataSource={userStore.values}
          columns={columns}
          className='general-table'
          bordered
          pagination={paginationProps}
        />
      </div>
    </div>
  );
});

export default List;
