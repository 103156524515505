import { FC, PropsWithChildren, useEffect } from 'react';
import { observer } from 'mobx-react';
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom'
import { Layout, Menu, Avatar, Dropdown, Row, Col, MenuProps, Space, Spin } from 'antd';
import  LoginService from '../../login/loginService';
import LocaleStore from 'stores/locale';
import { DownOutlined, UserOutlined, GlobalOutlined, TeamOutlined, HomeOutlined, ShoppingOutlined, FileAddOutlined, PieChartOutlined} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

import logo from 'images/vym-logo.png';
import LoginStore from 'modules/login/loginStore';
import workspaceStore from 'stores/workspace';
import { get } from 'lodash';

const { Header, Content } = Layout;
let UserLoginService = new LoginService();


const Main: FC<PropsWithChildren> = observer(({ children }) => {
  const { t } = useTranslation();
  const user = LoginStore.session.user.user;
  const navigate = useNavigate();
  const location = useLocation();
  console.log("🚀 ~ file: Main.tsx:24 ~ constMain:FC<PropsWithChildren>=observer ~ location:", location)

  const logout = async () => {
    UserLoginService.logout();
    window.location.replace('/');
  }

  const changeLocale = (lang: string) => {
    LocaleStore.setLocale(lang)
  }

  useEffect(() => {
    (async () => {
      await workspaceStore.fetchWorkspaces();
    })()
  }, [])

  const userMenuItems: MenuProps['items'] = [
    {
      key: 'propfile-item',
      label: <Link to='/profile'>{t('myProfile')}</Link>,
    },
    {
      key: 'users-item',
      label: <Link to='/users'>{t('menu.users')}</Link>,
      disabled: !LoginStore.isAdmin
    },
    {
      key: 'complexes-item',
      label: <Link to='/complexes'>{t('properties.new.complexes')}</Link>,
      disabled: !(LoginStore.isAdmin || LoginStore.isGeneralManager)
    },
    {
      key: 'sources-item',
      label: <Link to='/sources'>{t('sources.header')}</Link>,
      disabled: !(LoginStore.isAdmin || LoginStore.isGeneralManager)
    },
    {
      key: 'logout-item',
      label: <div onClick={logout}>{ t('menu.logout') }</div>,
    },
  ];

  const languageMenuItems: MenuProps['items'] = [
    {
      key: 'en',
      label: <div onClick={() => changeLocale('en') }><img src={require(`../../../images/flags/en.png`)} width='24px' className='mr-10 d-ib' /> {t('contacts.new.lng.en')}</div>,
    },
    {
      key: 'ru',
      label: <div onClick={() => changeLocale('ru') } key="ru3"><img src={require(`../../../images/flags/ru.png`)} width='24px' className='mr-10 d-ib' /> {t('contacts.new.lng.ru')}</div>,
    },
    {
      key: 'es',
      label: <div onClick={() => changeLocale('es') } key="sp4"><img src={require(`../../../images/flags/es.png`)} width='24px' className='mr-10 d-ib' /> {t('contacts.new.lng.es')}</div>,
    },
  ];

  const actionMenuItems: MenuProps['items'] = [
    {
      key: 'sale-report',
      label: <Link className='edit-title' to={`/report/sale`}>{t('properties.table.sale')}</Link>,
    },
    {
      key: 'request-report',
      label: <Link className='edit-title' to={`/report/requests`}>{t('requests.header')}</Link>,
      disabled: !(LoginStore.isAdmin || LoginStore.isGeneralManager)
    },
    {
      key: 'emails-report',
      label: <Link className='edit-title' to={`/report/emails`}>{t('tabs.emails')}</Link>,
      disabled: !(LoginStore.isAdmin || LoginStore.isGeneralManager)
    },
  ];

  const getWorkspaceName = () => {
    return get(workspaceStore.values.find((w) => w.id === workspaceStore.selectedId), `name[${LocaleStore.locale}]`);
  }

  const onWorkspaceChange = async (workspaceId: number) => {
    await workspaceStore.setSelected(workspaceId);
    navigate(location.pathname.includes("report") ? location.pathname : get(location.pathname.split("/"), [1]))
  }


  const workspaceMenuItems = () => {
    if (!user) {
      return [];
    }

    return workspaceStore.values.map((workspace) => {
      return {
        key: `workspace-${workspace.id}`,
        label: <span>
          {get(workspace.name, String(LocaleStore.locale))}
        </span>,
        onClick: () => onWorkspaceChange(workspace.id)
      }
    })
  }

  return (
    <Layout className="layout">
      <Header className='main-header'>
        <Row justify='space-between' align='middle'>
          <Col span={18} className='nav-block'>
            <Row align='middle' justify="start" className='mr-20'>
              <Col className='height-40 px-20 mr-20'>
                <Link to='/' ><img alt="logo" width={65} src={logo} /></Link>
              </Col>
              <Col>
                <Row gutter={20}>
                  {/* <Col className="nav-block">
                    <NavLink className={`nav-item ${window.location.pathname === "/" ? "active": ""}`} to='/contacts' >
                      <Row justify="start" align="middle">
                        <TeamOutlined className='mr-5' />
                        <span>{ t('menu.contacts') }</span>
                      </Row>
                    </NavLink>
                  </Col> */}
                  <Col className="nav-block">
                    <NavLink className={`nav-item ${window.location.pathname === "/" ? "active": ""}`} to='/clients' >
                      <Row justify="start" align="middle">
                        <TeamOutlined className='mr-5' />
                        <span>{ t('menu.clients') }</span>
                      </Row>
                    </NavLink>
                  </Col>
                  <Col className="nav-block">
                    <NavLink className="nav-item" to='/owners' >
                      <Row justify="start" align="middle">
                        <TeamOutlined className='mr-5' />
                        <span>{ t('menu.owners') }</span>
                      </Row>
                    </NavLink>
                  </Col>
                  <Col className="nav-block">
                    <NavLink className='nav-item' to='/properties'>
                      <Row justify="start" align="middle">
                        <HomeOutlined className='mr-5' />
                        { t('menu.objects') }
                      </Row>
                    </NavLink>
                  </Col>
                  <Col className="nav-block">
                    <NavLink className='nav-item' to='/deals'>
                      <Row justify="start" align="middle">
                        <ShoppingOutlined className='mr-5' />
                        { t('menu.deals') }
                      </Row>
                    </NavLink>
                  </Col>
                  <Col className="nav-block">
                    <NavLink className='nav-item' to='/requests'>
                      <Row justify="start" align="middle">
                        <FileAddOutlined className='mr-5' />
                        { t('menu.requests') }
                      </Row>
                    </NavLink>
                  </Col>
                  {(LoginStore.isAdmin || LoginStore.isGeneralManager) && <Col className="nav-block">
                    <Dropdown className='nav-item' menu={{ items: actionMenuItems.filter((item: any) => !item.disabled)}} trigger={['click']}>
                      <Row justify="start" align="middle">
                        <PieChartOutlined className='mr-5' />
                        { t('menu.reports') } <DownOutlined className='icon m-a ml-10' />
                      </Row>
                    </Dropdown>
                  </Col>}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
          <Space size={20} className='text-white'>
              <Spin spinning={workspaceStore.loading}>
                <Dropdown menu={{ items: workspaceMenuItems() }} trigger={['click']}>
                  <span className='text-white cr-p'>
                    {getWorkspaceName()}
                  </span>
                </Dropdown>
              </Spin>
              <Dropdown menu={{ items: userMenuItems.filter((item: any) => !item.disabled) }} trigger={['click']}>
                <span className='text-white cr-p'>
                  <Avatar size="small" icon={<UserOutlined />} className='mr-5'/> {user && user.name}
                </span>
              </Dropdown>
              <Dropdown menu={{ items: languageMenuItems, defaultSelectedKeys: ['1']}} trigger={['click']}>
                <GlobalOutlined />
              </Dropdown>
          </Space>
          </Col>
        </Row>
      </Header>
      <Content className='main-content'>
        { children }
      </Content>
    </Layout>
  );
})

export default Main;
