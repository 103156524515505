import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Row, Col} from 'antd';
import ActivityStore from 'stores/activity';
import contactStore from 'stores/contact';
import ContactDetails from '../components/ContactDetails';
import ContactForm from 'modules/contacts/components/New/Form'
import ActivityList from 'submodules/contacts/components/ActivityList';
import AppLayout from "modules/contacts/containers/Single/components/Layout";
import { EditOutlined } from "@ant-design/icons";

const Overview: FC = observer(() => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const contact: any = contactStore.selected;


  useEffect(() =>  {

    return () => ActivityStore.clearValues()
  }, [])

  const editContact = () => setIsEdit(true);

  const cancelEdit = () => setIsEdit(false);

  return (
    <AppLayout>
      <div className="tab">
        <Row justify='space-between' align='top'>
          <Col span={8} className='portlet'>
            <Row justify='space-between' className='portlet-head'>
              <h5>{t('contacts.contactDetail')}</h5>
              <EditOutlined className='cursor-pointer' onClick={editContact} />
            </Row>

            <div className='portlet-body'>
              {isEdit ? <ContactForm contact={contact} onCancelButton={cancelEdit} /> : <ContactDetails contact={contact} />}
            </div>
          </Col>

          <Col span={15}>
            <ActivityList contact={contact} />
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
})

export default Overview;
