import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { activityService } from 'services/activityService';
import ActivityStore from 'stores/activity';
import ProgressStore from 'stores/progress';
import Note from 'submodules/notes/components/Item';
import Task from 'submodules/tasks/components/Item';
import Attachment from 'submodules/files/components/Item';
import Price from 'submodules/properties/components/Price';
import { Row, Col, Timeline, Spin } from 'antd';
import { LOAD_ACTIVITIES } from 'constants/action';
import { PlusSquareOutlined } from "@ant-design/icons";

import { Property as IProperty } from "declarations/property";

const ItemTypes: any = {
  Note: Note,
  Task: Task,
  Attachment: Attachment
}

interface IData {
  property: IProperty;
}

const ActivityList: FC<IData> = observer(({property}) => {
  const { t } = useTranslation();

  useEffect(() => {
    activityService.loadResources('Property', property.id);
    return () => ActivityStore.clearValues();
  }, [])


  //NEED TO BE REFACTORED
  const renderActivities = () => {
    const activities = ActivityStore.values
    let options: any = { resource: property, resource_type: 'Property', isActivity: true }
    if (!(activities && activities.length)) {
      return null;
    }
    return activities.map((item: any) => {
      if (item['price']) {
        return <Timeline.Item key={`timeline_price`}><Price property={property} price={item['price']} /></Timeline.Item>
      } else {
        const ItemType = ItemTypes[item.class_name];

        if (!ItemType) {
          return null;
        }

        options[item.class_name.toLowerCase()] = item;
        options['id'] = item.id;
        return <Timeline.Item key={`timeline_${ItemType}_${item.id}`}><ItemType {...options} /></Timeline.Item>
      }
    })
  }

  const createdDate = moment(property.created_at).format('D MMMM YYYY [at] HH:mm');

  if(ProgressStore.isLoading(LOAD_ACTIVITIES)) {
    return <Spin />
  }

  return (
    <Timeline className='timeline'>
      {renderActivities()}
      <Timeline.Item key={`timeline_created`}>
        <Row className='contact-card-item'>
          <Row className='contact-card-header' justify="space-between">
            <Col>
              <Row justify="start" align='middle'>
                <Col>
                  <PlusSquareOutlined className='mr-10 fs-35' />
                </Col>
                <Col>
                  <p>{t('properties.propertyCreated')}</p>
                  <span>{createdDate}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Timeline.Item>
    </Timeline>
  );
})

export default ActivityList;
