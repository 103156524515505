import { FC } from 'react';
import { observer } from 'mobx-react';
import AppLayout from "modules/clients/containers/Single/components/Layout";
import clientStore from 'stores/client';
import Tasks from 'submodules/tasks/containers/index';


const ClientTasks: FC = observer(() => {
  const client: any = clientStore.selected;

  return (
    <AppLayout>
      <Tasks resource_type='Client' resource={client} />
    </AppLayout>
  );
})

export default ClientTasks;
