import { FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Row } from 'antd';
import { CONTACT_TYPES } from 'constants/mixed';
import { IContact } from 'contact';

interface IData {
  contact: IContact,
}

const CONTACT_PERSON_VALUE = 0;

const ContactDetails: FC<IData> = observer(({ contact }) => {

  const { t } = useTranslation();

  const renderLanguages = (languages: Array<string>) => {
    if(!languages || languages.length == 0) {
      return '-';
    }

    return (languages || []).map((language: string) => <img src={require(`../../../images/flags/${language}.png`)} width='24px' className='mr-10 flag' />)
  }

  const renderContactAttributes = (attributes: any) => {
    if(attributes.length == 0) {
      return '-';
    }

    return attributes.map((attribute: {value: string}) => <p>{attribute.value || '-'}</p>)
  }

    const isNotPerson = contact.contact_type != CONTACT_PERSON_VALUE;

    if(!contact) {
      return null
    }

    const createdAtDate = moment(contact.created_at).format('DD.MM.YYYY [at] HH:mm');
    const creatorName = contact.creator && contact.creator.name || '-'


  return (
    <div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.table.type')}</span>
        <p>{ CONTACT_TYPES[contact.contact_type.toString()] }</p>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.table.name')}</span>
        <p>{contact.name}</p>
      </div>
      { isNotPerson &&
        <div className="mb-20">
          <span className='contact-detail-title'>{t('contacts.new.website')}</span>
          <p>{contact.website || '-'}</p>
        </div>
      }
      { isNotPerson &&
        <div className="mb-20">
          <span className='contact-detail-title'>{t('contacts.new.address')}</span>
          <p>{contact.address || '-'}</p>
        </div>
      }
      { isNotPerson &&
        <div className="mb-20">
          <span className='contact-detail-title'>{t('contacts.new.contactPerson')}</span>
          <p>{contact.contact_person || '-'}</p>
        </div>
      }
      <div className="mb-20">
        <span className='contact-detail-title'>{t('tabs.email')}</span>
        <div className='contacts-attributes'>
          {renderContactAttributes(contact.emails_attributes)}
        </div>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('owners.table.phone')}</span>
        <div className='contacts-attributes'>
          {renderContactAttributes(contact.phones_attributes)}
        </div>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.new.mainLang')}</span>
        <p>{renderLanguages(contact.languages)}</p>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.new.otherLang')}</span>
        <p>{renderLanguages(contact.other_languages)}</p>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.new.nie')}</span>
        <p>{contact.nie || '-'}</p>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.new.source')}</span>
        <p>{contact.source && contact.source.name}</p>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.single.added')}</span>
        <p>{createdAtDate} <span className='fw-300'>by</span> {creatorName}</p>
      </div>
    </div>
  )
})

export default ContactDetails;
