import * as React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../modules/login/containers/Login';
// import Reset from '../modules/login/containers/Reset';
import Choose from '../modules/login/containers/Choose';

type Props = {}

const Guest = ({}: Props) => {
  return (
    <React.Fragment>
      <Routes>
        {/* <Route component={Reset} path='/reset' exact /> */}
        <Route element={<Choose />} path='/set_passwords' />
        <Route element={<Login />} path='/' />
        <Route path='*' element={<Navigate replace to="/" />}  />
      </Routes>
    </React.Fragment>
  );
}

export default Guest;