
export const name = (object: any, locale: any) => {
  if(locale == 'en-US') {
    locale = 'en'
  }
  if(!object) {
    return '';
  }
  const name: any = object.name;

  if(!name) {
    return '';
  }
  return name[locale];
}
