import { IPropertyCharacteristic } from 'declarations/propertyCharacteristic';
import { groupBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

export class PropertyCharacteristicStore {

  values: Array<IPropertyCharacteristic> = [];
  groupedValues: any = {};
  loading = false;
  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: Array<IPropertyCharacteristic>): void {
    this.values = values;
    this.groupedValues = groupBy(values, 'resource_type')
  }

  setLoading(val: boolean) {
    this.loading = val;
  }

}

export default (new PropertyCharacteristicStore())