import { FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Row, Col, Tag } from 'antd';
import { propertyService } from 'modules/properties/PropertyService';
import { TYPE_OPERATIONS } from 'constants/mixed';
import LocaleStore from 'stores/locale';
import { name } from 'utils/localized';
import { FileDoneOutlined, DownloadOutlined } from "@ant-design/icons";


interface IData {
  id: number,
  property: any,
}

const STATUS_COLORS: any = {
  published: '#4CAF50',
  draft: 'gray',
  archive: '#ff0000'
}

const Summary: FC<IData> = observer((props) => {
  const { t } =useTranslation();
  const lng = LocaleStore.locale;

  const downloadDocx = () => {
    const { property } = props;
    propertyService.downloadDocResource(property)
  }

  const { property } = props;
  const createdAtDate = moment(property.created_at).format('DD.MM.YYYY [at] HH:mm');
  const creatorName = property.creator && property.creator.name || '-'
  const updatedAtDate = moment(property.updated_at).format('DD.MM.YYYY [at] HH:mm');
  const editorName = property.editor && property.editor.name || '-'

  return (
    <div className='portlet'>
      <div className='portlet-head'>
        <h5><FileDoneOutlined className='mr-10 fs-20' />{t('properties.summary')}</h5>
      </div>

      <div className='portlet-body'>
      <div className="mb-15">
        <span className='contact-detail-title'>{t('properties.table.status')}</span>
        <Tag color={STATUS_COLORS[property.status]}>{property.status}</Tag>
      </div>
        <div className="mb-15">
          <span className='contact-detail-title'>{t('deals.new.operation')}</span>
          { property.operation.map((item: any) => <Tag>{TYPE_OPERATIONS[item]}</Tag>) }
        </div>
        <div className="mb-15">
          <span className='contact-detail-title'>{t('properties.refId')}</span>
          {property.identifier}
        </div>
        <div className="mb-15">
          <span className='contact-detail-title'>{t('properties.new.type')}</span>
          { name(property.property_type, lng) }
        </div>
        <div className="mb-15">
          <span className='contact-detail-title'>{t('properties.new.cartello')}</span>
          <strong>{ property.cartello === "true" ? t('properties.new.yes') : t('properties.new.no') } </strong>
        </div>
        <div className="mb-15">
          <span className='contact-detail-title'>{t('contacts.table.owner')}</span>
          { property.owner && (
            <a className='link' href={`/owners/${property.owner.id}/overview`} target='_blank'>{property.owner.name}</a>
          )}
        </div>
        <div className="mb-15">
          <span className='contact-detail-title'>{t('properties.new.energyCertificate')}</span>
            { property.energy_status === 'on'?
            <strong>
              <span className='mr-2'>
                {property.energy_type} | {t('properties.consumption')} = {property.energy_consumption}
              </span> | {t('properties.emission')} = {property.energy_emission}
            </strong> : <strong> {(property.energy_status || '').replace('_', ' ')} </strong>
          }
        </div>
        <div className="mb-15">
          <span className='contact-detail-title'>{t('wordBrochure')}</span>
          <a onClick={downloadDocx}><DownloadOutlined className='mr-5' /> {t('download')}</a>
        </div>
        <div className="mb-15">
          <span className='contact-detail-title'>{t('owners.table.created_at')}</span>
          <span>{createdAtDate} by {creatorName}</span>
        </div>
        <div className="mb-15">
          <span className='contact-detail-title'>{t('contacts.single.updated')}</span>
          <span>{updatedAtDate} by {editorName}</span>
        </div>
      </div>
    </div>
  )
});

export default Summary;
