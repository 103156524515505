import { observable, computed, action } from 'mobx';
import axios from 'axios';
import { IUser as UserType } from 'declarations/user';
import { Session } from 'declarations/session';
import { history } from 'utils/history';
// import type { Session as SessionType, User } from 'types';
import { sessionService } from 'utils/session';
import { makeAutoObservable } from 'mobx';
import debounce from 'lodash/debounce';
// import { ADMIN, ORGANIZER } from 'constants';
export interface Login {
  session: Session.Attributes,
  debouncedSetToken(token: string): any
}

export class Login {
  @observable session = sessionService.retrieveSession();

  constructor() {
    this.updateToken();
    this.debouncedSetToken = debounce(this.setToken, 5000);
    makeAutoObservable(this);
  }


  get isLogged(): boolean {
    return !!(this.session.user && this.session.token)
  }

  get isAdmin(): boolean {
    const role = this.session.user.user?.role;
    return role == 'admin'
  }

  get isManager(): boolean {
    const role = this.session.user.user?.role;
    return role == 'manager'
  }

  get isGeneralManager(): boolean {
    const role = this.session.user.user?.role;
    return role == 'general_manager'
  }

  get isExternalManager(): boolean {
    const role = this.session.user.user?.role;
    return role == 'external_manager'
  }

  // @computed
  // get isOrganizer(): boolean {
  //   const roles = (this.session.user.roles || []).map(role => role.name);
  //   return roles.includes(ORGANIZER) && !roles.includes(ADMIN)
  // }

  async login(user: any, token: string) {
    this.session.user = user;
    this.session.token = token;
    this.updateToken();
    sessionService.storeSession(user, token);
  }

  async logout() {
    this.session.user = {};
    this.session.token = null;
    await this.updateToken();
    const { user, token } = this.session;
    sessionService.storeSession(user, token);
  }

  async updateCurrentUser(user: any) {
    const newUser = {
      ...this.session,
        ...this.session.user,
        user: user
    }

    this.session.user = {...newUser};
    sessionService.updateSessionUser(newUser);
  }

  // @computed
  // get user(): User | null {
  //   if (this.isLogged) {
  //     const user: User = (this.session.user: any);
  //     return user;
  //   } else {
  //     return null;
  //   }
  // }
  //
  // @computed
  // get fullName(): string {
  //   const user = this.user;
  //   return (user && user.full_name) || '';
  // }

  setToken(newToken: string) {
    if (typeof newToken !== 'string')
      return;
    this.session.token = newToken;
    this.updateToken();
    const { user, token } = this.session;
    sessionService.storeSession(user, token);
  }

  updateToken() {
    const token = this.session.token;
    if (token) {
      axios.defaults.headers.common['Authorization'] = token;
    } else {
      axios.defaults.headers.common['Authorization'] = null;
    }
  }

}

export default new Login();

