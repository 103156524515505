import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { Provider, observer } from 'mobx-react';
import * as stores from './stores';
import 'antd/dist/reset.css';
import { I18nextProvider } from "react-i18next";
import i18n from 'utils/i18n';
import { Helmet } from 'react-helmet';
import 'assets/styles/main.scss'
import 'assets/styles/base/atomic.css'
import axios from "axios";
import { ConfigProvider } from "antd";
import dayjs from 'dayjs';
import enUS from 'antd/locale/en_US';
import loginStore from "modules/login/loginStore";

// const history = createBrowserHistory();
// axios.defaults.baseURL = "http://localhost:3000";
// axios.defaults.baseURL = "https://crm.vymcanarias.com/";
axios.defaults.baseURL = "https://vym.dev-evocode.net/";
dayjs.locale('en');
axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    loginStore.logout();
    window.location.href = "/"
  }
  return Promise.reject(error)
})

const App: FC = observer(() => {

  const theme = {
    token: {
      colorPrimaryHover: "rgba(200,146,17,85)",
      fontFamily: "Montserrat, sans-serif",
    },
  };

  return (
    <BrowserRouter>
      <Provider {...stores}>
        <ConfigProvider locale={enUS} theme={theme}>
          {/* <ToastContainer /> */}
            {/* <LocaleProvider locale={enUS}> */}
            <I18nextProvider i18n={i18n}>
              <div className="app-container">
              <Helmet>
                <title>VYM CRM</title>
                <meta name="description" content="VYM CRM" />
              </Helmet>
                <Routes />
              </div>
            {/* </LocaleProvider> */}
          </I18nextProvider>
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  );
})

export default App;
