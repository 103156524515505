import { FC, useState} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal,  Row, Col, Dropdown, Menu, Tooltip, Avatar, Tag } from 'antd';
import moment from 'moment';
import loginStore from 'modules/login/loginStore';
import { noteService } from 'services/noteService';
import NoteForm from './Form';
import { EllipsisOutlined, EditOutlined, DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { IContact } from 'contact';
import { Property as IProperty } from 'property';
import { INote } from 'note';
import { COLORS } from 'constants/mixed'

interface IData {
  id?: number,
  resource: IContact | IProperty,
  resource_type: string;
  isActivity?: boolean;
  note: INote,
}

const confirm = Modal.confirm;

const Item: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);

  const showConfirm = (note: INote) => {
    confirm({
      title: t('notes.confirmDelete'),
      content: '',
      onOk() {
        return new Promise((resolve, reject) => {
          noteService.deleteResource(note, resolve)
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }


  const renderActions = (note: INote) => {

    return (
      <Menu>
        <Menu.Item onClick={() => setIsEdit(true)} key="actions1"><EditOutlined className='mr-5' />
          {t('owners.table.edit')}
        </Menu.Item>
        <Menu.Item onClick={() => showConfirm(note)} key="action2">
          <DeleteOutlined className='mr-5' /> { t('properties.table.delete') }
        </Menu.Item>
      </Menu>
    )
  }

  const renderCardContent = () => {
    const { note, resource, resource_type, isActivity } = props;
    let content;

    if (isEdit) {
      content = <NoteForm
        onCancelButton={() => setIsEdit(false)}
        resource={resource}
        resource_type={resource_type}
        isActivity={isActivity}
        note={note}
      />
    } else {
      content = <Col>{note.value}</Col>
    }
    return content;
  }

  const { note } = props;
  const createdDate = moment(note.created_at).format('D MMMM YYYY [at] HH:mm');
  const tooltipText = t('privateTextNote');
  const user = loginStore.session.user.user;

    return (
      <div className='contact-card-item w-100p'>
        <Row className='contact-card-header' justify="space-between" align='middle'>
          <Col>
            <Row justify="start" align='middle'>
              <Col>
                <Avatar size={35} icon={<UserOutlined />} className='mr-10' />
              </Col>
              <Col>
                <p>{note.creator && note.creator.name || ''} {t('notes.leftNote')}</p>
                <span>{createdDate}</span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row justify="end" align='middle'>
              {note.is_public.toString() == 'false' &&
                <Col>
                  <Tooltip placement="top" title={tooltipText}>
                    <Tag color={COLORS[3]}>{t('private')}</Tag>
                  </Tooltip>
                </Col>
              }
              { (loginStore.isAdmin || user.id == note.created_by_id) &&
                <Col>
                  <Dropdown overlay={() => renderActions(note)} trigger={['click']}>
                    <div className='list-dropdown-button'>
                      <EllipsisOutlined className='three-dots ml-15' />
                    </div>
                  </Dropdown>
                </Col>
              }
            </Row>
          </Col>
        </Row>
        <div className='contact-card-content'>
          {renderCardContent()}
        </div>
      </div>
  );
})

export default Item;
