import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from "react-router-dom"
import { Tabs, Row, Col, Tag, Divider } from 'antd';
import { propertyTypesService } from 'services';
import { dealService } from 'services/DealService';
import dealStore from 'stores/deal';
import LocalStore from 'stores/locale';
import { TYPE_OPERATIONS, DEAL_STATUS_FROM_OPERATION, DEAL_STATUS_COLOR } from 'constants/mixed'
import DealBoard from '../components/DealBoard';
import DealDetails from '../components/DealDetails';
import { propertyService } from 'modules/properties/PropertyService';
import DealPropertySearch from '../components/DealPropertySearch';
import PropertyTypeStore from 'stores/type';
import contactStore from 'stores/contact';
import PropertyStore from 'stores/property';
import { name } from 'utils/localized';
import { PropertyType as IType } from 'declarations/propertyType';
import { ArrowLeftOutlined } from "@ant-design/icons";
import AppLayout from "modules/clients/containers/Single/components/Layout";


const TabPane = Tabs.TabPane;

const Deal: FC = observer(() => {
  const { deal_id } = useParams();
  const lng = LocalStore.locale;
  const { t } = useTranslation();
  const contact: any = contactStore.selected;

  useEffect(() => {
    dealService.loadResource(Number(deal_id))
    propertyTypesService.loadResources()
  }, [])

  const renderPropertyTypes = (deal: any) => {
    const types = PropertyTypeStore.values;

    if (!deal.property_type) {
      return '-'
    }

    return types.map((type: IType) => {
      if ((deal.property_type || []).includes(type.id.toString())) {
        return <Tag color='#655dc5' className='mb-10'>{name(type, lng)}</Tag>
      }
    })
  }

  // const onTabClick = () => {
  //   const deal: any = dealStore && dealStore.selected;
  //   if(PropertyStore.filterKeys.except_deal_id) {
  //     PropertyStore.updateFilter({ except_deal_id: deal.id, is_filter_deal: true })
  //     propertyService.loadResources(PropertyStore.filterKeys);
  //   }
  // }

  const deal: any = dealStore && dealStore.selected;

  if (!deal.id) {
    return null
  }

  return (
    <AppLayout>

      <div className="tab w-100p">
        <Row className='deal-header mb-40 fx-nw'>
          <Col className='fx-nos'>
            <Link className='back-to-deals' to={`/clients/${contact.id}/deals`}>
              <ArrowLeftOutlined className='mr-10' />
              {t('back')}
            </Link>
          </Col>
          <Col className='ml-10 mr-10'>
            <Divider type="vertical" />
          </Col>
          <Col>
            <Row gutter={30} className='fx-nw'>
              <Col className='fx-nos'>
              {t('tabs.deal')}: <strong>{t('deals.list.id')} {deal.id} </strong>
              </Col>
              <Col className='fx-nos'>
                {t('properties.table.status')}: <Tag color={DEAL_STATUS_COLOR[deal.status]}>{DEAL_STATUS_FROM_OPERATION[deal.operation][deal.status]}</Tag>
              </Col>
              <Col className='fx-nos'>
                {t('deals.new.operation')}: <strong>{TYPE_OPERATIONS[deal.operation]}</strong>
              </Col>
              <Col>
                <Row className='fx-nw'>
                  <Col className='mr-5'>
                    {t('owners.table.property')}:
                  </Col>
                  <Col>
                    <Row>
                      {renderPropertyTypes(deal)}
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className='fx-nos'>
                {t('deals.new.budget')}: <strong>
                  {deal.max_price ? `${deal.max_price} €`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '-'}
                </strong>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='deal-content'>
          <Tabs
            defaultActiveKey="1"
            animated={false}
            className='deal-tabs-container w-100p'
            destroyInactiveTabPane
          >
            <TabPane className='deal-tabs w-100p mh-100p' tab={t('deals.tab.board')} key="1">
              <DealBoard contact={contact} deal={deal} />
            </TabPane>
            <TabPane
              className='deal-tabs'
              tab={`${t('deals.tab.search')} +${deal.labels.length}`}
              key="2"
            >
              <DealPropertySearch contact={contact} deal={deal}/>
            </TabPane>
            <TabPane className='deal-tabs' tab={t('deals.tab.details')} key="3">
              <DealDetails contact={contact} deal={deal} />
            </TabPane>
          </Tabs>
        </Row>
      </div>
    </AppLayout>
  );
})

export default Deal;
