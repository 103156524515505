import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'antd';
import ActivityStore from 'stores/activity';
import ActivityList from 'modules/properties/components/Single/ActivityList';
import Summary from 'submodules/properties/components/Summary';
import propertyStore from 'stores/property';
import AppLayout from '../../Layout';
import { Property as IProperty } from "declarations/property";

const Overview: FC = observer(() => {
  const property = propertyStore.selected;

  useEffect(() => {
    return () => ActivityStore.clearValues()
  }, [])

  return (
    <AppLayout>
      <div className="tab">
        <Row justify='space-between' align='top'>
          <Col span={8}>
            <Summary property={property} id={property.id} />
          </Col>
          <Col span={15}>
            <ActivityList property={property} />
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
})

export default Overview;
