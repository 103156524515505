
export const LOGIN = 'LOGIN';

//LIST
export const LOAD_PROPERTIES = 'LOAD_PROPERTIES';
export const LOAD_REQUESTS = 'LOAD_REQUESTS';
export const LOAD_CONTACTS = 'LOAD_CONTACTS';
export const LOAD_CLIENTS = 'LOAD_CLIENTS';
export const LOAD_OWNERS = 'LOAD_OWNERS';
export const LOAD_DEALS = 'LOAD_DEALS';
export const LOAD_REPORT_DATA = 'LOAD_REPORT_DATA';
export const LOAD_SOURCES = 'LOAD_SOURCES';
export const LOAD_PROPERTY_TYPES = 'LOAD_PROPERTY_TYPES';
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_ACTIVITIES = 'LOAD_ACTIVITIES';
export const LOAD_NOTES = 'LOAD_NOTES';
export const LOAD_TASKS = 'LOAD_TASKS';
export const LOAD_FILES = 'LOAD_FILES';
export const LOAD_EMAILS = 'LOAD_EMAILS';
export const LOAD_EXPENSES = 'LOAD_EXPENSES';
export const LOAD_REPORTS = 'LOAD_REPORTS';
export const LOAD_RENTS = 'LOAD_RENTS';

//SINGLE
export const LOAD_CONTACT = 'LOAD_CONTACT';
export const LOAD_CLIENT = 'LOAD_CLIENT';
export const LOAD_OWNER = 'LOAD_OWNER';
export const LOAD_DEAL = 'LOAD_DEAL';
export const LOAD_PROPERTY = 'LOAD_PROPERTY';

//EXPORT
export const EXPORT_DEALS = 'EXPORT_DEALS';
export const EXPORT_PROPERTIES = 'EXPORT_PROPERTIES';
export const EXPORT_CONTACTS = 'EXPORT_CONTACTS';
export const EXPORT_CLIENTS = 'EXPORT_CLIENTS';
export const EXPORT_OWNERS = 'EXPORT_OWNERS';
export const EXPORT_REQUESTS = 'EXPORT_REQUESTS';
export const EXPORT_EXPENSES = 'EXPORT_EXPENSES';
export const ZIP_GALLERIES = 'ZIP_GALLERIES';

//UPDATE
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
export const UPDATE_GALLERY = 'UPDATE_GALLERY';
export const DELETE_GALLERY = 'DELETE_GALLERY';
export const UPDATE_FILE = 'UPDATE_FILE';

//CREATE
export const CREATE_EMAIL = 'CREATE_EMAIL';
export const CREATE_REQUEST = 'CREATE_REQUEST';
