import { observable, computed, action } from 'mobx';
import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { Property } from 'declarations/property';
import { IImage } from 'image';

interface IFilterKeys {
  statuses?: any,
  operations?: any,
  type_ids?: any,
  owners?: any,
  feature_ids?: any,
  label_ids?: any,
  class_ids?: any,
  q?: string,
  is_filter_deal?: any,
  except_deal_id?: any,
  sale_statuses?: any,
  rent_statuses?: any,
  region?: any,
  city?: any,
  complex?: any,
  search_properties?: string,
  sale_price_min?: number,
  sale_price_max?: number,
  rent_price_min?: number,
  rent_price_max?: number,
  cashback?: number,
  new_building?: string,
  date_from?: any,
  date_to?: any,
  bedroom_num?: number,
  bathroom_num?: number,
  has_tasks?: string,
  cartello: string | undefined,
}

export class PropertyStore {
  values: Array<Property> = [];
  selectValues: Array<Property> = [];
  count: number = 0;
  page: number | 'all' = 1;
  fetchLoading = false;
  selected: any = {};
  hasAcccess: boolean = true;
  filterKeys: IFilterKeys = {
    statuses: [],
    operations: [],
    type_ids: [],
    owners: [],
    feature_ids: [],
    label_ids: [],
    class_ids: [],
    q: '',
    is_filter_deal: null,
    except_deal_id: null,
    search_properties: '',
    sale_statuses: [],
    rent_statuses: [],
    region: [],
    city: [],
    complex: [],
    sale_price_min: undefined,
    sale_price_max: undefined,
    rent_price_min: undefined,
    rent_price_max: undefined,
    cashback: undefined,
    new_building: undefined,
    date_from: '',
    date_to: '',
    bedroom_num: undefined,
    bathroom_num: undefined,
    has_tasks: '',
    cartello: undefined,
  }
  images: IImage[] = [];
  fetchInProgress = false;
  saveInProgress = false;
  deleteInProgress = false;

  constructor() {
    makeAutoObservable(this);
  }


  setSearchText(value: string) {
    this.filterKeys.q = value;
  }

  setImages(images: IImage[]) {
    this.images = [...images];
  }

  setPage(page: number | "all") {
    this.page = page;
  }

  clearImages() {
    this.images = [];
  }


  addValues(values: Array<Property>, page: number | 'all', count: number, queryParams: Object): void {
    this.values = values;
    this.page = page
    this.count = count
  }

  addSelectValues(values: Array<Property>, page: number | 'all', count: number, queryParams: Object): void {
    this.selectValues = values;
    this.page = page
    this.count = count
  }

  removeValue(resource_id: number): void {
    this.values = this.values.filter((item: Property) => item.id != resource_id);
  }

  updateFilter(newFilter: {}) {
    this.filterKeys = {...this.filterKeys, ...newFilter}
  }

  setLoading(loading: boolean) {
    this.fetchLoading = loading;
  }

  resetFilter() {
    this.filterKeys = {
      statuses: [],
      operations: [],
      type_ids: [],
      owners: [],
      feature_ids: [],
      label_ids: [],
      class_ids: [],
      q: '',
      is_filter_deal: null,
      except_deal_id: null,
      search_properties: '',
      sale_statuses: [],
      rent_statuses: [],
      region: [],
      city: [],
      complex: [],
      sale_price_min: undefined,
      sale_price_max: undefined,
      rent_price_min: undefined,
      rent_price_max: undefined,
      date_from: '',
      date_to: '',
      bedroom_num: undefined,
      bathroom_num: undefined,
      has_tasks: '',
      cartello: undefined,
    }
  }

  addSelectedValue(value: any): void {
    this.selected = value;
  }

  addSelectedAttributes(params: any): void {
    this.selected = { ...this.selected, ...params };
  }

  updateValue(params: any): void {
    this.selected = { ...this.selected, ...params };
  }

  updateHasAccess(val: boolean): void {
    this.hasAcccess = val;
  }

  setFetchInProgress(val: boolean) {
    this.fetchInProgress = val;
  }
  setSaveInProgress(val: boolean) {
    this.saveInProgress = val;
  }
  setDeleteInProgress(val: boolean) {
    this.deleteInProgress = val;
  }

  clearData(): void {
    this.selected = {};
    this.values = [];
    this.selectValues = [];
    this.page = 1;
  }
}

export default (new PropertyStore())

