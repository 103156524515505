import axios from 'axios';
import { CLIENTS_URL} from 'constants/api';
import { IClient, FilterType } from "declarations/contact";



export const getClients = async (params: { workspace_id: number}) => {
  const resp = await axios.get(CLIENTS_URL, { params });
  return resp.data;
}

export const getById = async (clientId: number): Promise<IClient> => {
  const resp = await axios.get(`${CLIENTS_URL}/${clientId}`);
  return resp.data;
}

export const validate = async (email: string, workspace_id: number, client_id?: number) => {
  const resp = await axios.get(`${CLIENTS_URL}/validate_email`, { params: { email, workspace_id, client_id}});
  return resp.data;
}

export const create = async (params: Partial<IClient>): Promise<IClient> => {
  const resp = await axios.post(CLIENTS_URL, { client: params });
  return resp.data;
}

export const update = async (clientId: number, params: Partial<IClient>): Promise<IClient> => {
  const resp = await axios.put(`${CLIENTS_URL}/${clientId}`, { client: {...params, manager_id: typeof params.manager_id === "undefined" ? null : params.manager_id }});
  return resp.data;
}

export const del = async (clientId: number) => {
  const resp = await axios.delete(`${CLIENTS_URL}/${clientId}`);
  return resp.data;
}

export const exportClients = async (params: FilterType | {}) => {
  const resp = await axios.get(`${CLIENTS_URL}/export`, { params });
  return resp;
}