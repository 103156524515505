import { action } from 'utils/mobx';
import axios from 'axios';
import  reportStore  from '../stores/report';
import { PROMOTION_REPORT_URL, SALE_REPORT_URL, EMAIL_REPORT_URL } from 'constants/api';
import { LOAD_REPORTS } from 'constants/action';
import workspaceStore from "stores/workspace";

interface FilterType {

};

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any,
  loadResourcesRequest(queryParams: {}): Promise<object>
}

export class ReportsService implements Props {

  async loadResourcesRequest(type: string, filter: any = {}) {
    const url = this.getUrl(type);
    if(!url) {
      return {status: 500, data: []}
    }
    return axios.get(url, { params: {...filter} })
  }

  @action({ action: LOAD_REPORTS, minRequestTime: 500 })
  async loadResources(type: string, filter: any = {}) {
    reportStore.setLoading(true)
    const params = {
      ...filter,
      workspace_id: workspaceStore.selectedId,
    }
    const response: { status: number, data: Array<Object>} = await this.loadResourcesRequest(type, params);

    if (response.status == 200) {
      let values = [];
      values = response.data;

      this.addValues(values);
    }
    reportStore.setLoading(false)
    return response;
  }

  getUrl(type: string) {
    switch(type) {
      case 'promotion':
        return PROMOTION_REPORT_URL;
        break;
      case 'sale':
        return SALE_REPORT_URL;
        break;
      case 'email':
        return EMAIL_REPORT_URL;
        break;
    }
  }

  addValues(values: any) {
    reportStore.addValues(values);
  }
}

export const reportsService = new ReportsService();
