import { Row } from 'antd';
import { useDroppable } from "@dnd-kit/core";
import { useTranslation } from 'react-i18next';
import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import BoardItem from "./BoardItem";
import { IDeal } from 'declarations/deal';
import { IDealsProperty } from 'declarations/dealsProperty';
import { capitalize } from "lodash"

const containerStyle = {
  background: "rgba(196,197,214,.15)",
  padding: 10,
  margin: 10,
  flex: 1,
  minHeight: "400px"
};

interface IProps {
  id: string;
  items: any;
  className?: string;
  sendEmailPortal?: () => void;
  deal: IDeal;
  showModal: (dealProperty: IDealsProperty | null, isRejected?: boolean) => void;
}


export default function Container(props: IProps) {
  const { t } = useTranslation();
  const { id, items } = props;

  const { setNodeRef } = useDroppable({
    id
  });

  return (
    <div className={`${props.className} ml-5 mr-5`}>
      <SortableContext
        id={id}
        items={items}
        strategy={verticalListSortingStrategy}
      >
        <div ref={setNodeRef} style={containerStyle}>
          <Row
            justify='space-between'
            align='middle'
            className="card-column-header mb-20"
          >
            <h4>{t(`deals.statuses.${id}`)}</h4>
            { id === 'email' &&
              <a className='link' onClick={props.sendEmailPortal}>{t('deals.sendEmail')}</a>
            }
            <span>{(items || []).length}</span>
          </Row>
          {items.map((item: IDealsProperty) => (
            <BoardItem key={item.id} id={item.id} deal={props.deal} showModal={props.showModal} dealProperty={item} />
          ))}
        </div>
      </SortableContext>
    </div>
  );
}
