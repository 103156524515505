import { action } from 'utils/mobx';
import { request } from 'utils/mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import { message, notification } from 'antd';
import contactStore  from 'stores/contact';
import { CONTACTS_URL, BASE_URL } from 'constants/api';
import { Filter } from "declarations/filters";
import { LOAD_CONTACTS, EXPORT_CONTACTS } from 'constants/action';
import { saveAs } from 'file-saver';
import { IContact } from "declarations/contact";

interface FilterType {
    contact_types?: any,
    source_ids?: any,
    languages?: any,
    manager_ids?: any,
    creation_date?: any
};

interface IResponse {
  status: number,
  data: { message?: Array<string>, contacts: Array<IContact>, count: number }
}

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number | 'all'): any;
  loadResourcesRequest(queryParams: {}): Promise<object>;
  // addValues(values: Array<IContact>, page: number, queryParams: Object): void;
}

notification.config({
  placement: 'topRight',
  duration: 0
})

export class ContactService implements Props {

  async loadResourcesRequest (params: FilterType | {}): Promise<IResponse> {
    return axios.get(CONTACTS_URL, {
      params
    })
  }

  @action({ action: LOAD_CONTACTS, minRequestTime: 500 })
  async loadResources(params: FilterType = {}, page: number | 'all' = 1) {
    // let { filters, page } = (contactStore || {});
    const queryParams = {
      // ...filters,
      ...params,
      page
    };
    contactStore.setFetchInProgress(true);
    const response: IResponse = await this.loadResourcesRequest(queryParams);
    if (response.status == 200) {
      let values = [];
      values = response.data.contacts;

      this.addValues(values, page, response.data.count, params);
      // const paginationMeta = response.data[this.metaRoot] && response.data[this.metaRoot][this.paginationMetaRoot];
      // if (paginationMeta) {
        // this.store.addPaginationMeta(paginationMeta);
        // }
      }
      contactStore.setFetchInProgress(false);

    return response;
  }

  async resetFilters () {
    contactStore.resetFilter();
    this.loadResources({});
  }

  async changeFilters (newFilter: {}) {
    contactStore.updateFilter(newFilter);
    contactService.loadResources(newFilter);
  }

  async loadResourceRequest (resource_id: number) {
    return axios.get(`${CONTACTS_URL}/${resource_id}`)
  }

  async loadResource(resource_id: number) {
    contactStore.updateHasAccess(true);
    contactStore.setFetchInProgress(true);
    const response: { status: number, data: Object} = await this.loadResourceRequest(resource_id);

    if (response.status == 200) {
      let value: any = {};
      value = response.data;
      this.addSelectedValue(value);
    }

    if (response.status == 403) {
      contactStore.updateHasAccess(false);
    }

    contactStore.setFetchInProgress(false);
    return response;
  }

  async createResourceRequest(params: IContact) {
    return axios.post(CONTACTS_URL, { contact:
      params
    })
  }


  async createResource(params: any) {
    contactStore.setSaveInProgress(true);
    const response: IResponse = await this.createResourceRequest(params);
    contactStore.setSaveInProgress(false);
    return response;

  }

  async deleteResourceRequest(contact_id: number) {
    return axios.delete(`${CONTACTS_URL}/${contact_id}`);
  }


  async deleteResource(contact_id: number, callback: Function) {
    contactStore.setDeleteInProgress(true);
    const response: { status: number, message?: string } = await this.deleteResourceRequest(contact_id);

    contactStore.setDeleteInProgress(false);
    if (response.status == 403) {
      notification['error']({
        message: "You don't have access for this operation",
        placement: 'topRight',
        duration: 0,
      });
      callback();
      return;
    }

    if (response.status == 200) {
      message.success('Contact deleted!!')
      this.loadResources()
      callback()
    }

  }

  async updateResourceRequest(resource: any) {
    return axios.put(`${BASE_URL}/contacts/${resource.id}`, { contact: resource })
  }


  async updateResource(resource: Object, callback: Function) {
    contactStore.setSaveInProgress(true);
    const response: { data: any, status: number } = await this.updateResourceRequest(resource);

    if (response.status == 200) {
      this.addSelectedValue(response.data)
      message.success('Contact updated!')
      callback()
    }
    contactStore.setSaveInProgress(false);
    return resource;

  }

  async updateManagerResource(resource: Object, callback: Function) {
    contactStore.setSaveInProgress(true);
    const response: { data: any, status: number } = await this.updateResourceRequest(resource);

    if (response.status == 200) {
      this.updateValue(response.data)
      callback(response.data)
    }
    contactStore.setSaveInProgress(false);

  }

      async exportRequest (params: FilterType | {}){
        return axios.get(`${CONTACTS_URL}/export`, {
          params
        })
      }

      @action({ action: EXPORT_CONTACTS, minRequestTime: 500 })
      async exportResources(params: FilterType = {}, pageNum: number | 'all' = 1) {
        const filters = contactStore.filterKeys;
        let page = pageNum;

        const queryParams = {
          ...filters,
          ...params,
          page
        };
        contactStore.setExportInProgress(true);
        const response: any = await this.exportRequest(queryParams);

        if (response.status == 200) {
          const blob = new Blob([response.data])
          saveAs(blob, 'contacts.csv')
        }

        contactStore.setExportInProgress(false);
        return response;
      }



  addValues(values: Array<IContact>, page: number | 'all', count: number, params: {}) {
    contactStore.addValues(values, page, count, params);
  }

  addSelectedValue(value: any) {
    contactStore.addSelectedValue(value);
  }

  updateValue(contact: any) {
    contactStore.addValue(contact);
  }
}

export const contactService = new ContactService();
