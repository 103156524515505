import { FC, useState, useEffect} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { TYPE_OPERATIONS, DEAL_STATUS_FROM_OPERATION, DEAL_STATUS_COLOR } from 'constants/mixed'
import { propertyTypesService } from 'services/propertyTypes';
import { dealService } from 'services/DealService';
import { name } from 'utils/localized';
import DealForm from './DealForm';
import PropertyTypeStore from 'stores/type';
import LocaleStore from 'stores/locale';

import {  Row, Tag } from 'antd';

import { IContact } from 'declarations/contact';
import { IDeal } from 'declarations/deal';
import { PropertyType as IType } from 'declarations/propertyType';
import { EditOutlined } from "@ant-design/icons";

interface IData {
  contact: IContact,
  deal: IDeal,
}

const DealDetails:FC<IData> = observer((props) => {

  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();
  const lng = LocaleStore.locale;

  useEffect(() => {
    propertyTypesService.loadResources()
  }, [])

  const handleEditCancel = () => setIsEdit(false)

  const handleEdit = () => setIsEdit(true)


  const handleUpdate = (deal: IDeal) => {
    dealService.updateResource(deal, handleEditCancel)
  }

  const renderContent = () => {
    const { deal, contact } = props;
    let content;

    if (isEdit) {
      content = <DealForm
        deal={deal}
        contact={contact}
        handleOk={handleUpdate}
        handleCancel={handleEditCancel}
      />
    } else {
      content = staticContent()
    }

    return content;
  }

  const renderPropertyTypes = () => {
    const types = PropertyTypeStore.values;
    const {  deal } = props;

    if (!deal.property_type) {
      return '-'
    }

    return types.map((type: IType) => {
      if ((deal.property_type || []).includes(type.id.toString())) {
        return <Tag color='#655dc5'>{name(type, lng)}</Tag>
      }
    })

  }

  const renderBedrooms = () => {
    const { deal } = props;

    if(!(deal.bedrooms && deal.bedrooms.length > 0)){
      return '-'
    }

    return deal.bedrooms.map((bedroom: string) => <Tag className='mr-5'>{bedroom}</Tag>)
  }
  const staticContent = () => {
    const { deal } = props;

    return (
      <div className='section-content pxy-25'>
        <div className='mb-25'>
          <div>{t('properties.table.status')}</div>
          <div><Tag color={DEAL_STATUS_COLOR[deal.status]}>{DEAL_STATUS_FROM_OPERATION[deal.operation][deal.status].toLowerCase()}</Tag></div>
        </div>
        <div className='mb-25'>
          <div>{t('deals.new.operation')}</div>
          <div className='accent'>{TYPE_OPERATIONS[deal.operation]}</div>
        </div>
        <div className='mb-25'>
          <div>{t('deals.new.propertyType')}</div>
          <div className='mt-4'>{renderPropertyTypes()}</div>
        </div>
        <div className='mb-25'>
          <div>{t('deals.new.budget')}</div>
          <div className='accent'>{deal.max_price ? `${deal.max_price} €` : '-'} </div>
        </div>
        <div className='mb-25'>
          <div>{t('properties.new.island')}</div>
          <div className='accent'>{deal.island_name || '-'}</div>
        </div>
        <div className='mb-25'>
          <div>{t('properties.new.region')}</div>
          <div className='accent'>{deal.region_name || '-'}</div>
        </div>
        <div className='mb-25'>
          <div >{t('properties.new.city')}</div>
          <div className='accent'>{deal.city_name || '-'}</div>
        </div>
        <div className='mb-25'>
          <div>{t('properties.new.complex')}</div>
          <div className='accent'>{deal.complex_name || '-'}</div>
        </div>
        <div className='mb-25'>
          <div>{t('properties.new.bedrooms')}</div>
          <div className='accent'>{renderBedrooms()}</div>
        </div>
        <div className='mb-25'>
          <div>{t('manager')}</div>
          <div className='accent'>{deal.manager.name || '-'}</div>
        </div>
        <div>
          <div>{t('tabs.note')}</div>
          <div className='accent'>{deal.note || '-'}</div>
        </div>
      </div>
    )
  }

  return (
    <div className='portlet'>
      <div className='portlet-head'>
        <Row justify='space-between' align='middle'>
          <h5>{t('deals.dealsDetails')}</h5>
          <EditOutlined onClick={handleEdit} />
        </Row>
      </div>

      <div className='portlet-body'>
        {renderContent()}
      </div>
    </div>
  )
})

export default DealDetails;
