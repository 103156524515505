import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { propertyService } from '../../PropertyService';
import General from './Steps/General';
import Details from './Steps/Details';
import Address from './Steps/Address';
import Price from './Steps/Price';
import Promotion from './Steps/Promotion';
import Gallery from './Steps/Gallery';
import Description from './Steps/Description';
import shortid from 'shortid';

// import OwnerListStore from './OwnerListStore';
import { Steps, Form, Col, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom"
import propertyStore from 'stores/property';

const Step = Steps.Step;

const New: FC = observer(() => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [property, setProperty] = useState({
    id: undefined,
    status: 'published',
    type_ids: undefined,
    operation: [],
    identifier: '',
    gallery_key: shortid(),
    property_type_id: undefined,
    cartello: "false",
    owner_id: null,
    demo_url: '',
    video_url: '',
    website_ids: [],
    portal_ids: [],
    class_ids: [],
    label_ids: [],
    feature_ids: [],
    hide_price: false,
    energy_status: 'on',
    energy_type: undefined,
    energy_consumption: null,
    energy_emission: null,
    detail_attributes: {
      id: null,
      view: undefined,
      furniture: null,
      bedroom_count: undefined,
      bedroom_area: '',
      bathroom_count: undefined,
      bathroom_area: '',
      toilet_count: undefined,
      toilet_area: '',
      terrasse_count: undefined,
      terrasse_area: '',
      balcony_count: undefined,
      balcony_area: '',
      parking_count: undefined,
      parking_area: '',
      garage_count: undefined,
      garage_area: '',
      storeroom_count: undefined,
      storeroom_area: '',
      living_area: '',
      plot_area: '',
      total_area: '',
      utility_bills: '',
      electricity: '',
      water: '',
      garbage: ''
    },
    location_attributes: {
      id: null,
      city: '',
      address: '',
      region: '',
      lng: null,
      lat: null
    },
    price_attributes: {
      id: null,
      type: null,
      amount: null,
      comission_type: '0',
      comission_value: null,
      with_comission: '0',
      owners_amount: null,
      mortgage: false,
      vv: false,
      monthly_price: null,
      fixed_commission: null,
      monthly_commission: null,
      owners_monthly_price: null,
    },
    description_attributes: {
      id: null,
      value: { en: '', ru: '', es: '', it: '', pl: '', de: '' }
    },
    images: []
  });

  useEffect(() => {
    propertyStore.clearImages();
    return () => {
      propertyStore.clearImages();
      form.resetFields();
    }
  }, [])

  const onCreateProperty = (params: any, callback: Function) => {
    const newParams = { ...property, ...params };
    setProperty(newParams)
    if (property.id) {
      propertyService.updateResource('properties', newParams, () => {
        callback();
        navigate('/properties');
      })
    } else {
      propertyService.createResource(newParams, () => {
        callback();
        navigate('/properties')
      })
    }
  }


  const next = (params: any = {}) => {
    setCurrent(current + 1);
    setProperty({ ...property, ...params });
  }

  const prev = (params: any = {}) => {
    setCurrent(current - 1);
    setProperty({ ...property, ...params });
  }

  const getSteps = () => {
    const {
      id, hide_price, website_ids, portal_ids, class_ids, label_ids, feature_ids, property_type_id
    } = property;
    const steps = [{
      title: t('properties.new.steps.general'),
      content: <General next={next} general={property} />,
    }, {
      title: t('properties.new.steps.price'),
      content: <Price
        next={next}
        prev={prev}
        operation={property.operation}
        price={property.price_attributes}
      />,
    }, {
      title: t('properties.new.steps.details'),
      content: <Details
        next={next}
        prev={prev}
        operation={property.operation}
        details={property.detail_attributes}
      />,
    }, {
      title: t('properties.new.steps.address'),
      content: <Address
        form={form}
        prev={prev}
        next={next}
        locationAttributes={property.location_attributes}
        property={property}
      />
    }, {
      title: t('properties.new.steps.gallery'),
      content: <Gallery
        next={next}
        prev={prev}
        images={property.images}
        galleryKey={property.gallery_key}
        videoUrl={property.video_url}
        demoUrl={property.demo_url}
        property={property}
      />
    }, {
      title: t('properties.new.steps.description'),
      content: <Description
        next={next}
        prev={prev}
        descriptionAttributes={property.description_attributes}
      />
    }, {
      title: t('properties.new.steps.promotion'),
      content: <Promotion
        prev={prev}
        onCreateProperty={onCreateProperty}
        promotionAttributes={{ id, website_ids, portal_ids, class_ids, label_ids, feature_ids, hide_price, property_type_id }}
        property={property}
      />,
    }
    ];

    return steps;
  }

  const steps = getSteps();

  return (
    <div className="page new-property-page">
      <div className='mb-30'>
        <h3>{t('properties.new.header')}</h3>
      </div>
      <div className='contact-card-item w-100p'>
        <div className='contact-card-content w-100p'>
          <Space direction='vertical' className='w-100p'>
            <Col className='mt-10 mb-30'>
              <Steps current={current} className='wizard-steps'>
                {steps.map(item => <Step key={item.title} title={item.title} />)}
              </Steps>
            </Col>
            <Col className='mt-40 px-40'>
              <div className="steps-content">{steps[current].content}</div>
            </Col>
          </Space>
        </div>
      </div>
    </div>
  );
})

export default New;
