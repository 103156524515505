import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { rentService } from 'services/rentService';
import DateByCreator from 'components/DateByCreator';
import ProgressStore from 'stores/progress';
import LocaleStore from 'stores/locale';
import { LOAD_RENTS } from 'constants/action';
import rentStore from 'stores/rent';
import RentForm from 'modules/properties/components/Single/RentForm';
import { name } from 'utils/localized'
import { Table, Button, Menu, Row, Modal, Tag, Dropdown } from 'antd';
import { EditOutlined, DeleteOutlined, EllipsisOutlined, ShoppingOutlined } from "@ant-design/icons";
import { IRent } from 'declarations/rent';
import { Property as IProperty } from 'declarations/property';
import { IClient } from 'contact';
import contactStore from 'stores/contact';
import AppLayout from "modules/contacts/containers/Single/components/Layout";

const confirm = Modal.confirm;

const Rent: FC = observer(() => {
  const { t } = useTranslation();
  const [resource, setResource] = useState<IClient | IRent | {}>({});
  const [modalType, setModalType] = useState("");
  const lng = LocaleStore.locale;
  const contact: any = contactStore.selected;

  useEffect(() => {
    if (contact.id) {
      const filter = { client_id: contact.id }
      rentService.loadResources(filter)
    }
    return () => rentStore.clearValues();
  }, [contact.id])


  const showModal = (resource: IClient | IRent | {}, type: string) => {
    setModalType(type)
    setResource(resource)
  }

  const handleCancel = () => setModalType("");

  const columns = [
    {
      title: t('properties.table.property'),
      dataIndex: 'property',
      key: 'property'
    }, {
      title: t('rents.startDate'),
      dataIndex: 'start_date',
      key: 'start_date',
    }, {
      title: t('rents.endDate'),
      dataIndex: 'end_date',
      key: 'end_date',
    },
    // {
    //   title: 'Rent Term',
    //   dataIndex: 'rent_term',
    //   key: 'rent_term',
    // },
    {
      title: t('rents.status'),
      dataIndex: 'status',
      key: 'status',
    }, {
      title: t('properties.new.monthlyPrice'),
      dataIndex: 'monthly_price',
      key: 'monthly_price',
    }, {
      title: t('deals.list.created'),
      dataIndex: 'created_at',
      key: 'created_at',
    }, {
      title: '',
      dataIndex: 'actions',
      key: 'actions'
    }
  ]

  const renderLastVisit = (data: string) => {
    if (!data) {
      return '';
    }

    const createdAtDate = moment(data).format('DD.MM.YYYY');
    const createdAtTime = moment(data).format('\\at kk:mm');
    return <div>{createdAtDate} <p>{createdAtTime}</p> </div>
  }

  const getData = () => {
    const rents = rentStore.values;
    return (rents || []).map((rent: any, index: number) => {

      return {
        property:  renderPropertyInfo(rent.property),
        start_date: rent.start_date,
        end_date: rent.end_date,
        // rent_term: rent.rent_term,
        status: <Tag>{rent.status}</Tag>,
        monthly_price: <span>{`${rent.monthly_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €</span>,
        created_at: <DateByCreator resource={rent} />,
        actions: <Dropdown overlay={getActionComponent(rent)} trigger={['click']}>
        <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
      </Dropdown>
      }
    })
  }

  const showConfirm = (rent: IRent) => {
    confirm({
      title: t('rents.confirmDelete'),
      content: '',
      onOk() {
        return new Promise((resolve, reject) => {

          // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          rentService.deleteResource(rent, resolve)
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }

  const getActionComponent = (rent: IRent) => {

    return (
      <Menu
        mode="horizontal"
        className='actons-menu'
      >
        <Menu.Item key={`edit_user_${rent.id}`} onClick={() => showModal(rent, 'Rent')}>
          <span className='edit-title'>
            <EditOutlined />
            {t('owners.table.edit')}
          </span>
        </Menu.Item>
        <Menu.Item key={`delete_property_${rent.id}`} onClick={() => showConfirm(rent)}>
          <span className='delete-title'>
            <DeleteOutlined />
            {t('owners.table.delete')}
          </span>
        </Menu.Item>
      </Menu>
    )
  }

  const renderPropertyInfo = (property: IProperty) => {

    if(!property) {
      return null;
    }
    return <div>
      <p><Link to={`/properties/${property.id}/overview`} className='link'>{name(property, lng)}</Link></p>
    </div>
  }

  return (
    <AppLayout>
      <div className="tab">
        <Modal
          title={t('properties.table.rent')}
          visible={!!modalType}
          footer={null}
          onCancel={handleCancel}
          destroyOnClose
          className='modal'
          maskClosable={false}
        >
          <RentForm client={contact} onCancelButton={handleCancel} rent={resource as IRent}/>
        </Modal>

        <div className='portlet'>
          <div className='portlet-head'>
            <Row justify='space-between' align='middle'>
              <h5><ShoppingOutlined className='mr-10 fs-20' />{t('rents.header')}</h5>
              <Button onClick={() => showModal({}, 'Rent')} type='primary'>{t('rents.addRent')}</Button>
            </Row>
          </div>
          <div className='portlet-body'>
            <Table
              dataSource={getData()}
              loading={ProgressStore.isLoading(LOAD_RENTS)}
              columns={columns}
              className='owners-table' bordered />
          </div>
        </div>
      </div>
    </AppLayout>
  );
})

export default Rent;
