import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { includes } from 'lodash';

import { Row, Col } from 'antd';
import PriceForm from 'modules/properties/containers/New/Steps/Price';
import { COMISSION_TYPE_SYMBOLS, WITH_COMISSION_VALUES, SALE_VALUE, RENT_VALUE, TRASPASO_VALUE } from 'constants/mixed';
import { EditOutlined } from "@ant-design/icons";

interface IData {
  property: any
}

const Price: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);

  const handleEditCancel = () => setIsEdit(false);

  const handleEdit = () => setIsEdit(true);

  const renderContent = () => {
    const  { property } = props;
    const price = property.price_attributes;
    let content;

    if(isEdit) {
      content = <div className='contact-card-content'>
      <PriceForm
        price={{ ...price }}
        operation={property.operation}
        isNotSteper
        handleCancel={handleEditCancel}
      /></div>
    } else {
      content = staticContent()
    }

    return content;
  }

  const staticContent = () => {
    const  { property } = props;
    const price = property.price_attributes;
    const isSale = includes(property.operation, SALE_VALUE) || includes(property.operation, TRASPASO_VALUE)

    return (
      <div className='contact-card-content'>
        { price && isSale &&
          <div className='mb-30'>
            <div className='mb-20'><h5>{t('properties.table.sale')}</h5></div>
            <Row>
              <Col span={6} className='pb-10'><p>
              {t('properties.table.price')}:
              </p> <strong>{`${price.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} € ({price.price_type == true ? 'Exact' : 'Starting'})</strong></Col>
              <Col span={6} className='pb-10'><p>
              {t('price.new.comission')}:
              </p><strong>
                  { `${price.comission_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') }
                  <span className='ml-5'>{ COMISSION_TYPE_SYMBOLS[price.comission_type] }</span> ({WITH_COMISSION_VALUES[price.with_comission]})
                  </strong>
              </Col>
              <Col span={6} className='pb-10'><p>{t('price.new.ownerPrice')}:</p> <strong>{`${price.owners_amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €</strong></Col>
              <Col span={6} className='pb-10'><p>{t('price.new.ibi')}:</p> <strong>{price.ibi ? (`${price.ibi}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €') : '-'}</strong></Col>
            </Row>
            <Row>
              <Col span={6} className='pb-10'><p>{t('price.new.gift')}:</p>
                <strong>
                  <span>
                    {t(`price.new.giftTypes.${price.gift_type}`)}
                    {(price.cashback && price.gift_type === 'cashback') &&
                      <span className='ml-5'>{`(${price.cashback} €)`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                    }
                  </span>
                </strong>
              </Col>
              <Col span={6} className='pb-10'><p>{t('price.new.mortgage')}:</p> <strong>{price.mortgage ? 'Yes' : 'No'}</strong></Col>
              <Col span={6} className='pb-10'><p>VV:</p> <strong>{price.vv ? 'Yes' : 'No'}</strong></Col>
            </Row>
          </div>
        }
        { price && includes(property.operation, RENT_VALUE) &&
          <div>
            <div className='mb-20'><h5>{t('properties.table.rent')}</h5></div>
            <Row>
              <Col span={6} className='pb-10'><p>
              {t('properties.new.monthlyPrice')}:
              </p><strong>{price.monthly_price ? (`${price.monthly_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €') : '-'}</strong></Col>
              <Col span={6} className='pb-10'><p>
              {t('price.new.fixedCommission')}:
              </p><strong>{price.fixed_commission ? (`${price.fixed_commission}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €') : '-'}</strong></Col>
              <Col span={6} className='pb-10'><p>
              {t('price.new.monthlyCommission')}:
              </p><strong>{price.monthly_commission ? (`${price.monthly_commission}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €') : '-'}</strong></Col>
              <Col span={6} className='pb-10'><p>
              {t('price.new.ownersMonthPrice')}:
              </p><strong>{price.owners_monthly_price ? (`${price.owners_monthly_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €') : '-'}</strong></Col>
            </Row>
          </div>
        }
      </div>
    )
  }

  return (
    <div className="contact-card-item">
      <Row justify='space-between' align='middle' className='contact-card-header'>
        <h5>{t('properties.table.price')}</h5>
        <EditOutlined onClick={handleEdit} />
      </Row>
      {renderContent()}
    </div>
  )
})

export default Price;
