import { FC, useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { taskService } from 'services/taskService';
import ProgressStore from 'stores/progress';
import TaskStore from 'stores/task';
import TaskItem from '../components/Item';
import TaskForm from '../components/Form';
import { LOAD_TASKS } from 'constants/action';
import { Button, Row, Col, Modal, Empty, Spin } from 'antd';

import { IContact } from 'contact';
import { Property as IProperty } from 'property';

interface IData {
  resource: IContact | IProperty,
  resource_type: string;
}

const Index: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.resource.id) {
      taskService.loadResources({
        taskable_id: props.resource.id,
        taskable_type: props.resource_type
      })
    }
    return () => TaskStore.clearValues();
  }, [])



  const handleCancel = () => setVisible(false);


  const renderCardLists = () => {
    const tasks = TaskStore.values;

    if (tasks.length > 0) {
      return tasks.map((item) => <TaskItem {...props} task={item} />)
    } else {
      return <Empty className='mt-100' description={t('tasks.noTasks')} />
    }
  }


  const { resource } = props;
  const tasks = TaskStore.values;

  if(ProgressStore.isLoading(LOAD_TASKS)) {
    return <Spin className='loader-spiner-container' />
  }

  if (!tasks) {
    return null;
  }

  return (
    <div className='tab'>
      <Row justify="center">
        <Modal
          title={t('tasks.newTask')}
          visible={visible}
          footer={null}
          width='650px'
          destroyOnClose
          onCancel={handleCancel}
          className='modal'
          maskClosable={false}
        >
          <TaskForm onCancelButton={handleCancel} resource={resource} resource_type={props.resource_type} />
        </Modal>

        <Col span={16}>
          <Row justify="space-between" className='mb-30' align='middle'>
            <Col>
              <h3>{t('tabs.tasks')} ({tasks.length})</h3>
            </Col>
            <Col>
              <Button onClick={() => setVisible(true)} type='primary' >
                {t('tasks.newTask')}
              </Button>
            </Col>
          </Row>

          <Row className="card-list" justify="center">
            {renderCardLists()}
          </Row>
        </Col>
      </Row>
    </div>
  );
})

export default Index;
