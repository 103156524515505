  import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { dealService } from 'services/DealService';
import DateByCreator from 'components/DateByCreator';
import { TYPE_OPERATIONS, DEAL_STATUS_FROM_OPERATION, DEAL_STATUS_COLOR } from 'constants/mixed';
import ProgressStore from 'stores/progress';
import WorkspaceStore from 'stores/workspace';
import { LOAD_DEALS } from 'constants/action';
import dealStore from 'stores/deal';
import { Table, Button, Row, Col, Tag } from 'antd';
import { ShoppingOutlined } from "@ant-design/icons";

import { IDeal } from 'declarations/deal';
import { useTranslation } from 'react-i18next';
import propertyStore from 'stores/property';
import AppLayout from '../../Layout';



const List: FC = observer(() => {
  const { t } = useTranslation();
  const property = propertyStore.selected;

  useEffect(() => {
    const filter = { property_id: property.id }
    dealService.loadResources(filter, 'all')
  }, [])

  const columns = [
    {
      title: t('deals.list.id'),
      dataIndex: 'id',
      key: 'id'
    }, {
      title: t('deals.new.operation'),
      dataIndex: 'operation',
      key: 'operation'
    }, {
      title: t('manager'),
      dataIndex: 'manager',
      key: 'manager',
    }, {
      title: t('deals.new.status'),
      dataIndex: 'status',
      key: 'status',
    }, {
      title: t('deals.new.budget'),
      dataIndex: 'budget',
      key: 'budget',
    }, {
      title: t('deals.new.stage'),
      dataIndex: 'stage',
      key: 'stage',
    }, {
      title: t('deals.list.client'),
      dataIndex: 'client',
      key: 'client',
    }, {
      title: t('deals.list.created'),
      dataIndex: 'created_at',
      key: 'created_at',
    }, {
      title: '',
      dataIndex: 'actions',
      key: 'actions'
    }
  ];

  const renderLastVisit = (data: string) => {
    if (!data) {
      return '';
    }

    const createdAtDate = moment(data).format('DD.MM.YYYY');
    const createdAtTime = moment(data).format('\\at kk:mm');
    return <div>{createdAtDate} <p>{createdAtTime}</p> </div>
  }

  const getData = () => {
    const deals = dealStore.values;
    return (deals || []).map((deal: any, index: number) => {
      const dealStage = deal.deals_properties.find((item: any) => item.property_id == property.id)

      return {
        id: deal.id,
        client: renderClient(deal),
        manager: <p className='ws-nw'>{deal.manager && deal.manager.name || '-'}</p>,
        operation: <span>{TYPE_OPERATIONS[deal.operation]}</span>,
        status: getStatusContent(deal),
        stage: <Row justify='center' >{dealStage && dealStage.status}</Row>,
        budget: <span>{deal.max_price ? `${deal.max_price} €`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '-'}</span>,
        created_at: <DateByCreator resource={deal} />,
        actions: linkToDealDetails(deal)
      }
    })
  }

  const getStatusContent = (deal: IDeal) => {
    const status = DEAL_STATUS_FROM_OPERATION[deal.operation][deal.status];

    return (
      <Tag color={DEAL_STATUS_COLOR[deal.status]} className={status.toLowerCase()}>
        {DEAL_STATUS_FROM_OPERATION[`${deal.operation}`][deal.status]}
      </Tag>
    )
  }

  const renderClient = (deal: IDeal) => {
    return <div>
      <p>{t('contact')}: <Link to={`/clients/${deal.client.id}/overview`}>{deal.client && deal.client.name}</Link></p>
      <p>{t('manager')}: {deal.client && deal.manager.name || '-'}</p>
    </div>
  }

  const linkToDealDetails = (deal: IDeal) => {
    return <Link to={`/clients/${deal.client_id}/deals/${deal.id}`} className=''>
      <Button> 	{t('deals.viewDeal')} </Button>
    </Link>
  }

  const deals = dealStore.values;
  return (
    <AppLayout>
      <div className="tab">
        <div className='portlet'>
          <div className='portlet-head'>
            <Row justify='space-between' align='middle'>
              <h5><ShoppingOutlined className='mr-10 fs-20' />{t('menu.deals')} ({deals.length || 0})</h5>
            </Row>
          </div>
          <div className='portlet-body'>
            <Table
              dataSource={getData()}
              loading={ProgressStore.isLoading(LOAD_DEALS)}
              columns={columns}
              className='owners-table' bordered />
          </div>
        </div>
      </div>
    </AppLayout>
  );
})

export default List;
