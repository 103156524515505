import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link, useParams, useNavigate } from 'react-router-dom'
import {  Row, Col, Modal, Menu, Select, Tag, Divider, Form } from 'antd';
import { includes, toLower } from 'lodash';
import classNames from 'classnames';
import { propertyTypesService } from 'services';
import { webResourceService } from 'services/webResourceService';
import { propertyService } from '../../PropertyService';
import propertyStore from 'stores/property';
import LocaleStore from 'stores/locale';
// import Details from './containers/Tabs/Details';
// import Promotions from './containers/Tabs/Promotions';
// import Deals from './containers/Tabs/Deals';
// import Rents from './containers/Tabs/Rents';
// import Requests from './containers/Tabs/Request';
// import Overview from './containers/Tabs/Overview';
import AddressForm from '../New/Steps/Address';
import DetailsForm from '../New/Steps/Details';
import PriceForm from '../New/Steps/Price';
import GeneralForm from '../New/Steps/General';
import GalleryForm from './components/Tabs/GalleryForm';
import ReviewForm from '../Edit/ReviewForm';
import Notes from 'submodules/notes/containers/index';
import Tasks from 'submodules/tasks/containers/index';
import Files from 'submodules/files/containers/index';
import { name } from 'utils/localized';
import { validationResource } from 'utils/promValidation'
import WebResourceStore from 'stores/webResource';
import { Helmet } from 'react-helmet';
import withoutImage from 'images/placeholder-image.png';
import { COMISSION_TYPE_SYMBOLS, SALE_VALUE, RENT_VALUE, TRASPASO_VALUE } from 'constants/mixed';
import { EditOutlined, EllipsisOutlined } from "@ant-design/icons";
import workspaceStore from 'stores/workspace';
import NoAccess from "modules/NoAccess";

const SubMenu = Menu.SubMenu;
const Option = Select.Option;
const confirm = Modal.confirm;

const Single: FC = observer((props) => {
  const [modal, setModal] = useState("");
  const [status, setStatus] = useState("");
  const location = useLocation();
  const lng = LocaleStore.locale;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams()
  const [form] = Form.useForm();


  useEffect(() => {
    propertyTypesService.loadResources()
    webResourceService.loadResources();
    propertyService.loadResource(Number(id));
  }, [])

  useEffect(() => {
    navigate('/properties');
  }, [workspaceStore.selectedId])

  const getPropertyType = () => {
    const property = propertyStore && propertyStore.selected;
    if (!property) {
      return
    }
    return name(property.property_type, lng);
  }

  const showModal = (content: string) => setModal(content);

  const handleCancel = () => setModal('');

  const renderNotesPropertyPage = () => {
    const property: any = propertyStore && propertyStore.selected;
    return <Notes {...props} resource={property} resource_type='Property' />;
  }

  const renderFilesPropertyPage = () => {
    const property: any = propertyStore && propertyStore.selected;
    return <Files {...props} resource={property} resource_type='Property' />;
  }

  const renderTasksPropertyPage = () => {
    const property: any = propertyStore && propertyStore.selected;
    return <Tasks {...props} resource={property} resource_type='Property' />;
  }

  const validateChanges = (params: any): Array<string> => {
    const property: any = propertyStore && propertyStore.selected;
    const newProperty = {...property, ...params}
    if(newProperty.portal_ids.length == 0) {
      return []
    }
    const resources = WebResourceStore.values;
    const propertyResources = resources.filter((resource: any) => newProperty.portal_ids.includes(resource.id.toString()) )

    return propertyResources.map((res: any) => validationResource(res.name, newProperty)).flat()
  }


  const renderContentModal = () => {
    const property = propertyStore && propertyStore.selected;
    switch (modal) {
      case 'details':
        const details = property && property.detail_attributes;
        return <DetailsForm
          handleCancel={handleCancel}
          operation={property.operation}
          details={details}
          isNotSteper={true}
          validateChanges={validateChanges}
        />;
        break;
      case 'location':
        const location = property && property.location_attributes;
        return <AddressForm
          handleCancel={handleCancel}
          locationAttributes={location}
          isNotSteper={true}
          form={form}
          validateChanges={validateChanges}
          property={property}
        />
        break;
      case 'price':
        const price = property && property.price_attributes;
        return <PriceForm
          handleCancel={handleCancel}
          price={price}
          operation={property.operation}
          isNotSteper={true}
        />;
        break;
      case 'general':
        return <GeneralForm
          handleCancel={handleCancel}
          isNotSteper={true}
          general={property}
          validateChanges={validateChanges}
        />;
        break;
      case 'gallery':
        return <GalleryForm
          handleCancel={handleCancel}
          images={property.images}
          property={property}
        />;
      case 'promotion':
        return '';
        break;
      case 'review':
        return <ReviewForm
          status={status}
          handleCancel={handleCancel}
          property={property}
        />;
    }

    return '';
  }

  const updateStatus = (value: string, type: string) => {
    const property = propertyStore && propertyStore.selected;
    if(property.status === 'review') {
      setModal('review');
      setStatus(value);
      return;
    }
    confirm({
      title: t('properties.confirm'),
      content: '',
      onOk() {
        return new Promise((resolve, reject) => {
          const property = propertyStore && propertyStore.selected;
          propertyService.updateResource('properties', {id: property.id, [type]: value}, resolve)
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }

  const showOwnerContent = () => {
    const property = propertyStore && propertyStore.selected;
    if (!property.owner) {
      return '-'
    }
    const owner = property.owner;

    return <Link to={`/owners/${owner.id}/overview`} target='_blank' className='link'>{owner.name}</Link>
  }

  const renderSaleStatus = () => {
    const property = propertyStore && propertyStore.selected;

    return(
      <Select
        value={property.sale_status}
        onChange={(value: string) => updateStatus(value, 'sale_status')}
        className='status-menu-btn'
        showArrow={true}
        dropdownMatchSelectWidth={false}
        filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        <Option value='is_available'><Tag color='#4CAF50'>
          { t('properties.new.status.available') }
        </Tag></Option>
        <Option value='is_reserved'><Tag color='#9E9E9E'>
          {t('properties.new.status.reserved')}
        </Tag></Option>
        <Option value='sold'><Tag color='#FF9800'>
          {t('properties.new.status.sold')}
        </Tag></Option>
      </Select>
    )
  }

  const renderRentStatus = () => {
    const property = propertyStore && propertyStore.selected;

    return (
      <Select
        value={property.rent_status}
        onChange={(value: string) => updateStatus(value, 'rent_status')}
        className='status-menu-btn'
        showArrow={true}
        filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        dropdownMatchSelectWidth={false}
      >
        <Option value='available'><Tag color='#4CAF50'>{t('properties.new.status.available')}</Tag></Option>
        <Option value='reserved'><Tag color='#9E9E9E'>{t('properties.new.status.reserved')}</Tag></Option>
        <Option value='rented'><Tag color='#FF9800'>{t('properties.new.status.rented')}</Tag></Option>
      </Select>
    )
  }

    const property = propertyStore && propertyStore.selected;

    if(!propertyStore.hasAcccess) {
      return <NoAccess link="/properties" />
    }

    if (!property.id) {
      return null
    }

    const locationAttr = property.location_attributes;
    const detail = property.detail_attributes;
    const price = property.price_attributes;
    const isSale = includes(property.operation, SALE_VALUE) || includes(property.operation, TRASPASO_VALUE)

    return (
      <div className="properties-page page single">
        <Modal
          title={`${t('owners.table.edit')} ${t(`properties.new.steps.${toLower(modal)}`)}`}
          visible={!!modal}
          footer={null}
          className='modal'
          maskClosable={false}
          width={1000}
          onCancel={handleCancel}
          destroyOnClose
        >
          {renderContentModal()}
        </Modal>
        <Helmet>
          <title>
            {`${isSale ? "VS" : "VR"}${property.identifier}`} - {getPropertyType()} - {locationAttr && locationAttr.island && locationAttr.island.name}
          </title>
        </Helmet>
        <Row className='page-subheader portlet'>
          <Col className='subheader-top'>
            <Row justify='space-between' align='middle'>
              <Col>
                <Row align='middle'>
                  <h3 className='mr-10'>{name(property, lng)}</h3>
                  <Select
                    className='status-menu-btn'
                    onChange={(value: string) => updateStatus(value, 'status')}
                    value={property.status}
                    showArrow={true}
                    dropdownMatchSelectWidth={false}
                    filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option className='status-option' key="published"><Tag color='#4CAF50'>
                      {t('properties.new.status.published')}
                    </Tag></Option>
                    <Option className='status-option' key="draft"><Tag color='gray'>
                    {t('properties.new.status.draft')}
                    </Tag></Option>
                    <Option className='status-option' key="archive"><Tag color='#ff0000'>
                    {t('properties.new.status.archive')}
                    </Tag></Option>
                    <Option className='status-option' key="review"><Tag color='#cea143'>
                    {t('properties.new.status.review')}
                    </Tag></Option>
                  </Select>
                </Row>
              </Col>
              <Col>
                <Menu
                  mode="horizontal"
                  className='actions-menu'
                >
                  <SubMenu title={<span className='submenu-title-wrapper'><EllipsisOutlined /></span>}>
                    <Menu.Item key={`edit_property_${property.id}`}>
                      <span className='edit-title'>
                        <EditOutlined />
                        <Link to={`/properties/${property.id}/edit`}>{t('properties.edit.header')}</Link>
                      </span>
                    </Menu.Item>
                    <Menu.Item key={`general_property_${property.id}`} onClick={() => showModal('general')}>
                      <span className='edit-title'>
                        <EditOutlined />
                        {t('properties.generalInfo')}
                      </span>
                    </Menu.Item>
                    <Menu.Item key={`price_property_${property.id}`} onClick={() => showModal('price')}>
                      <span className='edit-title'>
                        <EditOutlined />
                        {t('properties.table.price')}
                      </span>
                    </Menu.Item>
                    <Menu.Item key={`details_property_${property.id}`} onClick={() => showModal('details')}>
                      <span className='edit-title'>
                        <EditOutlined />
                        {t('properties.new.steps.details')}
                      </span>
                    </Menu.Item>
                    <Menu.Item key={`gallery_property_${property.id}`} onClick={() => showModal('gallery')}>
                      <span className='edit-title'>
                        <EditOutlined />
                        {t('properties.new.steps.gallery')}
                      </span>
                    </Menu.Item>
                    {/* <Menu.Item key={`promotion_property_${property.id}`} onClick={() => showModal('promotion')}>
                      <span className='edit-title'>
                        <EditOutlined />
                        {t('properties.new.steps.promotion')}
                      </span>
                    </Menu.Item> */}
                  </SubMenu>
                </Menu>
              </Col>
            </Row>
          </Col>

          <Col className='subheader-body'>
            <Row>
              <Col span={10}>
                <Row className='fx-nw'>
                  <Col>
                    <img
                      src={property.current_image_url || withoutImage}
                      width='195'
                      height='127'
                      className='property-img'
                    />
                  </Col>
                  <Col className='pl-20'>
                    <div className='property-info'>
                      <div className='mb-10'>
                        <strong className='mr-10'>{t('properties.new.type')}: </strong>
                        {getPropertyType()}
                        { `${property.new_building}` == 'true' && [
                            <Divider type='vertical' />, t('properties.new.newBuilding')
                          ]
                        }
                      </div>
                      <div className='mb-10'><strong>{t('properties.new.totalArea')}: </strong> {detail && detail.total_area || 0} m²</div>
                      <div className='mb-10'><strong>{t('properties.new.city')}: </strong> {locationAttr && locationAttr.city && locationAttr.city.name} </div>
                      <div className='mb-10'><strong>{t('contacts.new.address')}: </strong> {locationAttr && locationAttr.address}</div>
                      <div className='mb-10'><strong>{t('properties.new.owner')}: </strong> {showOwnerContent()}</div>
                    </div>
                  </Col>
                </Row>
              </Col>
              { price && isSale &&
                <Col span={6} className='pl-20'>
                  <div className='mb-10 sale accent'>VS{property.identifier}</div>
                  <div className='mb-10'><strong>{t('properties.table.status')}: </strong>{renderSaleStatus()}</div>
                  <div className='mb-10'><strong>{t('properties.table.price')}: </strong> {`${price.amount  || '-'}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €</div>
                  <div className='mb-10'>
                    <strong className='mb-10'>{t('price.new.gift')}: </strong>
                    <span>
                      {t(`price.new.giftTypes.${price.gift_type}`)}
                      {(price.cashback && price.gift_type === 'cashback') &&
                        <span className='ml-5'>{`(${price.cashback} €)`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                      }
                    </span>
                  </div>
                  <div className='mb-10'>
                    <strong>{t('price.new.comission')}: </strong>
                    {`${price.comission_value  || '-'}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
                    {COMISSION_TYPE_SYMBOLS[price.comission_type] || '-'}
                    </div>
                  <div className='mb-10'><strong>{t('price.new.ownerPrice')}: </strong> {`${price.owners_amount  || '-'}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    <span className='ml-5'>
                      {t('price.new.mortgage')}: {price.mortgage ? t('properties.new.yes') : t('properties.new.no')}
                    </span>
                  </div>
                </Col>
              }
              {price && includes(property.operation, RENT_VALUE) &&
                <Col span={6} className='pl-20'>
                  <div className='mb-10 rent accent'>VR{property.identifier}</div>
                  <div className='mb-10'>
                    <strong>{t('properties.table.status')}: </strong> {renderRentStatus()}
                  </div>
                  <div className='mb-10'><strong>{t('properties.new.monthlyPrice')}: </strong>
                    {`${price.monthly_price  || '-'}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} € / {t('properties.month')}</div>
                  <div className='mb-10'><strong>{t('price.new.fixedCommission')}: </strong>
                    {`${price.fixed_commission  || '-'}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  </div>
                  <div className='mb-10'><strong>{t('price.new.monthlyCommission')}: </strong>
                    {`${price.monthly_commission  || '-'}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  </div>
                  <div className='mb-10'><strong>{t('price.new.ownersMonthPrice')}: </strong>
                    {`${price.owners_monthly_price  || '-'}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  </div>
                </Col>
              }
            </Row>
          </Col>

          <Col className="subheader-bottom">
            <Row>
              <Col span={24}>
                <ul className='subheader-navigation'>
                  <li className={classNames({ active: location.pathname.includes('overview') })}>
                    <Link to={`/properties/${property.id}/overview`}>{t('contacts.single.overview')}</Link>
                  </li>
                  <li className={classNames({ active: location.pathname.includes('details') })}>
                    <Link to={`/properties/${property.id}/details`}>{t('properties.new.steps.details')}</Link>
                  </li>
                  <li className={classNames({ active: location.pathname.includes('promotions') })}>
                    <Link to={`/properties/${property.id}/promotions`}>
                      {t('properties.new.steps.promotion')} ({property.promotions_count})
                    </Link>
                  </li>
                  <li className={classNames({ active: location.pathname.includes('deals') })}>
                    <Link to={`/properties/${property.id}/deals`}>{t('deals.header')}</Link>
                  </li>
                  <li className={classNames({ active: location.pathname.includes('rents') })}>
                    <Link to={`/properties/${property.id}/rents`}>{t('tabs.rent')} ({property.rents_count})</Link>
                  </li>
                  <li className={classNames({ active: location.pathname.includes('requests') })}>
                    <Link to={`/properties/${property.id}/requests`}>{t('tabs.requests')}
                      {property.requests_count}
                    </Link>
                  </li>
                  <li className={classNames({ active: location.pathname.includes('notes') })}>
                    <Link to={`/properties/${property.id}/notes`}>{t('tabs.notes')} {property.notes_count}</Link>
                  </li>
                  <li className={classNames({ active: location.pathname.includes('tasks') })}>
                    <Link to={`/properties/${property.id}/tasks`}>{t('tabs.tasks')} {property.tasks_count}</Link>
                  </li>
                  <li className={classNames({ active: location.pathname.includes('files') })}>
                    <Link to={`/properties/${property.id}/files`}>{t('tabs.files')} {property.files_count}</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row justify='space-between' className='page-content'>
          <Routes>
            <Route path="/properties/:id/overview" element={renderOverviewPropertyPage()}/>
            <Route path="/properties/:id/deals">{renderDealsPropertyPage()}</Route>
            <Route path="/properties/:id/rents">{renderRentsPropertyPage()}</Route>
            <Route path="/properties/:id/requests">{renderRequestsPropertyPage()}</Route>
            <Route path="/properties/:id/details">{renderDetailsPropertyPage()}</Route>
            <Route path="/properties/:id/promotions">{renderPromotionsPropertyPage()}</Route>
            <Route path="/properties/:id/notes">{renderNotesPropertyPage()}</Route>
            <Route path="/properties/:id/tasks">{renderTasksPropertyPage()}</Route>
            <Route path="/properties/:id/files">{renderFilesPropertyPage()}</Route>
            <Route path="/properties/:id/files">{renderFilesPropertyPage()}</Route>
          </Routes>
        </Row> */}
      </div >
    );
});

export default Single;
