import i18n from 'utils/i18n';
import { makeAutoObservable } from 'mobx';

export class LocaleStore {
  locale: string | undefined = "en";

  constructor() {
    makeAutoObservable(this);
  }

  setLocale(lang: string) {
    this.locale = lang;
    i18n.changeLanguage(lang)
  }
}

export default(new LocaleStore());