import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Row, Col} from 'antd';
import ActivityStore from 'stores/activity';
import ownerStore from 'stores/owner';
import OwnerDetails from '../components/OwnerDetails';
import OwnerForm from 'modules/owners/components/New/Form'
import ActivityList from 'submodules/owners/components/ActivityList';
import AppLayout from "modules/owners/containers/Single/components/Layout";
import { EditOutlined } from "@ant-design/icons";

const Overview: FC = observer(() => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const owner: any = ownerStore.selected;

  useEffect(() =>  {
    return () => ActivityStore.clearValues()
  }, [owner?.id])

  const editOwner = () => setIsEdit(true);

  const cancelEdit = () => setIsEdit(false);

  return (
    <AppLayout>
      <div className="tab">
        <Row justify='space-between' align='top'>
          <Col span={8} className='portlet'>
            <Row justify='space-between' className='portlet-head'>
              <h5>{t('owners.ownerDetail')}</h5>
              <EditOutlined className='cursor-pointer' onClick={editOwner} />
            </Row>

            <div className='portlet-body'>
              {isEdit ? <OwnerForm owner={owner} onCancelButton={cancelEdit} /> : <OwnerDetails owner={owner} />}
            </div>
          </Col>

          <Col span={15}>
            <ActivityList owner={owner} />
          </Col>
        </Row>
      </div>
    </AppLayout>
  );
})

export default Overview;
