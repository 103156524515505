import { action } from 'utils/mobx';
import { request } from 'utils/mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import { message } from 'antd';
import  userStore  from 'stores/user';
import workspaceStore from 'stores/workspace';
import { USERS_URL, CONNECTED_URL } from 'constants/api';
import { LOAD_USERS } from 'constants/action';
import { IUser } from "declarations/user";

interface FilterType {

};

interface IResponseType {
  status: number,
  data: {
    users: Array<IUser>,
    id: number;
    users_count: number
  }
}
declare interface Props {
  loadResources(params?: FilterType, pageNum?: number | 'all'): any,
  loadResourcesRequest(queryParams: {}): Promise<object>
}

export class UserListService implements Props {

  //LOAD profile
  @request()
  async loadProfileRequest(user_id: number): Promise<{ status: number, data: IUser}> {
    return axios.get(`${USERS_URL}/${user_id}`)
  }

  async loadProfile(user_id: number) {
    const response: { status: number, data: IUser } = await this.loadProfileRequest(user_id);
    userStore.setSelected(response.data);
  }

  // LOAD resourceS
  @request()
  async loadResourcesRequest(params: FilterType | {}){
    return axios.get(USERS_URL, {
      params
    })
  }

  @action({ action: LOAD_USERS, minRequestTime: 500 })
  async loadResources(params: FilterType = {}, page: number | 'all' = 1) {

    const queryParams = {
      workspace_id: workspaceStore.selectedId,
      ...params,
      page
    };

    const response: IResponseType = await this.loadResourcesRequest(queryParams);

    if (response.status == 200) {
      let values = [];
      values = response.data.users;
      this.addValues(values, page, response.data.users_count, queryParams);
    }

    return response;
  }

  @request()
  async createResourceRequest(params: IUser) {
    return axios.post(USERS_URL, { user:
      params
    })
  }


  async createResource(params: any, callback: Function) {

    const response: { status: number, data: { message?: Array<string>} } = await this.createResourceRequest(params);
    if (response.status == 200) {
      callback();
      this.loadResources();
      message.success('User created!')
    } else {
      message.error((response.data.message || []).join())
    }

  }



  @request()
  async createConnectionRequest(params: IUser) {
    return axios.post(`${CONNECTED_URL}`, { connection:
      params
    })
  }


  async createConnection(params: any, callback?: Function) {

    const response: { status: number, data: {user: IUser,  message?: Array<string>} } = await this.createConnectionRequest(params);

    if (response.status == 200) {
      this.updateValue(response.data.user)
      message.success('Gmail connected!')
    } else {
      message.error((response.data.message || []).join())
    }

  }

  @request()
  async deleteConnectionRequest(params: IUser) {
    return axios.delete(`${CONNECTED_URL}/${params['id']}`)
  }


  async deleteConnection(params: any, callback?: Function) {

    const response: { status: number, data: {user: IUser,  message?: Array<string>} } = await this.deleteConnectionRequest(params);

    if (response.status == 200) {
      this.updateValue(response.data.user)
      message.success('Gmail disconnected!')
    } else {
      message.error((response.data.message || []).join())
    }

  }

  @request()
  async updateResourceRequest(params: IUser) {
    return axios.put(`${USERS_URL}/${params.id}`, { user:
      params
    })
  }


  async updateResource(params: any, callback: Function) {

    const response: { status: number, data: any } = await this.updateResourceRequest(params);

    if (response.status == 200) {
      callback(response.data);
      this.loadResources();
      if(response.data && response.data.id) {
        this.updateValue(response.data)
      }
      message.success('User updated!')
    } else {
      message.error((response.data.message || []).join())
    }

  }

  @request()
  async deleteResourceRequest(user_id: number) {
    return axios.delete(`${USERS_URL}/${user_id}`)
  }


  async deleteResource(user_id: number, callback?: Function) {

    const response: { status: number } = await this.deleteResourceRequest(user_id);

    if (response.status) {
      message.success('User deleted!')
      this.loadResources()
      callback && callback()
    }

  }

  updateValue(value: IUser) {
    userStore.setSelected(value);
  }

  addValues(values: Array<IUser>, page: number | 'all', count: number, queryParams: {}) {
    userStore.addValues(values, page, count, queryParams);
  }
}

export const userListService = new UserListService();
