import { makeAutoObservable } from 'mobx';

import { IRequest } from 'declarations/request';
import { IClient } from 'contact';

interface IFilterKeys {
  operations?: any,
  source_id?: any,
  creation_date?: any,
  language?: any,
  contacts?: any,
  properties?: any
}

export class Deal {
  values: Array<IRequest> = [];
  count: number = 0;
  page: number | 'all' = 1;
  filters: Object = {};
  fetchInProgress = false;
  saveInProgress = false;
  deleteInProgress = false;

  filterKeys: IFilterKeys = {
    operations: [],
    source_id: [],
    creation_date: [],
    language: [],
    contacts: [],
    properties: []
  }

  constructor() {
    makeAutoObservable(this);
  }


  addValues(values: Array<IRequest>, page: number | 'all', count: number, queryParams: Object) {
    this.values = values;
    this.page = page;
    this.count = count;
  }

  updateValue(request: IRequest) {
    this.values = this.values.map((value: IRequest) => value.id != request.id ? value : request)
  }

  updateContactValue(client: IClient) {
    this.values = this.values.map((value: IRequest) => value.client_id != client.id ? value : {...value, client})
  }

  clearData(): void {
    this.values = [];
    this.resetFilter();
  }

  updateFilter(newFilter: {}) {
    this.filterKeys = {...this.filterKeys, ...newFilter}
  }

  resetFilter() {
    this.filterKeys = {
      operations: [],
      source_id: [],
      creation_date: [],
      language: [],
      contacts: [],
      properties: []
    }
  }

  setFetchInProgress(val: boolean) {
    this.fetchInProgress = val;
  }
  setSaveInProgress(val: boolean) {
    this.saveInProgress = val;
  }
  setDeleteInProgress(val: boolean) {
    this.deleteInProgress = val;
  }
  setPage(page: number | "all") {
    this.page = page;
  }

}

export default (new Deal())

