import { FC } from 'react';
import { observer } from 'mobx-react';
import { Row } from 'antd';


const General: FC = observer(() => {

  return (
    <Row justify='space-between'>
      <h3>Reports</h3>
    </Row>
  );
})

export default General;
