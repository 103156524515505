import { makeAutoObservable } from 'mobx';
import { saveAs } from 'file-saver';
import { IOwner } from 'declarations/contact';
import * as services from "services/ownersService";
import workspaceStore from './workspace';
export interface IOwnerList {
  values: IOwner
  // debouncedSetToken(token: string): any
}

interface IFilterKeys {
    contact_types?: any,
    source_ids?: any,
    languages?: any,
    manager_ids?: any,
    q?: string,
    date_from?: any,
    date_to?: any,
    owns_properties?: number,
    has_tasks?: boolean | string,
    page?: number | 'all';
}

interface IResponse {
  owners: IOwner[];
  count: number;
}

export class Owner {
  values: Array<IOwner> = [];
  page: number | 'all' = 1;
  count: number = 0;
  hasAcccess: boolean = true;
  filterKeys: IFilterKeys = {};
  selected?: IOwner;

  fetchInProgress = false;
  fetchSingleInProgress = false;
  saveInProgress = false;
  deleteInProgress = false;
  exportInProgress = false;
  fetchError: any = undefined;
  saveError: any = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchOwners(payload: IFilterKeys = {}, page?: number | 'all') {
    this.fetchInProgress = true;
    if (!workspaceStore.selectedId) {
      return null;
    }
    const params = {
      workspace_id: workspaceStore.selectedId,
      ...this.filterKeys,
      page: page || this.page,
      ...payload,
    }
    try {
      const data  = await services.getOwners(params);
      this.values = data.owners;
      this.count = data.count;
      if (page) {
        this.page = page;
      }
    } catch (err) {
      this.fetchError = err;
    }
    this.fetchInProgress = false;
  }

  async fetchOwner(ownerId: number) {
    this.fetchSingleInProgress = true;
    this.updateHasAccess(true);
    try {
      const data = await services.getById(ownerId);
      this.selected = data;
    } catch (err: any) {
      if(err.response.status == 403) {
        this.updateHasAccess(false);
      }
      this.fetchError = err;
    }
    this.fetchSingleInProgress = false;
  }

  async create(data: Partial<IOwner>) {
    this.saveInProgress = true;
    if (!workspaceStore.selectedId) {
      return null;
    }
    const params = {
      workspace_id: workspaceStore.selectedId,
      ...data,
    }
    try {
      await services.create(params);
    } catch (err) {
      this.saveError = err;
    }
    this.saveInProgress = false;
  }

  async update(ownerId: number, data: Partial<IOwner>) {
    this.saveInProgress = true;
    if (!workspaceStore.selectedId) {
      return null;
    }
    const params = {
      workspace_id: workspaceStore.selectedId,
      id: ownerId,
      ...data,
    }
    try {
      const response = await services.update(ownerId, params);
      this.addValue(response);
    } catch (err) {
      this.saveError = err;
    }
    this.saveInProgress = false;
  }

  async deleteOwner(ownerId: number) {
    this.deleteInProgress = true;

    try {
      await services.del(ownerId);
    } catch (err) {
      this.saveError = err;
    }
    this.deleteInProgress = false;
  }

  async export(params: IFilterKeys = {}, pageNum: number | 'all' = 1) {
    const filters = this.filterKeys;
    let page = pageNum;

    const queryParams = {
      workspace_id: workspaceStore.selectedId,
      ...filters,
      ...params,
      page
    };
    this.setExportInProgress(true);
    const response: any = await services.exportOwners(queryParams);

    if (response.status == 200) {
      const blob = new Blob([response.data])
      saveAs(blob, 'owners.csv')
    }

    this.setExportInProgress(false);
    return response;
  }



  addValues(values: Array<IOwner>, page: number | 'all', count: number, filters: {}) {
    this.values = values;
    this.page = page;
    this.count = count;
    this.filterKeys = {...this.filterKeys ,...filters};
  }

  updateFilter(newFilter: {}) {
    this.filterKeys = {...this.filterKeys, ...newFilter}
  }

  resetFilter() {
    this.filterKeys = {};
    this.page = 1;
  }

  addSelectedValue(value: IOwner): void {
    this.selected = value;
  }

  addSelectedAttributes(params: any): void {
    this.selected = { ...this.selected, ...params };
  }

  addValue(value: IOwner) {
    this.values = this.values.map((item: IOwner) => item.id == value.id ? value : item)
    this.selected = value;
  }

  clearSelected() {
    this.selected = undefined;
  }

  updateHasAccess(val: boolean): void {
    this.hasAcccess = val;
  }

  clearData(): void {
    this.selected = undefined;
    this.values = [];
    this.page = 1
  }

  clearErrors(): void {
    this.saveError = undefined;
    this.fetchError = undefined;
  }

  clear() {
    this.clearData();
    this.clearErrors();
    this.resetFilter();
  }

  setPage(page: number | "all") {
    this.page = page;
  }

  setFetchInProgress(val: boolean) {
    this.fetchInProgress = val;
  }
  setSaveInProgress(val: boolean) {
    this.saveInProgress = val;
  }
  setDeleteInProgress(val: boolean) {
    this.deleteInProgress = val;
  }
  setExportInProgress(val: boolean) {
    this.exportInProgress = val;
  }

}

export default (new Owner())

