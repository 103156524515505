import { FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CONTACT_TYPES } from 'constants/mixed';
import { IClient } from 'contact';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from "@ant-design/icons";
import { get, isEmpty, uniqueId } from 'lodash';
import workspaceStore from "stores/workspace";


interface IData {
  client: IClient,
}

const CLIENT_PERSON_VALUE = 0;

const ClientDetails: FC<IData> = observer(({ client }) => {

  const { t } = useTranslation();

  const renderLanguages = (languages: Array<string>) => {
    if(!languages || languages.length == 0) {
      return '-';
    }

    return (languages || []).map((language: string) => <img src={require(`../../../images/flags/${language}.png`)} width='24px' className='mr-10 flag' />)
  }

  const renderContactAttributes = (attributes: any) => {
    if(attributes.length == 0) {
      return '-';
    }


    return attributes.map((attribute: {value: string}) => {
      const same_emails = client.same_emails.filter((e) => attribute.value === e.email)
      return (
        <p key={`email-${attribute.value}`}>
          <span className='mr-10'>{attribute.value || '-'}</span>
          <span>{!isEmpty(same_emails) &&
            <Tooltip
              overlayClassName='w-400'
              title={
                <div>
                  {
                    same_emails.map((emailData) =>
                      <div className='mb-10' key={uniqueId()}>{t(`clients.new.${emailData.type}Exists`)} <Link className='link-primary' target="_blank" to={`/${emailData.type}s/${emailData.resource_id}/overview`}>{t("clients.new.viewDetails")} ({workspaceStore.getWorkspaceName(emailData.workspace_id) || t("anotherWorkspace")})</Link></div>
                    )
                  }
                </div>
              }
            >
              <InfoCircleOutlined className='text-orange' />
            </Tooltip>}
          </span>
        </p>
      )
    })
  }

    const isNotPerson = client.client_type != CLIENT_PERSON_VALUE;

    if(!client) {
      return null
    }

    const createdAtDate = moment(client.created_at).format('DD.MM.YYYY [at] HH:mm');
    const creatorName = client.creator && client.creator.name || '-'


  return (
    <div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.table.type')}</span>
        <p>{ CONTACT_TYPES[client.client_type.toString()] }</p>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.table.name')}</span>
        <p>{client.name}</p>
      </div>
      { isNotPerson &&
        <div className="mb-20">
          <span className='contact-detail-title'>{t('contacts.new.website')}</span>
          <p>{client.website || '-'}</p>
        </div>
      }
      { isNotPerson &&
        <div className="mb-20">
          <span className='contact-detail-title'>{t('contacts.new.address')}</span>
          <p>{client.address || '-'}</p>
        </div>
      }
      { isNotPerson &&
        <div className="mb-20">
          <span className='contact-detail-title'>{t('contacts.new.contactPerson')}</span>
          <p>{client.client_person || '-'}</p>
        </div>
      }
      <div className="mb-20">
        <span className='contact-detail-title'>{t('tabs.email')}</span>
        <div className='contacts-attributes'>
          {renderContactAttributes(client.emails_attributes)}
        </div>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('owners.table.phone')}</span>
        <div className='contacts-attributes'>
          {renderContactAttributes(client.phones_attributes)}
        </div>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.new.mainLang')}</span>
        <p>{renderLanguages(client.languages)}</p>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.new.otherLang')}</span>
        <p>{renderLanguages(client.other_languages)}</p>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.new.nie')}</span>
        <p>{client.nie || '-'}</p>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.new.source')}</span>
        <p>{client.source && client.source.name}</p>
      </div>
      <div className="mb-20">
        <span className='contact-detail-title'>{t('contacts.single.added')}</span>
        <p>{createdAtDate} <span className='fw-300'>by</span> {creatorName}</p>
      </div>
    </div>
  )
})

export default ClientDetails;
