import { FC } from 'react';
import { observer, } from 'mobx-react';
import propertyStore from 'stores/property';
import AppLayout from '../../Layout';
import Notes from 'submodules/notes/containers/index';

const PropertyNotes: FC = observer(() => {
  const property = propertyStore.selected;

  return (
    <AppLayout>
      <Notes resource={property} resource_type='Property' />
    </AppLayout>
  )

})

export default PropertyNotes;
