import { FC, useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Select, DatePicker } from 'antd';
import { requestService } from 'services/requestService';
import { sourceService } from 'services/sourceService';
import { propertyService } from 'modules/properties/PropertyService';
import clientStore from 'stores/client';
import workspaceStore from 'stores/workspace';
import PropertyStore from 'stores/property';
import SourceStore from 'stores/source';
import LocaleStore from 'stores/locale';
import { LANGUAGES } from "constants/mixed"

import { IClient, IContact } from 'declarations/contact';
import { ISource } from 'declarations/source';
import { Property as IProperty } from 'declarations/property';
import { keys } from 'lodash';
import { name } from 'utils/localized';


const { Option, OptGroup } = Select;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const  DEFAUL_FILTER_VALUE = {
  operations: [],
  source_id: [],
  creation_date: [],
  language: [],
  contacts: [],
  properties: []
}

const FilterRequest: FC = observer(() => {
  const [filterKeys, setFilterKeys] = useState(DEFAUL_FILTER_VALUE);
  const [form] = Form.useForm();
  const lng = LocaleStore.locale;
  const { t } = useTranslation();

  useEffect(() => {
    form.resetFields();
    clientStore.fetchClients({}, 'all');
    sourceService.loadResources();
    propertyService.loadSelectResources({})
    return () => {
      clientStore.clearData();
      PropertyStore.clearData();
    }
  }, [workspaceStore.selectedId])

  const resetFilters = () => {
    form.resetFields();
    setFilterKeys(DEFAUL_FILTER_VALUE);
    requestService.resetFilters();
  }

  const getFieldChange = async (value: any, name: string) => {
    console.log("🚀 ~ file: FilterRequests.tsx:60 ~ getFieldChange ~ name:", name)
    setFilterKeys({...filterKeys, [name]: value })
    const values = await form.getFieldsValue();

    const dates: any = values.creation_date;
    let newFilterKeys: any = {...values, [name]: value};
    delete newFilterKeys.creation_date;
    let dataFilter = {'date_from':  null, 'date_to': null};
    if (dates) {
      dataFilter = { 'date_from':  dates[0], 'date_to': dates[1] };
    }
    newFilterKeys = {...newFilterKeys, ...dataFilter};
    requestService.changeFilters(newFilterKeys)
  }

  const onFilterDate = async (dates: any) => {
    setFilterKeys({...filterKeys, creation_date: dates })
    const values = await form.getFieldsValue();

    let newFilterKeys: any = {...values};
    delete newFilterKeys.creation_date;
    let dataFilter;
    if(dates && dates.length) {
      dataFilter = { 'date_from':  dates[0], 'date_to': dates[1] }
    } else {
      dataFilter = { creation_date: null }
    }
    newFilterKeys = {...newFilterKeys, ...dataFilter}
    requestService.changeFilters(newFilterKeys)
  }

  const renderClients = () => {
    const clients = clientStore.values;
    return (clients || []).map((client: IClient) => <Option value={client.id} key={`client_${client.id}`}>{client.name}</Option>)
  }

  const loadProperty = () => {
    const properties = PropertyStore.values;
    return properties.map((property: IProperty) => <Option value={property.id}>{name(property, lng)}</Option>)
  }

  const loadSources = () => {
    const sources = SourceStore.groupedValues;
    const sourceKeys = keys(sources)

    return sourceKeys.map((category: string) => {
      return <OptGroup key={`source_${category}`} label={category}>
        {sources[category].map((source: ISource) => <Option value={source.id} key={`source_${source.id}`}>{source.name}</Option>)}
      </OptGroup>
    })
  }
  return (
    <Form className='filter-form' form={form}>
      <Form.Item
        {...formItemLayout}
        label={t('deals.new.operation')}
        name="operations"
      >
        <Select
          showArrow={true}
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'operations')}
          placeholder={t('placeholder.all')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value="sale">{t('properties.table.sale')}</Option>
          <Option value="rent">{t('properties.table.rent')}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('contacts.new.source')}
        name="source_id"
      >
        <Select
          showArrow={true}
          mode='multiple'
          showSearch
          onChange={(value: any) => getFieldChange(value, 'source_id')}
          placeholder={t('placeholder.all')}
          filterOption={(input: any, option: any) => typeof(option.props.children) == 'string' && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {loadSources()}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('contacts.new.creationDate')}
        name="creation_date"
      >
        <DatePicker.RangePicker
          format="DD.MM.YYYY"
          onChange={onFilterDate}
          className="w-100p"
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('contacts.new.language')}
        name="language"
      >
        <Select
          showArrow={true}
          mode='multiple'
          placeholder={t('placeholder.all')}
          onChange={(value: any) => getFieldChange(value, 'language')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {LANGUAGES.map((lng: string) => (
            <Option key={`languages-${lng}`} value={lng}>{t(`contacts.new.lng.${lng}`)}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('clients.table.client')}
        name="clients"
      >
        <Select
          showArrow={true}
          mode='multiple'
          showSearch
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value: any) => getFieldChange(value, 'clients')}
          placeholder={t('placeholder.all')}
        >
          {renderClients()}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('owners.table.property')}
        name="properties"
      >
        <Select
          showArrow={true}
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'properties')}
          placeholder={t('placeholder.all')}
          showSearch
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {loadProperty()}
        </Select>
      </Form.Item>
      <Form.Item
      >
        <Button type='primary' onClick={resetFilters} style={{ width: '100%' }}>
          {t('deals.filter.resetFilter')}
        </Button>
      </Form.Item>
    </Form>
  )
})

export default FilterRequest;