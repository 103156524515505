import { FC, useState, useEffect } from 'react';
import { observer} from 'mobx-react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { propertyTypesService } from 'services';
import { propertyService } from '../../../PropertyService';
import ProgressStore from 'stores/progress';
import PropertyTypeStore from 'stores/type';
import LocaleStore from 'stores/locale';
import ownerStore from 'stores/owner';
import { includes, some, uniq } from 'lodash';
import { SALE_VALUE, RENT_VALUE, TRASPASO_VALUE } from 'constants/mixed';
import { CHECK_IDENTIFIER_URL } from 'constants/api';
import { UPDATE_PROPERTY } from 'constants/action';
import { ArrowRightOutlined } from "@ant-design/icons";
import { getContactIcon } from "modules/owners/containers/List"

import { Form, Select, Input, Radio, Button,  Row, Col, notification } from 'antd';


import { PropertyType } from 'declarations/propertyType';
import { IOwner } from 'declarations/contact';

interface IData {
  next?(params: any): any;
  general: any;
  isNotSteper?: boolean;
  handleCancel?(): void;
  validateChanges?(params: any): Array<string>;
}

const { Option } = Select;

const General: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const lng = LocaleStore.locale as string;
  const [state, setState] = useState<any>({
    id: null,
    status: 'published',
    property_type_id: undefined,
    showBuilding: true,
    operation: undefined,
    identifier: '',
    cartello: false,
    new_building: '',
    owner_id: null,
    hasError: null,
    energy_status: 'on',
    energy_type: undefined,
    energy_consumption: null,
    energy_emission: null
  });

  useEffect(() => {
    propertyTypesService.loadResources()
    ownerStore.fetchOwners({}, 'all')
  }, [])

  useEffect(() => {
    if (props.general) {
      setState({
        ...state,
        ...props.general
      })
      form.setFieldsValue({ ...props.general, cartello: String(props.general.cartello) })
    }
  }, [props.general.id])

  const handleCancel = () => {
    if (props.handleCancel) {
      props.handleCancel();
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const val = form.getFieldValue("identifier")
    if (props.general.identifier !== val) {
      await checkIdentidfier()
    }
    form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length) && !state.hasError) {
      const values = form.getFieldsValue();
      const propertyValue = { ...state, ...values }

      let invalidPortalsList: Array<string> = [];

      if(props.validateChanges) {
        invalidPortalsList = props.validateChanges(propertyValue)
      }
      if(invalidPortalsList.length == 0) {
        await propertyService.updateResource('properties', propertyValue, () => handleCancel())
      } else {
        notification['error']({
          message: "Can't update property",
          placement: 'topRight',
          duration: 0,
          description: `Unpublish property from ${uniq(invalidPortalsList).join(' , ')} before updating.`
        });
      }
    }
  }

  const handleChangeId = (e: any) => {
    setState({ ...state, identifier: e.currentTarget.value, hasError: null })
  }

  const handleChangeOperation = (e: any) => {
    setState({...state, operation: e })
  }

  const handleChangeType = (typeId: string) => {
    const currentType: any = (PropertyTypeStore.values || []).find((item: any) => item.id == typeId)
    if(currentType.name['en'].includes('Land')) {
      form.setFieldsValue({ ...state, property_type_id: typeId, new_building: '' })
    } else {
      form.setFieldsValue({ ...state, property_type_id: typeId })
    }
  }

  const handleChangeEnergyStatus = (e: any) => {
    setState({ energy_status: e.target.value})
  }

  const checkIdentidfier = async () => {
    const identVal = form.getFieldValue("identifier");
    if (!identVal) {
      setState({ hasError: t('properties.message.identifier') })
    }
    const resp = await axios.get(CHECK_IDENTIFIER_URL, { params: { identifier: identVal } })
    if (resp.data.result) {
      setState({...state, hasError: t('properties.message.id') })
    }
    return resp.data.result
  }

  const nextStep = async (e: any) => {
    const ident = form.getFieldValue("identifier")
    let identifierPresent = false;
    if (props.general.identifier !== ident) {
      identifierPresent = await checkIdentidfier()
    }

    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length) && props.next && !identifierPresent) {
      const values = form.getFieldsValue();
      props.next(values)
    }
  }

  const getTypeOptions = (): Array<React.ReactNode> => {
    return (PropertyTypeStore.values || []).map((propertyType: PropertyType) => {
      const name: any = propertyType.name;
      return <Option value={propertyType.id}>{name[lng]}</Option>
    })
  }

  const getButtons = () => {
    let buttons;
    if (props.isNotSteper) {
      buttons = <div>
        <Button className='mr-10' type="primary" onClick={(e: any) => handleSubmit(e)}
          loading={ProgressStore.isLoading(UPDATE_PROPERTY)}
        >
          { t('save') }
        </Button>
        <Button onClick={handleCancel}>{t('cancel')}</Button>
      </div>
    } else {
      buttons = <div>
        <Button type="primary" onClick={(e: any) => nextStep(e)}>
        {t('continue')} <ArrowRightOutlined />
        </Button>
      </div>
    }

    return buttons;
  }

  const getIdentifier = (operationValue: string, identValue: string) => {
    let idValues = [];

    if (includes(operationValue, SALE_VALUE) || includes(operationValue, TRASPASO_VALUE)) {
      idValues.push(<span className='ml-10 sale'>VS{identValue}</span>)
    }
    if (includes(operationValue, RENT_VALUE)) {
      idValues.push(<span className='ml-10 rent'>VR{identValue}</span>)
    }

    return idValues;

  }

    const { hasError } = state;
    const owners = ownerStore.values;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
    const formItemRow = {
      wrapperCol: { span: 14, offset: 6 }
    };

    return (
      <div>
        <div>
          <Form onFinish={handleSubmit} form={form}>
            <Form.Item
              {...formItemRow}
            >
              <h3>{t('properties.generalInfo')}</h3>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.table.status')}
              name="status"
              rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
            >
              <Select
                placeholder={t('properties.placeholder.status')}
                disabled={props.general.status === 'review'}
                filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="published">
                  <div className='status-options published-status'>
                    {t('properties.new.status.published')}
                </div>
                </Option>
                <Option value="draft">
                  <div className='status-options draft-status'>
                  {t('properties.new.status.draft')}
                </div>
                </Option>
                <Option value="archive">
                  <div className='status-options archive-status'>
                    {t('properties.new.status.archive')}
                  </div>
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('deals.new.operation')}
              name="operation"
              rules={[{ required: true, message: t('validates.cantBeEmpty'), type: 'array' }]}
            >
              <Select mode="multiple" placeholder={t('deals.placeholder.operation')} onChange={handleChangeOperation} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="0">{t('properties.new.operation.sale')}</Option>
                <Option value="1">{t('properties.new.operation.rent')}</Option>
                <Option value="2">{t('properties.new.operation.traspaso')}</Option>
              </Select>
            </Form.Item>
            <Row>
              <Col span={8} offset={4}>
                <Form.Item
                  {...formItemLayout}
                  label={t('properties.new.id')}
                  validateStatus={hasError ? 'error' : ''}
                  help={<span>{hasError || "" }</span>}
                  rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
                  // className='mb-25'
                  name="identifier"

                >
                  <Input disabled={props.general.id} placeholder={t('properties.new.id')} onChange={handleChangeId} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const operationValue = getFieldValue("operation");
                    const identifierValue = getFieldValue("identifier");
                    return (
                      <span className='ml-20'>{t('properties.new.id')}: {getIdentifier(operationValue, identifierValue)}</span>
                      )
                    }}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.type')}
              name="property_type_id"
              rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
            >
              <Select placeholder={t('properties.placeholder.type')} onChange={handleChangeType} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getTypeOptions()}
              </Select>
            </Form.Item>
            {<Form.Item shouldUpdate noStyle>
             {({ getFieldValue }) => {
                const propType = getFieldValue("property_type_id");
                const currentType: any = (PropertyTypeStore.values || []).find((item: any) => item.id == propType)
                return !currentType?.name['en'].includes('Land') && <Form.Item
                {...formItemLayout}
                label={t('properties.new.newBuilding')}
                name="new_building"
                initialValue={""}
              >
                <Radio.Group>
                  <Radio.Button value={"true"}>{t('properties.new.yes')}</Radio.Button>
                  <Radio.Button value={"false"}>{t('properties.new.no')}</Radio.Button>
                  <Radio.Button value="">{t('properties.new.notSelected')}</Radio.Button>
                </Radio.Group>
              </Form.Item>
            }}
            </Form.Item>
            }
            {/* { showBuilding && <Form.Item
              {...formItemLayout}
              label={t('properties.new.newBuilding')}
              name="new_building"
              initialValue={""}
            >
              <Radio.Group>
                <Radio.Button value={true}>{t('properties.new.yes')}</Radio.Button>
                <Radio.Button value={false}>{t('properties.new.no')}</Radio.Button>
                <Radio.Button value="">{t('properties.new.notSelected')}</Radio.Button>
              </Radio.Group>
            </Form.Item> } */}
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.cartello')}
              name="cartello"
              initialValue={"false"}
            >
              <Radio.Group>
                <Radio.Button value={"true"}>{t('properties.new.yes')}</Radio.Button>
                <Radio.Button value={"false"}>{t('properties.new.no')}</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.energyCertificate')}
              name="energy_status"
            >
              <Radio.Group onChange={handleChangeEnergyStatus}>
                <Radio.Button value="on">{t('properties.new.on')}</Radio.Button>
                <Radio.Button value="in-progress">{t('properties.new.inProgress')}</Radio.Button>
                <Radio.Button value="off">{t('properties.new.off')}</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const energyStatus = getFieldValue("energy_status")
                return (
                  energyStatus === "on" && <>
                    <Form.Item
                      {...formItemLayout}
                      label={t('properties.new.energyCertificateType')}
                      name="energy_type"
                      rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
                    >
                      <Select
                        placeholder={t('properties.placeholder.type')}
                        filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {['A', 'B', 'C', 'D', 'E', 'F', 'G'].map((item: string) =>
                          <Option value={item}>{item}</Option>
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout}
                      label={t('properties.new.energyConsumption')}
                      name="energy_consumption"
                      rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
                    >
                      <Input type='number' min={0} className='details-options' />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout}
                      label={t('properties.new.energyEmission')}
                      name="energy_emission"
                      rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
                    >
                      <Input type='number' min={0} className='details-options' />
                    </Form.Item>
                  </>
                )
              }}
            </Form.Item>
            {/* { energy_status === 'on' && [<Form.Item
                {...formItemLayout}
                label={t('properties.new.energyCertificateType')}
                name="energy_type"
                rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
              >
                <Select
                  placeholder={t('properties.placeholder.type')}
                >
                  {['A', 'B', 'C', 'D', 'E', 'F', 'G'].map((item: string) =>
                    <Option value={item}>{item}</Option>
                  )}
                </Select>
              </Form.Item>,
              <Form.Item
                {...formItemLayout}
                label={t('properties.new.energyConsumption')}
                name="energy_consumption"
                rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
              >
                <Input type='number' min={0} className='details-options' />
              </Form.Item>,
              <Form.Item
                {...formItemLayout}
                label={t('properties.new.energyEmission')}
                name="energy_emission"
                rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
              >
                <Input type='number' min={0} className='details-options' />
              </Form.Item>]
            } */}
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.owner')}
              name="owner_id"
              rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
            >
              <Select
                showSearch
                placeholder={t('properties.placeholder.owner')}
                optionFilterProp="children"
                filterOption={(input: any, option: any) => option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {(owners || []).map((owner: IOwner) =>
                  <Option value={owner.id}>{getContactIcon(owner.contact_type, "mr-5")}
                    {owner.name + (owner.owner_person ? ` (${owner.owner_person})` : '')}
                  </Option>)
                }
              </Select>
            </Form.Item>
          </Form>
        </div>
        <Row>
          <Col span={6}></Col>
          <Col span={18}>
            <div className="form-controls">
              {getButtons()}
            </div>
          </Col>
        </Row>
      </div>
    )
})

export default General;
