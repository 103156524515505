import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { activityService } from 'services/activityService';
import ActivityStore from 'stores/activity';
import Note from 'submodules/notes/components/Item'
import Task from 'submodules/tasks/components/Item'
import Attachment from 'submodules/files/components/Item'
import { Row, Col, Timeline } from 'antd';
import { PlusSquareOutlined } from "@ant-design/icons";


import { IContact } from "declarations/contact";

const ItemTypes: any = {
  Note: Note,
  Task: Task,
  Attachment: Attachment
}

interface IData {
  contact: IContact;
}

const ActivityList: FC<IData> = observer((props) => {
  const { t } = useTranslation();

  useEffect(() => {
    const { contact } = props;
    activityService.loadResources('Contact', contact.id)
    return () => ActivityStore.clearValues()
  }, [])

  const renderActivities = () => {
    const { contact } = props;
    const activities = ActivityStore.values
    let options: any = { resource: contact, resource_type: 'Contact', isActivity: true }
    if (!(activities && activities.length)) {
      return null;
    }
    return activities.map((item: any) => {
      const ItemType = ItemTypes[item.class_name];

      if (!ItemType) {
        return null;
      }

      options[item.class_name.toLowerCase()] = item;
      options['id'] = item.id;
      return <Timeline.Item key={`timeline_${ItemType}_${item.id}`}>
        <ItemType {...options} />
      </Timeline.Item>
    })
  }

  const { contact } = props;
  const createdDate = moment(contact.created_at).format('D MMMM YYYY [at] HH:mm');

  return (
    <Timeline className='timeline'>
      {renderActivities()}
      <Timeline.Item key={`timeline_created`}>
        <Row className='contact-card-item'>
          <Row className='contact-card-header' justify="space-between">
            <Col>
              <Row justify="start" align='middle'>
                <Col>
                  <PlusSquareOutlined className='mr-10 fs-35' />
                </Col>
                <Col>
                  <p>{t('contacts.created')}</p>
                  <span>{createdDate}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Timeline.Item>
    </Timeline>
  );
})

export default ActivityList;
