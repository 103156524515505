import { IRent } from 'declarations/rent';
import { IClient } from 'declarations/contact';
import { makeAutoObservable } from 'mobx';

export class RentStore {

  values: Array<IRent> = [];
  selected: any = {};
  fetchInProgress = false;
  saveInProgress = false;
  deleteInProgress = false;

  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: Array<IRent>): void {
    this.values = values;
  }

  removeValue(resource_id: number): void {
    this.values = this.values.filter((item: IRent) => item.id != resource_id);
  }

  addSelectedValue(value: any): void {
    this.selected = value;
  }

  updateContactValue(client: IClient) {
    this.values = this.values.map((value: IRent) => value.client_id != client.id ? value : {...value, client})
  }

  clearValues(): void {
    this.values = [];
  }

  updateValue(value: IRent): void {
    this.values = this.values.map((item: IRent) => item.id != value.id ? item : value)
  }
  setFetchInProgress(val: boolean) {
    this.fetchInProgress = val;
  }
  setSaveInProgress(val: boolean) {
    this.saveInProgress = val;
  }
  setDeleteInProgress(val: boolean) {
    this.deleteInProgress = val;
  }
}

export default (new RentStore())
