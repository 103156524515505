export const BASE_URL = '/api/v1';
export const LOGIN_URL = `${BASE_URL}/auth/login`;
export const CONTACTS_URL = `${BASE_URL}/contacts`;
export const CLIENTS_URL = `${BASE_URL}/clients`;
export const USERS_URL = `${BASE_URL}/users`;
export const PROPERTY_TYPES_URL = `${BASE_URL}/property_types`;
export const CREATE_PROPERTY_URL = `${BASE_URL}/properties`;
export const GALLERY_URL = `${BASE_URL}/galleries`;
export const OWNERS_URL = `${BASE_URL}/owners`;
export const DEALS_URL = `${BASE_URL}/deals`;
export const DEALS_PROPERTY_URL = `${BASE_URL}/deals_properties`;
export const ISLAND_URL = `${BASE_URL}/islands`;
export const REGION_URL = `${BASE_URL}/regions`;
export const CITY_URL = `${BASE_URL}/cities`;
export const CITY_COMPLEX_URL = `${BASE_URL}/city_complexes`;
export const WEB_RESOURCES_URL = `${BASE_URL}/web_resources`;
export const PROPERTY_CHARACTERISTIC_URL = `${BASE_URL}/property_characteristics`;
export const SOURCE_URL = `${BASE_URL}/sources`;
export const NOTE_URL = `${BASE_URL}/notes`;
export const EMAIL_URL = `${BASE_URL}/emails`;
export const TASK_URL = `${BASE_URL}/tasks`;
export const ATTACHMENT_URL = `${BASE_URL}/attachments`;
export const ACTIVITIES_URL = `${BASE_URL}/activities`;
export const REQUESTS_URL = `${BASE_URL}/requests`;
export const EXPENSES_URL = `${BASE_URL}/expenses`;
export const CHECK_IDENTIFIER_URL = `${BASE_URL}/properties/identifier`;
export const EXPENSE_CATEGORY_URL = `${BASE_URL}/expense_categories`;
export const CONNECTED_URL = `${BASE_URL}/connections`;
export const PROMOTION_REPORT_URL = `${BASE_URL}/reports/promotions`;
export const SALE_REPORT_URL = `${BASE_URL}/reports/sales`;
export const EMAIL_REPORT_URL = `${BASE_URL}/reports/emails`;
export const RENT_URL = `${BASE_URL}/rents`;
export const DEAL_EMAILS_URL = `${BASE_URL}/deal_emails`;
export const DEAL_LABELS_URL = `${BASE_URL}/deal_labels`;
export const WORKSPACE_URL = `${BASE_URL}/workspaces`;
