import { SALE_VALUE, RENT_VALUE, TRASPASO_VALUE } from 'constants/mixed'
import { BLOCKED_TYPES, MANDATORY_FIELDS, ACCESS_OPERATION } from 'constants/rules';
import  PropertyTypeStore  from 'stores/type';

const validateOperationByResource = (resource_name: string, property: any) => {

  if(ACCESS_OPERATION[resource_name].includes(SALE_VALUE) && property.operation.includes(SALE_VALUE)) {
    return false;
  }
  if(ACCESS_OPERATION[resource_name].includes(RENT_VALUE) && property.operation.includes(RENT_VALUE)) {
    return false;
  }

  if(ACCESS_OPERATION[resource_name].includes(TRASPASO_VALUE) && property.operation.includes(TRASPASO_VALUE)) {
    return false;
  }

  return true
}

export const validationResource = (resource_name: string, property: any) => {
  const { property_type_id } = property;
  const type: any = PropertyTypeStore.values.find((item: any) => item.id == property_type_id)
  const blockByType = BLOCKED_TYPES[resource_name].includes(type.name['en'])
  const blockByOperation = validateOperationByResource(resource_name, property);
  let blockByFields = false;
  let bedResources = [];

  if(MANDATORY_FIELDS[resource_name]) {
    MANDATORY_FIELDS[resource_name].map((item: { currentField: string , children: Array<string> }) => {
      if(blockByFields) return;
      item['children'].forEach((field: string) => {
        const propertyValue = item['currentField'] ? property[item['currentField']][field] : property[field];

        if(type.name['en'].includes('Land') && resource_name == 'idealista.com') {
          blockByFields = false
          return
        }

        if(`${propertyValue}` == '0' || `${propertyValue || ''}`.trim().length == 0) {
          blockByFields = true
          return
        }
      })
    })
  }

  if(blockByType || blockByFields || blockByOperation) {
    bedResources.push(resource_name)
  }
  // if(blockByFields) {
  //   bedResources.push(
  //     `Can't be changed because fields ${getMandatoryFields(resource_name)} empty or equal 0 for ${resource_name}`
  //   )
  // }
  // if(blockByOperation) {
  //   bedResources.push(`Can't be changed because wrong operation for portal ${resource_name}`)
  // }

  return bedResources
}

const getMandatoryFields = (resource_name: string) => {
  if(MANDATORY_FIELDS[resource_name]) {
    const fields = MANDATORY_FIELDS[resource_name].map((item: { currentField: string , children: Array<string> }) => item['children'])
    return `${fields.flat().join(' or ')}`;
  }
  return 'some fields are empty'
}