import { IWorkspace } from 'declarations/workspace';
import { get } from 'lodash';
import { makeAutoObservable } from 'mobx';
import * as services from "services/workspaceService";
import localeStore from "stores/locale";


const WORKSPACE_KEY = 'workspaceId';
export class WorkspaceStore {

  values: Array<IWorkspace> = [];
  loading = false;
  fetchError: any;
  selectedId?: number;

  constructor() {
    makeAutoObservable(this);
    this.selectedId = Number(localStorage.getItem(WORKSPACE_KEY));
  }

  async fetchWorkspaces() {
    this.loading = true;
    try {
      const data = await services.getWorkspaces();
      const selectedId = Number(localStorage.getItem(WORKSPACE_KEY));
      this.selectedId = selectedId && data.find((i) => i.id === selectedId) ? selectedId :  get(data, [0, 'id']);
      this.values = data;
    } catch (err) {
      this.fetchError = err;
    }
    this.loading = false;
  }

  setSelected(id: number) {
    localStorage.setItem(WORKSPACE_KEY, String(id));
    this.selectedId = id;
  }

  getWorkspaceName(id: number) {
    return get(this.values.find((i) => i.id === id)?.name, [localeStore.locale || "en"])
  }

  addValues(values: Array<IWorkspace>): void {
    this.values = values;
  }

  setLoading(val: boolean) {
    this.loading = val;
  }
}

export default (new WorkspaceStore())