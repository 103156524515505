import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { propertyService } from '../../../PropertyService';
import ProgressStore from 'stores/progress';
import { includes, some } from 'lodash';
import {
  SALE_VALUE, RENT_VALUE, FIXED_PRICE_VALUE, PERCENTAGE_PRICE_VALUE,
  TRASPASO_VALUE, GIFT_TYPES
} from 'constants/mixed';
import { UPDATE_PROPERTY } from 'constants/action';

import {
  Form, Select, Input, Radio, Button, Row, Col, InputNumber, message, Space
} from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

interface IData {
  next?(params: any): any,
  prev?(params: any): any,
  price: any,
  operation: Array<any>,
  handleCancel?(): void,
  isNotSteper?: boolean
}

// interface Props {
//   form: any
// }
const { Option } = Select;

const CASHBACK_VALUE = 'cashback';

const Price: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form)

  const [state, setState] = useState({
    id: null,
    price_type: true,
    amount: null,
    comission_type: "0",
    comission_value: null,
    with_comission: '0',
    owners_amount: null,
    mortgage: false,
    vv: false,
    cashback: '',
    gift_type: 'no',
    monthly_price: null,
    fixed_commission: null,
    ibi: null,
    monthly_commission: null,
    owners_monthly_price: null,
  });

  useEffect(() => {
    if (props.price) {
      setState({
        ...state,
        ...props.price
      })
      form.setFieldsValue({...props.price, comission_type: String(props.price.comission_type)});
    }
  }, [])

  // useEffect(() => {
  //   changeOwnersPrice()
  // }, [formValues])

  const handleCancel = () => {
    if (props.handleCancel) {
      props.handleCancel();
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      const priceValue = { ...state, ...values }

      if (priceValue['cashback'] === 0) {
        message.error(t('price.message.cashback'))
        return
      }
      if (priceValue['cashback'] == undefined) {
        priceValue['cashback'] = ''
      }

      propertyService.updateResource('prices', priceValue, () => handleCancel())
    }
  }

  const onChangeSelectValue = async (e: any, field: string) => {
    await setState({...state, [field]: e });
    // changeOwnersPrice();
    handlePriceChange();
  }

  const onChangeGiftValue = (val: any) => {
    setState({ ...state, gift_type: val, cashback: '' })
  }

  const onChangeInputValue = (value: any, field: string) => {
    setState({...state, [field]: value })
    // changeOwnersPrice();
    handlePriceChange();
  }

  const onChangeOwnersValue = (value: any) => {
    const fieldName = 'owners_amount';

    setState({...state, [fieldName]: value })
    // form.setFieldsValue({[fieldName]: value})
    const priceField = 'owners_amount';
    const calculatedField = 'amount';
    // onChangeOwnersPrice(value, calculatedField);
    handlePriceChange(value, calculatedField);
  }

  const nextStep = async (e: any) => {
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length) && props.next) {
      const values = form.getFieldsValue();
      const priceValue = { ...state, ...values }
      props.next({ price_attributes: priceValue })
    }
  }

  const prevStep = async (e: any) => {
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length) && props.prev) {
      const values = form.getFieldsValue();
      const priceValue = { ...state, ...values }
      props.prev({ price_attributes: priceValue })
    }
  }

  const getButtons = () => {
    let buttons;
    if (props.isNotSteper) {
      buttons = <div>
        <Button className='mr-10' type="primary" onClick={handleSubmit} loading={ProgressStore.isLoading(UPDATE_PROPERTY)}>
        {t('save')}
        </Button>
        <Button onClick={handleCancel}>{t('cancel')}</Button>
      </div>
    } else {
      buttons =
        <div>
          <Button className='mr-10' onClick={prevStep}><ArrowLeftOutlined /> {t('back')}</Button>
          <Button type="primary" onClick={nextStep}>
          {t('continue')} <ArrowRightOutlined />
          </Button>
        </div>
    }

    return buttons;
  }

  const changeOwnersPrice = (priceField: string = 'amount', calculatedField: string = 'owners_amount') => {
    let ownersValue = 0;
    const comission_type = form.getFieldValue('comission_type');
    const amountValue = parseInt(form.getFieldValue(priceField), 10) || 0;
    const comissionValue = parseFloat(form.getFieldValue('comission_value')) || 0;

    if (!amountValue || !comissionValue) {
      return '';
    }

    if (Number(comission_type) === FIXED_PRICE_VALUE) {
      // if (with_comission === COMISSION_INCLUDED_VALUE) {
      ownersValue = amountValue - comissionValue;
      // } else {
        //   ownersValue = amountValue + comissionValue;
        // }
    }

    if (Number(comission_type) === PERCENTAGE_PRICE_VALUE) {
        // if (with_comission === COMISSION_INCLUDED_VALUE) {
      ownersValue = amountValue - ((amountValue * comissionValue) / 100);
        // } else {
          //   ownersValue = amountValue + ((amountValue * comissionValue) / 100);
          // }
    }
    console.log("🚀 ~ file: Price.tsx:199 ~ changeOwnersPrice ~ ownersValue:", ownersValue)

    form.setFieldsValue({ [calculatedField]: Math.round(ownersValue) });
  }

  const onChangeOwnersPrice = (amountValue: number,  calculatedField: string = 'owners_amount', params: any = {}) => {
    console.log("🚀 ~ file: Price.tsx:193 ~ onChangeOwnersPrice ~ params:", params)
    let ownersValue = 0;
    // const { comission_type, with_comission } = form.getFieldsValue(['comission_type', 'with_comission']);
    const comission_type = form.getFieldValue('comission_type');
    // const amountValue = parseInt(form.getFieldValue(priceField), 10) || 0;
    const comissionValue = parseFloat(form.getFieldValue('comission_value')) || 0;

    if (!amountValue || !comissionValue) {
      return '';
    }


    if (Number(comission_type) == FIXED_PRICE_VALUE) {
      // if (with_comission === COMISSION_INCLUDED_VALUE) {
        ownersValue = amountValue + comissionValue;
      // } else {
        // ownersValue = amountValue - comissionValue;
      // }
    }

    if (Number(comission_type) == PERCENTAGE_PRICE_VALUE) {
      // if (with_comission === COMISSION_INCLUDED_VALUE) {
        if (calculatedField == 'owners_amount') {
          ownersValue = (amountValue * 100) / (100 - comissionValue);
        } else {
          ownersValue = amountValue + ((amountValue * comissionValue) / 100);
        }
        // } else {
        // ownersValue = amountValue - ((amountValue * comissionValue) / 100);
      // }
    }

    console.log("🚀 ~ file: Price.tsx:214 ~ onChangeOwnersPrice ~ ownersValue:", ownersValue)
    form.setFieldsValue({ [calculatedField]: Math.round(ownersValue) });
  }


  const handlePriceChange = async (price?: number, calculatedField: string = 'owners_amount') =>  {
    let newPrice = price || 0;
    if ( typeof price === "undefined") {
      newPrice = await form.getFieldValue("amount");
    }

    const isAmountPrice = calculatedField === 'owners_amount';
    const field = isAmountPrice ? 'amount' : 'owners_amount';
    const comission_type = form.getFieldValue('comission_type');
    const comissionValue = parseFloat(form.getFieldValue('comission_value')) || 0;
    let calculatedFieldValue = 0;

    if (!comissionValue || !newPrice) {
      return ;
    }

    if (Number(comission_type) == FIXED_PRICE_VALUE) {
      calculatedFieldValue = isAmountPrice ? (newPrice - comissionValue) : (newPrice + comissionValue);
    }

    if (Number(comission_type) == PERCENTAGE_PRICE_VALUE) {
      if (isAmountPrice) {
        calculatedFieldValue = newPrice - ((newPrice * comissionValue) / 100);
      } else {
        if (comissionValue === 100) {
          calculatedFieldValue = newPrice * 100
        } else {
          calculatedFieldValue = (newPrice * 100) / (100 - comissionValue);
        }
      }

    }

    form.setFieldsValue({ [field]: newPrice, [calculatedField]: Math.round(calculatedFieldValue)})
  }


  const { gift_type } = state;

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  const formItemRow = {
    wrapperCol: { span: 14, offset: 6 }
  };

  return (
    <div>
      <div>
        <Form onFinish={handleSubmit} form={form}>
          {(includes(props.operation, SALE_VALUE) || includes(props.operation, TRASPASO_VALUE)) &&
            <div className='sale-price'>
              <Form.Item
                {...formItemRow}
              >
                <h3>{t('properties.new.salePrice')}</h3>
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={t('price.new.type')}
                name="price_type"
                initialValue={true}
              >
                <Radio.Group>
                  <Radio.Button value={true}>{t('price.new.exact')}</Radio.Button>
                  <Radio.Button value={false}>{t('price.new.starting')}</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {() => (
                  <Form.Item
                    {...formItemLayout}
                    label={t('properties.table.price')}
                    name="amount"
                    rules={[{ required: true, message: t('validates.cantBeEmpty') },]}
                  >
                    <InputNumber
                      placeholder={t('properties.table.price')}
                      min={0}
                      className="w-100p"
                      onChange={value => onChangeInputValue(value, 'amount')}
                      formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                      parser={(value: any) => value.replace(/(\.*)/g, '')}
                      addonAfter="€"
                    />
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={t('price.new.gift')}
                name="gift_type"
                initialValue="no"
              >
                <Select onChange={(e) => onChangeGiftValue(e)} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {GIFT_TYPES.map((val: string) => <Option value={val}>{t(`price.new.giftTypes.${val}`)}</Option>)}
                </Select>
              </Form.Item>
              { gift_type === CASHBACK_VALUE &&
                <Form.Item
                  {...formItemLayout}
                  label={t('price.new.cashback')}
                  name="cashback"
                  rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
                >
                    <InputNumber
                      placeholder={t('price.new.cashback')}
                      // min={0}
                      // onChange={value => onChangeInputValue(value, 'cashback')}
                      formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                      parser={(value: any) => value.replace(/(\.*)/g, '')}
                      addonAfter="€"
                    />
                </Form.Item>
              }
              <Form.Item label={t('price.new.comission')} {...formItemLayout}>
                <Space.Compact>
                  <Form.Item noStyle shouldUpdate>
                    {() => (
                      <Form.Item
                        {...formItemLayout}
                        // label={t('price.new.comission')}
                        name="comission_type"
                        initialValue={"0"}
                        rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
                        noStyle
                      >
                        <Select
                          placeholder={t('price.message.comission')}
                          onChange={(e) => onChangeSelectValue(e, 'comission_type')}
                          className="w-400 mr-20"
                          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          <Option value={"0"}>
                            {t('price.new.fixed')}
                          </Option>
                          <Option value={"1"}>
                            { t('price.new.percentage') }
                          </Option>
                        </Select>
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({getFieldValue}) => {
                        const comissionType = getFieldValue("comission_type");
                      return <Form.Item
                        rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
                        noStyle
                        name="comission_value"
                        className="w-200"
                      >
                          <Input
                            className='details-options w-100p'
                            min={0}
                            onChange={(value) => onChangeInputValue(value, 'comission_value')}
                            // formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            // parser={(value: any) => value.replace(/(\.*)/g, '')}
                            addonAfter={comissionType === "1" ? "%" : '€'}
                          />
                      </Form.Item>
                      }}
                    </Form.Item>
                    </Space.Compact>
                  </Form.Item>
                  {/* <Col span={7} className='comission-inputs'>
                    {getFieldDecorator('with_comission', {
                      initialValue: with_comission.toString()
                    })(
                      <Select onChange={(e) => onChangeSelectValue(e, 'with_comission')}>
                        <Option value="0">
                        {t('price.new.included')}
                      </Option>
                        <Option value="1">
                        {t('price.new.notIncluded')}
                      </Option>
                      </Select>
                    )}
                  </Col> */}
              <Form.Item shouldUpdate noStyle>
                {() => (
                  <Form.Item
                    {...formItemLayout}
                    label={t('price.new.ownerPrice')}
                    name="owners_amount"
                    rules={[{ required: true, message: t('validates.cantBeEmpty') },]}
                  >
                    <InputNumber
                      placeholder={t('price.new.ownerPrice')}
                      min={0}
                      className="w-100p"
                      onChange={onChangeOwnersValue}
                      formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                      parser={(value: any) => value.replace(/(\.*)/g, '')}
                      addonAfter="€"
                    />
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={t('price.new.ibi')}
                name="ibi"
              >
                <InputNumber
                  placeholder={t('price.new.ibi')}
                  min={0}
                  formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                  addonAfter="€"
                  className="w-100p"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={t('price.new.mortgage')}
                name="mortgage"
              >
                <Radio.Group>
                  <Radio.Button value={true}>{t('properties.new.yes')}</Radio.Button>
                  <Radio.Button value={false}>{t('properties.new.no')}</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={'VV'}
                name="vv"
              >
                <Radio.Group>
                  <Radio.Button value={true}>{t('properties.new.yes')}</Radio.Button>
                  <Radio.Button value={false}>{t('properties.new.no')}</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
          }
          {includes(props.operation, RENT_VALUE) &&
            <div className='rent-price'>
              <Form.Item
                {...formItemRow}
              >
                <h3>{t('properties.new.rentPrice')}</h3>
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={t('properties.new.monthlyPrice')}
                name="monthly_price"
                rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
              >
                <InputNumber
                  placeholder={t('properties.new.monthlyPrice')}
                  min={0}
                  formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                  addonAfter="€"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={t('price.new.fixedCommission')}
                name="fixed_commission"
              >
                <InputNumber
                  placeholder={t('price.new.fixedCommission')}
                  min={0}
                  formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                  addonAfter="€"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={t('price.new.monthlyCommission')}
                name="monthly_commission"
              >
                <InputNumber
                  placeholder={t('price.new.monthlyCommission')}
                  min={0}
                  formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                  addonAfter="€"
                  />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={t('price.new.ownersMonthPrice')}
                name="owners_monthly_price"
              >
                <InputNumber
                  placeholder={t('price.new.ownersMonthPrice')}
                  min={0}
                  formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                  addonAfter="€"
                />
              </Form.Item>
            </div>
          }
        </Form>
      </div>
      <Row>
        <Col span={6}></Col>
        <Col span={18}>
          <div className="form-controls">
            {getButtons()}
          </div>
        </Col>
      </Row>
    </div>
  )
});

export default Price;
