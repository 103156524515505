import { FC } from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { IUser } from 'declarations/user';

interface IData {
  resource: { created_at: string, creator: IUser };
  showNew?: boolean
}

const DateByCreator: FC<IData> = (props) => {

  const resource = props.resource;
  const { showNew} = props;
  const { t } = useTranslation();
  const createdAtDate = moment(resource.created_at).format('DD.MM.YYYY');
  const createdAtTime = moment(resource.created_at).format('\\at kk:mm');
  const name = resource.creator && resource.creator.name || '-'
  return (

     <div>
      { showNew && <Tag color='#655dc5' className='mb-10'>{t('new')}</Tag> }
      <p>
        {createdAtDate}
      </p>
      <p>{createdAtTime}</p>
      <p className='ws-nw'>by {name}</p>
    </div>
  )
}

export default DateByCreator;