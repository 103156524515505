import { action } from 'utils/mobx';
import axios from 'axios';
import { request } from 'utils/mobx';
// import { request, action } from 'utils';
import  activityStore  from '../stores/activity';
import { ACTIVITIES_URL } from 'constants/api';
import { LOAD_ACTIVITIES } from 'constants/action';


interface FilterType {

};

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any,
  // loadResourcesRequest(queryParams: {}): Promise<object>
}

export class ActivityService implements Props {

  async loadResourcesRequest(type: string, id: number) {
    return axios.get(ACTIVITIES_URL, { params: { type: type, id: id } })
  }

  @action({action: LOAD_ACTIVITIES, minRequestTime: 500 })
  async loadResources(type: string, id: number) {
    activityStore.setLoading(true);
    const response: { status: number, data: any} = await this.loadResourcesRequest(type, id);

    if (response.status == 200) {
      let values = [];
      values = response.data.activities;

      this.addValues(values);
    }
    activityStore.setLoading(false);

    return response;
  }

  addValues(values: any) {
    activityStore.addValues(values);
  }
}

export const activityService = new ActivityService();
