import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { requestService } from 'services/requestService';
import requestStore from 'stores/request';
import LocaleStore from 'stores/locale';
import clientStore from 'stores/client';
import ProgressStore from 'stores/progress';
import { LOAD_REQUESTS } from 'constants/action';
import RequestForm from 'modules/requests/components/New'
import { Table, Modal, Dropdown, Menu } from 'antd';
import { name } from 'utils/localized';
import loginStore from 'modules/login/loginStore';
import { EditOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";

import { IRequest } from 'declarations/request';
import { IClient } from 'contact';
import { Property as IProperty } from 'property';
import { isEmpty } from 'lodash';

interface IData {
  client: IClient
}

const confirm = Modal.confirm;

const List: FC<IData> = observer((props) => {

  const { t } = useTranslation();
  const lng = LocaleStore.locale;
  const [modalResource, setModalResource] = useState({});

  useEffect(() => {
    const { client } = props;
    if(client?.id) {
      requestStore.updateFilter({client_id: client.id});
      requestService.loadResources({ client_id: client.id, })
    }
    return () => requestStore.clearData()
  }, [props.client?.id])

  const columns: any = [
      {
        title: t('deals.list.id'),
        dataIndex: 'id',
        key: 'id'
      }, {
        title: t('deals.new.operation'),
        dataIndex: 'operation',
        key: 'operation',
      }, {
        title: t('owners.table.property'),
        dataIndex: 'property',
        key: 'property',
      }, {
        title: t('message'),
        dataIndex: 'message',
        key: 'message',
      }, {
        title: t('requests.showingDateTime'),
        dataIndex: 'showing_date',
        key: 'showing_date',
      }, {
        title: t('contacts.new.source'),
        dataIndex: 'source',
        key: 'source',
      }, {
        title: t('contacts.table.language'),
        dataIndex: 'language',
        key: 'language',
      }, {
        title: t('expenses.new.date'),
        dataIndex: 'date',
        key: 'date',
      }, {
        title: '',
        dataIndex: 'actions',
        fixed: "right",
        key: 'actions'
      }
    ];

  const showModal = (resource: IRequest | {}) => {
    setModalResource(resource);
  }

  const handleCancel = () => {
    setModalResource({});
  }

  const renderRequestDate = (data: string) => {
    if(!data) {
      return '';
    }
    const createdAtDate = moment(data).format('DD.MM.YYYY [at] HH:mm');
    return <span>{createdAtDate}</span>
  }


  const renderPropertyInfo = (property: IProperty) => {

    if(!property) {
      return null;
    }

    const price = property.price_attributes.amount;
    return <div>
      <p><Link to={`/properties/${property.id}/overview`} className='link'>{name(property, lng)}</Link></p>
      <div className='ws-nw'>
        <p>{t('properties.new.id')}: <span className="property-identifier">{property.identifier}</span></p>
        <p>{t('properties.table.price')}: {price ? <span>{`${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €</span> : "-"}</p>
        <p>{t('properties.new.owner')}: {property.owner ? <a href={`/owners/${property.owner.id}/overview`} target='_blank' className='link'>{property.owner.name}</a> : '-'}</p>
      </div>
    </div>
  }
  const renderShowingDate = (request: IRequest) => {
    return(
      <div className='ws-nw'>
        <p>{request.showing_date_at ? moment(request.showing_date_at).format('DD.MM.YYYY') : ''}</p>
        <p>{request.showing_time}</p>
      </div>
    )
  }

  const getData = () => {
    const requests = requestStore.values;

    return (requests || []).map((request: IRequest, index: number) => {
      return {
        id: request.id,
        operation: <span>{request.operation}</span>,
        property: renderPropertyInfo(request.property),
        message: <span>{request.message}</span>,
        showing_date: renderShowingDate(request),
        source: <span>{request.source_name}</span>,
        language: <div className='languages-list ws-nw'>
          { request.language ? <img className='flag' src={require(`../../../images/flags/${request.language}.png`)} /> : '-' }
        </div>,
        date: renderRequestDate(request.created_at),
        actions: <Dropdown overlay={getActionComponent(request)} trigger={['click']}>
          <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
        </Dropdown>
      }
    })
  }

  const getActionComponent = (request: IRequest) => {
    return (
      <Menu
        mode="horizontal"
        className='actons-menu'
      >
        <Menu.Item key={`edit_user_${request.id}`} onClick={() => showModal(request)}>
          <span className='edit-title'>
            <EditOutlined />
            {t('owners.table.edit')}
          </span>
        </Menu.Item>
        { (loginStore.isAdmin || loginStore.isGeneralManager) && <Menu.Item key={`delete_request_${request.id}`} onClick={() => showConfirm(request)}>
          <span className='delete-title'>
            <DeleteOutlined />
            {t('owners.table.delete')}
          </span>
        </Menu.Item>}
      </Menu>
    )
  }

  const showConfirm = (request: IRequest) => {
    confirm({
      title: t('requests.confirmDelete'),
      content: '',
      onOk() {
        return new Promise((resolve, reject) => {

          // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          requestService.deleteResource(request.id, resolve)
          clientStore.fetchClient(props.client.id)
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }

  const changePagination = (page: number) => {
    requestStore.setPage(page);
    requestService.loadResources({}, page);
  }

  const paginationProps = {
    pageSize: 10, onChange: changePagination, total: requestStore.count, showSizeChanger: false
  }

  return (
    <div className="content main-container-list owners-list-page">
      <Modal
        title={t('requests.addRequest')}
        open={!isEmpty(modalResource)}
        footer={null}
        onCancel={handleCancel}
        destroyOnClose
        maskClosable={false}
        className='modal'
      >
        <RequestForm handleCancel={handleCancel} request={modalResource as IRequest} />
      </Modal>
      <div className='list-table'>
        <Table
          dataSource={getData()}
          columns={columns}
          className='owners-table'
          loading={ProgressStore.isLoading(LOAD_REQUESTS)}
          bordered
          pagination={paginationProps}
        />
      </div>
    </div>
  );
})

export default List;
