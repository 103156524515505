import { FC } from 'react';
import { observer } from 'mobx-react';
import AppLayout from "modules/contacts/containers/Single/components/Layout";
import contactStore from 'stores/contact';
import Tasks from 'submodules/tasks/containers/index';


const ContactTasks: FC = observer(() => {
  const contact: any = contactStore.selected;

  return (
    <AppLayout>
      <Tasks resource_type='Contact' resource={contact} />
    </AppLayout>
  );
})

export default ContactTasks;
