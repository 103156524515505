import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Modal } from 'antd';
import { dealService } from 'services/DealService';
import DealList from '../components/DealList';
import DealForm from '../components/DealForm';
import loginStore from 'modules/login/loginStore';
import clientStore from 'stores/client';
import { IDeal } from 'declarations/deal'
import AppLayout from "modules/clients/containers/Single/components/Layout";
import { ShoppingOutlined } from "@ant-design/icons";

const DealsList: FC = observer(() => {

  const [editDeal, setEditDeal] = useState<IDeal | null | undefined>();
  const { t } = useTranslation();
  const showModal = (deal?: IDeal | null) => setEditDeal(deal);
  const client: any = clientStore.selected;

  const handleOk = (deal: IDeal) => {
    if(deal.id) {
      dealService.updateResource(deal, () => {
        handleCancel();
      })
    } else {
      const filter = {client_id: client.id};

      dealService.createResource(deal, filter, () => {
        handleCancel()
      })
    }
  }

  const handleCancel = () => setEditDeal(undefined)

  const exportDeals = () => {
    const filter = { client_id: client.id }
    dealService.exportDeals(filter, 'all')
  }

  return (
    <AppLayout>
      <div className="tab">
        <Modal
          title={t('tabs.deal')}
          visible={!!editDeal || editDeal === null}
          footer={null}
          onCancel={handleCancel}
          destroyOnClose
          className='modal'
          maskClosable={false}
        >
          <DealForm handleOk={handleOk} handleCancel={handleCancel} client={client} deal={editDeal} />
        </Modal>



        <div className='portlet'>
          <div className='portlet-head'>
            <Row justify='space-between' align='middle'>
              <h5><ShoppingOutlined className='mr-10 fs-20' />{t('menu.deals')} ({client.deals_count})</h5>
              <div>
                { loginStore.isAdmin && <Button className='mr-10' type='primary' onClick={exportDeals}>
                  {t('contacts.export')}
                </Button>}
                <Button type='primary' onClick={() => showModal(null)}>
                  {t('deals.newDeal')}
                </Button>
              </div>
            </Row>
            <div className='portlet-body'>
              <DealList client={client} showModal={showModal}/>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
})

export default DealsList;
