import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import loginStore from 'modules/login/loginStore';
import moment from 'moment';
import { fileService } from 'services/fileService';

import { Row, Col, Dropdown, Menu, Tooltip, Avatar, Modal, Tag } from 'antd';
import FileForm from './Form';
import { EllipsisOutlined, EditOutlined, DeleteOutlined, UserOutlined } from "@ant-design/icons";

import { IContact } from 'contact';
import { Property as IProperty } from 'property';
import { IFile } from 'file';
import { FILE_TYPES_ICONS, COLORS } from 'constants/mixed'


interface IData {
  id?: number,
  resource: IContact | IProperty,
  resource_type: string;
  attachment: IFile;
  isActivity?: boolean;
}

const confirm = Modal.confirm;

const Item: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);

  const renderActions = (file: IFile) => {

    return (
      <Menu>
        <Menu.Item onClick={() => setIsEdit(true)} key="file1"><EditOutlined className='mr-5' /> {t('owners.table.edit')}</Menu.Item>
        <Menu.Item key="file2" onClick={() => showConfirm(file)}><DeleteOutlined className='mr-5' />
          { t('emails.deleteNow') }
        </Menu.Item>
      </Menu>
    )
  }

  const showConfirm = (file: IFile) => {
    confirm({
      title: t("files.confirmDelete"),
      content: '',
      onOk() {
        return new Promise((resolve, reject) => {
          fileService.deleteResource(file, resolve)
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }

  const renderFiles = (file: { url: string, filename: string, filetype: string }) => {
    const iconName = FILE_TYPES_ICONS[file.filetype] || 'anyfile.png'
    return (
      <Row justify="start" className='mb-if-not-single'>
        <Col>
          <img className='fs-42 mr-10' width='40' src={require(`images/files/${iconName}`)} />
        </Col>
        <Col>
          <p className="fs-14">{file.filename}</p>
          <span> <a href={file.url} target='_blank' download className='link'>
            {t('emails.download')}</a>
          </span>
        </Col>
      </Row>
    )
  }

  const renderStaticContent = () => {
    const { attachment } = props;
    return (
      <div>
        <div className='file-title'>
          {attachment.files.map((file: any) => renderFiles(file))}
        </div>
        {attachment.note.trim() &&
          <div className='mt-20'>
            <strong>{t('tabs.note')}: </strong> {attachment.note}
          </div>
        }
      </div>
    )
  }

  const renderCardContent = () => {
    const { resource, resource_type, attachment, isActivity } = props;
    let content;

    if (isEdit) {
      content = <FileForm
        onCancelButton={() => setIsEdit(false)}
        resource={resource}
        resource_type={resource_type}
        isActivity={isActivity}
        attachment={attachment}
      />
    } else {
      content = renderStaticContent()
    }
    return content;
  }

    const { attachment } = props;
    const createdDate = moment(attachment.created_at).format('D MMMM YYYY [at] HH:mm')
    const tooltipText = t('emails.tooltipText');
    const user = loginStore.session.user.user;

    return (
      <div className='contact-card-item w-100p'>
        <Row className='contact-card-header' justify="space-between" align='middle'>
          <Col>
            <Row justify="start" align='middle'>
              <Col>
                <Avatar size={35} icon={<UserOutlined />} className='mr-10' />
              </Col>
              <Col>
                <p>{attachment.creator && attachment.creator.name} { t('files.uploadedFile') }</p>
                <span>{createdDate}</span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row justify="end" align='middle'>
              {attachment.is_public.toString() == 'false' &&
                <Col>
                  <Tooltip placement="top" title={tooltipText}>
                    <Tag color={COLORS[3]}>{t('private')}</Tag>
                  </Tooltip>
                </Col>
              }
              { (loginStore.isAdmin || user.id == attachment.created_by_id) &&
                <Col>
                  <Dropdown overlay={() => renderActions(attachment)} trigger={['click']}>
                    <div className='list-dropdown-button'>
                      <EllipsisOutlined className='three-dots ml-15' />
                    </div>
                  </Dropdown>
                </Col>
              }
            </Row>
          </Col>
        </Row>
        <div className='contact-card-content'>
          {renderCardContent()}
        </div>
      </div>
    );
})

export default Item;
