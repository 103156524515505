import { FC, useState } from 'react';
import { observer } from 'mobx-react';

import { VIEW_VALUES, DISTANCE_TO_OCEAN_VALUE, CONDITION_VALUE } from 'constants/mixed'
import DetailsForm from '../../../New/Steps/Details';
import { Row, Col } from 'antd';
import { EditOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

interface IData {
  property: any;
  validateChanges(params: any): Array<string>;
}


const Details: FC<IData> = observer((props) => {
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();

  const handleEditCancel = () => setIsEdit(false);

  const handleEdit = () => setIsEdit(true);

  const getDetailsValue = (key: string) => {
    const details = props.property.detail_attributes;
    const detailValueCount = details && details[`${key}_count`];
    const detailValueArea = details && details[`${key}_area`];
    let detailArea = detailValueArea ? `(${detailValueArea}m²)` : '';
    if (!detailValueCount) {
      return '-';
    }

    return <span className='accent'>{detailValueCount} {detailArea}</span>
  }

  const renderDetailsValue = (field: string, numberInfo: string) => {
    const details = props.property.detail_attributes;
    const detailValue = details && details[field];

    if (!detailValue) {
      return '-';
    }

    return <span className='accent'>{`${detailValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')} {numberInfo}</span>
  }

  const getViews = () => {
    const { property } = props;
    const details = property && property.detail_attributes;
    let viewValues = '-';

    if (details && (details.view || []).length > 0) {
      viewValues = details.view.map((viewId: number) => {
        return VIEW_VALUES[viewId]
      }).join(', ')
    }

    return viewValues;
  }

  const checkField = (field: string) => {
    const details = props.property.detail_attributes;
    const detailValue = details && details[field];

    if (!detailValue) {
      return '-';
    }
    if (String(detailValue) === 'true') {
      return t('properties.new.yes');
    }
    if (String(detailValue) === 'false') {
      return t('properties.new.no');
    }
    return detailValue
  }

  const checkArea = (field: string, info: string = 'm²') => {
    const details = props.property.detail_attributes;
    const detailValueArea = details && details[field];
    let detailArea = detailValueArea ? `(${detailValueArea}${info})` : '';

    return detailArea;
  }

  const renderContent = () => {
    const { property } = props;
    const details = property && property.detail_attributes;
    let content;

    if (!details) {
      return null;
    }

    if(isEdit) {
      content =
      <div className='contact-card-content'>
        <DetailsForm
          details={details}
          operation={property.operation}
          isNotSteper
          handleCancel={handleEditCancel}
          validateChanges={props.validateChanges}
        />
      </div>
    } else {
      content = staticContent()
    }

    return content;
  }


  const staticContent = () => {
    const { property } = props;
    const details = property && property.detail_attributes;

    return (
      <div className='contact-card-content'>
        <div>
          <div className='mb-20'>
            <h5> {t('properties.new.steps.details')} </h5>
          </div>
          <div>
            <Row justify='space-between'>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.view')}:</p> <strong>{getViews()}</strong></div>
              </Col>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.distToOcean')}:</p> <span className='accent'>{DISTANCE_TO_OCEAN_VALUE[details.dist_to_ocean] || '-'}</span></div>
              </Col>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.condition')}:</p> <span className='accent'>{CONDITION_VALUE[details.condition] || '-'}</span></div>
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.furniture')}:</p> <span className='accent'>{details.furniture || '-'}</span></div>
              </Col>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.airConditioner')}:</p> <span className='accent'>{checkField('air_conditioner')}</span></div>
              </Col>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.kitchen')}:</p> <span className='accent'>{details.kitchen ? t(`properties.new.${details.kitchen}`) : '-'}</span></div>
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.bedroom')}:</p> {getDetailsValue('bedroom')}</div>
              </Col>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.bathroom')}:</p> {getDetailsValue('bathroom')}</div>
              </Col>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.toilet')}:</p> {getDetailsValue('toilet')}</div>
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.wardrobe')}:</p> {getDetailsValue('wardrobe')}</div>
              </Col>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.storeroom')}:</p> {getDetailsValue('storeroom')}</div>
              </Col>
              <Col span={7}>
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.balcony')}:</p> {getDetailsValue('balcony')}</div>
              </Col>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.terrasse')}:</p> {getDetailsValue('terrasse')}</div>
              </Col>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.roofTerrasse')}:</p> <span className='accent'>
                    {checkField('roof_terrasse')} {checkArea('roof_terrasse_area')}
                  </span>
                </div>
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.publicParking')}:</p> <span className='accent'>
                    {checkField('public_parking')}
                  </span>
                </div>
              </Col>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.private_parking')}:</p> <span className='accent'>
                    {checkField('private_parking_count')}
                  </span>
                </div>
              </Col>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.garage')}: </p>{getDetailsValue('garage')}</div>
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.publicGarden')}: </p><span className='accent'>
                    {checkField('public_garden')}
                  </span>
                </div>
              </Col>
              <Col span={7}>
                  <div className='pb-20'><p>{t('properties.new.privateGarden')}:</p> <span className='accent'>
                      {checkField('private_garden')}
                    </span>
                  </div>
              </Col>
              <Col span={7}>
              </Col>
            </Row>
            <Row justify='space-between'>
              <Col span={7}>
                <div className='pb-20'><p>{t('properties.new.publicPool')}: </p><span className='accent'>{checkField('public_pool')}</span></div>
                <div className='pb-20'><p>{t('properties.new.publicPoolHeating')}:</p><span className='accent'>{checkField('public_pool_heating')}</span></div>
              </Col>
              <Col span={7}>
                  <div className='pb-20'><p>{t('properties.new.privatePool')}: </p><span className='accent'>{checkField('private_pool')} {checkArea('private_pool_area')}</span></div>
                  <div className='pb-20'><p>{t('properties.new.privatePoolHeating')}:</p><span className="accent">{checkField('private_pool_heating')}</span></div>
              </Col>
              <Col span={7}>
              </Col>
            </Row>
          </div>

        </div>
        <div>
          <div className='mb-20 mt-40'>
            <h5> {t('properties.table.area')} </h5>
          </div>
        </div>
        <div>
          <div>
            <Row justify='space-between' className='mt-10'>
              <Col span={7}>
                <div className='pb-20'>
                  <p>
                  {t('properties.new.totalArea')}:
                  </p> {renderDetailsValue('total_area', 'm2')}
                </div>
              </Col>
              <Col span={7}>
                <div className="pb-20">
                  <p>
                  {t('properties.new.totalLivingArea')}:
                  </p> {renderDetailsValue('living_area', 'm2')}
                </div>
              </Col>
              <Col span={7}>
                <div className="pb-20">
                  <p>
                  {t('properties.new.totalServiceArea')}:
                  </p> {renderDetailsValue('plot_area', 'm2')}
                </div>
              </Col>
            </Row>

            <div>
              <div className='mb-20 mt-40'>
                <h5> {t('properties.new.utilities')} </h5>
              </div>
              <Row justify='space-between'>
                <Col span={7}>
                  <div className='pb-20'><p>
                  {t('properties.new.utilityBills')}:
                  </p> {renderDetailsValue('utility_bills', `€ / ${t('properties.month')}`)}</div>
                  <div className='pb-20'><p>
                  {t('properties.new.electricity')}:
                  </p> {renderDetailsValue('electricity', `€ / ${t('properties.month')}`)}</div>
                </Col>
                <Col span={7}>
                  <div className='pb-20'><p>
                  {t('properties.new.water')}:
                  </p> {renderDetailsValue('water', `€ / ${t('properties.month')}`)}</div>
                </Col>
                <Col span={7}>
                  <div className='pb-20'><p>
                  {t('properties.new.garbage')}:
                  </p> {renderDetailsValue('garbage', `€ / ${t('properties.month')}`)}</div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    )
  }

    const { property } = props;
    const details = property && property.detail_attributes;

    if(!details) {
      return null;
    }

  return (
    <div className="contact-card-item">
      <Row justify='space-between' align='middle' className='contact-card-header'>
        <h5>{t('properties.new.steps.details')}</h5>
        <EditOutlined onClick={handleEdit} />
      </Row>
      {renderContent()}
    </div>
  )
})

export default Details;
