import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import loginStore from 'modules/login/loginStore';
import { Row, Col, Dropdown, Menu, Modal, Avatar, Tooltip, Tag } from 'antd';
import { EllipsisOutlined, EditOutlined, DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { capitalize } from 'lodash';
import { taskService } from 'services/taskService';
import moment from 'moment';
import TaskForm from './Form';
import { IContact } from 'contact';
import { Property as IProperty } from 'property';
import { ITask } from 'task';
import { COLORS } from 'constants/mixed'


interface IData {
  id?: number,
  resource: IContact | IProperty;
  resource_type: string;
  isActivity?: boolean;
  task: ITask
}

const confirm = Modal.confirm;
const REVERT_STATUS: any = { open: 'done', done: 'open' }

const Item: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit]=  useState(false);


  const showConfirm = (task: ITask) => {
    confirm({
      title: t('tasks.confirmDelete'),
      content: '',
      onOk() {
        return new Promise((resolve, reject) => {
          taskService.deleteResource(task, resolve)
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }

  const handleUpdate = (task: ITask) => {
    const { isActivity } = props;
    const newTask = { ...task, status: REVERT_STATUS[task.status] };
    taskService.updateResource(newTask, isActivity)

  }

  const returnReminder = (value: string) => {
    if(!value){
      return '-'
    }
    const getRemindText: any = {
      "one_hour":  t('tasks.oneHourRemind'),
      "three_hour": t('tasks.threeHourRemind'),
      "one_day": t('tasks.oneDayRemind'),
      "three_day": t('tasks.threeDayRemind'),
      "week": t('tasks.weekRemind'),
    }

    return getRemindText[value]
  }

  const renderActions = (task: ITask) => {
    const user = loginStore.session.user.user;

    return (
      <Menu>
        { (loginStore.isAdmin || user.id == task.created_by_id) &&
          <Menu.Item onClick={() => setIsEdit(true)} key="task1"><EditOutlined className='mr-5' />
            {t('owners.table.edit')}
          </Menu.Item>
        }
        <Menu.Item onClick={() => handleUpdate(task)} key="task3"><EditOutlined className='mr-5' />
          { t('tasks.changeTo') } "{capitalize(REVERT_STATUS[task.status])}"
        </Menu.Item>
        { (loginStore.isAdmin || user.id == task.created_by_id) &&
          <Menu.Item onClick={() => showConfirm(task)} key="task2">
            <DeleteOutlined className='mr-5' /> { t('properties.table.delete') }
          </Menu.Item>
        }
      </Menu>
    )
  }

  const renderStaticContent = (task: ITask) => {

    const classTask = classNames({
      '4': task.status == 'open',
      '5': task.status == 'done',
    })
    return (
      <div>
        <Row className='mb-20'>
          <span ><strong>{ t('tasks.title') }: </strong>{task.title}</span>
        </Row>
        {task.description &&
          <Row className='mb-20'>
            <span><strong>{ t('tasks.description') }:</strong> {task.description}</span>
          </Row>
        }
        <Row className='mb-20'>
          <Col span={8}>
            <span><strong>{ t('properties.table.status') }:</strong> <Tag color={`${COLORS[Number(classTask)]}`}>{task.status}</Tag></span>
          </Col>
          <Col span={14}>
            <span><strong>{ t('tasks.assignedTo') }:</strong> {task.assignee && task.assignee.name || '-'}</span>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <span><strong className='mr-5'>{ t('tasks.dueDate') }:</strong>
              {task.due_date ? `${moment(task.due_date).format('D.M.YYYY')} ${task.due_time}` : '-'}
            </span>
          </Col>
          <Col span={14}>
            <span><strong>{ t('tasks.emailReminder') }:</strong> {returnReminder(task.reminder)}</span>
          </Col>
        </Row>
      </div>
    )
  }

  const renderCardContent = () => {
    const { task, resource, resource_type, isActivity } = props;
    let content;

    if (isEdit) {
      content = <TaskForm
        onCancelButton={() => setIsEdit(false)}
        resource={resource}
        task={task}
        isActivity={isActivity}
        resource_type={resource_type}
      />
    } else {
      content = renderStaticContent(task)
    }
    return content;

  }

  const {  task } = props;
  const createdDate = moment(task.created_at).format('D MMMM YYYY [at] HH:mm')
  const tooltipText = t('emails.tooltipText')
  const user = loginStore.session.user.user;

  return (
    <div className='contact-card-item w-100p'>
      <Row className='contact-card-header' justify="space-between" align='middle'>
        <Col>
          <Row justify="start" align='middle'>
            <Col>
              <Avatar size={35} icon={<UserOutlined />} className='mr-10' />
            </Col>
            <Col>
              <p>{task.creator && task.creator.name || '-'} {t('tasks.created')}</p>
              <span>{createdDate}</span>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row justify="end" align='middle'>
            {task.is_public.toString() == 'false' &&
              <Col>
                <Tooltip placement="top" title={tooltipText}>
                  <Tag color={COLORS[3]}>{t('private')}</Tag>
                </Tooltip>
              </Col>
            }
            { (loginStore.isAdmin || user.id == task.created_by_id || user.id == task.user_id) &&
              <Col>
                <Dropdown overlay={() => renderActions(task)} trigger={['click']}>
                  <div className='list-dropdown-button'>
                    <EllipsisOutlined className='three-dots ml-15' />
                  </div>
                </Dropdown>
              </Col>
            }
          </Row>
        </Col>
      </Row>
      <div className='contact-card-content'>
        {renderCardContent()}
      </div>
    </div>
  );
})

export default Item;
