import { FC } from 'react';
import { observer } from 'mobx-react';
import AppLayout from "modules/owners/containers/Single/components/Layout";
import ownerStore from 'stores/owner';
import Tasks from 'submodules/tasks/containers/index';


const OwnerTasks: FC = observer(() => {
  const owner: any = ownerStore.selected;

  return (
    <AppLayout>
      <Tasks resource_type='Owner' resource={owner} />
    </AppLayout>
  );
})

export default OwnerTasks;
