import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { history } from 'utils/history';
// import type { Session as SessionType, User } from 'types';
import { sessionService } from 'utils/session';
import debounce from 'lodash/debounce';
// import { ADMIN, ORGANIZER } from 'constants';
import { saveAs } from 'file-saver';
import { IClient } from 'declarations/contact';
import Item from 'submodules/files/components/Item';
import * as services from "services/ClientService";
import workspaceStore from './workspace';
export interface IOwnerList {
  values: IClient
  // debouncedSetToken(token: string): any
}

interface IFilterKeys {
    contact_types?: any,
    source_ids?: any,
    languages?: any,
    manager_ids?: any,
    q?: string,
    date_from?: any,
    date_to?: any,
    owns_properties?: number,
    has_tasks?: boolean | string,
    page?: number | 'all';
}

interface IResponse {
  clients: IClient[];
  count: number;
}

export class Client {
  values: Array<IClient> = [];
  page: number | 'all' = 1;
  count: number = 0;
  hasAcccess: boolean = true;
  filterKeys: IFilterKeys = {};
  selected?: IClient;

  fetchInProgress = false;
  fetchSingleInProgress = false;
  saveInProgress = false;
  deleteInProgress = false;
  exportInProgress = false;
  fetchError: any = undefined;
  saveError: any = undefined;
  validateError: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  async fetchClients(payload: IFilterKeys = {}, page?: number | 'all') {
    this.fetchInProgress = true;
    if (!workspaceStore.selectedId) {
      return null;
    }
    const params = {
      workspace_id: workspaceStore.selectedId,
      ...this.filterKeys,
      page: page || this.page,
      ...payload,
    }
    try {
      const data  = await services.getClients(params);
      this.values = data.clients;
      this.count = data.count;
      if (page) {
        this.page = page;
      }
    } catch (err) {
      this.fetchError = err;
    }
    this.fetchInProgress = false;
  }

  async fetchClient(clientId: number) {
    this.fetchSingleInProgress = true;
    this.updateHasAccess(true);
    try {
      const data = await services.getById(clientId);
      this.selected = data;
    } catch (err: any) {
      if(err.response.status == 403) {
        this.updateHasAccess(false);
      }
      this.fetchError = err;
    }
    this.fetchSingleInProgress = false;
  }

  async validateEmail(email: string) {
    this.fetchSingleInProgress = true;
    const workspaceId = workspaceStore.selectedId;
    try {
      if (workspaceId) {
        const data = await services.validate(email, workspaceId);
        this.selected = data;
      }
    } catch (err: any) {
      this.validateError[err.email] = err;
    }
    this.fetchSingleInProgress = false;
  }

  async createClient(data: Partial<IClient>) {
    this.saveInProgress = true;
    if (!workspaceStore.selectedId) {
      return null;
    }
    const params = {
      workspace_id: workspaceStore.selectedId,
      ...data,
    }
    try {
      await services.create(params);
    } catch (err) {
      this.saveError = err;
    }
    this.saveInProgress = false;
  }

  async updateClient(clientId: number, data: Partial<IClient>) {
    this.saveInProgress = true;
    if (!workspaceStore.selectedId) {
      return null;
    }
    const params = {
      workspace_id: workspaceStore.selectedId,
      id: clientId,
      ...data,
    }
    try {
      const response = await services.update(clientId, params);
      this.addValue(response);
    } catch (err) {
      this.saveError = err;
    }
    this.saveInProgress = false;
  }

  async deleteClient(clientId: number) {
    this.deleteInProgress = true;

    try {
      await services.del(clientId);
    } catch (err) {
      this.saveError = err;
    }
    this.deleteInProgress = false;
  }

  async export(params: IFilterKeys = {}, pageNum: number | 'all' = 1) {
    const filters = this.filterKeys;
    let page = pageNum;

    const queryParams = {
      workspace_id: workspaceStore.selectedId,
      ...filters,
      ...params,
      page
    };
    this.setExportInProgress(true);

    const response: any = await services.exportClients(queryParams);

    if (response.status == 200) {
      const blob = new Blob([response.data])
      saveAs(blob, 'clients.csv')
    }

    this.setExportInProgress(false);
    return response;
  }



  addValues(values: Array<IClient>, page: number | 'all', count: number, filters: {}) {
    this.values = values;
    this.page = page;
    this.count = count;
    this.filterKeys = {...this.filterKeys ,...filters};
  }

  updateFilter(newFilter: {}) {
    this.filterKeys = {...this.filterKeys, ...newFilter}
  }

  resetFilter() {
    this.filterKeys = {};
    this.page = 1;
  }

  setPage(page: number | "all") {
    this.page = page;
  }

  addSelectedValue(value: IClient): void {
    this.selected = value;
  }

  addSelectedAttributes(params: any): void {
    this.selected = { ...this.selected, ...params };
  }

  addValue(value: IClient) {
    this.values = this.values.map((item: IClient) => item.id == value.id ? value : item)
    this.selected = value;
  }

  clearSelected() {
    this.selected = undefined;
  }

  updateHasAccess(val: boolean): void {
    this.hasAcccess = val;
  }

  clearData(): void {
    this.selected = undefined;
    this.values = [];
  }

  clearErrors(): void {
    this.saveError = undefined;
    this.fetchError = undefined;
    this.validateError = {};
  }

  clear() {
    this.clearData();
    this.clearErrors();
    this.resetFilter();
  }

  setFetchInProgress(val: boolean) {
    this.fetchInProgress = val;
  }
  setSaveInProgress(val: boolean) {
    this.saveInProgress = val;
  }
  setDeleteInProgress(val: boolean) {
    this.deleteInProgress = val;
  }
  setExportInProgress(val: boolean) {
    this.exportInProgress = val;
  }

}

export default (new Client())

