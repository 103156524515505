import {FC, useEffect, useState, PropsWithChildren} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment';
import { EditOutlined, DownOutlined, EllipsisOutlined, DeleteOutlined, BookOutlined, MailOutlined, ProfileOutlined, FileAddOutlined } from "@ant-design/icons";
import classNames from 'classnames';
import ManagerModal from './managerModal'
import { Button, Row, Col, Modal, Menu, Dropdown } from 'antd';
import NoteForm from 'submodules/notes/components/Form';
import TaskForm from 'submodules/tasks/components/Form';
import FileForm from 'submodules/files/components/Form';
import EmailForm from 'submodules/emails/components/Form';
import { Helmet } from 'react-helmet';
import { contactService } from 'services/ContactService';
import loginStore from 'modules/login/loginStore';
import contactStore from 'stores/contact';

import { IContact } from 'declarations/contact';
import { CONTACT_TYPES } from 'constants/mixed';
import { getContactIcon } from "modules/contacts/containers/List"
const { confirm } = Modal;

const Single: FC<PropsWithChildren> = observer(({ children }) => {

  const[showModal, setShowModal] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const location = useLocation();
  const contact: any = contactStore && contactStore.selected;
  const user = loginStore.session.user.user;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    contactService.loadResource(Number(id))
  }, [id])

  const handleOk = (values: { manager_id: number }) => {
    const contact = contactStore.selected;
    const value = { ...contact, ...values }
    contactService.updateResource(value, () => {
      setShowModal(undefined)
    })
  }

  const handleCancel = () => {
    setShowModal(undefined)
  }

  const updateShowModal = (type: string) => {
    setShowModal(type);
  }

  const showConfirm = (contact: IContact) => {

    confirm({
      title: t('contacts.checkDelete'),
      content: '',
      onOk() {
        return new Promise((resolve) => {
          contactService.deleteResource(contact.id, resolve)
        }).then(() => navigate('/contacts')).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }

  const returnHeaderMenuAction = () => {
    const contact: any = contactStore && contactStore.selected;

    return <Menu
      mode="horizontal"
      defaultSelectedKeys={[]}
      className='actons-menu'
    >
      <Menu.Item key={`edit_property_${contact.id}`}>
        <Link className='edit-title' to={`/contacts/${contact.id}/edit`}>
          <EditOutlined className='mr-5' />
          {t('properties.table.edit')}
        </Link>
      </Menu.Item>
      <Menu.Item key={`delete_contact_${contact.id}`} onClick={() => showConfirm(contact)}>
        <span className='delete-title'>
          <DeleteOutlined className='mr-5' />
          {t('owners.table.delete')}
        </span>
      </Menu.Item>
    </Menu>
  }

  const modalContent = () => {
    const contact: any = contactStore && contactStore.selected;
    if (!showModal) {
      return ''
    }

    switch (showModal) {
      case 'manager':
        return <ManagerModal
          manager_id={contact.manager_id}
          onSelectManager={handleOk}
          handleCancel={handleCancel}
        />
      case 'note':
        return <NoteForm onCancelButton={handleCancel} resource={contact} resource_type='Contact' />;
      case 'task':
        return <TaskForm onCancelButton={handleCancel} resource={contact} resource_type='Contact' />;
      case 'file':
        return <FileForm onCancelButton={handleCancel} resource={contact} resource_type='Contact' />;
      case 'email':
        return <EmailForm onCancelButton={handleCancel} contact={contact} contactType="Contact" />;
      default:
        return;
    }
  }

  const returnAddNewMenuAction = () => {
    return <Menu
      mode="horizontal"
      defaultSelectedKeys={[]}
      className='actons-menu'
    >
      <Menu.Item key={`contact_${contact.id}_note`} onClick={() => updateShowModal('note')}>
        <Row align="middle"><BookOutlined className='mr-5' /> {t('tabs.note')}</Row>
      </Menu.Item>
      <Menu.Item key={`contact_${contact.id}_email`} onClick={() => updateShowModal('email')}>
        <Row align="middle"><MailOutlined className='mr-5' /> {t('tabs.email')}</Row>
      </Menu.Item>
      <Menu.Item key={`contact_${contact.id}_task`} onClick={() => updateShowModal('task')}>
        <Row align="middle"><ProfileOutlined className='mr-5' /> {t('tabs.task')}</Row>
      </Menu.Item>
      <Menu.Item key={`new_${contact.id}_file`} onClick={() => updateShowModal('file')}>
        <Row align="middle"><FileAddOutlined className='mr-5' /> {t('tabs.file')}</Row>
      </Menu.Item>
    </Menu>
  }


  if(!contactStore.hasAcccess) {
    return <div className='noAccess'>
        <h1>403</h1>
        <div className='hint'>{t("noAccess")}</div>
        <Link to={`/contacts`}>{t('backToContacts')}</Link>
    </div>
  }

  if (!contact.id) {
    return null
  }
  const createdAtDate = moment(contact.created_at).format('DD.MM.YYYY \\at HH:mm');

  return (
    <div className="contacts-page page single">
      <Modal
        title={t(`modal.add_${showModal}`)}
        visible={!!showModal}
        footer={null}
        width={'1000px'}
        onCancel={handleCancel}
        maskClosable={false}
        className='modal'
      >
        {modalContent()}
      </Modal>
      <Helmet>
        <title>{contact.name} - {CONTACT_TYPES[contact.contact_type.toString()]}</title>
      </Helmet>
      <Row className='page-subheader portlet w-100p'>
        <Row justify='space-between' align='middle' className='subheader-top w-100p'>
          <Col>
            <Row align='middle'>
              <Col className='avatar mr-25'>{getContactIcon(contact.contact_type)}</Col>

              <Col>
                <Row className='mb-5'>
                  <h4>{contact.name}</h4>
                </Row>
                <Row align='middle' gutter={30}>
                  <Col>{t('contacts.table.type')}: <span>{CONTACT_TYPES[contact.contact_type.toString()]}</span></Col>
                  <Col>{t('contacts.single.added')}: <span>{createdAtDate}</span></Col>
                  <Col>{t('contacts.new.source')}: <span>{contact.source && contact.source.name}</span></Col>
                  { (loginStore.isAdmin || loginStore.isGeneralManager) &&
                    <Col>{t('manager')}:
                        <a onClick={() => setShowModal('manager')} className='link ml-5 mr-5'>{contact.manager && contact.manager.name || t('addManager')} <EditOutlined /></a>
                    </Col>
                  }
                  { loginStore.session.user.user.id == contact.manager_id &&
                    <Col>{t('manager')}:
                      <span className='link ml-5 mr-5'>{contact.manager && contact.manager.name}</span>
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
          </Col>

          <Col>
            <Dropdown overlay={returnAddNewMenuAction} trigger={['click']}>
              <Button type='primary' size='large'>
              {t('price.newPrice')} <DownOutlined />
              </Button>
            </Dropdown>
            <Dropdown overlay={returnHeaderMenuAction()} trigger={['click']}>
              <Button type='primary' size='large' className='ml-25'>
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>

        <div className="subheader-bottom">
          <ul className='subheader-navigation'>
            <li className={classNames({ active: location.pathname.includes('overview') })}>
              <Link to={`/contacts/${contact.id}/overview`}>{t('contacts.single.overview')}</Link>
            </li>
            <li className={classNames({ active: location.pathname.includes('owner') })}>
              <Link to={`/contacts/${contact.id}/owner`}>{t('contacts.single.owner')} ({contact.properties_count})</Link>
            </li>
            { (loginStore.isAdmin || user.id == contact.manager_id) &&
              <li className={classNames({ active: location.pathname.includes('deals') })}>
                <Link to={`/contacts/${contact.id}/deals`}>{t('menu.deals')} ({contact.deals_count})</Link>
              </li>
            }
            <li className={classNames({ active: location.pathname.includes('rents') })}>
              <Link to={`/contacts/${contact.id}/rents`}>{t('tabs.rent')}  ({contact.rents_count})</Link>
            </li>
            { (loginStore.isAdmin || user.id == contact.manager_id) &&
              <li className={classNames({ active: location.pathname.includes('requests') })}>
                <Link to={`/contacts/${contact.id}/requests`}>{t('tabs.requests')} ({contact.requests_count})</Link>
              </li>
            }
            <li className={classNames({ active: location.pathname.includes('notes') })}>
              <Link to={`/contacts/${contact.id}/notes`}>{t('tabs.notes')} {contact.notes_count}</Link>
            </li>
            <li className={classNames({ active: location.pathname.includes('emails') })}>
              <Link to={`/contacts/${contact.id}/emails`}>{t('tabs.emails')} ({contact.emails_count})</Link>
            </li>
            <li className={classNames({ active: location.pathname.includes('tasks') })}>
              <Link to={`/contacts/${contact.id}/tasks`}>{t('tabs.tasks')} {contact.tasks_count}</Link>
            </li>
            <li className={classNames({ active: location.pathname.includes('files') })}>
              <Link to={`/contacts/${contact.id}/files`}>{t('tabs.files')} {contact.files_count}</Link>
            </li>
          </ul>
        </div>
      </Row>

      <Row className='page-content'>
        {children}
      </Row>
    </div>
  );
})

export default Single;
