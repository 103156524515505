import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import WebResourceStore from 'stores/webResource';
import { LANGUAGES } from 'constants/mixed'
import { Row, Col } from 'antd';
import DescriptionForm from 'modules/properties/containers/New/Steps/Description';
import { EditOutlined, CheckOutlined } from "@ant-design/icons";

import { IWebResorce } from 'webResource';
import { useTranslation } from 'react-i18next';

interface IData {
  property: any
}

const Description: FC<IData> = observer((props) => {

  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();

  const handleEditCancel = () => setIsEdit(false);

  const handleEdit = () => setIsEdit(true);

  const renderContent = () => {
    const { property } = props;
    const description = property.description_attributes;
    let content;

    if (isEdit) {
      content = <DescriptionForm
        descriptionAttributes={{ ...description }}
        isNotSteper
        handleCancel={handleEditCancel}
      />
    } else {
      content = staticContent()
    }

    return content;
  }

  const renderResources = (type: string) => {
    const { property } = props;
    const webResources = WebResourceStore.groupedValues[type];

    if (!webResources) {
      return null;
    }
    return webResources.map((resource: IWebResorce) =>
      <Col span={8} className={classNames('mb-20', { 'op-25': resource.name != 'tenerifecenter.com' })}>
        <span className='mr-5'>{resource.name}</span>
        {(property[`${type}_ids`] || []).includes(resource.id.toString()) &&
          <CheckOutlined className='green-color' />
        }
      </Col>
    )
  }

  const staticContent = () => {
    return (
      <div>
        {LANGUAGES.map((lang: string) => descriptionContent(lang))}
      </div>
    )
  }

  const descriptionContent = (lang: string) => {
    const { property } = props;
    const description = property.description_attributes;
    return (
      <Row className='mb-30'>
        <Col>
          <img width='20' src={require(`../../../../../../images/flags/${lang}.png`)} className='flag mr-5' />
          {description && description.value[lang] || '-'}
        </Col>
      </Row>
    )
  }

  return (
    <div className="contact-card-item">
      <Row justify='space-between' align='middle' className='contact-card-header'>
        <h5>{t('properties.new.description')}</h5>
        <EditOutlined onClick={handleEdit} />
      </Row>
      <div className="contact-card-content">
        {renderContent()}
      </div>
    </div>
  )
})

export default Description;
