import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";
import { userListService } from 'modules/users/containers/UserListService';
import userStore from 'stores/user';
import { taskService } from 'services/taskService';

import { Form, Button, Input, Select, Row, Col, DatePicker, TimePicker } from 'antd';
import { IContact } from "declarations/contact";
import { Property as IProperty } from "declarations/property";
import { ITask } from "declarations/task";
import { IUser } from 'declarations/user';
import { some } from 'lodash';
import ownerStore from "stores/owner";
import clientStore from "stores/client";
import { propertyService } from 'modules/properties/PropertyService';

dayjs.locale("en");

interface IData {
  task?: ITask,
  resource: IContact | IProperty;
  isActivity?: boolean;
  resource_type: string;
  onCreateContact?(params: IContact): void;
  onCancelButton(): void;
}

const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const moment = require('moment');
moment.locale('en');
const TaskForm: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [state, setState] = useState<any>({
    task: {
      id: undefined,
      description: '',
      title: '',
      user_id: undefined,
      created_by_id: undefined,
      updated_by_id: undefined,
      is_public: 'true',
      taskable_id: undefined,
      status: 'open',
      due_date: '',
      due_time: '',
      reminder: ''
    }
  });

  useEffect(() => {
    const { task } = props;

    if (task && task.id) {
      form.setFieldsValue({...task, due_date: task.due_date && dayjs(task.due_date), due_time: task.due_time && dayjs(task.due_time, "HH:mm")})
      setState({
        ...state,
        task: {
          ...state.task,
          ...task
        }
      })
    } else {
      form.setFieldsValue({ due_time: dayjs()})
    }
    userListService.loadResources({}, 'all')
    return () => form.resetFields();
  }, [])

  const renderVisibilityText = (isPublic: boolean) => {
    let text;
    if (isPublic) {
      text = t('publicTextTask');
    } else {
      text = t('privateTextTask');
    }
    return <Row className='mb-20'><Col offset={6} className='visibility-text'>{text}</Col></Row>;
  }


  const handleSubmit = async () => {
    const { isActivity } = props;
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      const taskValue: any = { ...state.task, ...values };
      taskValue['taskable_id'] = props.resource.id;
      taskValue['taskable_type'] = props.resource_type;
      if(values['due_date'] && values['due_time']) {
        taskValue['timezone_offset'] = values['due_time'].format('Z');
        taskValue['due_date'] = `${values['due_date'].format('DD.MM.YYYY')} ${values['due_time'].format('HH:mm Z')}`;
      }

      if (state.task.id) {
        taskService.updateResource(taskValue, isActivity, props.onCancelButton)
      } else {
        await taskService.createResource(taskValue, props.onCancelButton)
        if(props.resource_type === "Owner") {
          ownerStore.fetchOwner(props.resource.id)
        } else if (props.resource_type === "Property") {
          propertyService.loadResource(props.resource.id)
        } else {
          clientStore.fetchClient(props.resource.id)
        }
      }
    }
  }

  const reminderList = () => {
    const dueDate = form.getFieldValue('due_date')
    const dueTime = form.getFieldValue('due_time')

    if(!dueDate || !dueTime) {
      return
    }

    const momentDate = dayjs(`${dueDate.format('DD.MM.YYYY')} ${dueTime.format('HH:mm')}`, "DD.MM.YYYY HH:mm")
    return [
      <Option value="">{ t('properties.new.notSelected') }</Option>,
      <Option value="one_hour" disabled={dayjs().add(1, 'hour') > momentDate}>{ t('tasks.oneHourRemind') }</Option>,
      <Option value="three_hour" disabled={dayjs().add(3, 'hour') > momentDate}>{t('tasks.threeHourRemind')}</Option>,
      <Option value="one_day" disabled={dayjs().add(1, 'day') > momentDate}>{t('tasks.oneDayRemind')}</Option>,
      <Option value="three_day" disabled={dayjs().add(3, 'day') > momentDate}>{t('tasks.threeDayRemind')}</Option>,
      <Option value="week" disabled={dayjs().add(1, 'week') > momentDate}>{t('tasks.weekRemind')}</Option>
    ];

  }

  const handleCancel = () => {
    form.resetFields();
    props.onCancelButton();
  }

  const owners = userStore.values;

  return (
    <div className="task-form">
      <Form form={form}>
        <Form.Item
          {...formItemLayout}
          label={t('tasks.title')}
          name="title"
          rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
        >
          <Input placeholder={t('tasks.title')} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('properties.new.steps.description')}
          name="description"
        >
          <Input.TextArea placeholder={t('properties.new.steps.description')} rows={6} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('properties.table.status')}
          initialValue="open"
          name="status"
        >
          <Select >
            <Option value="open">{t('tasks.open')}</Option>
            <Option value="done">{t('tasks.done')}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('tasks.assignee')}
          name="user_id"
        >
          <Select
            showSearch
            placeholder={t('properties.placeholder.owner')}
            optionFilterProp="children"
            filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {(owners || []).map((owner: IUser) => <Option value={owner.id}>{owner.name}</Option>)}
          </Select>
        </Form.Item>
        <Row justify='start'>
          <Col offset={3} span={12}>
            <Form.Item
              {...formItemLayout}
              label={t('tasks.dueDate')}
              name="due_date"
            >
              <DatePicker className="w-100p" format='DD.MM.YYYY' />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              {...formItemLayout}
              label={null}
              name="due_time"
            >
              <TimePicker format='HH:mm' />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item shouldUpdate noStyle>
          {({getFieldValue}) => {
            const date = getFieldValue("due_date")
            const time = getFieldValue("due_time")
            const userId = getFieldValue("user_id")
            return <Form.Item
              {...formItemLayout}
              label={t('tasks.emailReminder')}
              name="reminder"
            >
              <Select
                filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled={ !(date && userId && time) }
              >
                { reminderList() }
              </Select>
            </Form.Item>
          }}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('emails.visibility')}
          initialValue={false}
          className="mb-0"
          name="is_public"
        >
          <Select>
            <Option value={true}>{t('public')}</Option>
            <Option value={false}>{t('private')}</Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const visibility = getFieldValue("is_public");
              return renderVisibilityText(visibility)
            }}
          </Form.Item>
      </Form>
      <Row>
        <Col span={6}>
        </Col>
        <Col span={18}>
          <div className="form-controls">
            <Button type='primary' className='mr-10' onClick={handleSubmit}>{t('save')}</Button>
            <Button onClick={handleCancel}>{t('cancel')}</Button>
          </div>
        </Col>
      </Row>
    </div>
  );
})

export default TaskForm;
