import axios from 'axios';
import { OWNERS_URL} from 'constants/api';
import { IOwner, FilterType } from "declarations/contact";



export const getOwners = async (params: { workspace_id: number}) => {
  const resp = await axios.get(OWNERS_URL, { params });
  return resp.data;
}

export const getById = async (ownerId: number): Promise<IOwner> => {
  const resp = await axios.get(`${OWNERS_URL}/${ownerId}`);
  return resp.data;
}

export const validate = async (email: string, workspace_id: number, owner_id?: number) => {
  const resp = await axios.get(`${OWNERS_URL}/validate_email`, { params: { email, workspace_id, owner_id}});
  return resp.data;
}

export const create = async (params: Partial<IOwner>): Promise<IOwner> => {
  const resp = await axios.post(OWNERS_URL, { owner: params });
  return resp.data;
}

export const update = async (ownerId: number, params: Partial<IOwner>): Promise<IOwner> => {
  const resp = await axios.put(`${OWNERS_URL}/${ownerId}`, { owner: params });
  return resp.data;
}

export const del = async (ownerId: number) => {
  const resp = await axios.delete(`${OWNERS_URL}/${ownerId}`);
  return resp.data;
}

export const exportOwners = async (params: FilterType | {}) => {
  const resp = await axios.get(`${OWNERS_URL}/export`, { params });
  return resp;
}
