import { FC } from 'react';
import { Row, Col } from 'antd';
import { Helmet } from 'react-helmet';
import ProfileForm from '../components/Form';
import { useTranslation } from 'react-i18next';
import { UserOutlined} from "@ant-design/icons";


const Profile: FC = () => {
  const { t } = useTranslation();

  return (
    <Row justify='center' className='my-profile-page page mt-50'>
      <Helmet>
          <title>{t("myProfile")}</title>
        </Helmet>
      <Col className="portlet profile-container">
          <Row align='middle' className='portlet-head'>
            <Col>
              <h5><UserOutlined className='mr-10 fs-20'/>{t('myProfile')}</h5>
            </Col>
          </Row>
          <div className='portlet-body'>
            <ProfileForm />
          </div>

      </Col>
    </Row>
  )
}

export default Profile;