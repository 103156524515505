import { observable, action } from 'mobx';
import { makeAutoObservable } from 'mobx';


export class ReportStore {

  values: any = null;
  loading = false
  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: any): void {
    this.values = values;
  }

  setLoading(val: boolean) {
    this.loading = val
  }

  clearData(): void {
    this.values = null;
  }
}

export default (new ReportStore())

