import { IEmail } from 'declarations/email';
import { makeAutoObservable } from 'mobx';

export class EmailStore {

  values: Array<IEmail> = [];
  page: number = 1;
  count: number = 0;
  fetchInProgress = false;
  saveInProgress = false;
  deleteInProgress = false;

  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: Array<IEmail>, page: number = 1, count: number = 0): void {
    this.values = values;
    this.page = page
    this.count = count
  }

  removeValue(resource_id: number): void {
    this.values = this.values.filter((item: IEmail) => item.id != resource_id);
  }

  clearData(): void {
    this.values = [];
    this.page = 1
  }

  updateValue(email: IEmail): void {
    this.values = this.values.map((item: IEmail) => item.id != email.id ? item : {...item, is_public: email.is_public} );
  }

  setFetchInProgress(val: boolean) {
    this.fetchInProgress = val;
  }
  setSaveInProgress(val: boolean) {
    this.saveInProgress = val;
  }
  setDeleteInProgress(val: boolean) {
    this.deleteInProgress = val;
  }

}

export default (new EmailStore())

