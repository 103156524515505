import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import PriceSection from '../../components/Tabs/Price';
import DetailsSection from '../../components/Tabs/Details';
import DescriptionSection from '../../components/Tabs/Description';
import AddressSection from '../../components/Tabs/Address';
import GallerySection from '../../components/Tabs/Gallery';
import { webResourceService } from 'services/webResourceService';
import WebResourceStore from 'stores/webResource';
import { validationResource } from 'utils/promValidation'
import propertyStore from 'stores/property';
import AppLayout from '../../Layout';

const Details: FC = observer(() => {
  const property = propertyStore.selected;
  useEffect(() => {
    webResourceService.loadResources();
  }, [])

  const validateChanges = (params: any): Array<string> => {
    const newProperty = {...property, ...params}
    if(newProperty.portal_ids.length == 0) {
      return []
    }
    const resources = WebResourceStore.values;
    const propertyResources = resources.filter((resource: any) => newProperty.portal_ids.includes(resource.id.toString()) )

    return propertyResources.map((res: any) => validationResource(res.name, newProperty)).flat()
  };

  return (
    <AppLayout>
      <div className="tab">
        <div className="card-list">
          <PriceSection
            property={property}
          />
          <DetailsSection
            property={property}
            validateChanges={validateChanges}
          />
          <AddressSection
            property={property}
            validateChanges={validateChanges}
          />
          <DescriptionSection
            property={property}
          />
          <GallerySection
            property={property}
            images={property.images}
          />
        </div>
      </div>
    </AppLayout>
  )
})

export default Details;
