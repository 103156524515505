import {FC, useEffect} from 'react';
import { observer } from 'mobx-react';
import clientStore from 'stores/client';
import ClientForm from '../../components/New/Form';
import { useParams, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { IClient } from "declarations/contact";

const Edit: FC = observer(() => {
  const client: any = clientStore.selected;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if(params.id) {
      clientStore.fetchClient(Number(params.id))
    }
    return () => clientStore.clearSelected();
  }, [params.id]);

  const onCreateClient = async (params: IClient) => {
    await clientStore.updateClient(params.id, params)
    if(!clientStore.saveError) {
      navigate('/clients')
      clientStore.fetchClients();
      messageApi.success('Client updated!')
    } else {
      messageApi.error((clientStore.saveError.data.message || []).join())
    }
  }

  const onCancelButton = () => {
    navigate('/clients');
  }


  if (!client?.id) {
    return null;
  }

  return (
    <div className="edit-client-page page">
      {contextHolder}
      <div className='mb-30'>
        <h3 className='ta-c'>{t('clients.editClient')}</h3>
      </div>
      <div>
        <div>
          <ClientForm client={client} onCreateClient={onCreateClient} onCancelButton={onCancelButton} />
        </div>
      </div>
    </div>
  );
});

export default Edit;
