import { request, action } from 'utils/mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import { message } from 'antd';
import { GALLERY_URL } from 'constants/api';
import PropertyStore from 'stores/property';
import { PropertyType } from "declarations/propertyType";
import { UPDATE_GALLERY, DELETE_GALLERY } from 'constants/action';

interface FilterType {

};


export class GalleryService {

  // LOAD resourceS
  @request()
  async createResourceRequest(params: FormData) {
    return axios.post(GALLERY_URL, params )
  }

  async createResource(params: FormData) {
    // let { filters, page } = (ownerListStore || {});

    const response: { status: number, data: any} = await this.createResourceRequest(params);

    if (response.status) {
      const value = response.data;
      return value;
    }

  }

  // LOAD resourceS
  @request()
  async updateResourceRequest(resource_id: number, params: any) {
    return axios.put(`${GALLERY_URL}/${resource_id}`, params )
  }

  @action({ action: UPDATE_GALLERY, minRequestTime: 500 })
  async updateResource(resource_id: number, params: any) {
    const response: { status: number } = await this.updateResourceRequest(resource_id, params);

    if (response.status != 200) {
      message.error('Image not updated')
    }

  }

  @request()
  async changesResourceRequest(params: any) {

    return axios.post(`${GALLERY_URL}/changes`, params)
  }

  @action({ action: UPDATE_GALLERY, minRequestTime: 500 })
  async changesResource(params: any, callback: Function) {
    const response: { status: number, data: any } = await this.changesResourceRequest(params);

    if (response.status == 200) {
      PropertyStore.updateValue(response.data)
      callback()
    }

  }

  async deleteResourceRequest(resource_id: number) {
    return axios.delete(`${GALLERY_URL}/${resource_id}`)
  }
  @action({ action: DELETE_GALLERY, minRequestTime: 500 })
  async deleteResource(resource_id: number, callback?: Function) {
    const response: { status: number } = await this.deleteResourceRequest(resource_id);

    if (response.status) {
      callback && callback();
      // message.success('Image deleted');
    }

  }
}

export const galleryService = new GalleryService();
