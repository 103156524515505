import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ProgressStore from 'stores/progress';
import { noteService } from 'services/noteService';
import NoteStore from 'stores/note';
import NoteItem from '../components/Item';
import NoteForm from '../components/Form';
import { Button, Row, Col, Modal, Empty, Spin } from 'antd';
import { LOAD_NOTES } from 'constants/action';

import { IContact } from 'contact';
import { Property as IProperty } from 'property';
import { INote } from 'note';

interface IData {
  resource_type: string;
  resource: IContact | IProperty,
}

const Index: FC<IData> = observer((props) => {

  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.resource.id) {
      noteService.loadResources({
        notable_id: props.resource.id,
        notable_type: props.resource_type,
      })
    }
    return () => NoteStore.clearValues()
  }, [])

  const handleCancel = () => setVisible(false);

  const renderCardLists = () => {
    const notes = NoteStore.values;

    if (notes.length > 0) {
      return notes.map((note: INote) => <NoteItem {...props} note={note} />)
    } else {
      return <Empty className='mt-100' description={t('notes.noNotes')} />
    }
  }


  const { resource, resource_type } = props;
  const notes = NoteStore.values;

  if(ProgressStore.isLoading(LOAD_NOTES)) {
    return <Spin className='loader-spiner-container' />
  }

  if (!notes) {
    return null;
  }

  return (
    <div className='tab'>
      <Modal
        title={t('notes.addNote')}
        visible={visible}
        footer={null}
        width='650px'
        destroyOnClose
        onCancel={handleCancel}
        className='modal'
        maskClosable={false}
      >
        <NoteForm onCancelButton={handleCancel} resource={resource} resource_type={resource_type} />
      </Modal>
      <Row justify="center">
        <Col span={16}>
          <Row justify="space-between" className='mb-30' align='middle'>
            <Col>
              <h3>{t('tabs.notes')} ({notes.length})</h3>
            </Col>
            <Col>
              <Button onClick={() => setVisible(true)} type='primary'>{t('notes.newNote')}</Button>
            </Col>
          </Row>

          <Row className='card-list' justify="center">
            {renderCardLists()}
          </Row>
        </Col>
      </Row>
    </div>
  );
})

export default Index;
