import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { history } from 'utils/history';
// import type { Session as SessionType, User } from 'types';
import { sessionService } from 'utils/session';
import debounce from 'lodash/debounce';
// import { ADMIN, ORGANIZER } from 'constants';

import { IContact } from 'declarations/contact';
import Item from 'submodules/files/components/Item';

export interface IOwnerList {
  values: IContact
  // debouncedSetToken(token: string): any
}

interface IFilterKeys {
    contact_types?: any,
    source_ids?: any,
    languages?: any,
    manager_ids?: any,
    q?: string,
    date_from?: any,
    date_to?: any,
    owns_properties?: number,
    has_tasks?: string
}

export class Contact {
  values: Array<IContact> = [];
  page: number | 'all' = 1;
  count: number = 0;
  hasAcccess: boolean = true;
  filterKeys: IFilterKeys = {
    contact_types: [],
    source_ids: [],
    languages: [],
    manager_ids: [],
    q: '',
    date_from: '',
    date_to: '',
    owns_properties: undefined,
    has_tasks: ''
  }
  selected: {} | IContact = {};
  fetchInProgress = false;
  saveInProgress = false;
  deleteInProgress = false;
  exportInProgress = false;

  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: Array<IContact>, page: number | 'all', count: number, filters: {}) {
    this.values = values;
    this.page = page;
    this.count = count;
    this.filterKeys = {...this.filterKeys ,...filters};
  }

  updateFilter(newFilter: {}) {
    this.filterKeys = {...this.filterKeys, ...newFilter}
  }

  resetFilter() {
    this.filterKeys = {
      contact_types: [],
      source_ids: [],
      languages: [],
      manager_ids: [],
      q: '',
      date_from: '',
      date_to: '',
      owns_properties: undefined,
      has_tasks: ''
    }
  }

  addSelectedValue(value: IContact): void {
    this.selected = value;
  }

  addSelectedAttributes(params: any): void {
    this.selected = { ...this.selected, ...params };
  }

  addValue(value: IContact) {
    this.values = this.values.map((item: IContact) => item.id == value.id ? value : item)
  }

  clearSelected() {
    this.selected = {}
  }

  updateHasAccess(val: boolean): void {
    this.hasAcccess = val;
  }

  clearData(): void {
    this.selected = {};
    this.values = [];
  }

  setFetchInProgress(val: boolean) {
    this.fetchInProgress = val;
  }
  setSaveInProgress(val: boolean) {
    this.saveInProgress = val;
  }
  setDeleteInProgress(val: boolean) {
    this.deleteInProgress = val;
  }
  setExportInProgress(val: boolean) {
    this.exportInProgress = val;
  }

}

export default (new Contact())

