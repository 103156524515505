import axios from 'axios';
// import { request, action } from 'utils';
import  webResourceStore  from '../stores/webResource';
import { WEB_RESOURCES_URL } from 'constants/api';
import { IWebResorce } from "declarations/webResource";

interface FilterType {

};

declare interface Props {
  loadResources(params?: FilterType): any,
  loadResourcesRequest(queryParams: {}): Promise<object>
}

export class WebResourceService implements Props {

  // LOAD resourceS
  async loadResourcesRequest() {
    return axios.get(WEB_RESOURCES_URL)
  }

  async loadResources() {

    if(webResourceStore.values.length) {
      return
    }

    const response: { status: number, data: Array<IWebResorce>} = await this.loadResourcesRequest();

    if (response.status) {
      let values = [];
      values = response.data;

      this.addValues(values);
    }

    return response;
  }

  addValues(values: Array<IWebResorce>) {
    webResourceStore.addValues(values);
  }
}

export const webResourceService = new WebResourceService();
