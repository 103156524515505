import { makeAutoObservable } from 'mobx';

export class ActivityStore {

  values: Array<Object> = [];
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: Array<Object>): void {
    this.values = values;
  }

  updateValue(value: any): void {
    this.values = this.values.map((item: any) =>
      item.id !== value.id && item.class_name !== value.class_name ? item : value
    )
  }

  clearValues(): void {
    this.values = []
  }
  setLoading(val: boolean): void {
    this.loading = val;
  }
}

export default (new ActivityStore())

