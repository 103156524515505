import { action } from 'mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import { message } from 'antd';
import { request } from 'utils/mobx';
import  SourceStore  from '../stores/source';
import { SOURCE_URL } from 'constants/api';
import { ISource } from "declarations/source";

interface FilterType {
  page: number;
};

declare interface Props {
  // loadResources(params?: FilterType, pageNum?: number): any,
  loadResourcesRequest(queryParams: {}): Promise<object>
}

export class SourceService implements Props {

  // LOAD resourceS
  async loadResourcesRequest(params: any) {
    return axios.get(SOURCE_URL, { params })
  }

  async loadResources(params: any = {}) {
    // let { filters, page } = (ownerListStore || {});
    const filter = {
      ...params,
      page: params['page'] || 'all',
    }

    const response: { status: number, data: { sources: Array<ISource>, sources_count: number}} = await this.loadResourcesRequest(filter);

    if (response.status) {
      let values = [];
      values = response.data.sources;

      this.addValues(values, response.data.sources_count);
    }

    return response;
  }

  @request()
  async createResourceRequest(params: ISource) {
    return axios.post(SOURCE_URL, { source:
      params
    })
  }


  async createResource(params: any, callback: Function) {

    const response: { status: number, data: { message?: Array<string>} } = await this.createResourceRequest(params);
    if (response.status == 200) {
      callback();
      this.loadResources({ page: 1 });
      message.success('Source created!')
    } else {
      message.error((response.data.message || []).join())
    }

  }

  @request()
  async deleteResourceRequest(params: ISource) {
    return axios.delete(`${SOURCE_URL}/${params['id']}`)
  }


  async deleteResource(params: any, callback?: Function) {

    const response: { status: number, data: { message?: string } } = await this.deleteResourceRequest(params);

    if (response.status == 200) {
      message.success('Source deleted!')
      this.loadResources({ page: 1 });
    } else {
      message.error(response.data.message)
    }
    if(callback) {
      callback()
    }

  }

  @request()
  async updateResourceRequest(params: ISource) {
    return axios.put(`${SOURCE_URL}/${params.id}`, { source:
      params
    })
  }


  async updateResource(params: any, callback: Function) {

    const response: { status: number, data: any } = await this.updateResourceRequest(params);

    if (response.status == 200) {
      callback(response.data);
      // this.loadResources();
      if(response.data && response.data.id) {
        this.updateValue(response.data)
      }
      message.success('Source updated!')
    } else {
      message.error((response.data.message || []).join())
    }
  }

  updateValue(value: ISource) {
    SourceStore.updateValue(value);
  }

  addValues(values: Array<ISource>, count: number) {
    SourceStore.addValues(values, count);
  }
}

export const sourceService = new SourceService();
