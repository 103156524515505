import { FC, useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import axios from 'axios';
import { propertyService } from '../../PropertyService';
import ProgressStore from 'stores/progress';
import MapComponent from 'components/MapComponent';
import { regionsService } from 'services/regionService';
import { cityService } from 'services/cityService';
import { complexService } from 'services/complexService';
// import { propertyService } from '../../../PropertyService';
import RegionStore from 'stores/region';
import CityStore from 'stores/city';
import ComplexStore from 'stores/complex';
import { CHECK_IDENTIFIER_URL } from 'constants/api';
import { UPDATE_PROPERTY } from 'constants/action';

import { Form, Select, Input, Button, Row, Col } from 'antd';

import { Property as IProperty } from 'declarations/property';
import { IRegion } from 'declarations/region';
import { ICity } from 'declarations/city';
import { IComplex } from 'declarations/complex';
import { useTranslation } from 'react-i18next';
import { isEmpty, some } from 'lodash';

interface IData {
  status: string;
  property: IProperty;
  handleCancel?(): void;
}

const { Option } = Select;
const CENTER = { lat: 28.2268066312491, lng: -16.6391376609972 };


const Review: FC<IData> = observer(({status, property, handleCancel}) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState<string | null>(null)
  const [form] = Form.useForm();

  useEffect(() => {
    Promise.all([
      regionsService.loadResources(),
      cityService.loadResources(),
      complexService.loadResources(),
    ])

    if (status) {
      form.setFieldsValue({ ...property.location_attributes })
    }
  }, [])

  const onCancel = () => handleCancel && handleCancel();

  const handleSubmit = async () => {
    const values = form.getFieldsValue();
    if (property.identifier !== values.identifier) {
      await checkIdentidfier();
    }
    await form.validateFields()
    if ((!some(form.getFieldsError(), ({ errors }) => errors.length)) && !hasError) {
      const propertyValue = {
        ...property,
        identifier: values.identifier,
        status: status || "review",
        location_attributes: {
        ...property.location_attributes,
        ...values
        }
      }
      propertyService.updateResource('properties', propertyValue, () => onCancel())
    }
  }

  const checkIdentidfier = async () => {
    const identifier = form.getFieldValue("identifier");
    if (!identifier) {
      setHasError(t('properties.message.identifier'))
      return
    }
    await axios.get(CHECK_IDENTIFIER_URL, { params: { identifier: identifier } }).then((resp) => {
      if (resp.data.result) {
        setHasError(t('properties.message.id'));
      }
    })
  }


  const getButtons = () => {
    return <div>
      <Button className='mr-10' type="primary" onClick={(e: any) => handleSubmit()}
        loading={ProgressStore.isLoading(UPDATE_PROPERTY)}
      >
        { t('save') }
      </Button>
      <Button onClick={handleCancel}>{t('cancel')}</Button>
    </div>
  }

  const getIdentifier = () => {
    const identifier = form.getFieldValue("identifier");
    let idValues = [];
    idValues.push(<span className='ml-10 sale'>VS{identifier}</span>)
    return idValues;
  }

  const onChangeRegion = (region_id: number) => {
    form.setFieldsValue({
      city_id: null,
      city_complex_id: null,
      region_id: region_id
    })
  }

  const onChangeCity = (city_id: number) => {
    const city: any = CityStore.values.find((city: ICity) => city.id === city_id);
    const region_id = city ? city.region_id : property.location_attributes.region_id;

    form.setFieldsValue({city_id: city_id, city_complex_id: null, region_id: region_id})
  }

  const onChangeComplex = (complex_id: number) => {
    const complex: any = ComplexStore.values.find((complex: IComplex) => complex.id == complex_id);

    if(!complex) {
      return
    }

    form.setFieldsValue({
      city_id: complex.city_id,
      region_id: complex.region_id
    })

    form.setFieldsValue({city_id: complex.city_id, region_id: complex.region_id})
  }

  const getRegionsOptions = () => {
    const regions = RegionStore.values;
    return regions.map((region: IRegion) => <Option value={region.id}>{region.name}</Option> )
  }

  const getCitiesOptions = (cities: ICity[]) => {
    return (cities || []).map((city: IRegion) => <Option value={city.id}>{city.name}</Option> )
  }

  const getCityComplexesOptions = (complexes: IComplex[]) => {
    return (complexes || []).map((complex: IRegion) => <Option value={complex.id}>{complex.name}</Option> )
  }

  const handleClick = (data: any) => {
    const lat  = data.lat;
    const lng = data.lng;
    form.setFieldsValue({lat, lng})
  }

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  const formItemRow = {
    wrapperCol: { span: 14, offset: 6 }
  };


    return (
      <div>
        <div>
          <Form onFinish={handleSubmit} form={form}>
          <Form.Item name="lat" noStyle><Input type="hidden" /></Form.Item>
          <Form.Item name="lng" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item
              {...formItemRow}
            >
              <h3>{t('properties.new.status.review')}</h3>
            </Form.Item>
            <Row>
              <Col span={8} offset={4}>
                <Form.Item
                  {...formItemLayout}
                  label={t('properties.new.id')}
                  validateStatus={hasError ? 'error' : ''}
                  help={<span>{hasError || ''}</span>}
                  className='mb-25'
                  name="identifier"
                  rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
                >
                  <Input placeholder={t('properties.new.id')} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item shouldUpdate noStyle>
                  {({getFieldValue}) => {
                    const identifier = getFieldValue("identifier")
                    return (
                      identifier && <span className='ml-20'>{t('properties.new.id')}: {getIdentifier()}</span>
                    )
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.address')}
              className='mb-25'
              name="address_from_wp"
            >
              <Input
                className='w-100p'
                disabled={true}
                value={property.location_attributes?.address_from_wp}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.region')}
              name="region_id"
              rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
            >
              <Select
                onChange={onChangeRegion}
                showSearch
                filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value=''>{t('properties.new.notSelected')}</Option>
                { getRegionsOptions() }
              </Select>
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const region_id = getFieldValue("region_id")
                const cities = region_id ? CityStore.groupedValues[region_id] : CityStore.values

              return <Form.Item
                  {...formItemLayout}
                  label={t('properties.new.city')}
                  name="city_id"
                  rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
                >
                  <Select
                    onChange={onChangeCity}
                    showSearch
                    filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value=''>{t('properties.new.notSelected')}</Option>
                    { getCitiesOptions(cities) }
                  </Select>
                </Form.Item>
              }}
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const region_id = getFieldValue("region_id")
                const city_id = getFieldValue("city_id")
                let complexes = region_id ? ComplexStore.groupedValues[region_id] : ComplexStore.values

                if(city_id) {
                  complexes = (complexes || []).filter((comp: IComplex) => comp.city_id == city_id)
                }

                return <Form.Item
                  {...formItemLayout}
                  label={t('properties.new.complex')}
                  name="city_complex_id"
                  rules={[{ required: false, message: t('properties.placeholder.complex') }]}
                >
                  <Select
                    onChange={onChangeComplex}
                    showSearch
                    filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value=''>{t('properties.new.notSelected')}</Option>
                    { getCityComplexesOptions(complexes) }
                  </Select>
                </Form.Item>
              }}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={t('properties.new.map')}
              shouldUpdate={() => false}
            >
              <div className='map-container w-500 h-350'>
                <MapComponent handleClick={handleClick} coordinates={{ lng: property.location_attributes.lng, lat: property.location_attributes.lat }} center={CENTER} form={form} />
              </div>
            </Form.Item>
          </Form>
        </div>
        <Row>
          <Col span={6}></Col>
          <Col span={18}>
            <div className="form-controls">
              {getButtons()}
            </div>
          </Col>
        </Row>
      </div>
    )
})

export default Review;