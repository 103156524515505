import {FC, useState, useEffect, useCallback} from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { debounce } from 'lodash';
import New from '../../components/New';
import { complexService } from 'services/complexService';
import complexStore from 'stores/complex';
import { cityService } from 'services/cityService';
import { useTranslation } from 'react-i18next';

import { islandsService } from 'services/islandService';
import cityStore  from 'stores/city';
import islandStore  from 'stores/island';
import { Table, Button, Menu, Modal, Dropdown, Row, Col, message, Input, Select } from 'antd';
import { CloseOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Helmet } from 'react-helmet';
import { IComplex } from 'declarations/complex';
import { ICity } from 'declarations/city';
import { IIsland } from 'declarations/island';
import workspaceStore from "stores/workspace";

const confirm = Modal.confirm;
const Search = Input.Search;
const { Option } = Select;

const List: FC = observer(() => {
  const [state, setState] = useState<any>({
    showModal: false,
    search: '',
    city_id: undefined,
    island_id: undefined,
    page: 1
  })
  const { t } = useTranslation();
  const workspace_id = workspaceStore.selectedId;

  useEffect(() => {
    cityService.loadResources()
    islandsService.loadResources()
    complexService.loadResources({ page: 1 })
  }, [workspace_id])

  const showModal = () => setState({...state, showModal: true });

  const handleOk = (complex: IComplex) => {
    complexService.createResource(complex, () => {
      setState({...state, showModal: false});
    })
  }

  const handleCancel = () => setState({...state, showModal: false});

  const getColumns = () => {

    return [
      {
        title: t('properties.table.id'),
        dataIndex: 'id',
        key: 'id',
        width: 80,

      }, {
        title: t('owners.table.name'),
        dataIndex: 'name',
        key: 'name',

      }, {
        title: t('properties.new.island'),
        dataIndex: 'island',
        key: 'island',
        width: 120,
      }, {
        title: t('properties.new.city'),
        dataIndex: 'city',
        key: 'city',
        width: 380,
      }, {
        title: t('menu.objects'),
        dataIndex: 'properties_count',
        key: 'properties_count',
        width: 80,
      }, {
        title: t('deals.filter.creationDate'),
        dataIndex: 'created_at',
        key: 'created_at',
        width: 180,
      }, {
        title: '',
        dataIndex: 'actions',
        key: 'id',
        width: 30,
      }
    ]
  }

  const onChangeFilter = useCallback(
    debounce((params) => {
      const {city_id, island_id, search } = params;
      complexService.loadResources({ city_id, island_id, q: search, page: 1 })
    }, 500),
    []
  )


  const onSearch = async (e: any) => {
    const params = { ...state, search: e.target.value, page: 1 };
    setState(params)
    onChangeFilter(params)
  }

  const renderDate = (data: string) => {
    if (!data) {
      return '';
    }

    const createdAtDate = moment(data).format('DD.MM.YYYY');
    const createdAtTime = moment(data).format('\\at kk:mm');
    return <div>{createdAtDate} <p>{createdAtTime}</p> </div>
  }

  const getData = () => {
    const complexes = complexStore.values;
    return (complexes || []).map((complex: any, index: number) => {
      return {
        id: complex.id,
        name: complex.name,
        city: complex.city_name,
        island: complex.island_name,
        properties_count: complex.properties_count,
        created_at: renderDate(complex.created_at),
        actions: <Dropdown overlay={getActionComponent(complex)} trigger={['click']}>
            <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
        </Dropdown>
      }
    })
  }

  const showConfirm = (complex: IComplex) => {
    if(complex.properties_count > 0) {
      return message.error(
        'Can not delete this complex. You have to reassign the properties to another complex'
      )
    }
    confirm({
      title: t('complexes.confirmDelete'),
      content: '',
      onOk() {
        return new Promise((resolve, reject) => {
          complexService.deleteResource(complex, resolve)
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() { },
    });
  }

  const getActionComponent = (complex: IComplex) => {

    return (
      <Menu
        mode="horizontal"
        defaultSelectedKeys={['2']}
        className='actons-menu'
      >
        <Menu.Item key={`delete_complex_${complex.id}`} onClick={() => showConfirm(complex)}>
          <span className='delete-title'>
            <DeleteOutlined className='mr-5' />
            {t('owners.table.delete')}
          </span>
        </Menu.Item>
      </Menu>
    )
  }

  const renderUserModal = () => {
    return <New handleOk={handleOk} handleCancel={handleCancel} />
  }

  const changePagination = (page: number) => {
    const { city_id, island_id } = state;
    setState({...state, page})
    complexService.loadResources({ page, city_id, island_id, q: state.search });
  }

  const getCitiesOptions = () => {
    const cities  = state.island_id ? cityStore.values.filter((city) => city.island_id === state.island_id) : cityStore.values;
    return cities.map((city: ICity) => <Option value={city.id}>{city.name}</Option> )
  }

  const getIslandsOptions = () => {
    const islands  = islandStore.values;
    return islands.map((island: IIsland) => <Option value={island.id}>{island.name}</Option> )
  }

  const onChange = async (value: number) => {
    const params = { ...state, city_id: value, q: '', page: 1 };
    await setState(params)
    onChangeFilter(params);
  }

  const clearFilter = async () => {
    const params = { city_id: undefined, island_id: undefined,  search: '', page: 1 };
    setState(params)
    onChangeFilter(params);
  }

  const onChangeIsland = (value: number) => {
    const params = { ...state, island_id: value, city_id: undefined, q: '', page: 1 };
    setState(params)
    onChangeFilter(params);
  }



  const paginationProps = {
    pageSize: 10,
    onChange: changePagination,
    total: complexStore.count,
    showSizeChanger: false,
    current: state.page,
  }

  return (
    <div className="users-page page">
      <Helmet>
        <title>{t("properties.new.complexes")}</title>
      </Helmet>
      <Modal
        title={t('complexes.addComplex')}
        visible={state.showModal}
        footer={null}
        onCancel={handleCancel}
        maskClosable={false}
        destroyOnClose
        className='modal'
      >
        {renderUserModal()}
      </Modal>

      <Row align='middle' justify='space-between' className='page-subheader'>
        <Col span={6}>
          <h3>{t('properties.new.complexes')} ({complexStore.count})</h3>
        </Col>
        <Col span={18}>
          <Row justify='end'>
            <Col className='mr-10'>
              <Select
                showSearch
                placeholder={t('properties.new.island')}
                onChange={onChangeIsland}
                style={{ width: '200px' }}
                size='large'
                value={state.island_id}
                filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value=''>{t('complexes.allIslands')}</Option>
                { getIslandsOptions() }
              </Select>
            </Col>
            <Col className='mr-10'>
              <Select
                showSearch
                placeholder={t('properties.new.city')}
                onChange={onChange}
                style={{ width: '200px' }}
                size='large'
                value={state.city_id}
                filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value=''>{t('complexes.allCities')}</Option>
                { getCitiesOptions() }
              </Select>
            </Col>
            <Col>
              <Search
                placeholder={t('properties.search')}
                value={state.search}
                onChange={onSearch}
                className='h-100p'
                size='large'
                allowClear={true}
              />
            </Col>
            <Col className='mr-10 ml-10'>
              <Button type='default' size='large' onClick={() => clearFilter()}>
                <CloseOutlined />
              </Button>
            </Col>
            <Col>
              <Button type='primary' size='large' onClick={() => showModal()}>{t('complexes.addComplex')}</Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className='page-content'>
        <Table
          dataSource={getData()}
          columns={getColumns()}
          className='general-table'
          bordered
          pagination={paginationProps}
        />
      </div>
    </div>
  );
})

export default List;
