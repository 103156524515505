import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { emailService } from 'services/emailService';
import EmailStore from 'stores/email';
import EmailList from 'submodules/emails/components/List';
import AppLayout from "modules/owners/containers/Single/components/Layout";
import ownerStore from 'stores/owner';


const Emails: FC = observer(() => {
  const owner: any = ownerStore.selected;

  useEffect(() => {
    if (owner?.id) {
      emailService.loadResources({ letterable_id: owner.id, letterable_type: "Owner", page: 1 })
    }
    return () => EmailStore.clearData();
  }, [owner?.id])

  return (
    <AppLayout>
      <EmailList contact={owner} contactType="Owner" />
    </AppLayout>
  );
})

export default Emails;
