import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Select, DatePicker, Checkbox } from 'antd';
import { sourceService } from 'services/sourceService';
import ownerStore from 'stores/owner';
import { keys, omit } from 'lodash';
import SourceStore from 'stores/source';
import { LANGUAGES } from "constants/mixed"
import workspaceStore from 'stores/workspace';

import { ISource } from "declarations/source";


const { Option, OptGroup } = Select;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const formItemLayoutCheckbox = {
  labelCol: { span: 12 },
  wrapperCol: { span: 2, offset: 10 },
};

const DEFAUL_FILTER_VALUE = {
  client_types: [],
  source_ids: [],
  languages: [],
  manager_ids: [],
  creation_date: [],
  owns_properties: undefined,
  has_tasks: false
}

const FilterOwner: FC = observer(() => {
  const [filter, setFilter] = useState(DEFAUL_FILTER_VALUE);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    sourceService.loadResources();
    return () => {
      ownerStore.clearData();
      ownerStore.resetFilter();
      form.resetFields();
    }
  }, [workspaceStore.selectedId])



  const resetFilters = () => {
    form.resetFields();
    setFilter(DEFAUL_FILTER_VALUE)
    ownerStore.resetFilter();
    ownerStore.fetchOwners();
  }

  const getFieldChange = async (value: any, name: string) => {
    const newFilter = { ...filter, [name]: value };
    setFilter(newFilter)
    const dates: any = filter.creation_date;
    let dataFilter = {'date_from':  null, 'date_to': null};
    if (dates) {
      dataFilter = { 'date_from':  dates[0], 'date_to': dates[1] };
    }
    const filterValues = omit({...newFilter, ...dataFilter}, "creation_date");
    await ownerStore.updateFilter(filterValues)
    ownerStore.fetchOwners(filterValues, 1)

  }

  const onFilterDate = async (dates: any) => {
    const newFilter = { ...filter, creation_date: dates };
    let filterValues;
    if (dates) {
      setFilter(newFilter);
      let dataFilter = { 'date_from':  dates[0], 'date_to': dates[1] }
      filterValues = omit({...newFilter, ...dataFilter}, "creation_date");
    } else {
      form.resetFields();
      filterValues = omit({...filter}, ["creation_date", "date_from", "date_to"]);
    }
    ownerStore.fetchOwners(filterValues, 1)
  }

  const renderSources = () => {
    const sources = SourceStore.groupedValues;
    const sourceKeys = keys(sources)

    return sourceKeys.map((category: string) => {
      return <OptGroup key={`source_${category}`} label={category}>
        {sources[category].map((source: ISource) => <Option value={source.id} key={`source_${source.id}`}>{source.name}</Option>)}
      </OptGroup>
    })
  }

  return (
    <Form className='filter-form' form={form}>
      <Form.Item
        {...formItemLayout}
        label={t('contacts.filter.type')}
        name="type"
      >
        <Select
          showArrow={true}
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'owner_types')}
          placeholder={t('placeholder.all')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value="0" key='client_types_0'>{t('contacts.new.type.person')}</Option>
          <Option value="1" key='client_types_1'>{t('contacts.new.type.company')}</Option>
          <Option value="2" key='client_types_2'>{t('contacts.new.type.agency')}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('contacts.new.source')}
        name="source"
      >
        <Select
          showArrow={true}
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'source_ids')}
          placeholder={t('placeholder.all')}
          showSearch
          filterOption={(input: any, option: any) => typeof(option.props.children) == 'string' && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {renderSources()}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('contacts.new.language')}
        name="language"
      >
        <Select
          showArrow={true}
          onChange={(value: any) => getFieldChange(value, 'languages')}
          mode='multiple'
          placeholder={t('placeholder.all')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {LANGUAGES.map((lng: string) => (
            <Option key={`languages_${lng}`} value={lng}>{t(`contacts.new.lng.${lng}`)}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('contacts.new.creationDate')}
        name="creation_date"
      >
        <DatePicker.RangePicker
          format="DD.MM.YYYY"
          onChange={onFilterDate}
          className="w-100p"
        />
      </Form.Item>
      <Form.Item
        {...formItemLayoutCheckbox}
        label={t('contacts.filter.placeholderHasTask')}
        colon={false}
        name="has_tasks"
        valuePropName='checked'
      >
        <Checkbox onChange={(e: any) => getFieldChange(e.target.checked, 'has_tasks')} />
      </Form.Item>
      <Form.Item>
        <Button type='primary' onClick={resetFilters} style={{ width: '100%' }}>{t('deals.filter.resetFilter')}</Button>
      </Form.Item>
    </Form>
  )
});

export default FilterOwner;