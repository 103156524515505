import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Form, Button, Select, Checkbox, DatePicker, Row, Col, InputNumber } from 'antd';
import { propertyService } from '../../PropertyService';
import { propertyTypesService } from 'services/propertyTypes';
import { webResourceService } from 'services/webResourceService';
import { propertyCharacteristicService } from 'services/propertyCharacteristicService';
import { regionsService } from 'services/regionService';
import { cityService } from 'services/cityService';
import { islandsService } from 'services/islandService';
import { complexService } from 'services/complexService';
import workspaceStore from 'stores/workspace';
import { GIFT_TYPES } from 'constants/mixed';
import PropertyTypeStore from 'stores/type';
import ownerStore from 'stores/owner';
import PropertyCharacteristicStore from 'stores/propertyCharacteristic';
import WebResourceStore from 'stores/webResource';
import IslandStore from 'stores/island';
import RegionStore from 'stores/region';
import LocaleStore from 'stores/locale';
import CityStore from 'stores/city';
import ComplexStore from 'stores/complex';

import { PropertyType } from "declarations/propertyType";
import { IRegion } from 'declarations/region';
import { ICity } from 'declarations/city';
import { IComplex } from 'declarations/complex';
import { IPropertyCharacteristic } from 'propertyCharacteristic';
import { IWebResorce } from 'webResource';
import { name } from 'utils/localized';
import { IContact } from 'contact';
import { IIsland } from 'island';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import { getContactIcon } from "modules/owners/containers/List"

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const formItemLayoutCheckbox = {
  labelCol: { span: 12 },
  wrapperCol: { span: 2, offset: 10 },
};

const  DEFAUL_FILTER_VALUE = {
  statuses: [],
  operations: [],
  type_ids: [],
  owners: [],
  feature_ids: [],
  label_ids: [],
  class_ids: [],
  web_resource_ids: [],
  sale_statuses: [],
  rent_statuses: [],
  island: [],
  region: [],
  city: [],
  complex: [],
  cartello: null,
  sale_price_min: null,
  sale_price_max: null,
  rent_price_min: null,
  rent_price_max: null,
  gift_type: '',
  new_building: 'all',
  creation_date: [],
  has_tasks: false,
  bedroom_num: undefined,
  bathroom_num: undefined
}


const FilterProp: FC = observer(() => {
  const [filterKeys, setFilter] = useState(DEFAUL_FILTER_VALUE);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const lng = LocaleStore.locale;

  useEffect(() => {
    propertyTypesService.loadResources();
    ownerStore.fetchOwners({}, 'all');
    webResourceService.loadResources();
    propertyCharacteristicService.loadResources();
    islandsService.loadResources();
    regionsService.loadResources();
    cityService.loadResources();
    form.resetFields();
    complexService.loadResources();
  }, [workspaceStore.selectedId])

  const resetFilters = () => {
    form.resetFields();
    setFilter(DEFAUL_FILTER_VALUE)
    propertyService.resetFilters();
  }

  const getFieldChange = (value: any, name: string) => {
    setFilter({...filterKeys, [name]: value })
    const dates: any = filterKeys.creation_date;
    const newFilter = { ...filterKeys, [name]: value };
    let dataFilter = {'date_from':  null, 'date_to': null};

    if (dates) {
      dataFilter = { 'date_from':  dates[0], 'date_to': dates[1] };
    }
    propertyService.changeFilters(omit({...newFilter, ...dataFilter}, "creation_date"))
  }

  const onFilterDate = (dates: any) => {
    const newFilter = { ...filterKeys, creation_date: dates };
    if (dates) {
      setFilter({...filterKeys, creation_date: dates})
      let dataFilter = { 'date_from':  dates[0], 'date_to': dates[1] }
      propertyService.changeFilters(omit({...newFilter, ...dataFilter}, "creation_date"))
    } else {
      form.resetFields();
      propertyService.changeFilters(omit({...filterKeys}, ["creation_date", "date_from", "date_to"]))
    }
  }

  const renderOwnsProperties = () => {
    let selectArray = [];
    for (let n = 1; n < 21; n++) {
      selectArray.push(<Option value={n} key={`own_${n}`}>{n}</Option>);
    }
    return selectArray;
  }

  const renderCharacteristic = (type: string) => {
    const characteristics = PropertyCharacteristicStore.groupedValues[type];

    if(!characteristics) {
      return null;
    }

    return characteristics.map((char: IPropertyCharacteristic) => {
      return <Option value={char.id.toString()} key={`${type}_${char.id}`}>
          { name(char, lng) }
        </Option>
    })

  }

  const renderTypes = () => {
    const types : any = PropertyTypeStore.values;
    return (types || []).map((type: PropertyType) => {
      const name: any = type.name;
      return <Option value={type.id} key={`type_${type.id}`}>{ name[lng || "en"] }</Option>
    })
  }

  const renderContacts = () => {
    const owners : any = ownerStore.values;
    return (owners || []).map((owner: IContact) =>
      <Option value={owner.id} key={`owner_${owner.id}`}>
        {getContactIcon(owner.contact_type, "mr-5")}
        { owner.name  + (owner.contact_person ? ` (${owner.contact_person})` : '') }
      </Option>
    )
  }

  const renderWebResources = () => {
    const resources : any = WebResourceStore.values;
    return (resources || []).map((resource: IWebResorce) =>
    <Option value={resource.id} key={`resource_${resource.id}`}>{ resource.name }</Option> )
  }

  const renderIslandResources = () => {
    const islands : any = IslandStore.values;
    return (islands || []).map((island: IIsland) =>
    <Option value={island.id} key={`region_${island.id}`}>{ island.name }</Option> )
  }

  const renderRegionResources = () => {
    const regions : any = RegionStore.values;
    return (regions || []).map((region: IRegion) =>
    <Option value={region.id} key={`region_${region.id}`}>{ region.name }</Option> )
  }

  const renderCityResources = () => {
    const cities : any = CityStore.values;
    return (cities || []).map((city: ICity) =>
    <Option value={city.id} key={`city_${city.id}`}>{ city.name }</Option> )
  }

  const renderComplexResources = () => {
    const complexes : any = ComplexStore.values;
    return (complexes || []).map((complex: IComplex) =>
    <Option value={complex.id} key={`complex_${complex.id}`}>{ complex.name }</Option> )
  }

  return (
    <Form className='filter-form' form={form}>
      <Form.Item
        {...formItemLayout}
        label={t('properties.table.status')}
        name="statuses"
      >
        <Select
          showArrow={true}
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'statuses')}
          placeholder={t('placeholder.all')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
          <Option value="published" key='statuses_0'>
            {t('properties.new.status.published')}
          </Option>
          <Option value="draft" key='statuses_1'>
            {t('properties.new.status.draft')}
          </Option>
          <Option value="archive" key='statuses_2'>
            {t('properties.new.status.archive')}
          </Option>
          <Option value="review" key='statuses_3'>
            {t('properties.new.status.review')}
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('deals.new.operation')}
        name="operations"
      >
        <Select
          showArrow={true}
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'operations')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          placeholder={t('placeholder.all')}
        >
          <Option value="0" key='operations_0'>{t('properties.new.operation.sale')}</Option>
          <Option value="1" key='operations_1'>{t('properties.new.operation.rent')}</Option>
          <Option value="2" key='operations_2'>{t('properties.new.operation.traspaso')}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('properties.new.type')}
        name="type_ids"
      >
        <Select
          showArrow={true}
          onChange={(value: any) => getFieldChange(value, 'type_ids')}
          mode='multiple'
          showSearch
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          placeholder={t('placeholder.all')}
        >
          {renderTypes()}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={`${t('properties.new.newBuilding')}`}
        name="new_building"
      >
        <Select
          showArrow={true}
          onChange={(value: any) => getFieldChange(value, 'new_building')}
          placeholder={t('placeholder.any')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value='all'>{t('properties.all')}</Option>
          <Option value='true'>{t('properties.new.yes')}</Option>
          <Option value='false'>{t('properties.new.no')}</Option>
          <Option value='not_selected'>{t('properties.new.notSelected')}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('properties.new.owner')}
        name="owners"
      >
        <Select
          showArrow={true}
          mode='multiple'
          showSearch
          filterOption={(input: any, option: any) => option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value: any) => getFieldChange(value, 'owners')}
          placeholder={t('placeholder.all')}
        >
          {renderContacts()}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('properties.new.features')}
        name="feature_ids"
      >
        <Select
          showArrow={true}
          mode='multiple'
          showSearch
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value: any) => getFieldChange(value, 'feature_ids')}
          placeholder={t('placeholder.all')}
        >
          {renderCharacteristic('feature')}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('properties.new.labels')}
        name="label_ids"
      >
        <Select
          showArrow={true}
          mode='multiple'
          showSearch
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value: any) => getFieldChange(value, 'label_ids')}
          placeholder={t('placeholder.all')}
        >
          {renderCharacteristic('label')}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('properties.new.classes')}
        name="class_ids"
      >
        <Select
          showArrow={true}
          showSearch
          mode='multiple'
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value: any) => getFieldChange(value, 'class_ids')}
          placeholder={t('placeholder.any')}
        >
          {renderCharacteristic('class')}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('properties.new.promotions')}
        name="web_resource_ids"
      >
        <Select
          showArrow={true}
          showSearch
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'web_resource_ids')}
          placeholder={t('placeholder.all')}
        >
          {renderWebResources()}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('properties.new.saleStatus')}
        name="sale_statuses"
      >
        <Select
          showArrow={true}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'sale_statuses')}
          placeholder={t('placeholder.all')}
        >
          <Option value="is_available" key='operations_0'>
            {t('properties.new.status.available')}
          </Option>
          <Option value="is_reserved" key='operations_1'>{t('properties.new.status.reserved')}</Option>
          <Option value="sold" key='operations_2'>{t('properties.new.status.sold')}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('properties.new.rentStatus')}
        name="rent_statuses"
      >
        <Select
          showArrow={true}
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'rent_statuses')}
          placeholder={t('placeholder.all')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value="is_available" key='operations_0'>{t('properties.new.status.available')}</Option>
          <Option value="is_reserved" key='operations_1'>{t('properties.new.status.reserved')}</Option>
          <Option value="rented" key='operations_2'>{t('properties.new.status.rented')}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('properties.new.islands')}
        name="island"
      >
        <Select
          showArrow={true}
          mode='multiple'
          showSearch
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value: any) => getFieldChange(value, 'island')}
          placeholder={t('placeholder.all')}
        >
          {renderIslandResources()}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('properties.new.regions')}
        name="region"
      >
        <Select
          showArrow={true}
          mode='multiple'
          showSearch
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value: any) => getFieldChange(value, 'region')}
          placeholder={t('placeholder.all')}
        >
          {renderRegionResources()}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('properties.new.cities')}
        name="city"
      >
        <Select
          showArrow={true}
          mode='multiple'
          showSearch
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value: any) => getFieldChange(value, 'city')}
          placeholder={t('placeholder.all')}
        >
          {renderCityResources()}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('properties.new.complex')}
        name="complex"
      >
        <Select
          showArrow={true}
          mode='multiple'
          showSearch
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value: any) => getFieldChange(value, 'complex')}
          placeholder={t('placeholder.all')}
        >
          {renderComplexResources()}
        </Select>
      </Form.Item>
      <Row justify='space-between'>
        <Col span={11}>
          <Form.Item
            {...formItemLayout}
            label={t('properties.new.salePrice')}
            name="sale_price_min"
          >
            <InputNumber
              onChange={(value: any) => getFieldChange(value, 'sale_price_min')}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '\.')}
              parser={(value: any) => value.replace(/(\.*)/g, '')}
              placeholder={t('properties.placeholder.minPrice')}
              className='w-100p'
            />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            {...formItemLayout}
            label={t('properties.new.salePrice')}
            name="sale_price_max"
          >
            <InputNumber
              onChange={(value: any) => getFieldChange(value, 'sale_price_max')}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '\.')}
              parser={(value: any) => value.replace(/(\.*)/g, '')}
              placeholder={t('properties.placeholder.maxPrice')}
              className='w-100p'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify='space-between'>
        <Col span={11}>
          <Form.Item
            {...formItemLayout}
            label={t('properties.new.rentPrice')}
            name="rent_price_min"
          >
            <InputNumber
              onChange={(value: any) => getFieldChange(value, 'rent_price_min')}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '\.')}
              parser={(value: any) => value.replace(/(\.*)/g, '')}
              placeholder={t('properties.placeholder.minPrice')}
              className='w-100p'
            />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            {...formItemLayout}
            label={t('properties.new.rentPrice')}
            name="rent_price_max"
          >
            <InputNumber
              onChange={(value: any) => getFieldChange(value, 'rent_price_max')}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '\.')}
              parser={(value: any) => value.replace(/(\.*)/g, '')}
              placeholder={t('properties.placeholder.maxPrice')}
              className='w-100p'
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        {...formItemLayout}
        label={`${t('price.new.gift')}`}
        name="gift_type"
      >
        <Select
          showArrow={true}
          onChange={(value: any) => getFieldChange(value, 'gift_type')}
          placeholder={t('placeholder.any')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value=''>{t('placeholder.any')}</Option>
          {GIFT_TYPES.map((val: string) => <Option value={val}>{t(`price.new.giftTypes.${val}`)}</Option>)}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('deals.filter.creationDate')}
        name="creation_date"
      >
        <DatePicker.RangePicker
          format="DD.MM.YYYY"
          onChange={onFilterDate}
          className="w-100p"
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={`${t('properties.new.bedroom')} #`}
        name="bedroom_num"
      >
        <Select
          showArrow={true}
          onChange={(value: any) => getFieldChange(value, 'bedroom_num')}
          placeholder={t('placeholder.any')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {renderOwnsProperties()}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={`${t('properties.new.bathroom')} #`}
        name="bathroom_num"
      >
        <Select
          showArrow={true}
          onChange={(value: any) => getFieldChange(value, 'bathroom_num')}
          placeholder={t('placeholder.any')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {renderOwnsProperties()}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={`${t('properties.new.cartello')}`}
        name="cartello"
      >
        <Select
          showArrow={true}
          onChange={(value: any) => getFieldChange(value, 'cartello')}
          placeholder={t('placeholder.any')}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value='all'>{t('properties.all')}</Option>
          <Option value='true'>{t('properties.new.yes')}</Option>
          <Option value='false'>{t('properties.new.no')}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayoutCheckbox}
        label={t('properties.new.hasOpenTask')}
        colon={false}
        name="has_tasks"
        valuePropName='checked'
      >
        <Checkbox onChange={(e: any) => getFieldChange(e.target.checked, 'has_tasks')} />
      </Form.Item>
      <Form.Item
      >
        <Button type='primary' onClick={resetFilters} style={{ width: '100%' }}>
          {t('deals.filter.resetFilter')}
        </Button>
      </Form.Item>
    </Form>
  )
});

export default FilterProp;