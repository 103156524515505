import { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { cityService } from 'services/cityService';
import { islandsService } from 'services/islandService';
import cityStore  from 'stores/city';
import islandStore  from 'stores/island';
import { Form, Select, Input, Button, Row, Col } from 'antd';

import { ICity } from 'declarations/city';
import { useTranslation } from 'react-i18next';
import { IIsland } from 'island';
import { get, some } from 'lodash';

interface IData {
  handleOk(complex: any): any,
  handleCancel(): void,
}

const { Option } = Select;
const defaulState = {
  name: '',
  city_id: undefined,
  island_id: undefined,
  cities: [],
  islands: []
}

const  New: FC<IData> = observer(({handleOk, handleCancel}) => {

  const { t } = useTranslation();
  const [state, setState] = useState<any>(defaulState);
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  useEffect(() => {
    islandsService.loadResources().then(() => setState({
      ...state,
      islands: islandStore.values,
      island_id: get(islandStore.values, '[0].id')
    }))
    cityService.loadResources().then(() => setState({...state, cities: cityStore.values}))
  }, [])

  const handleSubmit = async () => {
    await form.validateFields();
    if (some(form.getFieldsError(), ({ errors }) => errors.length)) {
      return false;
    } else {
      const values = form.getFieldsValue();
      const city: any = cityStore.values.find((city) => city.id == values['city_id'])
        const complexValues = { ...state, ...values, region_id: city.region_id }
        handleOk(complexValues);
    }
  }

  const onChangeIsland = (island_id: number) => {
    const cities = cityStore.values.filter((item) => item.island_id === island_id)
    form.setFieldsValue({city_id: null})
    setState({
      ...state,
      city_id: null,
      island_id,
      cities
    })
  }

  const getCitiesOptions = () => {
    const cities = state.island_id ?
      state.cities.filter((city: ICity) => city.island_id === state.island_id) :
      state.cities

    return cities.map((city: ICity) => <Option value={city.id}>{city.name}</Option> )
  }

  const getIslandsOptions = () => {
    const islands  = islandStore.values;
    return islands.map((island: IIsland) => <Option value={island.id}>{island.name}</Option> )
  }

  return (
    <div className="add-user">
      <Form onFinish={handleSubmit} form={form} name='new_user'>
        <Form.Item
          {...formItemLayout}
          label={t('owners.table.name')}
          name="name"
          rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
        >
          <Input placeholder={t('owners.table.name')} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={t('properties.new.island')}
          name="island_id"
          rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
        >
          <Select
            showSearch
            onChange={onChangeIsland}
            filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              { getIslandsOptions() }
            </Select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('properties.new.city')}
          name="city_id"
          rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
        >
          <Select
            showSearch
            filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            { getCitiesOptions() }
          </Select>
        </Form.Item>
        <Row>
          <Col span={8}></Col>
          <Col span={16}>
            <div className="form-controls">
              <Button type="primary" onClick={handleSubmit} className='mr-10'>
                {t('save')}
              </Button>
              <Button onClick={handleCancel}>
                {t('cancel')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
})

export default New;
