import { FC} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { includes } from 'lodash';
import {  Row, Col, Avatar } from 'antd';
import moment from 'moment';
import { COMISSION_TYPE_SYMBOLS, SALE_VALUE, RENT_VALUE, TRASPASO_VALUE } from 'constants/mixed';
import { Property as IProperty } from 'property';
import { UserOutlined } from "@ant-design/icons";

interface IData {
  id?: number,
  property: IProperty,
  price: any,
}


const Item: FC<IData> = observer((props) => {
  const { t } = useTranslation();
  const { price, property } = props;
  const createdDate = moment(price.new.updated_at).format('D MMMM YYYY [at] HH:mm');
  const name = price.new.editor && price.new.editor.name || '-';
  const isSale = includes(property.operation, SALE_VALUE) || includes(property.operation, TRASPASO_VALUE)

  return (
    <div className='contact-card-item'>
      <div className='contact-card-header'>
        <Row justify="start">
          <Col>
            <Avatar size={35} icon={<UserOutlined />} className='mr-10' />
          </Col>
          <Col>
            <p>{name} {t('price.updatePrice')}</p>
            <span>{createdDate}</span>
          </Col>
        </Row>
      </div>
      <Row className='contact-card-content' justify={'space-between'}>
        <Col span={24}>
          {price.new && isSale &&
            <Row className='mb-20'>
              <Col span={12}>
                <h4 className='mb-20'>{t('price.old')}</h4>
                <div className=' mb-10'>
                  <strong>{t('deals.new.operation')}: </strong><span> {t('deals.new.dealOperation.sale')}</span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.type')}: </strong><span>{t('price.new.exact')}</span></div>
                <div className=' mb-10'><strong>{t('properties.table.price')}: </strong><span>
                  {`${price.old.amount || '-'}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.gift')}: </strong><span>
                    {t(`price.new.giftTypes.${price.old.gift_type}`)}
                    {(price.old.cashback && price.old.gift_type === 'cashback') &&
                      <span className='ml-5'>{`(${price.old.cashback} €)`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                    }
                  </span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.comission')}: </strong>
                  <span>
                    {`${price.old.comission_value || '-'}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    {COMISSION_TYPE_SYMBOLS[price.old.comission_type]}
                  </span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.ownerPrice')}: </strong><span>
                  {`${price.old.owners_amount || '-'}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.ibi')}: </strong><span>{`${price.old.ibi || '-'}`}</span></div>
                <div className=' mb-10'><strong>{t('price.new.mortgage')}: </strong><span>
                  {price.old.mortgage ? t('properties.new.yes') : t('properties.new.no')}</span>
                </div>
              </Col>
              <Col span={12}>
                <h4 className='mb-20'>{t('price.newPrice')}</h4>
                <div className=' mb-10'>
                  <strong>{t('deals.new.operation')}: </strong><span> {t('deals.new.dealOperation.sale')}</span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.type')}: </strong><span>{t('price.new.exact')}</span></div>
                <div className=' mb-10'><strong>{t('properties.table.price')}: </strong><span>
                  {`${price.new.amount || '-'}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.gift')}: </strong><span>
                  {t(`price.new.giftTypes.${price.new.gift_type}`)}
                    {(price.new.cashback && price.new.gift_type === 'cashback') &&
                      <span className='ml-5'>{`(${price.new.cashback} €)`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                    }
                  </span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.comission')}: </strong>
                  <span>
                    {`${price.new.comission_value || '-'}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    {COMISSION_TYPE_SYMBOLS[price.new.comission_type]}
                  </span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.ownerPrice')}: </strong><span>
                  {`${price.new.owners_amount || '-'}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.ibi')}: </strong><span>
                  {`${price.new.ibi || '-'}`}</span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.mortgage')}: </strong><span>
                  {price.new.mortgage ? t('properties.new.yes') : t('properties.new.no')}</span>
                </div>
              </Col>
            </Row>
          }
          {price.new && includes(property.operation, RENT_VALUE) &&
            <Row>
              <Col span={12}>
                <div className=' mb-10'>
                  <strong>{t('deals.new.operation')}: </strong><span> {t('deals.new.dealOperation.rent')}</span>
                </div>
                <div className=' mb-10'><strong>{t('properties.new.monthlyPrice')}: </strong>
                  <span>{`${price.old.monthly_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.fixedCommission')}: </strong>
                  <span>{`${price.old.fixed_commission}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.monthlyCommission')}: </strong>
                  <span>{`${price.old.monthly_commission}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                </div>
                <div><strong>{t('price.new.ownersMonthPrice')}: </strong>
                  <span>{`${price.old.owners_monthly_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                </div>
              </Col>
              <Col span={12}>
                <div className=' mb-10'>
                  <strong>{t('deals.new.operation')}: </strong><span> {t('deals.new.dealOperation.rent')}</span>
                </div>
                <div className=' mb-10'><strong>{t('properties.new.monthlyPrice')}: </strong>
                  <span>{`${price.new.monthly_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.fixedCommission')}: </strong>
                  <span>{`${price.new.fixed_commission}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                </div>
                <div className=' mb-10'><strong>{t('price.new.monthlyCommission')}: </strong>
                  <span>{`${price.new.monthly_commission}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                </div>
                <div><strong>{t('price.new.ownersMonthPrice')}: </strong>
                  <span>{`${price.new.owners_monthly_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                </div>
              </Col>
            </Row>
          }
        </Col>
      </Row>
    </div>
  );
})

export default Item;
