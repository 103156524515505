// @flow
import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import LoginService from '../loginService';
import {
  Form, Input, Button, Row, Col
} from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from 'images/vym-logo.png';
import { useTranslation } from 'react-i18next';
import { LockOutlined } from "@ant-design/icons";
let UserLoginService = new LoginService();

interface ILoginAttributes {
  password: string,
  password_confirmation: string
}

const Choose: FC = observer(() => {
  const [confirmDirty, setConfirm] = useState(false);
  const location = useLocation();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const reset_password_token = location.search.slice(7);
    // form.validateFields((err: object, values: ILoginAttributes) => {
    //   if (!err) {
    //     UserLoginService.reset(values.password, values.password_confirmation, reset_password_token, () => navigate('/'));
    //   }
    // });
  }

  const handleConfirmBlur = (e: any) => {
    const { value } = e.target;
    setConfirm(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule: any, value: any, callback: Function) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(t('login.messagePassword'));
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule: any, value: any, callback: Function) => {
    if (value && confirmDirty) {
      // form.validateFields(['password_confirmation'], { force: true });
    }
    callback();
  };

  return (
    <div className='login-page page'>
      <Row justify='center'>
        <Col className='layout-content'>
          <Row className='layout-top'>
            <Row align='middle' justify='center'>
              <Col>
                <Link to='/' >
                  <img src={logo} className='d-ib'/>
                </Link>
              </Col>
              <Col>
                <span>{t('login.vimCrm')}</span>
              </Col>
            </Row>
            <p>{t('login.placeholder.setPassword')}</p>
          </Row>
          <Row className='login-main'>
            <Form className="set-password-form" onFinish={handleSubmit}>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('login.placeholder.password')
                  },
                  {
                    validator: validateToNextPassword,
                  },
                ]}
              >
                <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('login.password')} />
              </Form.Item>
              <Form.Item hasFeedback>
                <Input.Password onBlur={handleConfirmBlur} prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Repeat Password" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" size='large' htmlType="submit" className='w-100p'>
                  {t('login.setPassword')}
                </Button>
              </Form.Item>
            </Form>
            <div className='ta-c'>
              <a onClick={() => { navigate('/')}} className='link'>
                {t('login.logIn')}
              </a>
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );

});

export default Choose;
