import { FC, useState, useEffect} from 'react';
import { observer } from 'mobx-react';
import { some, startCase, toLower } from 'lodash';
import { sourceService } from 'services/sourceService';
import { contactService } from 'services/ContactService';
import SourceStore from 'stores/source';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Form, Button, Input, Select, Row, Col, message } from 'antd';
import { CloseOutlined, PlusOutlined, HomeOutlined, UserOutlined, SolutionOutlined } from "@ant-design/icons";
import { LANGUAGES } from "constants/mixed"

import { keys } from 'lodash';

import { IContact } from "declarations/contact";
import { ISource } from "declarations/source";

interface IData {
  contact?: IContact,
  onCreateContact?(params: IContact): void;
  onCancelButton(): void;
}

interface IContactInfo {
  id?: null | number,
  value?: string,
  _destroy?: boolean
}

const { Option, OptGroup } = Select;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const formItemRow = {
  wrapperCol: { sm: {span: 18, offset: 6} }
};
const InitialState = {
  contact: {
    id: null,
    contact_type: '0',
    name: '',
    website: '',
    address: '',
    contact_person: '',
    nie: '',
    source_id: undefined,
    languages: [],
    other_languages: [],
    phones_attributes: [{ value: '' }],
    emails_attributes: [{ value: '' }]
  }
}
const ContactForm:  FC<IData> = observer(({contact, onCancelButton, onCreateContact}) => {

  const [state, setState] = useState<any>({...InitialState});
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { contact_type } = state.contact;
  const [messageApi, contextHolder] = message.useMessage();


  useEffect(() => {
    setState({...InitialState})
    form.resetFields();
    if (contact) {
      form.setFieldsValue({ ...contact, contact_type: String(contact.contact_type) })
      setState({
        ...state,
        contact: {
          ...state.contact,
          ...contact
        }
      })
    }
    sourceService.loadResources()
    return () => {
      form.resetFields()
      setState(InitialState)
    }
  }, [])

  const handleSubmit = async () => {
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => errors.length)) {
      const values = form.getFieldsValue();
      const contactValue: IContact = { ...state.contact, ...values };
      contactValue['emails_attributes'] = contactValue['emails_attributes'].filter((item: { value: string }) => item.value)
      contactValue['phones_attributes'] = contactValue['phones_attributes'].filter((item: { value: string }) => item.value)
      if (state.contact.id) {
        await contactService.updateResourceRequest(contactValue).then((resp) => {
          contactService.addSelectedValue(resp.data)
          messageApi.success('Contact updated!')
          onCancelButton();
        }).catch(({response}) => messageApi.error((response.data.message || []).join()))
        return
      }

      if (onCreateContact && !state.contact.id) {
        onCreateContact(contactValue)
      }
    }
  }

  const removeContactInfo = (attributesTitle: string, elIndex: number) => {
    const contact: any = state.contact;
    const itemObject = contact[attributesTitle][elIndex];

    if (itemObject.id) {
      const newItem = { ...itemObject, _destroy: 'true' }
      const attributesArray = contact[attributesTitle].map((item: IContactInfo) => item.id == itemObject.id ? newItem : item)

      setState({
        ...state,
        contact: {
          ...state.contact,
          [attributesTitle]: attributesArray
        }
      })
    } else {
      const newItems = contact[attributesTitle].filter((item: IContactInfo, index: number) => index != elIndex);
      setState({
        ...state,
        contact: {
          ...state.contact,
          [attributesTitle]: newItems
        }
      })
    }
  }

  const addContactInfo = (type: string) => {
    const contact: any = state.contact;
    const attributesTitle = `${type}s_attributes`;
    setState({
      ...state,
      contact: {
        ...state.contact,
        [attributesTitle]: [...contact[attributesTitle], ...[{ id: null, value: '' }]]
      }
    })
  }

  const handleInfoChange = (e: any, field: string, index: number) => {
    const contact: any = state.contact;
    const value = e.target.value;
    const newValue = { ...contact[field][index], value: value }
    setState({
      ...state,
      contact: {
        ...state.contact,
        [field]: contact[field].map((item: IContactInfo, indexItem: number) => index == indexItem ? newValue : item)
      }
    })
  }

  const onTypeChange = (value: string) => {
    setState({
      ...state,
      contact: {
        ...state.contact,
        contact_type: value
      }
    })
  }

  const renderContactInfo = (type: string) => {
    const contact: any = state.contact;
    const attributesTitle = `${type}s_attributes`;
    const lableTitle = startCase(toLower(type));

    return contact[attributesTitle].map((item: IContactInfo, index: number) => {
      if (item._destroy) {
        return;
      }
      return (
        <Form.Item
          {...formItemLayout}
          label={index == 0 ? (lableTitle + " :") : ' '}
          className={classNames({ 'more-contact-items': index != 0 })}
          colon={false}
        >
          <Row align='middle' className='fx-nw'>
            <Input value={item.value} placeholder={lableTitle} type={type} onChange={(e) => handleInfoChange(e, attributesTitle, index)} />
            <CloseOutlined className='ml-10' onClick={() => removeContactInfo(attributesTitle, index)} />
          </Row>
        </Form.Item>
      )
    })
  }

  const renderSources = () => {
    const sources = SourceStore.groupedValues;
    const sourceKeys = keys(sources)

    return sourceKeys.map((category: string) => {
      return <OptGroup key={`source_${category}`} label={category}>
        {sources[category].map((source: ISource) => <Option value={source.id} key={`source_${source.id}`}>{source.name}</Option>)}
      </OptGroup>
    })
  }

  return (
    <div>
      {contextHolder}
      <div className="property-steps-container">
        <Form form={form}>
          <Form.Item
            {...formItemLayout}
            label={t('contacts.filter.type')}
            name="contact_type"
            initialValue="0"
          >
            <Select onChange={onTypeChange} value={String(state.contact?.contact_type || "")}>
              <Option value="0"><UserOutlined className='mr-5' />{t('contacts.new.type.person')}</Option>
              <Option value="1"><HomeOutlined className='mr-5' />{t('contacts.new.type.company')}</Option>
              <Option value="2"><SolutionOutlined className='mr-5' />{t('contacts.new.type.agency')}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={t('contacts.new.name')}
            name="name"
            rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
          >
            <Input placeholder={t('contacts.new.name')} value={state.contact?.name} />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({getFieldValue}) => {
              const isNotPerson = getFieldValue("contact_type") !== "0";
              return isNotPerson &&
                <Form.Item
                  {...formItemLayout}
                  label={t('contacts.new.website')}
                  name="website"
                >
                  <Input placeholder={t('contacts.new.website')} />
                </Form.Item>
            }}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({getFieldValue}) => {
              const isNotPerson = getFieldValue("contact_type") !== "0";
              return isNotPerson &&
                <Form.Item
                  {...formItemLayout}
                  label={t('contacts.new.address')}
                  name="address"
                >
                  <Input placeholder={t('contacts.new.address')} value={state.contact?.address} />
                </Form.Item>
            }}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({getFieldValue}) => {
              const isNotPerson = getFieldValue("contact_type") !== "0";
              return isNotPerson &&
                <Form.Item
                  {...formItemLayout}
                  label={t('contacts.new.contactPerson')}
                  name="contact_person"
                >
                  <Input placeholder={t('contacts.new.contactPerson')} value={state.contact.contact_person} />
                </Form.Item>
            }}
          </Form.Item>
          {renderContactInfo('email')}
          <Form.Item
            {...formItemRow}
          >
            <a className='add-item-link' onClick={() => addContactInfo('email')}>
              <PlusOutlined className='mr-5' />
              {t('contacts.new.email')}
            </a>
          </Form.Item>
          {renderContactInfo('phone')}
          <Form.Item
            {...formItemRow}
          >
            <a className='add-item-link' onClick={() => addContactInfo('phone')}>
              <PlusOutlined className='mr-5' />
              {t('contacts.new.phone')}
            </a>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="languages"
            label={t('contacts.new.mainLang')}
            initialValue={[]}
          >
            <Select mode="multiple">
              {LANGUAGES.map((lng: string) => (
                <Option key={`languages-${lng}`} value={lng}>{t(`contacts.new.lng.${lng}`)}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('contacts.new.otherLang')}
            name="other_languages"
          >
            <Select mode="multiple">
              {LANGUAGES.map((lng: string) => (
                <Option key={`other_languages-${lng}`} value={lng}>{t(`contacts.new.lng.${lng}`)}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('contacts.new.nie')}
            name="nie"
          >
            <Input placeholder={t('contacts.new.nie')} />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={t('contacts.new.source')}
            name="source_id"
            rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
          >
            <Select
              placeholder={t('contacts.new.placeholderSource')}
              showSearch
              filterOption={(input: any, option: any) => typeof(option.props.children) == 'string' && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {renderSources()}
            </Select>
          </Form.Item>
        </Form>
        <Row>
          <Col sm={{ offset: 6 }}>
            <Button type='primary' className='mr-15' onClick={handleSubmit}>{t('save')}</Button>
            <Button onClick={onCancelButton}>{t('cancel')}</Button>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default ContactForm;
