import {FC, useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment';
import { EditOutlined, DownOutlined, EllipsisOutlined, DeleteOutlined, BookOutlined, MailOutlined, ProfileOutlined, FileAddOutlined } from "@ant-design/icons";
import classNames from 'classnames';
import { Button, Row, Col, Modal, Menu, Dropdown } from 'antd';
import NoteForm from 'submodules/notes/components/Form';
import TaskForm from 'submodules/tasks/components/Form';
import FileForm from 'submodules/files/components/Form';
import EmailForm from 'submodules/emails/components/Form';
import { Helmet } from 'react-helmet';
import loginStore from 'modules/login/loginStore';
import ownerStore from 'stores/owner';

import { IOwner } from 'declarations/contact';
import { CONTACT_TYPES } from 'constants/mixed';
import { getContactIcon } from "modules/owners/containers/List";
import NoAccess from "modules/NoAccess";

const { confirm } = Modal;

const Single: FC = observer((props) => {

  const[showModal, setShowModal] = useState<string | undefined>(undefined);
  const { t } = useTranslation();
  const location = useLocation();
  const owner: any = ownerStore && ownerStore.selected;
  const user = loginStore.session.user.user;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    ownerStore.fetchOwner(Number(id))
  }, [])

  const handleCancel = () => {
    setShowModal(undefined)
  }

  const updateShowModal = (type: string) => {
    setShowModal(type);
  }

  const showConfirm = (owner: IOwner) => {

    confirm({
      title: t('owners.checkDelete'),
      content: '',
      onOk: async () => {
        await ownerStore.deleteOwner(owner.id)
        navigate('/owners')
      },
    });
  }

  const returnHeaderMenuAction = () => {
    return <Menu
      mode="horizontal"
      defaultSelectedKeys={[]}
      className='actons-menu'
    >
      <Menu.Item key={`edit_property_${owner.id}`}>
        <Link className='edit-title' to={`/owners/${owner.id}/edit`}>
          <EditOutlined className='mr-5' />
          {t('properties.table.edit')}
        </Link>
      </Menu.Item>
      {loginStore.isAdmin &&
        <Menu.Item key={`delete_contact_${owner.id}`} onClick={() => showConfirm(owner)}>
          <span className='delete-title'>
            <DeleteOutlined className='mr-5' />
            {t('owners.table.delete')}
          </span>
        </Menu.Item>
      }
    </Menu>
  }

  const modalContent = () => {
    const owner: any = ownerStore && ownerStore.selected;
    if (!showModal) {
      return ''
    }

    switch (showModal) {
      case 'note':
        return <NoteForm onCancelButton={handleCancel} resource={owner} resource_type='Owner' />;
      case 'task':
        return <TaskForm onCancelButton={handleCancel} resource={owner} resource_type='Owner' />;
      case 'file':
        return <FileForm onCancelButton={handleCancel} resource={owner} resource_type='Owner' />;
      case 'email':
        return <EmailForm onCancelButton={handleCancel} contact={owner} contactType="Owner" />;
      default:
        return;
    }
  }

  const returnAddNewMenuAction = () => {
    return <Menu
      mode="horizontal"
      defaultSelectedKeys={[]}
      className='actons-menu'
    >
      <Menu.Item key={`owner_${owner.id}_note`} onClick={() => updateShowModal('note')}>
        <Row align="middle"><BookOutlined className='mr-5 p-5' /> {t('tabs.note')}</Row>
      </Menu.Item>
      <Menu.Item key={`owner_${owner.id}_email`} onClick={() => updateShowModal('email')}>
        <Row align="middle"><MailOutlined className='mr-5' /> {t('tabs.email')}</Row>
      </Menu.Item>
      <Menu.Item key={`owner_${owner.id}_task`} onClick={() => updateShowModal('task')}>
        <Row align="middle"><ProfileOutlined className='mr-5' /> {t('tabs.task')}</Row>
      </Menu.Item>
      <Menu.Item key={`new_${owner.id}_file`} onClick={() => updateShowModal('file')}>
        <Row align="middle"><FileAddOutlined className='mr-5' /> {t('tabs.file')}</Row>
      </Menu.Item>
    </Menu>
  }


  if(!ownerStore.hasAcccess) {
    return <NoAccess link="/owners" />
  }

  if (!owner?.id) {
    return null
  }
  const createdAtDate = moment(owner.created_at).format('DD.MM.YYYY \\at HH:mm');

  return (
    <div className="contacts-page page single">
      <Modal
        title={t(`modal.add_${showModal}`)}
        visible={!!showModal}
        footer={null}
        width={'1000px'}
        onCancel={handleCancel}
        className='modal'
        maskClosable={false}
        destroyOnClose={true}
      >
        {modalContent()}
      </Modal>
      <Helmet>
        <title>{owner.name} - {CONTACT_TYPES[owner.owner_type.toString()]}</title>
      </Helmet>
      <Row className='page-subheader portlet'>
        <Row justify='space-between' align='middle' className='subheader-top'>
          <Col>
            <Row align='middle'>
              <Col className='avatar mr-25'>{getContactIcon(owner.owner_type)}</Col>

              <Col>
                <Row className='mb-5'>
                  <h4>{owner.name}</h4>
                </Row>
                <Row align='middle' gutter={30}>
                  <Col>{t('contacts.table.type')}: <span>{CONTACT_TYPES[owner.owner_type.toString()]}</span></Col>
                  <Col>{t('contacts.single.added')}: <span>{createdAtDate}</span></Col>
                  <Col>{t('contacts.new.source')}: <span>{owner.source && owner.source.name}</span></Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col>
            <Dropdown overlay={returnAddNewMenuAction} trigger={['click']}>
              <Button type='primary' size='large'>
              {t('price.newPrice')} <DownOutlined />
              </Button>
            </Dropdown>
            <Dropdown overlay={returnHeaderMenuAction()} trigger={['click']}>
              <Button type='primary' size='large' className='ml-25'>
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>

        <Row className="subheader-bottom">
          <Col span={24}>
            <ul className='subheader-navigation'>
              <li className={classNames({ active: location.pathname.includes('overview') })}>
                <Link to={`/owners/${owner.id}/overview`}>{t('contacts.single.overview')}</Link>
              </li>
              <li className={classNames({ active: location.pathname.includes('owner') })}>
                <Link to={`/owners/${owner.id}/owner`}>{t('contacts.single.owner')} ({owner.properties_count})</Link>
              </li>
              { (loginStore.isAdmin || user.id == owner.manager_id) &&
                <li className={classNames({ active: location.pathname.includes('deals') })}>
                  <Link to={`/owners/${owner.id}/deals`}>{t('menu.deals')} ({owner.deals_count})</Link>
                </li>
              }
              <li className={classNames({ active: location.pathname.includes('rents') })}>
                <Link to={`/owners/${owner.id}/rents`}>{t('tabs.rent')}  ({owner.rents_count})</Link>
              </li>
              { (loginStore.isAdmin || user.id == owner.manager_id) &&
                <li className={classNames({ active: location.pathname.includes('requests') })}>
                  <Link to={`/owners/${owner.id}/requests`}>{t('tabs.requests')} ({owner.requests_count})</Link>
                </li>
              }
              <li className={classNames({ active: location.pathname.includes('notes') })}>
                <Link to={`/owners/${owner.id}/notes`}>{t('tabs.notes')} {owner.notes_count}</Link>
              </li>
              <li className={classNames({ active: location.pathname.includes('emails') })}>
                <Link to={`/owners/${owner.id}/emails`}>{t('tabs.emails')} ({owner.emails_count})</Link>
              </li>
              <li className={classNames({ active: location.pathname.includes('tasks') })}>
                <Link to={`/owners/${owner.id}/tasks`}>{t('tabs.tasks')} {owner.tasks_count}</Link>
              </li>
              <li className={classNames({ active: location.pathname.includes('files') })}>
                <Link to={`/owners/${owner.id}/files`}>{t('tabs.files')} {owner.files_count}</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Row>
    </div>
  );
})

export default Single;
