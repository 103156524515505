import { SALE_VALUE, RENT_VALUE, TRASPASO_VALUE } from 'constants/mixed'

export const BLOCKED_TYPES: any = {
  'idealista.com': [],
  'fotocasa.es': [
    'Garage / storage room', 'New construction', 'Traspaso', 'Trapaso - cafe / bar', 'Trapaso - Shop',
    'Trapaso - Salon', 'Trapaso - Other', 'Business with space', 'Business with space - Cafe',
    'Business with space - Shop', 'Business with room - Salon', 'Business with space - Other', 'Building',
    'Buildings - Investment up to 1 million', 'Buildings - Investment over 1 million', 'Office'
  ],
  'kyero.com': [],
  'pisos.com': [
    'New construction', 'Traspaso', 'Trapaso - cafe / bar', 'Trapaso - Shop', 'Trapaso - Salon',
    'Trapaso - Other', 'Business with space - Cafe', 'Business with space - Shop', 'Business with room - Salon',
    'Business with space - Other', 'Building', 'Buildings - Hotels', 'Buildings - Investment up to 1 million',
    'Buildings - Investment over 1 million'
  ],
  'rightmove.co.uk': [
    'Traspaso', 'Trapaso - Salon', 'Trapaso - Other', 'Business with space', 'Business with room - Salon',
    'Business with space - Other', 'Buildings - Investment up to 1 million', 'Buildings - Investment over 1 million'
  ],
  'prian.ru': ['Garage / storage room', 'New construction'],
  'intenerife.eu': []
}

export const MANDATORY_FIELDS: any = {
  'pisos.com': [
    { currentField: 'location_attributes', children: ['address', 'building'] },
    { currentField: 'detail_attributes', children: ['total_area'] }
  ],
  'idealista.com': [{ currentField: 'detail_attributes', children: ['bedroom_count', 'bathroom_count', 'total_area'] }],
  'fotocasa.es': [
    { currentField: 'detail_attributes', children: ['total_area'] },
    { currentField: 'location_attributes', children: ['lng', 'lat'] }
  ],
  'rightmove.co.uk': [{ currentField: 'detail_attributes', children: ['bedroom_count', 'total_area'] }]
}

export const ACCESS_OPERATION: any = {
  'idealista.com': [SALE_VALUE, RENT_VALUE, TRASPASO_VALUE],
  'fotocasa.es': [SALE_VALUE, TRASPASO_VALUE],
  'kyero.com': [SALE_VALUE, RENT_VALUE, TRASPASO_VALUE],
  'pisos.com': [SALE_VALUE, TRASPASO_VALUE],
  'rightmove.co.uk': [SALE_VALUE, TRASPASO_VALUE],
  'prian.ru': [SALE_VALUE, TRASPASO_VALUE],
  'intenerife.eu': [SALE_VALUE, TRASPASO_VALUE]
}
