import { groupBy } from 'lodash';
import { IComplex } from 'declarations/complex';
import { makeAutoObservable } from 'mobx';

export class ComplexStore {

  values: Array<IComplex> = [];
  count: number = 0
  groupedValues: any = {};
  fetchInProgress = false;
  saveInProgress = false;
  deleteInProgress = false;

  constructor() {
    makeAutoObservable(this);
  }

  addValues(values: Array<IComplex>, count: number): void {
    this.values = values;
    this.count = count;
    this.groupedValues = groupBy(values, 'region_id')
  }

  setFetchInProgress(val: boolean) {
    this.fetchInProgress = val;
  }
  setSaveInProgress(val: boolean) {
    this.saveInProgress = val;
  }
  setDeleteInProgress(val: boolean) {
    this.deleteInProgress = val;
  }
}

export default (new ComplexStore())