import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Select, DatePicker, Checkbox } from 'antd';
import { sourceService } from 'services/sourceService';
import { userListService } from 'modules/users/containers/UserListService';
import { contactService } from 'services/ContactService';
import contactStore from 'stores/contact';
import { keys, omit } from 'lodash';
import SourceStore from 'stores/source';
import UserStore from 'stores/user';
import { LANGUAGES } from "constants/mixed"

import { ISource } from "declarations/source";
import { IUser } from "declarations/user";

const { Option, OptGroup } = Select;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const formItemLayoutCheckbox = {
  labelCol: { span: 12 },
  wrapperCol: { span: 2, offset: 10 },
};

const DEFAUL_FILTER_VALUE = {
  contact_types: [],
  source_ids: [],
  languages: [],
  manager_ids: [],
  creation_date: [],
  owns_properties: undefined,
  has_tasks: false
}

const FilterCon: FC = observer(() => {
  const [filter, setFilter] = useState(DEFAUL_FILTER_VALUE);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    sourceService.loadResources();
    userListService.loadResources({}, 'all');
    return () => {
      contactStore.clearData();
      contactStore.resetFilter();
    }
  }, [])

  const resetFilters = () => {
    form.resetFields();
    setFilter(DEFAUL_FILTER_VALUE)
    contactService.resetFilters();
  }

  const getFieldChange = (value: any, name: string) => {
    const newFilter = { ...filter, [name]: value };
    setFilter(newFilter)
    const dates: any = filter.creation_date;
    let dataFilter = {'date_from':  null, 'date_to': null};
    if (dates) {
      dataFilter = { 'date_from':  dates[0], 'date_to': dates[1] };
    }

    contactService.changeFilters(omit({...newFilter, ...dataFilter}, "creation_date"))
  }

  const onFilterDate = async (dates: any) => {
    const newFilter = { ...filter, creation_date: dates };
    if (dates) {
      setFilter(newFilter);
      let dataFilter = { 'date_from':  dates[0], 'date_to': dates[1] }
      contactService.changeFilters(omit({...newFilter, ...dataFilter}, "creation_date"))
    } else {
      contactService.changeFilters(omit({...filter}, ["creation_date", "date_from", "date_to"]))
    }
  }

  const renderSources = () => {
    const sources = SourceStore.groupedValues;
    const sourceKeys = keys(sources)

    return sourceKeys.map((category: string) => {
      return <OptGroup key={`source_${category}`} label={category}>
        {sources[category].map((source: ISource) => <Option value={source.id} key={`source_${source.id}`}>{source.name}</Option>)}
      </OptGroup>
    })
  }

  const renderUsers = () => {
    const users = UserStore.values;
    return (users || []).map((user: IUser) => <Option value={user.id} key={`user_${user.id}`}>{user.name}</Option>)
  }

  const renderOwnsProperties = () => {
    let selectArray = [];
    for (let n = 1; n < 21; n++) {
      selectArray.push(<Option value={n} key={`own_${n}`}>{n}</Option>);
    }
    return selectArray;
  }

  return (
    <Form className='filter-form' form={form}>
      <Form.Item
        {...formItemLayout}
        label={t('contacts.filter.type')}
        name="type"
      >
        <Select
          showArrow={true}
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          showSearch
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'contact_types')}
          placeholder={t('placeholder.all')}
        >
          <Option value="0" key='contact_types_0'>{t('contacts.new.type.person')}</Option>
          <Option value="1" key='contact_types_1'>{t('contacts.new.type.company')}</Option>
          <Option value="2" key='contact_types_2'>{t('contacts.new.type.agency')}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('contacts.new.source')}
        name="source"
      >
        <Select
          showArrow={true}
          mode='multiple'
          onChange={(value: any) => getFieldChange(value, 'source_ids')}
          placeholder={t('placeholder.all')}
          filterOption={(input: any, option: any) => typeof(option.props.children) == 'string' && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          showSearch
        >
          {renderSources()}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('contacts.new.language')}
        name="language"
      >
        <Select
          showArrow={true}
          onChange={(value: any) => getFieldChange(value, 'languages')}
          mode='multiple'
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          placeholder={t('placeholder.all')}
        >
          {LANGUAGES.map((lng: string) => (
            <Option key={`languages-${lng}`} value={lng}>{t(`contacts.new.lng.${lng}`)}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('contacts.new.manager')}
        name="manager"
      >
        <Select
          showArrow={true}
          mode='multiple'
          showSearch
          filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value: any) => getFieldChange(value, 'manager_ids')}
          placeholder={t('placeholder.all')}
        >
          {renderUsers()}
          <Option value="not_assigned" key='manager_none'>{t('contacts.new.notAssigned')}</Option>
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('contacts.new.creationDate')}
        name="creation_date"
      >
        <DatePicker.RangePicker
          format="DD.MM.YYYY"
          onChange={onFilterDate}
          className="w-100p"
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label={t('contacts.new.ownsProperties')}
        name="owns_properties"
      >
        <Select
          showArrow={true}
          onChange={(value: any) => getFieldChange(value, 'owns_properties')}
          placeholder={t('placeholder.any')}
        >
          {renderOwnsProperties()}
        </Select>
      </Form.Item>
      <Form.Item
        {...formItemLayoutCheckbox}
        label={t('contacts.filter.placeholderHasTask')}
        colon={false}
        name="has_tasks"
        valuePropName='checked'
      >
        <Checkbox onChange={(e: any) => getFieldChange(e.target.checked, 'has_tasks')} />
      </Form.Item>
      <Form.Item>
        <Button type='primary' onClick={resetFilters} style={{ width: '100%' }}>{t('deals.filter.resetFilter')}</Button>
      </Form.Item>
    </Form>
  )
});

export default FilterCon;