import { action } from 'mobx';
import axios from 'axios';
// import { request, action } from 'utils';
import  regionStore  from '../stores/region';
import  workspaceStore  from '../stores/workspace';
import { REGION_URL } from 'constants/api';
import { IRegion } from "declarations/region";

interface FilterType {

};

declare interface Props {
  loadResources(params?: FilterType, pageNum?: number): any,
  loadResourcesRequest(queryParams: {}): Promise<object>
}

export class RegionsService implements Props {

  // LOAD resourceS
  async loadResourcesRequest() {
    return axios.get(REGION_URL, { params: {workspace_id: workspaceStore.selectedId }})
  }

  async loadResources(callback?: Function) {
    // let { filters, page } = (ownerListStore || {});
    // if(regionStore.values.length > 0) {
    //   return;
    // }

    const response: { status: number, data: Array<IRegion>} = await this.loadResourcesRequest();

    if (response.status == 200) {
      let values = [];
      values = response.data;
      if(callback){
        callback(values)
      }

      this.addValues(values);
    }

    return response;
  }

  addValues(values: Array<IRegion>) {
    regionStore.addValues(values);
  }
}

export const regionsService = new RegionsService();
