import { FC, useEffect, useState, useCallback} from 'react';
import { observer} from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ManagerModal from 'modules/clients/containers/Single/components/managerModal';
import clientStore from 'stores/client';
import workspaceStore from 'stores/workspace';
import { Link } from 'react-router-dom';
import { debounce, isEmpty, uniqueId } from 'lodash';
import loginStore from 'modules/login/loginStore';
import { Table, Button, Modal, Dropdown, Input, Divider } from 'antd';
import { CONTACT_TYPES } from 'constants/mixed'
import { EditOutlined, DeleteOutlined, EllipsisOutlined, HomeOutlined, UserOutlined, SolutionOutlined } from "@ant-design/icons";
import { renderMenu } from "utils/menu";

import FilterClients from '../../components/Filter/FilterContacts';
import DateByCreator from 'components/DateByCreator';
import ListContainer from 'modules/ListContainer';
import { Helmet } from 'react-helmet';
import { IClient } from 'declarations/contact';

interface IData {
  contact?: IClient;
}

interface IClientResources {
  id?: number;
  value: string
}

// const confirm = Modal.confirm;

export const getContactIcon = (type: number, className?: string) => {
  switch (type) {
    case 0:
      return <UserOutlined className={className} />
    case 1:
      return <HomeOutlined className={className} />
    case 2:
      return <SolutionOutlined className={className} />
    default:
      return;
  }
}


const List: FC<IData> = observer(() => {

  const [search, setSearch] = useState('');
  const [modalClient, setClient] = useState<IClient | false>(false);
  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    clientStore.fetchClients()
    return () => {
      clientStore.clear();
      setSearch("");
    }
  }, [workspaceStore.selectedId])

  const columns = [
      {
        title: t('clients.table.type'),
        dataIndex: 'contact_type',
        key: 'contact_type',
        width: 130,
        render: (type: keyof typeof CONTACT_TYPES, client: IClient) => (
          <div>{getContactIcon(client.client_type, "mr-10")}
          <span>{CONTACT_TYPES[client.client_type]}</span>
        </div>
        )
      }, {
        title: t('clients.table.name'),
        dataIndex: 'name',
        width: 200,
        key: 'name',
        render: (name: string, client: IClient) => renderName(client)
      },
      {
        title: t('menu.deals'),
        dataIndex: 'deals',
        width: 60,
        key: 'deals',
        render: (_: string, client: IClient) => renderDealsContent(client)
      },
      {
        title: t('manager'),
        dataIndex: 'manager',
        key: 'manager',
        render: (_: string, client: IClient) => renderManagerContent(client)
      },
      {
        title: t('clients.table.language'),
        dataIndex: 'languages',
        width: 100,
        key: 'languages',
        render: (languages: string[], client: IClient) => (!isEmpty(languages) || !isEmpty(client.other_languages)) && <div className='languages-list ws-nw'>
          <span className=''>{renderLanguages(languages)}</span>
          <Divider />
          {(client.other_languages || []).length > 0 && <span>{renderLanguages(client.other_languages)}</span>}
        </div>
      }, {
        title: t('clients.table.created_at'),
        dataIndex: 'created_at',
        width: 130,
        key: 'created_at',
        render: (_: string, client: IClient) => <DateByCreator resource={client} />
      }, {
        title: '',
        dataIndex: 'actions',
        width: 60,
        key: 'id',
        render: (name: string, client: IClient) => <Dropdown menu={{ items: renderMenu(getActionComponent(client)) }} trigger={['click']}>
          <div className='list-dropdown-button'><EllipsisOutlined className='three-dots' /></div>
        </Dropdown>
      }
    ];

  const renderName = (client: IClient) => {
    return (
      <div>
        <p><Link target="_blank" to={`/clients/${client.id}/overview`} className='link'>{client.name}</Link></p>
        {(client.phones_attributes || []).map((phone: IClientResources) => <p key={uniqueId()}>{phone.value}</p>)}
        {(client.emails_attributes || []).map((email: IClientResources) => <p key={uniqueId()}>{email.value}</p>)}
      </div>
    )
  }

  const renderDealsContent = (client: IClient) => {
    if(!loginStore.isAdmin) {
      return (loginStore.session.user.user.id == client.manager_id && client.manager) ? client.deals_count : "-";
    }

    return client.deals_count;
  }

  const renderManagerContent = (client: IClient) => {
    let managerContent;

    if(!loginStore.isAdmin && !loginStore.isGeneralManager) {
      return (loginStore.session.user.user.id == client.manager_id && client.manager) ? client.manager.name : "-";
    }


    if (client.manager_id && client.manager) {
      managerContent = <a onClick={() => setShowModal(client)} className='link'>
        {/* Doesn't render without manager.name */}
        <span className='mr-5'>{client.manager && client.manager.name} </span>
        <EditOutlined />
      </a>
    } else {
      managerContent = <a onClick={() => setShowModal(client)} className='mr-5 link'>{t('addManager')}</a>
    }
    return managerContent;
  }

  const renderLanguages = (languages: Array<string>) => {
    return (languages || []).map((language: string) =>
      <img key={uniqueId()} className='flag' src={require(`../../../../images/flags/${language}.png`)} />
    )
  }

  const showConfirm = (client: IClient) => {
    modal.confirm({
      title: t('clients.checkDelete'),
      content: '',
      onOk: async () =>  {
        await clientStore.deleteClient(client.id)
        clientStore.fetchClients();
      },
    });
  }

  const getActionComponent = (client: IClient) => {

    return [
      {
        key:`edit_${client.id}`,
        label: <Link to={`/clients/${client.id}/edit`} className='edit-title'>
          <EditOutlined className='mr-5' />
          {t('clients.table.edit')}
        </Link>
      },
      {
        key:`delete_${client.id}`,
        label: <div onClick={() => showConfirm(client)}>
          <span className='delete-title'>
            <DeleteOutlined className='mr-5' />
            {t('clients.table.delete')}
          </span>
        </div>,
        disabled: !(loginStore.isAdmin || loginStore.isGeneralManager)
      }
    ]
  }

  const handleCancel = () => setClient(false);

  const setShowModal = (client: IClient) => setClient(client);

  const handleOk = async (values: { manager_id: number }) => {
    if (modalClient) {
      const client: any = modalClient;
      const value = { id: client.id, ...values }
      await clientStore.updateClient(client.id, value)
      setClient(false);
    }
  }

  const setSearchText = useCallback(debounce((value) => {
    clientStore.updateFilter({ q: value })
    clientStore.fetchClients({}, 1)
    }, 500),
    []
    )

  const onSearch = (e: any) => {
    const value = e.target.value;
    setSearch(value)
    setSearchText(value);
  }

  const changePagination = (page: number) => {
    clientStore.setPage(page)
    clientStore.fetchClients(clientStore.filterKeys, page);
  }

  const exportClients = () => {
    clientStore.export()
  }


  const showModal: any | IClient = modalClient;

  if (!(clientStore && clientStore.values)) {
    return null;
  }

  const paginationProps = {
    pageSize: 10,
    onChange: changePagination,
    total: clientStore.count,
    showSizeChanger: false,
    current: typeof clientStore.page == "number" ? clientStore.page : 1,
  }

  return (
    <ListContainer
      title={<span>{`${t('clients.header')} (${clientStore.count})`}</span>}
      sidebar={<FilterClients />}
      loading={clientStore.fetchInProgress}
      extra={
        <>
          <Input.Search
            placeholder="Search ..."
            value={search}
            onChange={onSearch}
            size="large"
            className='h-100p'
            allowClear={true}
          />
          {(loginStore.isAdmin || loginStore.isGeneralManager) && <Button
            type='primary'
            size='large'
            loading={clientStore.exportInProgress}
            onClick={() => exportClients()}>
              {t('clients.export')}
          </Button>}
          <Link to='/clients/new'><Button type='primary' size='large'>{t('clients.addClient')} </Button></Link>
        </>
      }


    >
      <Modal
        title={t('clients.addManager')}
        className='modal'
        open={!!modalClient}
        footer={null}
        onCancel={handleCancel}
        maskClosable={false}
        destroyOnClose
      >
        <ManagerModal
          manager_id={showModal && showModal.manager_id}
          onSelectManager={handleOk}
          handleCancel={handleCancel}
        />
      </Modal>
      <Helmet>
        <title>{t("clients.header")}</title>
      </Helmet>
      <Table
        dataSource={clientStore.values}
        columns={columns}
        bordered
        loading={clientStore.fetchInProgress}
        pagination={paginationProps}
        className='general-table'
      />
      {contextHolder}
    </ListContainer>
  );
});

export default List;
